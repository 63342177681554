import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';

export const determineCustomAlertMethod = (textChecked, emailChecked) => {
  if (textChecked && emailChecked) {
    return alertMethodConstants.SMS_AND_EMAIL;
  } else if (emailChecked) {
    return alertMethodConstants.EMAIL;
  } else if (textChecked) {
    return alertMethodConstants.SMS;
  } else {
    return null;
  }
};
