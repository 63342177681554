import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useState, useEffect } from 'react';
import useEditAlertMutation from 'legacy/features/alerts/services/reactQuery/mutations/useEditAlertMutation';
import { Checkbox } from 'legacy/shared/v1/styles/components/Checkbox';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';
import { CheckboxTextSpan } from 'legacy/shared/v1/styles/components/Form';
import {
  MuteAlertsCheckboxWrapperDiv,
  TestModeModalWrapper,
} from 'legacy/shared/v1/styles/components/ManageAlerts';
import { BoldedItem, ModalText } from 'legacy/shared/v1/styles/components/Modal';

import GenericFormModal from 'legacy/shared/v1/ui/modals/GenericFormModal';
import useAlertsSummaryProviderContext from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/hooks/useAlertsSummaryProviderContext';

export const EnableTestModeModal = ({
  modalData: { show, alert, testModeEnabled, testModeMuteAlertsOption },
  handleCloseModal,
}) => {
  const {
    queryKeys: { alertsQueryKey },
  } = useAlertsSummaryProviderContext();
  const mutation = useEditAlertMutation();
  const actionVerb = testModeEnabled ? 'Disable' : 'Enable';
  const queryClient = useQueryClient();
  const [testModeMuteAlerts, setTestModeMuteAlerts] = useState(testModeMuteAlertsOption);

  useEffect(() => {
    setTestModeMuteAlerts(testModeMuteAlertsOption);
  }, [alert]);

  const getCheckboxText = () => {
    return testModeMuteAlertsOption && alert?.alertDetails?.alertInfo?.testModeEnabled
      ? 'Notifications are currently muted'
      : 'Mute all notifications';
  };

  return (
    show && (
      <GenericFormModal
        customWidth={480}
        title={`${actionVerb} Test Mode`}
        initialValues={{
          muteAlerts: false,
        }}
        submitHandler={async () => {
          try {
            alert.alertDetails.alertInfo.testModeEnabled =
              !alert?.alertDetails?.alertInfo.testModeEnabled;
            alert.alertDetails.alertInfo.testModeMuteAlerts = testModeMuteAlerts;
            let result = await mutation.mutateAsync({
              alert: { ...alert },
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            await queryClient.invalidateQueries({ queryKey: alertsQueryKey });
            handleCloseModal();
          }
        }}
        handleCloseModal={handleCloseModal}
        errorMessage={null}
        submitButtonText={actionVerb}
      >
        {(formikProps) => (
          <TestModeModalWrapper>
            <ModalText>
              Enabling Test Mode will allow you to perform routine hardware testing for critical
              alerts such as alerts that rely on the K9 Temp Critical Sensor.
            </ModalText>
            <ModalText>
              When Test Mode is enabled, all alerts and logs will be prefaced by “TEST MODE”.
              Additionally, you can choose to mute all notifications, resulting in only logs being
              created for this alert while Test Mode is enabled. When Test Mode is disabled, all
              notificaions will resume.
            </ModalText>
            <MuteAlertsCheckboxWrapperDiv
              title={
                alert?.testModeEnabled ? 'Cannot edit this option while Test Mode is enabled' : ''
              }
            >
              <Checkbox singleCheck disabled={alert?.alertDetails?.alertInfo?.testModeEnabled}>
                <CheckboxInner
                  isChecked={testModeMuteAlerts}
                  onClick={() => {
                    setTestModeMuteAlerts(!testModeMuteAlerts);
                  }}
                />
              </Checkbox>
              <CheckboxTextSpan>{getCheckboxText()}</CheckboxTextSpan>
            </MuteAlertsCheckboxWrapperDiv>
            <ModalText>
              Do you really want to {actionVerb} Test Mode for this alert:
              <br />
              <BoldedItem> {alert?.alertDetails?.alertInfo?.alertName}?</BoldedItem>
            </ModalText>
          </TestModeModalWrapper>
        )}
      </GenericFormModal>
    )
  );
};

export default EnableTestModeModal;
