/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';

export const GeofenceMiniCardContainerDiv = styled.div`
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
  display: grid;
  grid-template-areas: 'Col Col Col';
  grid-template-columns: 50px 240px 30px;
  grid-gap: 0px;
  margin-bottom: 20px;
  padding: 5px 5px 5px 0px;

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${colors.fog};
    border: 2px solid #8a909c;
  `};
`;

export const GeofenceName = styled.h6`
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.midnight};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 240px;
`;

export const GeofenceLastEntered = styled.h6`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.midnight};
`;

export const GeofenceDetailDiv = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;

export const GeofenceCardListWrapperDiv = styled.div`
  width: 100%;
  margin-right: 20px;
`;

export const GeofenceCardOuterWrapperDiv = styled.div`
  width: 380px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: auto;
  right: 11px;
  top: 115px;

  @media ${device.large} {
    width: 750px;
  }
`;

export const GeofenceCardContainerDiv = styled.div`
  padding: 20px 5px 15px 25px;
  max-height: 500px;
  overflow-y: scroll;
  @media ${device.large} {
    overflow-y: visible;
    max-height: 1000px;
    padding: 15px 5px 15px 25px;
  }
`;

export const FlexWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;

  @media ${device.large} {
    margin-bottom: 10px;
  }
`;

export const NameTypeField = styled.div`
  width: 180px;
  margin-right: 20px;
`;

export const ActiveTimeOptionDiv = styled.div`
  width: 120px;
  margin-right: 20px;
`;

export const EndDateDiv = styled.div`
  width: 190px;
  margin-right: 20px;
  margin-top: 2px;
  margin-bottom: 35px;

  @media ${device.large} {
    width: 135px;
    margin-bottom: 0px;
    margin-top: -4px;
  }
`;

export const TimeDiv = styled.div`
  width: 300px;
  margin-bottom: 35px;
  margin-right: 20px;
  margin-top: 3px;

  @media ${device.large} {
    width: 120px;
    margin-bottom: 0px;
    margin-top: -3px;
  }

  ${(props) =>
    props.expiresAtEndTime &&
    `
    margin-top: 10px;
    @media ${device.large} {
      margin-top: -1px;
  }
  `};
`;

export const DurationDiv = styled.div`
  width: 300px;
  margin-right: 20px;
  margin-top: -1px;
  margin-bottom: 0px;

  @media ${device.large} {
    width: 100px;
    margin-bottom: -21px;
  }
`;

export const TimeZoneDiv = styled.div`
  width: 135px;
  margin-right: 20px;
  margin-bottom: 5px;
`;

export const ExpiresAtDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;

  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const RangeDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 25px;

  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const DstDiv = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 30px;

  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const DayRangeOptionsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const CheckboxDayLabelSpan = styled.span`
  font-size: 14px;
  margin-left: 5px;
  margin-top: -2px;
  margin-right: 30px;
`;

export const OtaUpdateOptionsDiv = styled.div`
  margin-top: 20px;
`;

export const OtaUpdateCheckboxDiv = styled.div`
  margin-top: 10px;
`;

export const GeofenceInfoDiv = styled.div`
  margin-top: 20px;
`;

export const LastSeenInfoDiv = styled.div`
  margin-top: -5px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;

  @media ${device.large} {
    width: 550px;
  }
`;

export const GeofencesDrawHint = styled.div`
  display: inline-block;
  padding: 10px 0px 10px 10px;
  width: 180px;
  height: 52px;
  background-color: ${colors.midnight};
  position: absolute;
  top: 60px;
  right: 40px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 400;
  color: ${colors.cotton};
  ${(props) =>
    props.hideGeofenceHint &&
    `
      display:none;
  }

  `}
`;

export const GotItButton = styled.button`
  margin-top: 8px;
  margin-right: 10px;
  float: right;
  border: none;
  background: transparent;
  color: ${colors.cotton};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const GeofenceCategoryHeaderDiv = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol';
  grid-template-columns: 20px 200px;
  grid-gap: 10px;
  margin-bottom: 15px;
`;

export const GeofenceCategoryDiv = styled.div`
  margin-bottom: 20px;
  display: none;

  ${(props) =>
    props.isExpanded &&
    `
    display: block;
  `};
`;

export const CategoryToggleDiv = styled.div`
  cursor: pointer;
`;

export const SimpleRangeDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 65px;
`;

export const GeofencesCategoryFiltersDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 180px;
`;

export const IntersectionsFiltersDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 60px;
`;

export const GeofenceValidationDiv = styled.div`
  color: ${colors.cherry};
`;

export const UnsavedChangesWrapperDiv = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;
`;
export const UnsavedChangesDiv = styled.div`
  font-size: 12px;
  font-weight: 700;

  @media ${device.large} {
    float: right;
  }
`;

export const EndTimeOptionDiv = styled.div`
  margin-top: -8px;

  @media ${device.large} {
    margin-top: -1px;
  }
`;

export const GeofenceActvityItemWrapperDiv = styled.div`
  display: grid;
  grid-template-areas: 'Col Col ';
  grid-template-columns: 10px 300px;
  grid-gap: 0px;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 700;
`;

export const ActivityDot = styled.div`
  width: 7px;
  height: 7px;
  margin-top: 7px;
  background: ${colors.midnight};
  border-radius: 50%;
`;

export const GeofenceActvityItemDetail = styled.div`
  display: grid;
  grid-template-rows: 15px 15px;
  grid-gap: 0px;
  margin-top: 5px;
`;

export const GeofenceExpiredMessage = styled.div`
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.cherry};
`;

export const DrawMode = styled.div`
  display: inline-block;
  padding: 10px 0px 10px 10px;
  width: 153px;
  height: 32px;
  background-color: ${colors.midnight};
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 700;
  color: ${colors.cotton};
`;

export const GeofenceCardCollapsedName = styled.div`
  margin-top: -15px;
  margin-left: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  @media ${device.large} {
    font-size: 14px;
  }
`;

export const UnsavedChanges = styled.span`
  font-weight: 900;
`;

export const CollapseCardButton = styled.div`
  cursor: pointer;
  position: 'relative';
  width: 'auto';
  margin-right: 20px;
  margin-top: 3px;
`;

export const CollapsedCard = styled.div`
  cursor: pointer;
`;
