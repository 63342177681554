import { useEffect, useState } from 'react';

import { SimpleDropdownItem } from 'legacy/shared/v1/styles/components/DropdownMenu';
import ActionMenu from 'legacy/shared/v1/ui/table/ActionMenu';
import EditRecipientGroupMenuItem from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/EditRecipientGroupMenuItem';
import DeleteRecipientGroupMenuItem from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/DeleteRecipientGroupMenuItem';
import DeleteRecipientGroupModal from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/modals/DeleteRecipientGroupModal';

const ManageRecipientGroupsActionMenu = ({ recipientGroup }) => {
  const [deleteRecipientGroupModalData, setDeleteRecipientGroupModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setDeleteRecipientGroupModalData({
      show: false,
      recipientGroup: recipientGroup,
    });
  }, [recipientGroup]);
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <EditRecipientGroupMenuItem recipientGroupId={recipientGroup.recipientGroupId} />
        </SimpleDropdownItem>

        <SimpleDropdownItem>
          <DeleteRecipientGroupMenuItem
            onMenuItemClick={() =>
              setDeleteRecipientGroupModalData((prevState) => ({ ...prevState, show: true }))
            }
          />
        </SimpleDropdownItem>
      </ActionMenu>
      {/* Modals */}
      <>
        <DeleteRecipientGroupModal
          modalData={deleteRecipientGroupModalData}
          handleCloseModal={() =>
            setDeleteRecipientGroupModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
      </>
    </>
  );
};

export default ManageRecipientGroupsActionMenu;
