import { useContext } from 'react';

import { ManageAlertsContext } from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/AlertsSummaryProvider';

const useAlertsSummaryProviderContext = () => {
  const manageAlertsContext = useContext(ManageAlertsContext);

  if (!manageAlertsContext) {
    throw 'useAlertsSummaryProviderContext must be used within a AlertsSummaryProvider';
  }

  return manageAlertsContext;
};

export default useAlertsSummaryProviderContext;
