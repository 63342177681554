import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getOrgGeofenceActivityData } from 'legacy/core/api/geofences';
import { getGroups } from 'legacy/core/api/groups';
import { decorateVehicle, getMapModeVehicles, getMapVehicles } from 'legacy/core/api/vehicles';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { submitLogout } from 'legacy/core/redux/user/actions';
import { checkForUserPermission } from 'legacy/core/redux/user/selectors';
import LivemapContainer from 'legacy/features/livemap.prebilling/ui/LivemapContainer';
import LoadingWrapper from 'legacy/shared/v1/components/LoadingWrapper';
import { permissionData, variableScopeAllowedUserRoles } from 'legacy/shared/v1/constants/users';
import useInterval from 'legacy/shared/v1/hooks/useInterval';
import { HideLiveMapDiv } from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { loginIsFresh } from 'legacy/shared/v1/utilities/localStore';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';

const LivemapPage = () => {
  const { vehicleId: navigateToVehicleId } = useParams();
  const user = useSelector((state) => state.user);

  const userRole = useSelector((state) => state.user.user.userRole);
  const userOrgId = useSelector((state) => state.user.user.organizationId);
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const organizationsLoading = useSelector((state) => state.organizations.loading);
  const requestingLogOut = useSelector((state) => state.user.requestingLogOut);
  const orgPermissions = useSelector((state) => state.user.orgPermissions);
  const fullscreenMapMode = useSelector((state) => state.ui.fullscreenMapMode);

  const dispatch = useDispatch();
  const [vehiclesLoaded, setVehiclesLoaded] = useState(false);
  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [queuedVehicles, setQueuedVehicles] = useState({});
  const [vehicles, setVehicles] = useState(null);
  const [groups, setGroups] = useState(null);
  const [geofenceActivity, setGeofenceActivity] = useState([]);
  const [hasLivemapPermission, setHasLivemapPermission] = useState(false);
  const [delay, setDelay] = useState(5000);

  const isDoneLoading =
    vehiclesLoaded &&
    groups !== null &&
    vehicles !== null &&
    (userOrgId === filteredOrganizationId || orgPermissions);

  const dispatchNotification = {
    vehicleFetchError: (err) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Vehicles', err),
        }),
      ),
    groupFetchError: (err) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Groups', err),
        }),
      ),
    geofenceActivityFetchError: (err) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Geofence Activity', err),
        }),
      ),
  };

  const checkLogin = () => {
    if (loginIsFresh()) {
      return true;
    } else {
      dispatch(submitLogout({ isTimedOut: true }));
      return false;
    }
  };

  // fix for phones scroll issue
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchVehicles = async () => {
    try {
      const orgId = variableScopeAllowedUserRoles.includes(userRole)
        ? filteredOrganizationId
        : userOrgId;

      const { response } = fullscreenMapMode
        ? await getMapModeVehicles(orgId)
        : await getMapVehicles(orgId);

      let vehicles = response.message
        .filter((v) => v.gps)
        .map((v) => ({
          ...v.gps,
          ...decorateVehicle({
            vehicleFromAPI: v,
          }),
        }));
      setQueuedVehicles({ vehicles, fromOrgId: filteredOrganizationId });
    } catch (err) {
      logWcpError(err);

      if (!requestingLogOut) {
        checkLogin();
        dispatchNotification.vehicleFetchError(err.toString());
      }

      return false;
    }
  };

  const fetchGroups = async () => {
    try {
      const orgId = variableScopeAllowedUserRoles.includes(userRole) ? filteredOrganizationId : '';
      const { response } = await getGroups(orgId);

      let groups = response.message.map((group) => ({
        ...group,
        vehicle_count:
          group.vehicle_id?.length !== null && group.vehicle_id?.length !== undefined
            ? group.vehicle_id?.length
            : 0,
      }));
      setGroups(groups);
      setGroupsLoaded(true);
    } catch (err) {
      logWcpError(err);

      if (!requestingLogOut) {
        checkLogin();
        dispatchNotification.groupFetchError(err);
      }

      return false;
    }
  };

  const fetchGeofenceActivity = async () => {
    if (fullscreenMapMode && hasLivemapPermission) {
      try {
        const orgId = variableScopeAllowedUserRoles.includes(userRole)
          ? filteredOrganizationId
          : userOrgId;
        let startTime = new Date(Date.now() - 20000).toISOString();
        let endTime = new Date(Date.now()).toISOString();
        const { response } = await getOrgGeofenceActivityData({
          organizationId: orgId,
          startTime: startTime,
          endTime: endTime,
        });

        let data = response.message.logs;

        if (data.length > 0) {
          // concat response data with state and create new array
          let newGeofenceActivity = [...geofenceActivity, ...data];

          // remove duplicates
          // filter out any that aren't the first ocurring index
          newGeofenceActivity = newGeofenceActivity.filter(
            (activity, index) =>
              // find index of constructed unique id
              // geofence_id + vehicle_id_timestamp
              newGeofenceActivity.findIndex(
                (a) =>
                  a.geofence_id + a.vehicle_id_timestamp ===
                  activity.geofence_id + activity.vehicle_id_timestamp,
              ) === index,
          );

          // set state
          setGeofenceActivity([...newGeofenceActivity]);
        }
      } catch (err) {
        logWcpError(err);

        if (!requestingLogOut) {
          checkLogin();
          dispatchNotification.geofenceActivityFetchError(err.toString());
        }
      }
    }
  };

  useEffect(() => {
    if (queuedVehicles.fromOrgId === filteredOrganizationId) {
      setVehicles(queuedVehicles.vehicles);
      setVehiclesLoaded(true);
    }
  }, [queuedVehicles]);

  useEffect(() => {
    setGroups(null);
    setVehicles(null);
    setVehiclesLoaded(false);
    setGeofenceActivity([]);
  }, [filteredOrganizationId]);

  useInterval({
    callback: async () => {
      if (hasLivemapPermission) {
        return (
          await fetchVehicles({ isInitialFetch: false, fromOrgId: filteredOrganizationId }),
          fetchGeofenceActivity()
        );
      }
    },
    delay,
    dependencies: [filteredOrganizationId, organizationsLoading, hasLivemapPermission],
  });

  useEffect(() => {
    let hasPermissions = checkForUserPermission(user, permissionData.viewmap);
    setHasLivemapPermission(hasPermissions);

    if (hasPermissions) {
      fetchVehicles();
      fetchGroups();
      fetchGeofenceActivity();
    } else {
      setGroups([]);
      setVehicles([]);
      setGeofenceActivity([]);
      setVehiclesLoaded(true);
    }
  }, [orgPermissions]);

  // groups !== null is a temporary fix for the groups loading issue until we convert this component to use react-query
  return (
    <LoadingWrapper isLoading={!isDoneLoading}>
      {hasLivemapPermission ? (
        <LivemapContainer
          vehicles={vehicles}
          groups={groups}
          navigateToVehicleId={navigateToVehicleId}
          geofenceActivity={geofenceActivity}
        />
      ) : (
        <HideLiveMapDiv>
          <IconSvgComponent
            svgFileName="hide-live-map"
            alt="Map Hidden"
            style={{ marginBottom: '20px' }}
          />
          <h3>You do not currently have access to view the live map</h3>
          <h5>Please contact your organization admin to request permission</h5>
        </HideLiveMapDiv>
      )}
    </LoadingWrapper>
  );
};
export default LivemapPage;
