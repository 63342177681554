import CreateGroupModal from 'legacy/features/vehicleGroups/ui/modals/CreateGroupModal';
import React from 'react';
import { useState } from 'react';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const CreateGroupButton = () => {
  const [createGroupModalData, setCreateGroupModalActive] = useState({ show: false });

  return (
    <>
      <ActionButtonWithIcon create onClick={() => setCreateGroupModalActive({ show: true })}>
        <IconSvgComponent svgFileName="plus-simple" alt="Create" />
        <ActionButtonText>Create Group</ActionButtonText>
      </ActionButtonWithIcon>
      <CreateGroupModal
        modalData={createGroupModalData}
        handleCloseModal={() => setCreateGroupModalActive({ show: false })}
      />
    </>
  );
};

export default CreateGroupButton;
