import {
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

const SuppressAlertsMenuItem = ({ onMenuItemClick, onClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Suppress Alerts</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default SuppressAlertsMenuItem;
