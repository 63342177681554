import { handleActions } from 'redux-actions';

import {
  receiveUsers,
  requestActivateUser,
  requestDeactivateUser,
  requestEditUser,
} from 'legacy/core/redux/users/actions';
import { receiveUser } from 'legacy/core/redux/user/actions';

const defaultState = {
  users: [],
};

const setUserIsLoading = (users, username) => {
  const matchingUserIndex = users.findIndex((user) => user.username === username);
  if (matchingUserIndex > -1) {
    users[matchingUserIndex].loadingStatus = true;
  }
  return users;
};

const users = handleActions(
  {
    [requestActivateUser]: (state, { payload: username }) => {
      const nextUsers = setUserIsLoading(state.users, username);
      return {
        ...state,
        users: nextUsers,
      };
    },
    [requestDeactivateUser]: (state, { payload: username }) => {
      const nextUsers = setUserIsLoading(state.users, username);
      return {
        ...state,
        users: nextUsers,
      };
    },
    [receiveUsers]: (state, { payload: users }) => {
      const nextUsers = users
        .concat(
          state.users.reduce((missingPreviousUsers, user) => {
            const previousUserIndex = users.findIndex(
              (payloadUser) => user.username === payloadUser.username,
            );
            return previousUserIndex < 0 ? missingPreviousUsers.concat(user) : missingPreviousUsers;
          }, []),
        )
        .map((user) => ({
          ...user,
          loadingStatus: false,
        }));

      return {
        ...state,
        users: nextUsers,
      };
    },
    [requestEditUser]: (state, { payload: username }) => {
      const nextUsers = setUserIsLoading(state.users, username);
      return {
        ...state,
        users: nextUsers,
      };
    },
    [receiveUser]: (state, { payload: { user } }) => {
      const userIndex = state.users.findIndex((stateUser) => user.username === stateUser.username);
      const nextUsers = state.users.slice();
      userIndex > -1 ? nextUsers.splice(userIndex, 1, user) : nextUsers.push(user);

      return {
        ...state,
        users: nextUsers,
      };
    },
  },
  defaultState,
);

export default users;
