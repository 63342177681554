import React, { useContext, useEffect, useState } from 'react';

import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'legacy/shared/v1/constants/vehicle';
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import { ClearFilterIcon } from 'legacy/shared/v1/styles/components/Icons';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';
import {
  FilterLabel,
  LiveMapSidebarHead,
  MapCheckboxCol,
  MapFilterCheckboxes,
  SidebarHideToggle,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { sortAscendingAlphaOptions } from 'legacy/shared/v1/utilities/vehicle';

const LivemapSidebarVehicleFilter = ({ groups }) => {
  const {
    actions: { setFilteredMapVehicles, setLivemapSidebarStates, setSelectedVehicle },
    state: { allVehicles, filteredVehicles, selectedVehicle },
  } = useContext(LivemapApi);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const [vehicleOnlineStatusFilters, setVehicleOnlineStatusFilters] = useState(
    vehicleOnlineStatusViewData.generateCheckboxes(false),
  );

  const [vehicleRespondingStatusFilters, setVehicleRespondingStatusFilters] = useState(
    vehicleRespondingStatusViewData.generateCheckboxes(false),
  );

  const [vehicleTypeFilters, setVehicleTypeFilters] = useState(
    vehicleTypeViewData.generateCheckboxes(false),
  );

  const [showOutOfService, setShowOutOfService] = useState(false);

  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    const storedData = localStorage.getItem('livemapFilters');

    if (storedData) {
      const data = JSON.parse(storedData);

      setSelectedGroup(data.selectedGroup);
      setVehicleOnlineStatusFilters(data.vehicleOnlineStatusFilters);
      setVehicleRespondingStatusFilters(data.vehicleRespondingStatusFilters);
      setVehicleTypeFilters(data.vehicleTypeFilters);
      setShowOutOfService(data.showOutOfService);
      setFilterCount(data.filterCount);
    }
  }, []);

  useEffect(() => {
    const data = {
      selectedGroup,
      vehicleOnlineStatusFilters,
      vehicleRespondingStatusFilters,
      vehicleTypeFilters,
      showOutOfService,
      filterCount,
    };

    localStorage.setItem('livemapFilters', JSON.stringify(data));
  }, [
    selectedGroup,
    vehicleOnlineStatusFilters,
    vehicleRespondingStatusFilters,
    vehicleTypeFilters,
    showOutOfService,
    filterCount,
  ]);

  useEffect(() => {
    if (!allVehicles) return;

    // get statuses and types we are looking for from filter
    let includedOnlineStatuses = Object.keys(vehicleOnlineStatusFilters)
      .filter((k) => vehicleOnlineStatusFilters[k].checked)
      .map((k) => vehicleOnlineStatusFilters[k].id);

    let includedRespondingStatuses = Object.keys(vehicleRespondingStatusFilters)
      .filter((k) => vehicleRespondingStatusFilters[k].checked)
      .map((k) => vehicleRespondingStatusFilters[k].id);

    let includedTypes = Object.keys(vehicleTypeFilters)
      .filter((k) => vehicleTypeFilters[k].checked)
      .map((k) => vehicleTypeFilters[k].id);

    let fv = allVehicles.filter(
      (v) => !selectedGroup || (selectedGroup && selectedGroup.vehicle_id.includes(v.vehicle_id)),
    );

    fv = fv.filter((v) => showOutOfService || !v.meta.out_of_service);

    let includedOnlineStatusesCount = includedOnlineStatuses.length;
    let includedRespondingStatusesCount = includedRespondingStatuses.length;
    let includedTypesCount = includedTypes.length;
    let groupFilterCount = selectedGroup ? 1 : 0;
    let outOfServiceCount = showOutOfService ? 1 : 0;

    if (includedOnlineStatusesCount > 0) {
      fv = fv.filter((v) => includedOnlineStatuses.includes(v.onlineStatus));
    }

    if (includedRespondingStatusesCount > 0) {
      fv = fv.filter((v) => includedRespondingStatuses.includes(v.respondingStatus));
    }

    if (includedTypesCount > 0) {
      fv = fv.filter((v) => includedTypes.includes(v.meta.vehicle_type));
    }

    //hide out of service vehicles by default and only show those vehicle when checked
    showOutOfService
      ? (fv = fv.filter((v) => v.meta.out_of_service === true))
      : (fv = fv.filter((v) => !v.meta.out_of_service));

    //if the selected vehicle is not in the new filtered list, close the map detail card
    if (selectedVehicle && !fv.find((v) => v.vehicle_id === selectedVehicle.vehicle_id)) {
      setSelectedVehicle(null);
    }

    setFilteredMapVehicles(fv);
    setFilterCount(
      includedOnlineStatusesCount +
        includedRespondingStatusesCount +
        includedTypesCount +
        outOfServiceCount +
        groupFilterCount,
    );
  }, [
    selectedGroup,
    vehicleOnlineStatusFilters,
    vehicleRespondingStatusFilters,
    vehicleTypeFilters,
    showOutOfService,
    allVehicles,
  ]);

  const handleGroupFilterChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedGroup(null);
    } else {
      const [selectedGroup] = groups.filter((group) =>
        groupOptions.find((option) => (option && selectedOption.value) === group.group_name),
      );
      setSelectedGroup(selectedGroup);
    }
  };

  const handleClearFilters = () => {
    setVehicleOnlineStatusFilters(vehicleOnlineStatusViewData.generateCheckboxes(false));
    setVehicleRespondingStatusFilters(vehicleRespondingStatusViewData.generateCheckboxes(false));
    setVehicleTypeFilters(vehicleTypeViewData.generateCheckboxes(false));
    setSelectedGroup(null);
    setShowOutOfService(false);
  };
  const groupOptions = groups
    .map((group) => ({
      value: group.group_name,
      label: group.group_name,
    }))
    .sort(sortAscendingAlphaOptions);

  const renderCheckboxes = (filterState, filterStateUpdate, filterBy) =>
    Object.keys(filterState).map((k, i) => (
      <Checkbox liveMap key={i}>
        <CheckboxInput
          id={filterState[k].dom_id}
          onChange={() => {
            filterState[k].checked = !filterState[k].checked;
            filterStateUpdate({ ...filterState });
          }}
          checked={filterState[k].checked}
        />
        <CheckboxLabel liveMap htmlFor={filterState[k].dom_id}>
          {filterState[k].title} (
          {filteredVehicles.filter((v) => filterBy(v, filterState[k].id)).length})
        </CheckboxLabel>
      </Checkbox>
    ));

  return (
    <LiveMapSidebarHead>
      <FilterLabel>
        {filterCount == 0
          ? 'No filters applied'
          : filterCount + ` filter${filterCount > 1 ? 's' : ''} applied`}
        {filterCount > 0 ? (
          <ClearFilterIcon
            mapFilters
            onClick={handleClearFilters}
            svgFileName={'red-x'}
            title="Clear all filters"
            alt="Clear all filters"
          />
        ) : null}
      </FilterLabel>

      <StyledReactSelect
        liveMapFilter
        name="group"
        //need this key to dynamically generate so that the seect if forced to re-render with the updated state
        key={`groupSelect${selectedGroup?.group_name}`}
        placeholder="Filter by group..."
        // the standard or check we do eslewhere for the placeholder text doesnt work here,
        // we need the or check outside of the label assignment otherwise there is always a clear button even with placeholder text
        defaultValue={selectedGroup ? { label: selectedGroup?.group_name } : 'Filter by group...'}
        onChange={(selectedOption) => handleGroupFilterChange(selectedOption)}
        closeMenuOnSelect={true}
        isClearable={true}
        isSearchable={false}
        options={groupOptions}
      />
      <SidebarHideToggle
        onClick={() =>
          setLivemapSidebarStates({
            livemapMainSidebarOpen: false,
          })
        }
      >
        <IconSvgComponent
          svgFileName="hideLiveMapFilter"
          title="Hide map filter"
          alt="Hide map filter"
        />
      </SidebarHideToggle>
      <MapFilterCheckboxes>
        <MapCheckboxCol>
          {renderCheckboxes(
            vehicleOnlineStatusFilters,
            setVehicleOnlineStatusFilters,
            (v, id) => v.onlineStatus === id,
          )}
          {renderCheckboxes(
            vehicleRespondingStatusFilters,
            setVehicleRespondingStatusFilters,
            (v, id) => v.respondingStatus === id,
          )}
          <Checkbox liveMap>
            <CheckboxInput
              id="out_of_service"
              onChange={() => {
                setShowOutOfService(!showOutOfService);
              }}
              checked={showOutOfService}
            />
            <CheckboxLabel liveMap htmlFor={'out_of_service'}>
              {'Out of Service'} (
              {allVehicles?.filter((v) => v.meta.out_of_service === true).length})
            </CheckboxLabel>
          </Checkbox>
        </MapCheckboxCol>
        <MapCheckboxCol>
          {renderCheckboxes(
            vehicleTypeFilters,
            setVehicleTypeFilters,
            (v, id) => v.meta.vehicle_type === id,
          )}
        </MapCheckboxCol>
      </MapFilterCheckboxes>
    </LiveMapSidebarHead>
  );
};

export default LivemapSidebarVehicleFilter;
