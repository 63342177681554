import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { maintenanceIntervalDropdownData } from 'legacy/features/maintenance/data/maintenanceIntervalDropdownData';
import { maintenanceStatusDropdownData } from 'legacy/features/maintenance/data/maintenanceStatusDropdownData';
import { maintenanceTypeDropdownData } from 'legacy/features/maintenance/data/maintenanceTypeDropdownData';
import {
  addDisplayCommas,
  stripCommas,
  stripTrailingPercentage,
} from 'legacy/features/maintenance/utilities/formatting';
import { mapToFrontend, mapToBackend } from 'legacy/shared/v2/utilities/mapping/mapper';
import {
  formatISOTimestampToLongDateWithTime,
  formatISOTimestampToShortDate,
  getShortDateInIsoFormat,
  getUtc,
} from 'legacy/shared/v1/utilities/time';

export const vehicleMaintenanceMapping = {
  completed_date: { frontendProperty: 'completedDate' },
  created_date: {
    frontendProperty: 'createdDate',
    transformValueToFrontend: (value) => value && formatISOTimestampToLongDateWithTime(value),
    //strip created date, we should not be sending this and the backend wont update it with this call
    transformValueToBackend: (value) => null,
  },
  diagnostic_code: {
    frontendProperty: 'diagnosticCode',
  },
  diagnostic_code_description: {
    frontendProperty: 'diagnosticCodeDescription',
  },
  due_date: {
    frontendProperty: 'dueDate',
    transformValueToFrontend: (value) => value && formatISOTimestampToShortDate(value),
    transformValueToBackend: (value) => value && getUtc(getShortDateInIsoFormat(value)),
  },
  due_in: { frontendProperty: 'dueIn' },
  due_in_unit: { frontendProperty: 'dueInUnit' },
  due_in_value: {
    frontendProperty: 'dueInValue',
    transformValueToFrontend: (value) => (value ? value : null),
  },
  engine_hours: {
    frontendProperty: 'engineHours',
    transformValueToFrontend: (value) => (value ? value : null),
  },
  event_id: { frontendProperty: 'eventId' },
  interval: {
    frontendProperty: 'interval',
    transformValueToBackend: (interval) => interval,
  },
  interval_unit: {
    frontendProperty: 'intervalType',
    transformValueToFrontend: (value) =>
      maintenanceIntervalDropdownData.find((dd) => dd.value === value),
    transformValueToBackend: (object) =>
      object
        ? maintenanceIntervalDropdownData.find((dd) => dd.label === object.label)?.value
        : maintenanceIntervalConstants.NONE,
  },
  is_suppressed: { frontendProperty: 'isSuppressed' },
  last_in: {
    frontendProperty: 'lastIn',
    transformValueToFrontend: (value) => (value ? formatISOTimestampToShortDate(value) : 'N/A'),
  },
  maintenance_type: {
    frontendProperty: 'maintenanceType',
    transformValueToFrontend: (value) =>
      maintenanceTypeDropdownData.find((dd) => dd.value === value),
    transformValueToBackend: (object) =>
      maintenanceTypeDropdownData.find((dd) => dd.label === object.label)?.value,
  },
  notes: { frontendProperty: 'notes' },
  odometer: {
    frontendProperty: 'odometer',
    transformValueToFrontend: (value) => (value ? value : null),
  },
  schedule_duplicate_event: { frontendProperty: 'scheduleDuplicateEvent' },
  next_interval_unit: {
    frontendProperty: 'nextIntervalType',
    transformValueToFrontend: (value) =>
      maintenanceIntervalDropdownData.find((dd) => dd.value === value),
    transformValueToBackend: (object) =>
      object
        ? maintenanceIntervalDropdownData.find((dd) => dd.label === object.label)?.value
        : maintenanceIntervalConstants.NONE,
  },
  next_interval_value: { frontendProperty: 'nextIntervalValue' },
  next_due_date: {
    frontendProperty: 'nextDueDate',
    transformValueToFrontend: (value) => value && formatISOTimestampToShortDate(value),
    transformValueToBackend: (value) => value && getUtc(getShortDateInIsoFormat(value)),
  },
  status: { frontendProperty: 'status' },
  total_mileage: { frontendProperty: 'totalMileage' },
  vehicle_id: { frontendProperty: 'vehicleId' },
  vehicle_label: { frontendProperty: 'vehicleLabel' },
  vehicle_make: { frontendProperty: 'vehicleMake' },
  vehicle_type: { frontendProperty: 'vehicleType' },
};

export const mapMaintenanceDataToFrontend = (maintenanceEvent) => {
  const mappedMaintenanceData = mapToFrontend(maintenanceEvent, vehicleMaintenanceMapping, false);
  mappedMaintenanceData.maintenanceTypeDisplay = maintenanceTypeDropdownData.find(
    (dd) => dd.value === mappedMaintenanceData.maintenanceType.value,
  ).label;
  mappedMaintenanceData.dueInValueDisplay = mappedMaintenanceData.dueInValue
    ? addDisplayCommas(mappedMaintenanceData.dueInValue)
    : 'N/A';
  mappedMaintenanceData.engineHoursDisplay = mappedMaintenanceData.engineHours
    ? addDisplayCommas(mappedMaintenanceData.engineHours)
    : 'N/A';
  mappedMaintenanceData.odometerDisplay = mappedMaintenanceData.odometer
    ? addDisplayCommas(mappedMaintenanceData.odometer)
    : 'N/A';
  mappedMaintenanceData.rawCreatedDate = maintenanceEvent.created_date;
  mappedMaintenanceData.dueInUnit =
    mappedMaintenanceData.dueInUnit !== '%'
      ? ' ' + mappedMaintenanceData.dueInUnit
      : mappedMaintenanceData.dueInUnit;
  return mappedMaintenanceData;
};

export const mapAndMassageMaintenanceData = (maintenanceEvent) => {
  const mappedMaintenanceData = mapToBackend(maintenanceEvent, vehicleMaintenanceMapping);

  if (maintenanceEvent.intervalType.value === maintenanceIntervalConstants.OIL_LIFE) {
    mappedMaintenanceData.interval = +stripTrailingPercentage(maintenanceEvent.interval);
  }

  //strip commas and coerce to an integer
  mappedMaintenanceData.interval = +stripCommas(mappedMaintenanceData.interval);

  // if due date is selected (due_date should also be given), clear interval
  if (
    mappedMaintenanceData.interval_unit === maintenanceIntervalConstants.DATE &&
    mappedMaintenanceData.due_date !== null
  ) {
    mappedMaintenanceData.interval = null;
  }

  // if interval unit is total mileage, clear interval and fill total mileage
  if (mappedMaintenanceData.interval_unit === maintenanceIntervalConstants.TOTAL_MILEAGE) {
    mappedMaintenanceData.total_mileage = mappedMaintenanceData.interval;

    mappedMaintenanceData.interval = null;
  }

  //schedule duplicate event mapping logic
  if (mappedMaintenanceData.schedule_duplicate_event) {
    switch (mappedMaintenanceData.next_interval_unit) {
      case maintenanceIntervalConstants.DATE:
        if (mappedMaintenanceData.next_due_date !== null) {
          mappedMaintenanceData.next_interval_value = null;
        }
        break;
      case maintenanceIntervalConstants.TOTAL_MILEAGE:
        mappedMaintenanceData.next_total_mileage = mappedMaintenanceData.next_interval_value
          ? +stripCommas(mappedMaintenanceData.next_interval_value)
          : null;
        mappedMaintenanceData.next_interval_value = null;
        mappedMaintenanceData.next_due_date = null;
        break;
      case maintenanceIntervalConstants.ENGINE_HOURS:
      case maintenanceIntervalConstants.MILEAGE:
        mappedMaintenanceData.next_interval_value = mappedMaintenanceData.next_interval_value
          ? +stripCommas(mappedMaintenanceData.next_interval_value)
          : null;
        mappedMaintenanceData.next_due_date = null;
        break;
      case maintenanceIntervalConstants.OIL_LIFE:
        mappedMaintenanceData.next_interval_value = +stripTrailingPercentage(
          mappedMaintenanceData.next_interval_value,
        );

      default:
        break;
    }
  }

  return mappedMaintenanceData;
};

export const mapMaintenanceTotalMilageData = ({ maintenanceEvent }) => {
  // if interval unit is total mileage, assign total mileage to interval
  if (maintenanceEvent.intervalType?.value === maintenanceIntervalConstants.TOTAL_MILEAGE) {
    maintenanceEvent.interval = maintenanceEvent.totalMileage;
  }

  return maintenanceEvent;
};

export const mapMaintenanceDataToCsv = ({ maintenanceEvents }) => {
  return maintenanceEvents.map((s) => {
    return {
      Vehicle_Name: s.vehicleLabel,
      Make: s.vehicleMake,
      Odoometer: s.odometer,
      Engine_Hours: s.engineHours,
      Due_In: s.dueIn,
      Type: maintenanceTypeDropdownData.find((t) => t.value === s.maintenanceType)?.label,
      Last_In: s.lastIn,
      Status: maintenanceStatusDropdownData.find((m) => m.value === s.status)?.label,
    };
  });
};

export const getVehicleNameDisplay = (records) => {
  if (records) {
    records.forEach((record) => {
      record.vehicleNameDisplay = record.vehicleLabel;
    });
  }
  return records;
};
