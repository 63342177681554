import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';

export const maintenanceLogsTableColumns = [
  {
    title: 'Completed Date',
    displayProperty: 'formattedTime',
    sortProperty: 'completed_date',
    width: '15%',
  },
  {
    title: 'Odometer',
    displayProperty: 'odometerDisplay',
    sortProperty: 'completed_odometer',
  },
  {
    title: 'Engine Hours',
    displayProperty: 'engineHoursDisplay',
    sortProperty: 'completed_engine_hours',
  },
  {
    title: 'Interval',
    displayProperty: 'intervalDisplay',
  },
  {
    title: 'Type',
    displayProperty: 'maintenanceTypeDisplay',
  },
  {
    title: 'Notes',
    displayProperty: 'notesDisplay',
    width: '35%',
    render({ rowData, cellJsx }) {
      return (
        <span title={'Notes: ' + rowData.notesDisplay}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Marked Completed By',
    displayProperty: 'markedCompletedByDisplay',
    width: '15%',
    render({ rowData, cellJsx }) {
      return (
        <span title={'Marked Completed By: ' + rowData.markedCompletedByDisplay}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },
];
