import {
  getCooldownPeriodLabelFromValue,
  getCooldownPeriodValueFromLabel,
} from 'legacy/features/alerts/data/ui/dropdown/cooldownPeriodDropdownData';
import { getCooldownOptions } from 'legacy/features/alerts/data/ui/dropdown/getCooldownOptions';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';
import { CooldownDelayDiv } from 'legacy/shared/v1/styles/components/ManageAlerts';
import FilteredDropdown from 'legacy/shared/v1/ui/dropdowns/FilteredDropdown';

const CooldownPeriodDropdown = ({ notificationIndex }) => {
  const {
    actions: { updateAlertNotificationDetailsAction },
    state: {
      alert: { alertNotifications, alertDetails },
    },
  } = useAlertFormsProviderContext();

  const { cooldownMinutes } = alertNotifications[notificationIndex];
  const { alertType } = alertDetails.alertInfo;

  useMakeFormikField({
    fieldName: 'cooldownMinutesField',
    fieldValue: cooldownMinutes,
  });

  return (
    <CooldownDelayDiv>
      <FilteredDropdown
        restrictedHeightResults
        hideClearX
        dropdownOptions={getCooldownOptions(alertType)}
        defaultSelectedItem={getCooldownPeriodLabelFromValue(cooldownMinutes)}
        title={'Repeat Notification'}
        placeholder={cooldownMinutes && getCooldownPeriodLabelFromValue(cooldownMinutes)}
        isFiltered={true}
        closeOnSelect={true}
        onItemSelect={(label) => {
          if (label) {
            const cooldownMinutes = getCooldownPeriodValueFromLabel(label);

            updateAlertNotificationDetailsAction({ notificationIndex, cooldownMinutes });
          }
        }}
      />
    </CooldownDelayDiv>
  );
};

export default CooldownPeriodDropdown;
