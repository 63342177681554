import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import NavigationItemsContainer from 'legacy/core/navigation/components/NavigationItemsContainer';
import {
  closeSidebar as closeSidebarAction,
  openSidebar as openSidebarAction,
  requestEndCloseSidebar as requestEndCloseSidebarAction,
} from 'legacy/core/redux/sidebar/actions';
import { sizes } from 'legacy/shared/v1/utilities/media';
import SidebarAside from 'legacy/shared/v1/styles/components/Sidebar';

const NavigationSidebar = ({ fullscreenMapMode }) => {
  const dispatch = useDispatch();

  const expanded = useSelector((state) => state.sidebar.visible);
  const sidebarIsCollapsed = useSelector((state) => !state.sidebar.visible);
  const openedOnClick = useSelector((state) => state.sidebar.openedOnClick);

  const openSidebarHandler = () => {
    if (openedOnClick) return;
    dispatch(openSidebarAction());
  };

  const closeSidebarHandler = () => {
    if (openedOnClick) return;
    dispatch(closeSidebarAction());
  };

  return (
    <div>
      <Media
        queries={{
          tablet: { maxWidth: sizes.tablet },
          mobile: { maxWidth: sizes.mobile },
        }}
      >
        {(matches) => {
          if (matches.mobile) {
            return (
              <SidebarAside expanded={expanded}>
                <NavigationItemsContainer sidebarIsCollapsed={sidebarIsCollapsed} />
              </SidebarAside>
            );
          } else if (matches.tablet) {
            return (
              <SidebarAside expanded={expanded}>
                <NavigationItemsContainer sidebarIsCollapsed={sidebarIsCollapsed} />
              </SidebarAside>
            );
          } else {
            return (
              <SidebarAside
                expanded={expanded}
                onMouseEnter={openSidebarHandler}
                onMouseLeave={closeSidebarHandler}
                onTransitionEnd={(e) => dispatch(requestEndCloseSidebarAction(e))}
                fullscreenMapMode={fullscreenMapMode}
              >
                <NavigationItemsContainer sidebarIsCollapsed={sidebarIsCollapsed} />
              </SidebarAside>
            );
          }
        }}
      </Media>
    </div>
  );
};

export default NavigationSidebar;
