import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';

// TODO this needs testing
export const getAggregateAlertMethodFromRecipientList = (alertRecipients, displayValues) => {
  if (!alertRecipients?.length) return 'N/A';

  // Count occurrences of each alert method
  const countAlertMethod = (am) =>
    alertRecipients.reduce((count, r) => {
      return r.recipientUserAlertMethod === am ? count + 1 : count;
    }, 0);

  // Count each type of alert method
  const countEmail = countAlertMethod(alertMethodConstants.EMAIL);
  const countSms = countAlertMethod(alertMethodConstants.SMS);
  const countBoth = countAlertMethod(alertMethodConstants.SMS_AND_EMAIL);
  const countNone = countAlertMethod(null) + countAlertMethod('Logs Only'); //ugh

  // Total number of recipients
  const totalRecipients = alertRecipients.length;

  // Check if all recipients have the same alert method
  if (countEmail === totalRecipients) return displayValues ? 'Email' : alertMethodConstants.EMAIL;
  if (countSms === totalRecipients) return displayValues ? 'Text' : alertMethodConstants.SMS;
  if (countBoth === totalRecipients)
    return displayValues ? 'Text and Email' : alertMethodConstants.SMS_AND_EMAIL;
  if (countNone === totalRecipients) return 'None';

  // Check for any mixture of alert methods, including "None"
  if (
    (countEmail > 0 && (countSms > 0 || countBoth > 0 || countNone > 0)) ||
    (countSms > 0 && (countEmail > 0 || countBoth > 0 || countNone > 0)) ||
    (countBoth > 0 && (countEmail > 0 || countSms > 0 || countNone > 0)) ||
    (countNone > 0 && (countEmail > 0 || countSms > 0 || countBoth > 0))
  ) {
    return 'Custom';
  }
  return 'Invalid Configuration';
};
