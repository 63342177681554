import {
  AnalyticsInfographics,
  Bar,
  BarResults,
  BarResultsWrapper,
  ExportWrapper,
  LargeResult,
  LargeResultLabel,
  NoDataDiv,
  SmallLabel,
} from 'legacy/shared/v1/styles/components/Analytics';
import { ActionButtonWithIcon } from 'legacy/shared/v1/styles/components/Button';
import {
  convertDuration,
  ISOTimeFormatted,
  formatISOTimestampToLongDateWithTime,
} from 'legacy/shared/v1/utilities/time';
import { createExportDownload } from 'legacy/shared/v1/utilities/csvExport';

const AnalyticsLogsResultsSummary = ({ logsQueryResults, selectedVehicle }) => {
  let topNotRespondingSpeedWidth = 0;
  let topRespondingSpeedWidth = 0;

  const topSpeed =
    logsQueryResults &&
    logsQueryResults.summary.top_responding_speed >
      logsQueryResults.summary.top_not_responding_speed
      ? logsQueryResults.summary.top_responding_speed
      : logsQueryResults.summary.top_not_responding_speed;

  if (logsQueryResults && logsQueryResults.summary.top_not_responding_speed != 0) {
    logsQueryResults.summary.top_not_responding_speed >
    logsQueryResults.summary.top_responding_speed
      ? (topNotRespondingSpeedWidth = 100)
      : (topNotRespondingSpeedWidth = Math.round(
          (logsQueryResults.summary.top_not_responding_speed / topSpeed) * 100,
        ));
  }

  if (logsQueryResults && logsQueryResults.summary.top_responding_speed != 0) {
    logsQueryResults.summary.top_not_responding_speed <
    logsQueryResults.summary.top_responding_speed
      ? (topRespondingSpeedWidth = 100)
      : (topRespondingSpeedWidth = Math.round(
          (logsQueryResults.summary.top_responding_speed / topSpeed) * 100,
        ));
  }

  const onlineSpeedPercentage =
    logsQueryResults && logsQueryResults.summary.average_online_speed != 0
      ? Math.round((logsQueryResults.summary.average_online_speed / topSpeed) * 100)
      : 0;

  const respondingSpeedPercentage =
    logsQueryResults && logsQueryResults.summary.average_responding_speed != 0
      ? Math.round((logsQueryResults.summary.average_responding_speed / topSpeed) * 100)
      : 0;
  const prepareDataForExport = () => {
    let resultsForExport = logsQueryResults.events.map((e) => ({
      Timestamp: formatISOTimestampToLongDateWithTime(e.timestamp),
      Timestamp_ISO: ISOTimeFormatted(e.timestamp),
      Status: e.status,
      Responding: e.responding ? 'Responding' : 'Not Responding',
      Duration: convertDuration(e.duration),
      Duration_in_Seconds: e.duration,
      Distance_Traveled: e.distance_traveled ? `${e.distance_traveled} Miles` : 'N/A',
      Average_Speed: e.avg_speed,
      Top_Speed: e.top_speed,
    }));

    let csvFileName = `wcp_analytics_logs_export_for_'${selectedVehicle.meta.label}`;
    createExportDownload(resultsForExport, csvFileName);
  };

  return (
    <>
      {logsQueryResults.summary.online_count == 0 && (
        <NoDataDiv analytics>
          There is no data to report. Please consider a broader date range.
        </NoDataDiv>
      )}
      <AnalyticsInfographics logs>
        <div>
          <LargeResult responding>{logsQueryResults.summary.responding_count} times</LargeResult>
          <LargeResultLabel>Responding</LargeResultLabel>
        </div>
        <div>
          <LargeResult online>{logsQueryResults.summary.online_count} times</LargeResult>
          <LargeResultLabel>Online</LargeResultLabel>
        </div>

        <ExportWrapper>
          <ActionButtonWithIcon onClick={prepareDataForExport} export>
            Export to .csv
          </ActionButtonWithIcon>
        </ExportWrapper>
        <BarResultsWrapper>
          <BarResults logs>
            <LargeResult online bars>
              {logsQueryResults.summary.average_online_speed}
            </LargeResult>
            <div>
              <Bar barWidth={onlineSpeedPercentage} online></Bar>
              <SmallLabel>Avg Not Responding Speed</SmallLabel>
            </div>
          </BarResults>
          <BarResults logs>
            <LargeResult online bars>
              {logsQueryResults.summary.top_not_responding_speed}
            </LargeResult>
            <div>
              <Bar barWidth={topNotRespondingSpeedWidth} online></Bar>
              <SmallLabel>Top Not Responding Speed</SmallLabel>
            </div>
          </BarResults>
          <BarResults logs>
            <LargeResult responding bars>
              {logsQueryResults.summary.average_responding_speed}
            </LargeResult>
            <div>
              <Bar barWidth={respondingSpeedPercentage} responding></Bar>
              <SmallLabel>Avg Responding Speed</SmallLabel>
            </div>
          </BarResults>
          <BarResults logs>
            <LargeResult responding bars>
              {logsQueryResults.summary.top_responding_speed}
            </LargeResult>
            <div>
              <Bar barWidth={topRespondingSpeedWidth} responding></Bar>
              <SmallLabel>Top Responding Speed</SmallLabel>
              <SmallLabel gps>*All speeds determined by OBD or GPS</SmallLabel>
            </div>
          </BarResults>
        </BarResultsWrapper>
      </AnalyticsInfographics>
    </>
  );
};

export default AnalyticsLogsResultsSummary;
