import {
  TimeLabel,
  TimeValue,
  VehicleTime,
} from 'legacy/shared/v1/styles/components/VehicleDetailCard';
import { convertDuration, getSecondsFromStartTime } from 'legacy/shared/v1/utilities/time';
import { formatISOTimestampToLongDateWithTime } from 'legacy/shared/v1/utilities/time';

const VehicleOnlineTime = ({ isOnline, onlineStartTime, lastSeen }) => {
  return (
    <div>
      {isOnline ? (
        <VehicleTime title={formatISOTimestampToLongDateWithTime(onlineStartTime)}>
          <TimeLabel>Online for: </TimeLabel>
          <TimeValue>
            {convertDuration(getSecondsFromStartTime({ fromTime: onlineStartTime }), true)}
          </TimeValue>
        </VehicleTime>
      ) : (
        <VehicleTime title={formatISOTimestampToLongDateWithTime(lastSeen)}>
          <TimeLabel>Last Seen: </TimeLabel>
          <TimeValue>
            {convertDuration(getSecondsFromStartTime({ fromTime: lastSeen }), true)} ago
          </TimeValue>
        </VehicleTime>
      )}
    </div>
  );
};

export default VehicleOnlineTime;
