import React, { useEffect, useState } from 'react';
import SortableTableWithoutPagination from 'legacy/shared/v1/ui/table/SortableTableWithoutPagination';
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';

import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';

import colors from 'legacy/shared/v1/constants/colors';
import checkIcon from 'assets/images/check@2x.png';
import { vehicleTypeViewData } from 'legacy/shared/v1/constants/vehicle';
import {
  TruncatedText,
  TruncatedTextWithWrap,
} from 'legacy/shared/v1/styles/components/TruncatedText';
import { CheckboxTextSpan } from 'legacy/shared/v1/styles/components/Form';

const GroupModalVehicleTable = ({ vehicles, setVehicleInputValue }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [vehicles]);

  useEffect(() => {
    setVehicleInputValue(selected);
  }, [selected]);

  const allSelected = () => {
    return selected.length === vehicles.length;
  };

  const onRow = (record, index) => {
    return {
      style: {
        backgroundColor: index % 2 === 0 ? colors.cloud : colors.concrete,
      },
    };
  };

  const handleCheckbox = (row, index) => {
    return (e) => {
      const indexOfItem = selected.indexOf(row.vehicle_id);
      let nextSelected = selected.slice();

      if (indexOfItem > -1) {
        nextSelected.splice(indexOfItem, 1);
      } else {
        nextSelected = [...nextSelected, row.vehicle_id];
      }

      setSelected(nextSelected);
    };
  };

  return (
    <div style={styles.tableWrapper}>
      <SortableTableWithoutPagination
        data={vehicles}
        rowKey={(record) => record.vehicle_id}
        onRow={onRow}
        columns={[
          {
            title: (() => (
              <>
                <Checkbox className="checkbox group-check-header" tableHeader>
                  <CheckboxInput
                    id={'selectAllVehicles'}
                    onChange={() => {
                      if (!allSelected()) {
                        setSelected(vehicles.map((v) => v.vehicle_id));
                      } else {
                        setSelected([]);
                      }
                    }}
                    checked={allSelected()}
                  />
                  <CheckboxLabel htmlFor={'selectAllVehicles'} />
                </Checkbox>
                {'Vehicle Name'}
              </>
            ))(),
            dataIndex: 'meta.label',
            key: 'label',

            render: (value, row, index) => {
              let isSelected = selected.indexOf(row.vehicle_id) > -1;
              return (
                <div style={styles.vehicleNameCell} title={'Vehicle Name: ' + row.meta.label}>
                  <Checkbox singleCheck groupTable>
                    <CheckboxInner isChecked={isSelected} onClick={handleCheckbox(row, index)} />
                  </Checkbox>
                  <TruncatedText style={{ marginTop: 16 }}>{row.meta.label}</TruncatedText>
                </div>
              );
            },
            onHeaderCell: () => ({ style: styles.vehicleNameColumn }),
            onCell: () => ({ style: styles.vehicleNameCell }),
          },
          {
            title: 'Type',
            key: 'type',
            width: '5%',
            render: (vehicle) => {
              let value = vehicle.meta.vehicle_type;
              return (
                <span>
                  <TruncatedText>{vehicleTypeViewData[value].title}</TruncatedText>
                </span>
              );
            },
          },
          {
            title: 'Year',
            key: 'year',
            render: (vehicle) => {
              let value = vehicle.meta.mfg_year;
              return <span>{value}</span>;
            },
          },
          {
            title: 'License Plate',
            key: 'license_plate',
            width: '5%',
            render: (vehicle) => {
              let value = vehicle.meta.license_plate;
              return <span>{value}</span>;
            },
          },
          {
            title: 'Make',
            key: 'make',
            render: (vehicle) => {
              let value = vehicle.meta.make;
              return <span>{value}</span>;
            },
          },
          {
            title: 'Model',
            key: 'model',
            render: (vehicle) => {
              let value = vehicle.meta.model;
              return <span>{value}</span>;
            },
          },
        ]}
      />
    </div>
  );
};

export default GroupModalVehicleTable;

const styles = {
  tableWrapper: {
    marginLeft: -35,
    marginRight: -20,
    marginBottom: 20,
  },
  vehicleNameColumn: {
    paddingLeft: 47,
  },
  vehicleNameCell: {
    padding: '5px 8px 13px 0px',
    display: 'flex',
    width: 140,
    marginLeft: 23,
  },
  vehicleName: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 500,
    fontSize: 14,
    color: colors.midnight,
  },
  checkboxHolderImage: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    width: 14,
    height: 14,
    marginRight: 20,
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
  },
  checkboxHolderImageSelected: {
    position: 'relative',
    display: 'inline-block',
    top: 'calc(50% + 2px)',
    borderRadius: 2,
    border: '1px solid rgba(138, 144, 156, 0.6)',
    width: 14,
    height: 14,
    marginRight: 20,
    border: 'none',
    backgroundImage: `url(${checkIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};
