import styled from 'styled-components';

import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';

export const Container = styled.div`
  position: relative;
  max-width: 475px;
  width: 100%;
  margin: auto;
  padding: 23px 23px 23px 40px;
  font-family: Muli, sans-serif;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

  ${(props) =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}

  ${(props) =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight}px;
  `}

  ${(props) =>
    props.autoHeight &&
    `
    max-height: none;
    height: auto;
  `};

  ${(props) =>
    props.vehiclesForm &&
    `
    padding: 30px 15px 30px 15px;
    @media ${device.medium} {
      padding: 30px 30px 30px 30px;
    }
  `};

  /* @media ${device.large} {
    width: 53%;
  } */
`;
export const ModalContent = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const ModalButtonContainerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CollapseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
export const CloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  color: ${colors.midnight};
  font-size: 24px;
  width: 100%;
  padding-left: 0px;
  ${(props) =>
    props.noIcon &&
    `
    padding-left:25px;
  `};

  ${(props) =>
    props.confirmationModal &&
    `
    margin-bottom: 42px;
  `};

  ${(props) =>
    props.smallHeight &&
    `
    margin-bottom: 20px;
  `};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.topSpacing &&
    `
    margin-top:50px;
  `};

  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const ModalText = styled.div`
  padding-right: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  letter-spacing: 1;
  color: ${colors.haze};

  ${(props) =>
    props.smallHeight &&
    `
    margin-bottom: 0px;
  `};
`;

export const BoldedItem = styled.span`
  font-weight: 700;
  color: ${colors.midnight};
`;

export const ReleaseNotesText = styled.span`
  color: ${colors.midnight};
  font-weight: 500;
`;
