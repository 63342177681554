import { Component, createRef } from 'react';

import {
  FieldError,
  FormAsterisk,
  FormControls,
  FormFieldAnimated,
  FormLabelAnimated,
} from 'legacy/shared/v1/styles/components/Form';

import IconSvgComponent from '../icons/IconSvgComponent';

// TODO refactor this to functional component
class AnimatedField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.initialValue || '',
      isFocused: this.props.value ? true : false,
      isDeleteKey: false,
    };
  }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate', prevProps.validationError, this.props.validationError);
  //   if (prevProps.validationError !== this.props.validationError) {
  //     console.log('validationError changed');
  //   }
  // }

  input = createRef();

  handleInputChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    if (typeof this.props.handleChange === 'function') {
      this.props.handleChange(e);
    }

    this.handleFieldBlur(e);
  };

  handleFieldFocus = () => {
    this.setState({
      isFocused: true,
      isDeleteKey: false,
    });
  };

  handleFieldBlur = (e) => {
    typeof this.props.handleBlur === 'function' && this.props.handleBlur(e);
    if (e.target.value === '' && !this.state.isDeleteKey) {
      this.setState({
        isFocused: false,
      });
    } else {
      this.setState({
        isFocused: true,
      });
    }
  };

  handleKeyUp = (e) => {
    const { handleKeyUp } = this.props;
    if (this.state.isFocused) {
      // eslint-disable-next-line
      handleKeyUp ? handleKeyUp.apply(this, [e]) : null;
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      this.setState({
        isDeleteKey: true,
      });
    }
  };

  setInputValue = (nextValue) => {
    const input = this.input.current || this.input;

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    ).set;

    nativeInputValueSetter.call(input, nextValue);
    input.dispatchEvent(new Event('input', { bubbles: true }));
  };

  input = this.props.innerRef ? this.props.innerRef : createRef();

  render() {
    const {
      id,
      name,
      type,
      required,
      infoIcon,
      disabled,
      placeholder,
      labelText,
      touched,
      innerRef,
      value: propValue,
      proxyValue,
      forceAnimated,
      validationError,
      containerStyles,
      inputStyles,
      labelOffset,
      prefixIcon,
      suffixIcon,
      smallerTextMobile,
      configName,
      onClick,
      autoComplete,
      geofenceCard,
      validateInstantly,
      maintenanceModal,
    } = this.props;

    const { value: stateValue, isFocused } = this.state;

    return (
      <FormControls onClick={onClick} style={containerStyles}>
        <FormLabelAnimated
          animated={isFocused || forceAnimated}
          offset={labelOffset}
          htmlFor={id}
          prefixIcon={prefixIcon}
          suffixIcon={suffixIcon}
        >
          {placeholder || labelText}
          {required ? <FormAsterisk>*</FormAsterisk> : null}
          {infoIcon ? <IconSvgComponent svgFileName="info" /> : null}
        </FormLabelAnimated>

        <FormFieldAnimated
          ref={this.input}
          onFocus={this.handleFieldFocus}
          onBlur={this.handleFieldBlur}
          onChange={this.handleInputChange}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          type={type ? type : 'text'}
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          infoIcon={infoIcon}
          value={propValue || stateValue}
          invalid={touched && validationError}
          spellCheck={false}
          prefixIcon={prefixIcon}
          suffixIcon={suffixIcon}
          smallerTextMobile={smallerTextMobile}
          configName={configName}
          style={inputStyles}
          autoComplete={autoComplete}
          geofenceCard={geofenceCard}
          validateInstantly={validateInstantly}
          maintenanceModal={maintenanceModal}
        />
        <FieldError>{(touched || validateInstantly) && validationError}</FieldError>
        {this.props.children}
      </FormControls>
    );
  }
}

export default AnimatedField;
