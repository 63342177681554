import { wcpDebugProviderLog } from 'legacy/core/error/utilities/ErrorLogger';
import { camelCase } from 'lodash';

export const generateReducerWithPayload = (actionReducerMap) => (state, action) => {
  const reducer = actionReducerMap[action.type];
  if (!reducer) {
    throw Error(`Unknown action: ${JSON.stringify(action)}`);
  }
  return reducer(state, action.payload);
};

export const createActionDispatchers = (dispatch, actionReducerMap) => {
  return Object.keys(actionReducerMap).reduce(
    (acc, type) => ({
      ...acc,
      [camelCase(type) + 'Action']: (payload) => {
        return dispatch({ type, payload });
      },
    }),
    {},
  );
};

export const createActionDispatchersV2 = (dispatch, actionReducerMap) => {
  return Object.keys(actionReducerMap).reduce(
    (acc, type) => ({
      ...acc,
      [type]: (payload) => {
        wcpDebugProviderLog(`ACTION: ${type}`, { type, payload });
        return dispatch({ type, payload });
      },
    }),
    {},
  );
};
