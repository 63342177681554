import { getValueOrFallbackIfUndefined } from 'legacy/shared/v2/utilities/reducerHelpers/getValueOrFallbackIfUndefined';

// updateAlertDetailsAction  →  updateAlertDetailsReducer
export const updateAlertDetailsReducer = (state, payload) => {
  let { alertName, triggerValue, vehicleId, vehicleGroupId } = payload;

  return {
    ...state,
    alert: {
      ...state.alert,
      alertDetails: {
        ...state.alert.alertDetails,
        alertInfo: {
          ...state.alert.alertDetails.alertInfo,
          alertName: getValueOrFallbackIfUndefined(
            alertName,
            state.alert.alertDetails.alertInfo.alertName,
          ),

          triggerValueObject: {
            ...state.alert.alertDetails.alertInfo.triggerValueObject,
            value: getValueOrFallbackIfUndefined(
              triggerValue,
              state.alert.alertDetails.alertInfo.triggerValueObject.value,
            ),
          },
        },
        vehicleInfo: {
          vehicleId,
          vehicleGroupId,
        },
      },
    },
  };
};
