import AddAlertRecipientRowButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/AddAlertRecipientRowButton';
import IncludeLocationCheckbox from 'legacy/features/alerts/ui/components/AlertForms/shared/checkboxes/IncludeLocationCheckbox';
import CooldownPeriodDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/CooldownPeriodDropdown';
import DelayDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/DelayDropdown';
import {
  DelayCooldownWrapper,
  InfoTextWrapper,
  NotificationHeader,
  RecipientHeaderWrapper,
} from 'legacy/shared/v1/styles/components/ManageAlerts';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const NoAlertRecipientsView = () => {
  const {
    state: { alert },
  } = useAlertFormsProviderContext();

  const { alertNotificationGuid } = alert.alertNotifications[0];

  return (
    <>
      <RecipientHeaderWrapper logsOnly>
        <NotificationHeader>Primary Notification</NotificationHeader>
        <AddAlertRecipientRowButton
          alertNotificationGuid={alertNotificationGuid}
          title={'Add Recipient'}
        />
      </RecipientHeaderWrapper>
      <InfoTextWrapper>
        Logs will still be created for this alert scenario even when no recipient is chosen
      </InfoTextWrapper>
    </>
  );
};

export default NoAlertRecipientsView;
