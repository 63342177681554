import { useRef, useState } from 'react';

import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/FilteredDropdown';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';
import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptions';
import { getFormattedDuid } from 'legacy/shared/v2/utilities/getFormattedDuid';
import ResetVsgRegistrationModal from 'legacy/features/vehicles/components/modals/ResetVsgRegistrationModal';
import ConfirmNullVsgIdModal from 'legacy/features/vehicles/components/modals/ConfirmNullVsgIdModal';
import { VehicleEditQRScannerButton } from 'legacy/shared/v1/styles/components/Button';
import {
  VsgIdSelectionInnerWrapperDiv,
  VsgIdSelectionWrapperDiv,
} from 'legacy/shared/v1/styles/components/VehicleEdit';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import QRCodeReader from 'legacy/shared/v1/ui/qr/QRCodeReader';
import { FieldError } from 'legacy/shared/v1/styles/components/Form';

const VehicleDeviceIdField = ({
  formikProps: { values, errors, setFieldValue },
  resetPreferencesModalActive,
  handleConfirmVsgResetPrefencesAccepted,
  handleDeviceIdDirty,
  handleChange,
  handleConfirmVerifyVsgIdAccepted,
  nullVsgModalActive,
  unassociatedVsgs,
  duid,
  setDuid,
}) => {
  const vsgDropdownOptions = new DropdownOptions(
    unassociatedVsgs?.map((vsg) => ({
      label: getFormattedDuid(vsg.vsgDuid),
      value: vsg.vsgDuid,
    })) || [],
  );

  const deviceIdRef = useRef();
  const [isDeviceScannerOpen, setDeviceScannerOpen] = useState(false);

  const toggleDeviceScanner = () => setDeviceScannerOpen((prevState) => !prevState);

  const handleQRCodeScan = () => (scannedResult) => {
    scannedResult = scannedResult.replace(/(\r\n|\n|\r)/gm, '');
    setDuid(scannedResult);
    toggleDeviceScanner();
    handleDeviceIdDirty(true);
    setFieldValue('duid', scannedResult);
  };

  useMakeFormikField({
    fieldName: 'duid',
    fieldValue: duid,
  });
  return (
    <>
      {resetPreferencesModalActive ? (
        <ResetVsgRegistrationModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVsgResetPrefencesAccepted(isAccepted);
          }}
        />
      ) : null}
      {nullVsgModalActive ? (
        <ConfirmNullVsgIdModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVerifyVsgIdAccepted(isAccepted);
          }}
        />
      ) : null}
      <VsgIdSelectionWrapperDiv>
        <VsgIdSelectionInnerWrapperDiv noVsgSelected={!duid}>
          <FilteredDropdown
            fieldLabel={'VSG Registration ID'}
            placeholderLabel="Select a VSG ID..."
            styleOptions={{
              showClearSelectionButton: true,
              narrowWidth: false,
              fullWidth: true,
            }}
            setup={{
              dropdownOptions: vsgDropdownOptions,
              preselectedOption: duid
                ? {
                    label: getFormattedDuid(duid), // TODO, some VSG ids do not get formatted correctly here
                    value: duid,
                  }
                : null,
              handleSelect: (selectedOption) => {
                handleDeviceIdDirty(selectedOption.value !== duid);
                setDuid(selectedOption.value);
              },
            }}
          />
        </VsgIdSelectionInnerWrapperDiv>
        {!duid && (
          <div>
            <VehicleEditQRScannerButton type="button" onClick={toggleDeviceScanner}>
              <IconSvgComponent
                svgFileName={'qr-scanner'}
                title="Scan QR code"
                alt="Scan QR code"
              />
            </VehicleEditQRScannerButton>
          </div>
        )}
      </VsgIdSelectionWrapperDiv>
      <QRCodeReader
        isOpen={isDeviceScannerOpen}
        handleScan={handleQRCodeScan(setFieldValue, 'duid', deviceIdRef, toggleDeviceScanner)}
      />
      <FieldError>{errors.vsgDuid}</FieldError>
    </>
  );
};

export default VehicleDeviceIdField;
