import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import { useEffect } from 'react';
import { useState } from 'react';

export const useFetchGroupByNameQuery = ({ groupName }) => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();

  const [group, setGroup] = useState(null);

  useEffect(() => {
    if (groups) {
      let g = groups.find((g) => g.group_name === groupName);
      setGroup(g);
    }
  }, [groups]);

  return [fetchGroupsQuery, group];
};
