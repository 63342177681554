import {
  vehicleTypeViewData,
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
} from 'legacy/shared/v1/constants/vehicle';
import colors from 'legacy/shared/v1/constants/colors';

import { MAP_ANIMATION_DURATION } from 'legacy/shared/v1/constants/map';
import { vehicleAddonAlertLevels } from 'legacy/features/vehicles/constants/vehicleAddonAlertLevels';

// HELPERS //

// CHECK IF THESE ARE CORRECT WHEREVER THEY WERE MIGRATE TO
// Gets next lat/long coordinate for vehicle icon based on current vehicle gps lat/long and animation progress
export const getNextCoordinates = (startLocation, endLocation, elapsedProgress) => {
  return startLocation.longitude && startLocation.latitude
    ? [
        startLocation.longitude +
          (endLocation.longitude - startLocation.longitude) * elapsedProgress,
        startLocation.latitude + (endLocation.latitude - startLocation.latitude) * elapsedProgress,
      ]
    : [endLocation.longitude, endLocation.latitude];
};

// Gets next rotation direction for vehicle icon based on current vehicle gps heading and animation progress
export const getNextRotation = (startHeading, endHeading, elapsedProgress) => {
  elapsedProgress = Math.min(elapsedProgress * 2, 1);
  let nextRotation;

  if (endHeading > startHeading && endHeading - startHeading < 180) {
    nextRotation = startHeading + (endHeading - startHeading) * elapsedProgress;
  }

  if (endHeading > startHeading && endHeading - startHeading > 180) {
    nextRotation = startHeading - (startHeading - endHeading + 360) * elapsedProgress;
  }

  if (endHeading < startHeading && endHeading - startHeading > -180) {
    nextRotation = startHeading - (startHeading - endHeading) * elapsedProgress;
  }

  if (endHeading < startHeading && endHeading - startHeading < -180) {
    nextRotation = startHeading + (360 - (startHeading - endHeading)) * elapsedProgress;
  }

  if (endHeading === startHeading) {
    nextRotation = endHeading;
  }

  return nextRotation;
};

export const getAlertLevelMapboxAssetName = (addons) => {
  if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[3].label && a.is_active))
    return 'addonHighAlert';
  else if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[2].label && a.is_active))
    return 'addonMediumAlert';
  else if (addons.some((a) => a.alert_level === vehicleAddonAlertLevels[1].label && a.is_active))
    return 'addonLowAlert';
  else return ''; // mapbox does not like null here
};

// Gets default geojson feature for vehicle icon using vehicle data to populate geometry and properties
export const getBaseVehicleFeature = (v) => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: getVehicleGpsCoordinates(v),
  },
  properties: {
    id: v.vehicle_id,
    name: v.meta.alias ? v.meta.alias : v.meta.label,
    status: v.onlineStatus,
    ...(v.addons.length > 0 ? { alert_level: getAlertLevelMapboxAssetName(v.addons) } : {}),
    halo_color:
      v.onlineStatus === vehicleOnlineStatusViewData.INACTIVE.id ? colors.midnight : colors.cherry,
    type: v.meta.vehicle_type,
    icon_type: `${vehicleTypeViewData[v.meta.vehicle_type].icon}${
      v.onlineStatus === vehicleOnlineStatusViewData.INACTIVE.id ? '_inactive' : ''
    }`,
    icon_rotate: v.gps.heading,
    circle_opacity: v.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id ? 0.2 : 0,
    vehicle_opacity: v.isSelected === null || v.isSelected === true ? 1 : 0.5,
  },
});

// PUBLIC //
export const getVehicleGpsCoordinates = (v) => {
  return [v.gps.longitude, v.gps.latitude];
};

export const animatePlaybackLocation =
  ({
    renderMapData,
    startTime, // ref
    frameHandle, // ref
    duration,
    startLocation,
    endLocation,
    playbackPerformanceMode,
  }) =>
  (timestamp) => {
    if (startTime.current === null) {
      startTime.current = timestamp;
    }
    // elapsed time is calculated since start of current animation from animationArray
    const elapsedTime = timestamp - startTime.current;
    const elapsedProgress = Math.min((elapsedTime / duration).toFixed(2), 1);

    let nextCoordinates = getNextCoordinates(
      { longitude: startLocation.long, latitude: startLocation.lat },
      { longitude: endLocation.long, latitude: endLocation.lat },

      elapsedProgress,
    );

    let nextHeading = getNextRotation(startLocation.heading, endLocation.heading, elapsedProgress);
    // update map
    renderMapData(nextCoordinates, nextHeading, startLocation.status);
    if (!playbackPerformanceMode) {
      if (elapsedTime < duration) {
        frameHandle.current = requestAnimationFrame(
          animatePlaybackLocation({
            renderMapData,
            startTime, // ref
            frameHandle, // ref
            duration,
            startLocation,
            endLocation,
          }),
        );
      }
    }
  };
