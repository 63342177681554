import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import useAlertRecipientDropdown from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/dropdowns/useAlertRecipientDropdown';
import useFetchRecipientGroupMembers from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/dropdowns/useFetchRecipientGroupMembers';
import NewFilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/NewFilteredDropdown';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';

// alert recipient dropdown
// contains a list of both individuals and groups
const AlertRecipientDropdown = ({ alertNotification, recipientRow }) => {
  let { alertNotificationGuid } = alertNotification;

  let { recipientRowGuid, recipientGroup, recipientUser } = recipientRow;

  const {
    actions: { updateAlertRecipientAction },
  } = useAlertFormsProviderContext();

  const dropdownOptions = useAlertRecipientDropdown({ alertNotification, recipientRow });
  useFetchRecipientGroupMembers({ alertNotification, recipientRow });

  // determine the recipient user or group name for the formik field value as well as the dropdown initial value
  let recipientUserOrGroupName = recipientGroup
    ? recipientGroup.recipientGroupName
    : recipientUser
      ? recipientUser.recipientUserName
      : null;

  // set up formik field
  useMakeFormikField({
    fieldName: 'alertRecipientField',
    fieldValue: recipientUserOrGroupName,
    triggerValidation: true,
  });

  return (
    <div>
      <NewFilteredDropdown
        styleOptions={{
          hideClearX: true,
        }}
        title="Recipient"
        placeholderLabel="Select a recipient..."
        setup={{
          dropdownOptions,
          filterOptions: (u) =>
            !alertNotification?.alertRecipientRows
              ?.map((r) => r?.recipientUser?.recipientUserName)
              .includes(u.value),
          initialValue: recipientUserOrGroupName,

          onSelect: ({ value }) => {
            updateAlertRecipientAction({
              alertNotificationGuid,
              recipientRowGuid,
              selectedRecipientValue: value,
            });
          },
        }}
      />
      {/* <FieldError>
        {formik.touched.alertRecipientField && formik.errors.alertRecipientField}
      </FieldError> */}
    </div>
  );
};

export default AlertRecipientDropdown;
