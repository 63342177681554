import { useEffect } from 'react';

import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import VehiclePlaybackPlayPauseControl from 'legacy/features/playback/ui/components/VehiclePlaybackSidebarPlaybackControls/VehiclePlaybackPlayPauseControl';
import VehiclePlaybackSlider from 'legacy/features/playback/ui/components/VehiclePlaybackSidebarPlaybackControls/VehiclePlaybackSlider';
import VehiclePlaybackSpeedSummary from 'legacy/features/playback/ui/components/VehiclePlaybackSidebarPlaybackControls/VehiclePlaybackSpeedSummary';
import useInterval from 'legacy/shared/v1/hooks/useInterval';
import {
  NoPlaybackDiv,
  PlaybackControlsDiv,
} from 'legacy/shared/v1/styles/components/VehiclePlayback';
import VehiclePlaybackSpeedDropdown from 'legacy/features/playback/ui/components/VehiclePlaybackSidebarPlaybackControls/VehiclePlaybackSpeedDropdown';
import VehiclePlaybackExportCsvButton from 'legacy/features/playback/ui/components/VehiclePlaybackRequestForm/buttons/VehiclePlaybackExportCsvButton';

const VehiclePlaybackSidebarPlaybackControls = () => {
  const {
    actions: { setPlaybackAnimationControlsAction },
    state: {
      playbackData: { locationData },
      playbackAnimationControls: {
        currentPlaybackDataIndex,
        playbackToggle,
        playbackSpeed,
        selectedPlaybackSpeed,
      },
    },
  } = useVehiclePlaybackProviderContext();

  useInterval({
    callback: () => {
      let nextFrameIndex = currentPlaybackDataIndex + 1;

      if (currentPlaybackDataIndex < locationData?.length - 1) {
        setPlaybackAnimationControlsAction({ currentPlaybackDataIndex: nextFrameIndex });
      } else {
        setPlaybackAnimationControlsAction({ playbackSpeed: null, playbackToggle: false });
      }
    },
    delay: playbackSpeed,
  });

  useEffect(() => {
    setPlaybackAnimationControlsAction({
      playbackSpeed: playbackToggle ? selectedPlaybackSpeed.value : null,
    });
  }, [selectedPlaybackSpeed]);

  return locationData == null ? (
    <></> /* If locationData is null, return an empty fragment */
  ) : locationData.length > 0 ? (
    <PlaybackControlsDiv>
      <VehiclePlaybackSpeedDropdown />
      <VehiclePlaybackSlider />
      <VehiclePlaybackPlayPauseControl />
      <VehiclePlaybackSpeedSummary />
      <VehiclePlaybackExportCsvButton />
    </PlaybackControlsDiv>
  ) : (
    <NoPlaybackDiv>There is no vehicle playback data for the current date/time range</NoPlaybackDiv>
  );
};

export default VehiclePlaybackSidebarPlaybackControls;
