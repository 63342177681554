import { playbackSpeedOptions } from 'legacy/features/playback/data/ui/dropdown/playbackSpeedOptions';
import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';

import { FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';
import { PlaybackSpeedDiv } from 'legacy/shared/v1/styles/components/VehiclePlayback';

const VehiclePlaybackSpeedDropdown = () => {
  const {
    actions: { setPlaybackAnimationControlsAction },
  } = useVehiclePlaybackProviderContext();
  return (
    <PlaybackSpeedDiv>
      <FormLabelAnimated animated>Playback Speed</FormLabelAnimated>

      <StyledReactSelect
        name="playbackSpeed"
        placeholder="Playback Speed"
        defaultValue={{
          label: 'Normal',
        }}
        onChange={(e) => setPlaybackAnimationControlsAction({ selectedPlaybackSpeed: e })}
        isDisabled={false}
        isClearable={false}
        isSearchable={false}
        options={playbackSpeedOptions}
      />
    </PlaybackSpeedDiv>
  );
};

export default VehiclePlaybackSpeedDropdown;
