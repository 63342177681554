import { alertRecipientType } from 'legacy/features/alerts/data/enums/alertRecipientType';
import { v4 as uuidv4 } from 'uuid';

export const generateEmptyAlert = () => {
  // construct a new alert object
  const newAlert = {
    alertId: null,
    organizationId: null,
    alertDetails: {
      alertInfo: {
        alertName: null,
        isActive: null,
        alertType: null,
        triggerValueObject: {
          addon: null,
          value: null,
        },
        unitOfMeasure: null,
      },
      vehicleInfo: {
        vehicleGroupId: null,
        vehicleId: null,
      },
      decorated: {
        targetVehicleOrGroupName: null,
        activeInactiveDropdownOption: null,
        unitOfMeasureDropdownOption: null,
        alertAddonDropdownOption: null,
        summaryTable: { recipientColumn: null, valueColumn: null, conditionColumn: null },
      },
    },
    alertNotifications: [
      {
        alertNotificationGuid: uuidv4(),
        alertRecipientRows: [
          {
            recipientRowGuid: uuidv4(),
            recipientRowAlertMethod: null,
          },
        ],
        cooldownMinutes: 1440,
        delayMinutes: 0,
        isLocationIncluded: true,
      },
    ],
  };

  return newAlert;
};
