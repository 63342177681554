import { getOrganizationUsersApi } from 'legacy/features/users/api/get/getOrganizationUsersApi';
import useGenericQuery from 'legacy/shared/v2/hooks/reactQuery/useGenericQuery';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

const useFetchOrganizationUsersQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getOrganizationUsersApi',
    apiFn: getOrganizationUsersApi,
    onErrorCallback: (err) => {
      logWcpError(err);
    },
  });

  return [queryResult.data, queryKey];
};

export default useFetchOrganizationUsersQuery;
