import { apiCall } from 'legacy/core/api/API';
import { mapAndMassageMaintenanceData } from 'legacy/features/maintenance/utilities/mapping';
import { mapRecipientDataToBackend } from 'legacy/features/recipientGroups/utilities/recipientGroupMapping';

export const putRecipientGroupApi = async ({ organizationId, updatedGroup }) => {
  // map and massage maintenance data
  const mappedRecipientGroup = mapRecipientDataToBackend(updatedGroup);

  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  return await apiCall({
    path: `/user_group/${updatedGroup.recipientGroupId}`,
    method: 'put',
    queryStringParameters,
    body: {
      ...mappedRecipientGroup,
    },
  });
};
