import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFirmwaresByVehicle } from 'legacy/core/api/firmware';
import { updateFetchError } from 'legacy/features/firmware/helpers/notifications';
import { FIRMWARE_REFETCH_INTERVAL } from 'legacy/shared/v1/constants/firmwares';
import { checkLogin } from 'legacy/shared/v1/utilities/user';

const useFetchFirmwareByVehiclesQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
} = {}) => {
  const dispatch = useDispatch();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const [updates, setFirmwareUpdateData] = useState(null);

  // gets firmware updates
  const fetchUpdatesQuery = useQuery({
    queryKey: ['fetchFirmwareUpdatesByVehicleQuery' + key],
    queryFn: () => {
      return getFirmwaresByVehicle({ organizationId: filteredOrganizationId });
    },

    enabled: autoRun && checkLogin(),
    select: (response) => {
      return response.data.message;
    },
    onError: (err) => dispatch(updateFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
  });

  useEffect(() => {
    setFirmwareUpdateData(fetchUpdatesQuery.data);
  }, [fetchUpdatesQuery.data]);

  return {
    fetchUpdatesQuery,
    updates,
  };
};

export default useFetchFirmwareByVehiclesQuery;
