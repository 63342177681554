import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'legacy/shared/v1/styles/components/Button';

const CancelButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      type="button"
      onClick={() => {
        // navigate to managealerts
        navigate('/managealerts');
      }}
      withRightSpacer
      mediumAlt
      default
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
