import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { alertValidators } from 'legacy/features/alerts/services/formik/validation/alertValidators';

export const validateAlert = (values) => {
  const {
    alertNameField,
    alertTypeField,
    alertTriggerValueField,
    unitOfMeasureField,
    alertAddonField,
    activeInactiveDropdownValueField,
  } = values;

  const alertNameErrors = alertValidators.alertNameFieldValidation(alertNameField, 36);
  const alertTypeErrors = alertValidators.alertTypeFieldValidation(alertTypeField);

  const alertTriggerValueErrors =
    (values.alertTypeField === alertTypeConstants.SPEED_EXCEEDS ||
      values.alertTypeField === alertTypeConstants.NR_SPEED_EXCEEDS ||
      values.alertTypeField === alertTypeConstants.OFFLINE_TIME_EXCEEDS) &&
    alertValidators.alertTriggerValueFieldValidation(alertTriggerValueField);

  const unitOfMeasureErrors =
    (values.alertTypeField === alertTypeConstants.SPEED_EXCEEDS ||
      values.alertTypeField === alertTypeConstants.NR_SPEED_EXCEEDS ||
      values.alertTypeField === alertTypeConstants.OFFLINE_TIME_EXCEEDS) &&
    alertValidators.unitOfMeasureFieldValidation(unitOfMeasureField);

  const alertAddonErrors =
    values.alertTypeField === alertTypeConstants.ADDON_ACTIVE &&
    alertValidators.alertAddonFieldValidation(alertAddonField);

  const activeInactiveDropdownValueErrors =
    (values.alertTypeField === alertTypeConstants.ADDON_ACTIVE ||
      values.alertTypeField === alertTypeConstants.CHECK_ENGINE_LIGHT) &&
    alertValidators.triggerDropdownValueValidation(activeInactiveDropdownValueField);

  // validate each alert method in the alert notifications / recipient rows / row alert methods
  const alertMethodErrors = values.alert?.alertNotifications
    .map((an) => an.alertRecipientRows)
    .flat()
    .map((rr) => alertValidators.alertMethodFieldValidation(rr.recipientRowAlertMethod))
    .filter(Boolean);

  // validate each alert recipient in the alert notifications / recipient rows / row recipients (which can either be a group or a user)
  const alertRecipientErrors = values.alert?.alertNotifications
    .map((an) => an.alertRecipientRows)
    .flat()
    .map((rr) =>
      alertValidators.alertRecipientFieldValidation(rr.recipientUser || rr.recipientGroup),
    )
    .filter(Boolean);

  let errors = {
    ...(alertNameErrors ? { alertNameField: alertNameErrors } : {}),
    ...(alertTypeErrors ? { alertTypeField: alertTypeErrors } : {}),
    ...(alertTriggerValueErrors ? { alertTriggerValueField: alertTriggerValueErrors } : {}),
    ...(unitOfMeasureErrors ? { unitOfMeasureField: unitOfMeasureErrors } : {}),
    ...(alertAddonErrors ? { alertAddonField: alertAddonErrors } : {}),
    ...(activeInactiveDropdownValueErrors
      ? { activeInactiveDropdownValueField: activeInactiveDropdownValueErrors }
      : {}),

    // notifications and recipients
    ...(alertMethodErrors?.length > 0 ? { alertMethodField: alertMethodErrors } : {}),
    ...(alertRecipientErrors?.length > 0 ? { alertRecipientField: alertRecipientErrors } : {}),
  };

  return errors;
};
