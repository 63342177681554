import { BackendValidationError } from 'legacy/core/error/classes/BackendValidationError';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { activeInactiveDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/activeInactiveDropdownOptions';
import { offlineTimeUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/offlineTimeUnitDropdownOptions';
import { speedUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/speedUnitDropdownOptions';
import { offlineTimeUnitConverterToFrontend } from 'legacy/features/alerts/services/utilities/offlineTimeUnitConverterToFrontend';
import { vehicleAddonOptions } from 'legacy/features/vehicles/constants/vehicleAddonDictionary';

// TODO this is being used by both the backend and the updateAlertType reducer... need to refactor
// for now 'null' is probably coming from a reducer whereas 'undefined' is coming from the backend
export const decorateFromTriggerValue = ({ alertDetails }) => {
  let { decorated, alertInfo } = alertDetails;
  let { alertType, triggerValueObject, unitOfMeasure } = alertInfo;
  decorated.summaryTable = {};

  switch (alertType) {
    case alertTypeConstants.ADDON_ACTIVE:
      // triggerValueObject: { addon: 'addon name', value: 'active/inactive' }
      {
        try {
          if (triggerValueObject.addon === undefined) {
            throw new BackendValidationError('Addon name is required for alert type ADDON_ACTIVE');
          }
        } catch (e) {
          logWcpError(e);

          // force 'make a selection'
          triggerValueObject.addon = null;
        } finally {
          // addon
          decorated.alertAddonDropdownOption = triggerValueObject.addon
            ? {
                label: vehicleAddonOptions.getLabelByValue(triggerValueObject.addon),
                value: triggerValueObject.addon,
              }
            : null;

          // active / inactive
          decorated.activeInactiveDropdownOption = {
            value: triggerValueObject.value,
            label: activeInactiveDropdownOptions.getLabelByValue(triggerValueObject.value),
          };

          decorated.summaryTable.valueColumn =
            triggerValueObject?.addon && triggerValueObject?.value
              ? `${vehicleAddonOptions.getLabelByValue(
                  triggerValueObject.addon,
                )} ${activeInactiveDropdownOptions.getLabelByValue(triggerValueObject.value)}`
              : '';
        }
      }
      break;
    case alertTypeConstants.CHECK_ENGINE_LIGHT:
      // triggerValueObject: { value: 'active/inactive' }
      {
        let label = triggerValueObject.value
          ? activeInactiveDropdownOptions.getLabelByValue(triggerValueObject.value)
          : '';

        decorated.activeInactiveDropdownOption = {
          value: triggerValueObject.value,
          label,
        };

        decorated.summaryTable.valueColumn = decorated.activeInactiveDropdownOption.label;
      }
      break;
    case alertTypeConstants.MAINTENANCE_DUE:
      // triggerValueObject: { value: null }
      {
        decorated.activeInactiveDropdownOption = {
          value: triggerValueObject.value,
          label: 'N/A',
        };

        decorated.summaryTable.valueColumn = decorated.activeInactiveDropdownOption.label;
      }
      break;
    case alertTypeConstants.SPEED_EXCEEDS:
    case alertTypeConstants.NR_SPEED_EXCEEDS:
      // triggerValueObject: { value: 'number' }
      // unitOfMeasure: 'mph' | 'km/h'
      {
        setUnitOfMeasure(unitOfMeasure, triggerValueObject, decorated, speedUnitDropdownOptions);
      }
      break;
    case alertTypeConstants.OFFLINE_TIME_EXCEEDS:
      // triggerValueObject: { value: 'number' }
      // unitOfMeasure: 'minutes' | 'hours' | 'days' | 'weeks'
      {
        setUnitOfMeasure(
          unitOfMeasure,
          triggerValueObject,
          decorated,
          offlineTimeUnitDropdownOptions,
        );
      }
      break;
    default:
      throw new BackendValidationError(`Invalid alert type. Received: ${alertType}`);
  }
};

const setUnitOfMeasure = (unitOfMeasure, triggerValueObject, decorated, dropdownOptions) => {
  try {
    if (unitOfMeasure === undefined) {
      throw new BackendValidationError(
        'Unit of measure is required for alert type OFFLINE_TIME_EXCEEDS',
      );
    }
  } catch (e) {
    logWcpError(e);

    // force 'make a selection'
    unitOfMeasure = null;
  } finally {
    if (unitOfMeasure === null) {
      unitOfMeasure = dropdownOptions.getAllOptions()[0].value;
    }

    let value = offlineTimeUnitConverterToFrontend(unitOfMeasure, triggerValueObject.value);

    let label = dropdownOptions.getLabelByValue(unitOfMeasure);
    label = label.replace('(s)', value === 1 ? '' : 's');

    decorated.dropdownOptions = {
      label,
      value,
    };

    decorated.summaryTable.valueColumn = `${value} ${label}`;
  }
};
