import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import ManageAlertsLogCard from 'legacy/features/alerts/ui/components/AlertLogsSummary/mobile/ManageAlertsLogCard';
import ManageAlertsLogsActionMenu from 'legacy/features/alerts/ui/components/AlertLogsSummary/menus/ManageAlertsLogsActionMenu';
import DeviceLogsCard from 'legacy/features/billing/ui/components/DeviceLogsSummary/mobile/DeviceLogsCard';

const DeviceLogsCardContainer = ({ deviceLogs }) => {
  return (
    <div>
      <Cards>
        {deviceLogs.map((d) => (
          <DeviceLogsCard log={d} key={d.timestamp + d.action} />
        ))}
      </Cards>
    </div>
  );
};

export default DeviceLogsCardContainer;
