import React, { useRef, useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl';
import {
  whelenGpsCoordinates,
  mapboxAccessToken,
  mapboxStyleUrl,
} from 'legacy/shared/v1/constants/map';
import {
  MapContentDiv,
  MapboxDiv,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import { MapboxCustomControls } from 'legacy/shared/v1/utilities/map/MapboxCustomControls';

import MapControls from 'legacy/shared/v1/widgets/MapControls_legacy';
import { getHeatmapLocationInLocal } from 'legacy/shared/v1/utilities/localStore';

const AnalyticsHeatmapMapboxComponent = ({
  heatmapData,
  respondingHeatmapData,
  notRespondingHeatmapData,
  showRespondingLayers,
  showNotRespondingLayers,
}) => {
  const mapbox = useRef(null);
  const mapContainer = useRef(null);
  const [respondingHeatmapDataFeatures, setRespondingHeatmapDataFeatures] =
    useState(respondingHeatmapData);
  const [notRespondingHeatmapDataFeatures, setNotRespondingHeatmapDataFeatures] =
    useState(notRespondingHeatmapData);
  const [respondingLayerIds, setRespondingLayerIds] = useState([]);
  const [notRespondingLayerIds, setNotRespondingLayerIds] = useState([]);

  const initializeMap = ({ mapContainer }) => {
    mapboxgl.accessToken = mapboxAccessToken;
    const sessionBounds = getHeatmapLocationInLocal();

    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapboxStyleUrl,
      dragRotate: false,
      maxZoom: 15,
      bounds: sessionBounds ? sessionBounds : whelenGpsCoordinates,
      fitBoundsOptions: { padding: 50, maxZoom: 10 },
    });

    map.addControl(
      new MapboxCustomControls(
        <MapControls map={map} showSatelliteLayerToggle={false} showFocusToggle={true} />,
      ),
      'bottom-right',
    );

    const mapLoadedEvent = new Event('mapLoaded', { bubbles: true });
    window.document.dispatchEvent(mapLoadedEvent);

    map.on('load', function () {});
    // set ref to mapbox object
    mapbox.current = map;
  };

  const initializeMapData = () => {
    setRespondingHeatmapDataFeatures(respondingHeatmapData);
    setNotRespondingHeatmapDataFeatures(notRespondingHeatmapData);
  };

  useEffect(() => {
    initializeMapData();
  }, [respondingHeatmapData, notRespondingHeatmapData]);

  useEffect(() => {
    initializeMapData();

    if (!mapbox.current) initializeMap({ mapContainer });

    return () => {
      if (mapbox && mapbox.current) {
        mapbox.current.remove();
        mapbox.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (mapbox.current) {
      if (respondingLayerIds.length > 0) {
        //check for old layers and remove them
        for (let i = 0; i < respondingLayerIds.length; i++) {
          if (mapbox.current.getLayer(respondingLayerIds[i])) {
            mapbox.current.removeLayer(respondingLayerIds[i]);
            mapbox.current.removeSource('vehicle_responding_data' + i);
          }
          setRespondingLayerIds([]);
        }
      }
      if (respondingHeatmapDataFeatures) {
        let opacityValue1 = 7 / respondingHeatmapDataFeatures.length;
        let opacityValue2 = 0.5 / respondingHeatmapDataFeatures.length;
        let opacityValue3 = 9 / respondingHeatmapDataFeatures.length;
        let opacityValue4 = 1 / respondingHeatmapDataFeatures.length;
        // create layers for each link (month) of responding data we get back
        for (let i = 0; i < respondingHeatmapDataFeatures.length; i++) {
          mapbox.current.addSource('vehicle_responding_data' + i, {
            type: 'geojson',
            data: respondingHeatmapDataFeatures[i],
          });

          mapbox.current.addLayer({
            id: 'vehicle-heatmap-responding-' + i,
            type: 'heatmap',
            source: 'vehicle_responding_data' + i,

            paint: {
              'heatmap-weight': ['interpolate', ['linear'], ['heatmap-density'], 0.1, 1, 0.5, 20],
              'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 10, 5, 24, 75],
              'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 9, 0.1, 20, 3],
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(0, 0, 0, 0.4)',
                0.5,
                'rgba(170, 100, 100, .5)',
                0.55,
                'rgba(235, 135, 100, .5)',
                0.6,
                'rgba(200, 200, 100, .7)',
                0.65,
                'rgba(249, 242, 150,0.3)',
              ],
              //the rhyme or reason to the following 4 values is somewhat unknown at this point, but now we are preserving a consisent look regardless of the number of layers
              'heatmap-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                opacityValue1,
                opacityValue2,
                opacityValue3,
                opacityValue4,
              ],
            },
          });
          //add each layer id to the array so we can use this list to rmeove them later when the query is refreshed
          setRespondingLayerIds((respondingLayerIds) => [
            ...respondingLayerIds,
            'vehicle-heatmap-responding-' + i,
          ]);
        }
      }
    }
  }, [respondingHeatmapDataFeatures]);

  useEffect(() => {
    if (mapbox.current) {
      if (notRespondingLayerIds.length > 0) {
        //check for old layers and remove them
        for (let i = 0; i < notRespondingLayerIds.length; i++) {
          if (mapbox.current.getLayer(notRespondingLayerIds[i])) {
            mapbox.current.removeLayer(notRespondingLayerIds[i]);
            mapbox.current.removeSource('vehicle_not_responding_data' + i);
          }
          setNotRespondingLayerIds([]);
        }
      }
      if (notRespondingHeatmapDataFeatures) {
        let opacityValue1 = 7 / notRespondingHeatmapDataFeatures.length;
        let opacityValue2 = 0.5 / notRespondingHeatmapDataFeatures.length;
        let opacityValue3 = 9 / notRespondingHeatmapDataFeatures.length;
        let opacityValue4 = 1 / notRespondingHeatmapDataFeatures.length;
        // create layers for each link (month) of not responding data we get back
        for (let i = 0; i < notRespondingHeatmapDataFeatures.length; i++) {
          mapbox.current.addSource('vehicle_not_responding_data' + i, {
            type: 'geojson',
            data: notRespondingHeatmapDataFeatures[i],
          });

          mapbox.current.addLayer({
            id: 'vehicle-heatmap-not-responding-' + i,
            type: 'heatmap',
            source: 'vehicle_not_responding_data' + i,

            paint: {
              'heatmap-weight': ['interpolate', ['linear'], ['heatmap-density'], 0.1, 1, 0.5, 20],
              'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 10, 5, 24, 75],
              'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 9, 0.1, 20, 3],
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(0, 0, 0, 0.4)',
                0.5,
                'rgba(170, 100, 100, .5)',
                0.55,
                'rgba(235, 135, 100, .5)',
                0.6,
                'rgba(200, 200, 100, .7)',
                0.65,
                'rgba(249, 242, 150,0.3)',
              ],
              //the rhyme or reason to the following 4 values is somewhat unknown at this point, but now we are preserving a consisent look regardless of the number of layers
              'heatmap-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                opacityValue1,
                opacityValue2,
                opacityValue3,
                opacityValue4,
              ],
            },
          });
          //add each layer id to the array so we can use this list to rmeove them later when the query is refreshed
          setNotRespondingLayerIds((notRespondingLayerIds) => [
            ...notRespondingLayerIds,
            'vehicle-heatmap-not-responding-' + i,
          ]);
        }
      }
    }
  }, [notRespondingHeatmapDataFeatures]);

  useEffect(() => {
    if (mapbox.current) {
      if (respondingLayerIds.length > 0) {
        //toggle visibility of all responding layers based off of showRespondingLayers
        if (showRespondingLayers) {
          for (let i = 0; i < respondingLayerIds.length; i++) {
            if (mapbox.current.getLayer(respondingLayerIds[i])) {
              mapbox.current.setLayoutProperty(respondingLayerIds[i], 'visibility', 'visible');
            }
          }
        } else {
          for (let i = 0; i < respondingLayerIds.length; i++) {
            if (mapbox.current.getLayer(respondingLayerIds[i])) {
              mapbox.current.setLayoutProperty(respondingLayerIds[i], 'visibility', 'none');
            }
          }
        }
      }
    }
  }, [showRespondingLayers]);

  useEffect(() => {
    if (mapbox.current) {
      if (notRespondingLayerIds.length > 0) {
        //toggle visibility of all not responding layers based off of showNotRespondingLayers
        if (showNotRespondingLayers) {
          for (let i = 0; i < notRespondingLayerIds.length; i++) {
            if (mapbox.current.getLayer(notRespondingLayerIds[i])) {
              mapbox.current.setLayoutProperty(notRespondingLayerIds[i], 'visibility', 'visible');
            }
          }
        } else {
          for (let i = 0; i < notRespondingLayerIds.length; i++) {
            if (mapbox.current.getLayer(notRespondingLayerIds[i])) {
              mapbox.current.setLayoutProperty(notRespondingLayerIds[i], 'visibility', 'none');
            }
          }
        }
      }
    }
  }, [showNotRespondingLayers]);

  return (
    <MapContentDiv>
      <MapboxDiv ref={mapContainer} />
    </MapContentDiv>
  );
};

export default AnalyticsHeatmapMapboxComponent;
