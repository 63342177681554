import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getGeofencesData, getIntersectionsData } from 'legacy/core/api/geofences';
import GeofencesContainer from 'legacy/features/geofences/geofences/components/GeofencesContainer';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';

const GeofencesPage = ({ currentOrganizationId }) => {
  const [geofencesData, setGeofencesData] = useState([]);
  const [intersectionsData, setIntersectionsData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState([]);

  const reloadGeofencesData = async () => {
    setDataLoaded(false);
    let {
      response: { message: responseData },
    } = await getGeofencesData({ organizationId: currentOrganizationId });

    let mappedData = responseData.map((r) => ({
      label: r.label,
      geofenceId: r.geofence_id,
      organizationId: r.organization_id,
      vehicleState: r.vehicle_state,
      category: r.category,
      enabled: r.enabled,
      points: r.points,
      activeRange: r.active_range,
      activeTimeType: r.active_time_type,
      timeZone: r.timezone,
      expirationTime: r.expiration_time,
      dst: r.daylight_savings,
      performConfigUpdate: r.perform_config_update ? r.perform_config_update : false,
      performFirmwareUpdate: r.perform_firmware_update ? r.perform_firmware_update : false,
      latest: r.latest,
    }));

    let {
      response: { message: intersectionResponseData },
    } = await getIntersectionsData({ organizationId: currentOrganizationId });

    let mappedIntersectionData = intersectionResponseData.map((r) => ({
      category_name: r.category_name,
      enabled: r.enabled,
    }));
    setGeofencesData(mappedData);
    setIntersectionsData(mappedIntersectionData);
    setDataLoaded(true);
  };

  useEffect(() => {
    async function reload() {
      setDataLoaded(false);
      await reloadGeofencesData();
      setDataLoaded(true);
    }
    reload();
  }, [currentOrganizationId]);

  return (
    <>
      <GeofencesContainer
        currentOrganizationId={currentOrganizationId}
        geofencesData={geofencesData}
        intersectionsData={intersectionsData}
        reloadGeofencesData={reloadGeofencesData}
      />
      {!dataLoaded && <LoadingOverlay></LoadingOverlay>}
    </>
  );
};

export default connect((state) => ({
  currentOrganizationId: state.user.filteredOrganizationId,
}))(GeofencesPage);
