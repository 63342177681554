import { offlineTimeUnitConstants } from 'legacy/features/alerts/data/enums/offlineTimeUnitConstants';
import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptionsLegacy';

// dropdown data
export const offlineTimeUnitDropdownOptions = new DropdownOptions([
  { value: offlineTimeUnitConstants.MINUTES, label: 'Minute(s)' },
  { value: offlineTimeUnitConstants.HOURS, label: 'Hour(s)' },
  { value: offlineTimeUnitConstants.DAYS, label: 'Day(s)' },
  { value: offlineTimeUnitConstants.WEEKS, label: 'Week(s)' },
]);
