import { useNavigate } from 'react-router-dom';

import Button from 'legacy/shared/v1/styles/components/Button';
import { AssignCancelDiv } from 'legacy/shared/v1/styles/components/Form';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';

const CreateRecipientGroupFormSubmit = ({ formik }) => {
  const navigate = useNavigate();
  return (
    <AssignCancelDiv>
      <>
        <Button
          type="button"
          onClick={() => navigate('/managerecipientgroups')}
          withRightSpacer
          mediumAlt
          default
        >
          Cancel
        </Button>
        <ButtonWithLoader
          isLoading={formik.isSubmitting}
          confirmText={'Create Recipient Group'}
          loadingStyleProp={'submittingWithSpinnerModal'}
          notLoadingStyleProp={'mediumAlt'}
          disabled={false}
        />
      </>
    </AssignCancelDiv>
  );
};

export default CreateRecipientGroupFormSubmit;
