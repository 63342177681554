import Media from 'react-media';

import { sizes } from 'legacy/shared/v1/utilities/media';

const MediaWrapper = ({ mobileComponent, nonMobileComponent }) => {
  return (
    <Media
      queries={{
        tablet: { maxWidth: sizes.tablet },
        mobile: { maxWidth: sizes.mobile },
      }}
    >
      {(matches) => (matches.tablet ? mobileComponent : nonMobileComponent)}
    </Media>
  );
};

export default MediaWrapper;
