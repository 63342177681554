import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import useUpdateRecipientGroupMutation from 'legacy/features/recipientGroups/hooks/mutations/useUpdateRecipientGroupMutation';
import { validateRecipientGroup } from 'legacy/features/recipientGroups/utilities/validators';

const useEditRecipientGroupForm = () => {
  const updateRecipientGroupMutation = useUpdateRecipientGroupMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {},
    validate: validateRecipientGroup,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await updateRecipientGroupMutation.mutateAsync({ updatedGroup: values });
      navigate('/managerecipientgroups');
    },
  });

  return formik;
};

export default useEditRecipientGroupForm;
