import { apiCall } from 'legacy/core/api/API';
import { mapAndMassageMaintenanceData } from 'legacy/features/maintenance/utilities/mapping';

export const putMaintenanceApi = async ({
  maintenanceEvent,
  organizationId,
  isSuppressed,
  isCompleted,
}) => {
  // map and massage maintenance data
  const mappedMaintenanceEvent = mapAndMassageMaintenanceData(maintenanceEvent);
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  return await apiCall({
    path: `/maintenance/${mappedMaintenanceEvent.event_id}`,
    method: 'put',
    queryStringParameters,
    body: { ...mappedMaintenanceEvent, is_suppressed: isSuppressed, is_completed: isCompleted },
  });
};
