import { useState } from 'react';
import { useSelector } from 'react-redux';

import { verifyDuid } from 'legacy/core/api/vehicles';

const useVerifyVsgIdQuery = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [verifyVsgWarningConfirmed, setVerifyVsgWarningConfirmed] = useState(false);
  const [verifyVsgIdModalActive, setVerifyVsgIdModalActive] = useState(false);
  const [multiVsgIdCheck, setMultiVsgIdCheck] = useState(false);

  const verifyVsgId = async (duid) => {
    const { response } = await verifyDuid(duid, filteredOrganizationId);
    let isValidVsg = response.message.valid;
    setMultiVsgIdCheck(duid);
    return isValidVsg;
  };

  const vsgIsValidAndAcknowledged = async (duid) => {
    let sameVsgId = multiVsgIdCheck === duid ? true : false;
    let isValidVsg = await verifyVsgId(duid);
    return isValidVsg || verifyVsgWarningConfirmed;
  };

  return [
    vsgIsValidAndAcknowledged,
    verifyVsgIdModalActive,
    setVerifyVsgIdModalActive,
    setVerifyVsgWarningConfirmed,
  ];
};

export default useVerifyVsgIdQuery;
