import { apiCall } from 'legacy/core/api/API';

export const getMaintenanceLogsApi = async ({ organizationId, startDate, endDate, vehicleId }) => {
  const apiResponse = await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/maintenance/logs/${vehicleId}`,
    method: 'get',
    queryStringParameters: {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
    },
  });
  let maintenanceLogs = apiResponse.data.message.logs;
  return maintenanceLogs;
};
