import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';
import { addDisplayCommas } from 'legacy/features/maintenance/utilities/formatting';
import { manageAlertsLogsTableColumns } from 'legacy/features/alerts/ui/components/AlertLogsSummary/tables/ManageAlertsLogsTableColumns';

const ManageAlertsLogsTable = ({ alertLogs, handleUpdateListingCount }) => {
  let alertLogsTableData = alertLogs?.map((alertLog) => {
    return {
      timestamp: alertLog.timestamp,
      formattedTime: alertLog.formattedTime,
      vehicleName: alertLog.vehicleName,
      alertTypeDisplay: alertLog.alertTypeDisplay,
      triggerValueDisplay: alertLog.triggerValueDisplay,
      measuredValueWithCommas: addDisplayCommas(alertLog.measuredValueDisplay),
      measuredValue: alertLog.measuredValueDisplay,
      testModeEnabled: alertLog.testModeEnabled,
      formattedUserDisplayNames: alertLog.formattedUserDisplayNames,
      aggregateAlertMethod: alertLog.aggregateAlertMethod,
    };
  });
  return (
    <WcpTable
      tableClassName="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={manageAlertsLogsTableColumns}
      tableSourceData={alertLogsTableData ? alertLogsTableData : []}
      rowKey={(record, i) => i}
      emptyText="No alert logs are currently associated with this alert"
      setListingCount={handleUpdateListingCount}
      defaultSortOrder={SORT_ORDER.DESC}
    />
  );
};

export default ManageAlertsLogsTable;
