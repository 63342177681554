import React from 'react';
import { Link } from 'react-router-dom';
import {
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

const AssignAddonsMenuItem = ({ groupId }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn>
        <Link to={`${encodeURIComponent(groupId)}/addons`} style={styles.groupEditButton}>
          Assign Add-ons
        </Link>
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default AssignAddonsMenuItem;

const styles = {
  groupEditButton: {
    textDecoration: 'none',
    display: 'block',
  },
};
