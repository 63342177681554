import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  PlayPauseCheckbox,
  PlayPauseCheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import {
  formatISOTimestampToLongDateWithoutYear,
  formatISOTimestampToStandardTime,
} from 'legacy/shared/v1/utilities/time';

const VehiclePlaybackPlayPauseControl = () => {
  const {
    actions: { setPlaybackAnimationControlsAction },
    state: {
      playbackData: { locationData },
      playbackAnimationControls: { currentPlaybackDataIndex, playbackToggle },
    },
  } = useVehiclePlaybackProviderContext();

  const togglePlayback = (playbackToggle) => {
    // if at the end of playback and they click play, restart playback
    if (currentPlaybackDataIndex === locationData?.length - 1 && playbackToggle) {
      setPlaybackAnimationControlsAction({ currentPlaybackDataIndex: 0, playbackToggle });
    }
    // otherwise, continue playback
    else {
      setPlaybackAnimationControlsAction({ playbackToggle });
    }
  };

  return (
    <>
      <PlayPauseCheckbox
        id="switch"
        checked={playbackToggle}
        onChange={(e) => {
          togglePlayback(e.target.checked);
        }}
      />
      <PlayPauseCheckboxLabel for="switch" title="Play" />
      <br />
      <h6>
        {'Current position: ' +
          formatISOTimestampToStandardTime(locationData[currentPlaybackDataIndex].time)}
      </h6>
      <h6>
        {formatISOTimestampToLongDateWithoutYear(locationData[currentPlaybackDataIndex].time)}
      </h6>
      <h6>{locationData[currentPlaybackDataIndex].speed} MPH</h6>
    </>
  );
};

export default VehiclePlaybackPlayPauseControl;
