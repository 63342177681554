import React from 'react';
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import SortableTableWithoutPagination from 'legacy/shared/v1/ui/table/SortableTableWithoutPagination';
import colors from 'legacy/shared/v1/constants/colors';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';

export default class SelectFSETable extends React.Component {
  columns = [
    {
      title: 'First Name',
      dataIndex: 'givenName',
      key: 'firstName',
      render: (value, row, index) => {
        const selected = this.state.selected.indexOf(row.username) > -1;
        return (
          <div style={styles.firstNameColumn}>
            <Checkbox>
              <CheckboxInner
                fseTable
                isChecked={selected}
                onClick={this.handleCheckbox(row, index)}
              />
              <span style={styles.name}>{value}</span>
            </Checkbox>
          </div>
        );
      },
      onHeaderCell: () => ({
        style: {
          ...styles.firstNameColumn,
          ...styles.headerCell,
        },
      }),
      onCell: () => ({ style: styles.firstNameCell }),
    },
    {
      title: 'Last Name',
      dataIndex: 'familyName',
      key: 'lastName',
      onHeaderCell: () => ({ style: styles.headerCell }),
      render: (value) => <span style={styles.name}>{value}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      onHeaderCell: () => ({ style: styles.headerCell }),
      render: (value) => <span>{value}</span>,
    },
  ];

  onRow = (record, index) => {
    return {
      style: {
        backgroundColor: index % 2 === 0 ? colors.cloud : colors.concrete,
      },
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  handleCheckbox(row, index) {
    return (e) => {
      const { selected } = this.state;
      const indexOfItem = selected.indexOf(row.username);
      let nextSelected = selected.slice();

      if (indexOfItem > -1) {
        nextSelected.splice(indexOfItem, 1);
      } else {
        nextSelected = [...nextSelected, row.username];
      }
      this.props.setSelected(nextSelected);
      this.setState({
        selected: nextSelected,
      });
    };
  }

  render() {
    const { FSEs } = this.props;
    return (
      <div style={styles.tableWrapper}>
        <SortableTableWithoutPagination
          columns={this.columns}
          data={this.props.FSEs}
          rowKey={(record) => record.username}
          onRow={this.onRow}
        />
      </div>
    );
  }
}

const styles = {
  tableWrapper: {
    marginLeft: -23,
    marginRight: -23,
  },
  firstNameColumn: {
    paddingLeft: 47,
  },
  firstNameCell: {
    padding: '16px 8px 16px 0',
  },
  headerCell: {
    background: 'white',
  },
  name: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 700,
    fontSize: 14,
    color: colors.midnight,
  },
};
