import { Formik } from 'formik';

import RemoveMaintenanceItemContainerButton from 'legacy/features/maintenance/components/MaintenanceBuilder/buttons/RemoveMaintenanceItemContainerButton';
import DueDateField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/DueDateField';
import IntervalNumberField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/IntervalNumberField';
import MaintenanceTypeField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/MaintenanceTypeField';
import NotesField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/NotesField';
import MobileRemoveMaintenanceItemButton from 'legacy/features/maintenance/components/MaintenanceBuilder/mobile/MobileRemoveMaintenanceItemButton';
import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { validateMaintenanceItem } from 'legacy/features/maintenance/utilities/validators';
import {
  MaintenanceControlOuterWrapperDiv,
  MaintenanceTypeItemDiv,
} from 'legacy/shared/v1/styles/components/Maintenance';
import IntervalTypeDropdown from 'legacy/features/maintenance/components/MaintenanceBuilder/dropdowns/IntervalTypeDropdown';
import { addDisplayCommas } from 'legacy/features/maintenance/utilities/formatting';

const NewMaintenanceItemForm = ({
  maintenanceItem,
  handleUpdateMaintenanceItem,
  handleRemoveMaintenanceItem,
  vehicle,
  orgUsesEngineHours,
  handleIsMaintenceBuilderValid,
  orgUsesEnhancedTelematics,
}) => {
  const handleUpdate = ({ maintenanceItemValues }) => {
    let updatedMaintenanceItem = {
      ...maintenanceItem,
      ...maintenanceItemValues,
    };

    handleUpdateMaintenanceItem({ updatedMaintenanceItem });
  };
  const clearValidationAndRemoveItem = () => {
    // clear out old validation error
    // todo: add item specific isValid bool because right now even without this new change
    // once validation is addressed for one item it falsey clear the validation flag even if another item has invalid state
    handleIsMaintenceBuilderValid({ isValid: true });
    handleRemoveMaintenanceItem();
  };

  return (
    <MaintenanceTypeItemDiv>
      <Formik
        initialValues={{
          intervalType: maintenanceItem.intervalType,
          interval: addDisplayCommas(maintenanceItem.interval),
          dueDate: maintenanceItem.dueDate,
          notes: maintenanceItem.notes ? maintenanceItem.notes : '',
        }}
        validate={(values) => {
          let validation = validateMaintenanceItem(values);
          handleUpdate({ maintenanceItemValues: values });
          handleIsMaintenceBuilderValid({ isValid: _.isEmpty(validation) });
          return validation;
        }}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(formikProps) => {
          return (
            <>
              <MaintenanceTypeField maintenanceTypeLabel={maintenanceItem.maintenanceType?.label} />
              <MaintenanceControlOuterWrapperDiv>
                <IntervalTypeDropdown
                  formikProps={formikProps}
                  noOdometer={vehicle?.obd?.odometer === null}
                  noEngineHours={
                    vehicle?.obd?.engine_hours === null || orgUsesEngineHours === false
                  }
                  noOilLife={vehicle?.obd?.oil_life === null}
                />
                {formikProps.values.intervalType?.value === maintenanceIntervalConstants.DATE ? (
                  <DueDateField formikProps={formikProps} />
                ) : (
                  <IntervalNumberField formikProps={formikProps} />
                )}

                <NotesField formikProps={formikProps} />
                <RemoveMaintenanceItemContainerButton
                  handleRemoveMaintenanceItem={clearValidationAndRemoveItem}
                />
              </MaintenanceControlOuterWrapperDiv>
              <MobileRemoveMaintenanceItemButton
                handleRemoveMaintenanceItem={clearValidationAndRemoveItem}
              />
            </>
          );
        }}
      </Formik>
    </MaintenanceTypeItemDiv>
  );
};

export default NewMaintenanceItemForm;
