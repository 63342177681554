import styled from 'styled-components';
import device from 'legacy/shared/v1/constants/sizing';
import colors from 'legacy/shared/v1/constants/colors';

export const MonthYearFormDiv = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol';
  grid-template-columns: 130px 80px;
  grid-gap: 20px;
  margin-bottom: 30px;
`;

export const HeatmapDateDiv = styled.div`
  width: 130px;
`;

export const YearDiv = styled.div`
  width: 80px;
`;

export const HeatmapMonthRangeValidationDiv = styled.div`
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 20px;
  color: ${colors.cherry};
`;
