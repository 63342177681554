import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'legacy/core/redux/store';

export class MapboxCustomControls {
  constructor(controlsComponent) {
    this.controlsComponent = controlsComponent;
  }
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl';

    let root = ReactDOM.createRoot(this._container);
    root.render(<Provider store={store}>{this.controlsComponent}</Provider>);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
