import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { editConfiguration } from 'legacy/core/redux/configurations/actions';

/**
 * @ Components
 */
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import AnimatedTextarea from 'legacy/shared/v1/ui/fields/AnimatedTextarea';

/**
 * Styled Components
 */
import {
  FormFoot,
  FormLegend,
  FormActions,
  FormAsterisk,
} from 'legacy/shared/v1/styles/components/Form';
import Button, { ButtonWithSpinnerContainer } from 'legacy/shared/v1/styles/components/Button';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';

/**
 * @ Constants
 */
import colors from 'legacy/shared/v1/constants/colors';

/**
 * @ Utilities
 */
import { validateConfiguration } from 'legacy/shared/v1/utilities/validators';
import { setModalConfirmationMemoized } from 'legacy/shared/v1/utilities/modal';

const setModalConfirmation = setModalConfirmationMemoized();

export default connect(null, (dispatch) => ({
  editConfiguration: (configurationId, onSuccess) => async (configuration, formikActions) => {
    const success = await dispatch(editConfiguration(configuration, configurationId));
    formikActions.setSubmitting(false);
    if (success) {
      onSuccess();
    }
  },
}))(({ children, editConfiguration, configDetails, onSuccess }) => {
  return (
    <Formik
      initialValues={{
        configName: configDetails.configName || '',
        description: configDetails.description || '',
        archived: configDetails.archived || 'false',
        action: 'Edit',
      }}
      validate={validateConfiguration}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={editConfiguration(configDetails.configId, onSuccess)}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form style={styles.formBody}>
            <AnimatedField
              name="configName"
              placeholder="Configuration Name"
              value={values.configName}
              touched={touched.configName}
              validationError={errors.configName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              required
              configName={true}
            />
            <AnimatedTextarea
              name="description"
              placeholder="Description"
              value={values.description}
              touched={touched.description}
              validationError={errors.description}
              handleChange={handleChange}
              handleBlur={handleBlur}
              required
            />
            {children}

            <FormFoot style={styles.formFoot}>
              <FormLegend>
                <FormAsterisk>*</FormAsterisk> &mdash; required fields
              </FormLegend>

              <FormActions>
                <ButtonWithLoader
                  isLoading={isSubmitting}
                  confirmText={'Confirm'}
                  loadingStyleProp={'submittingWithSpinnerModal'}
                  notLoadingStyleProp={'formConfirm'}
                  clickHandler={handleSubmit}
                />
              </FormActions>
            </FormFoot>
          </form>
        );
      }}
    </Formik>
  );
});

const styles = {
  formBody: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  formFoot: {
    marginTop: '24px',
  },
};
