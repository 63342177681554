import { calculateTotalCounts } from 'legacy/features/maintenance/utilities/general';

export const setAllMaintenanceEventsReducer = (state, payload) => {
  const { allMaintenanceEvents } = payload;

  // calculate total counts
  let totalCounts = calculateTotalCounts(allMaintenanceEvents);

  if (totalCounts.listingCount > state.listingCount) totalCounts.listingCount = state.listingCount;

  const newState = {
    ...state,
    allMaintenanceEvents,
    filteredMaintenanceEvents: allMaintenanceEvents,
    ...totalCounts,
  };

  return newState;
};
