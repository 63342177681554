import { useMutation, useQueryClient } from '@tanstack/react-query';

import { editVehicleMetaById } from 'legacy/core/api/vehicles';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

export const useEditVehicleAliasMutation = () => {
  const queryClient = useQueryClient();

  const EditAliasMutation = useMutation({
    mutationFn: ({ vehicle }) => editVehicleMetaById(vehicle),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchVehiclesQuery']);
    },
    onError: (err) => {
      logWcpError(err);
    },
  });

  return EditAliasMutation;
};
