import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import { addDisplayCommas } from 'legacy/features/maintenance/utilities/formatting';

const VehicleOdometerField = ({ formikProps: { values, touched, handleChange, handleBlur } }) => {
  const odometerValue = addDisplayCommas(values.odometer);
  return (
    <div>
      <AnimatedField
        name="odometer"
        placeholder="Odometer"
        value={odometerValue}
        touched={touched.odometer}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={true}
      />
    </div>
  );
};

export default VehicleOdometerField;
