import { Link } from 'react-router-dom';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';
import ManageAlertsActionMenu from 'legacy/features/alerts/ui/components/AlertsSummary/menus/ManageAlertsActionMenu';

export const manageAlertsSummaryTableColumns = [
  {
    title: 'Alert Name',
    displayProperty: 'alertName',
    className: 'name',
    width: '20%',
    render({ rowData, cellJsx }) {
      return (
        //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
        <span className={rowData.alertNameClasses} title={rowData.alertNameTooltip}>
          <TruncatedTextWithWrap>
            <Link
              to={{
                pathname: `/editalert/${encodeURIComponent(rowData.alertId)}`,
              }}
              style={{ textDecoration: 'none' }}
            >
              {cellJsx}
              {rowData.testModeEnabled ? ' (TEST MODE) ' : ''}{' '}
              {rowData.isActive ? '' : '(Deactivated)'}
            </Link>
          </TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Condition',
    displayProperty: 'condition',
  },
  {
    title: 'Value',
    displayProperty: 'alertValue',
  },

  {
    title: 'Vehicle/Group',
    displayProperty: 'targetVehicleOrGroupName',
    width: '10%',
    render({ cellValue, cellJsx }) {
      return (
        <span title={`Vehicle/Group: ${cellValue} `}>
          <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
        </span>
      );
    },
  },

  {
    title: 'Recipients',
    displayProperty: 'recipient',
    render({ rowData, cellJsx }) {
      return (
        //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
        <span
          title={
            rowData.multiLevel
              ? 'Recipients: (Multi-level) -' + rowData.recipient
              : 'Recipients: ' + rowData.recipient
          }
        >
          <TruncatedTextWithWrap>
            {rowData.multiLevel ? '(Multi-level)' : ''} {cellJsx}
          </TruncatedTextWithWrap>
        </span>
      );
    },
  },

  {
    title: 'Method',
    displayProperty: 'alertMethod',
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    render({ rowData }) {
      // passing in the original alert tree here bc this is also used in the cards and other checks currently and I dont want to refactor at this time, if at all
      return <ManageAlertsActionMenu alert={rowData.rawAlert} />;
    },
  },
];
