import React from 'react';
import { deviceProductIds } from 'legacy/shared/v1/constants/device';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';
import {
  FormLabelAnimated,
  MobileVehicleDeviceWrapper,
} from 'legacy/shared/v1/styles/components/Form';
import {
  getCccConfigOptions,
  getCccConfigSelectedValue,
  getCccFirmwareChannelOptions,
  getCccFirmwareChannelSelectedValue,
  getVsgChannelOptions,
  getVsgChannelSelectedValue,
} from 'legacy/shared/v1/utilities/vehicle';

export const VehicleDeviceFields = ({
  otaConfigEditPermissions,
  otaFirmwareEditPermissions,
  firmwareChannels,
  categorizedDevices,
  configurations,
  generateHandleSelectChange,
}) => {
  let vsg = categorizedDevices.connectivityModule[0];
  let ccc = categorizedDevices.cenComCore[0];

  // VSG
  const vsgChannelOptions = getVsgChannelOptions(firmwareChannels);
  let vsgChannelSelectedValue = getVsgChannelSelectedValue(vsgChannelOptions, vsg);

  // CenCom Core
  const cccFirmwareChannelOptions = getCccFirmwareChannelOptions(firmwareChannels);
  let cccFirmwareChannelSelectedValue = getCccFirmwareChannelSelectedValue(
    cccFirmwareChannelOptions,
    ccc,
  );

  let cccConfigOptions = getCccConfigOptions(configurations, ccc);
  let cccConfigSelectedValue = getCccConfigSelectedValue(cccConfigOptions, ccc);

  // If the previously selected channel is an archived config, add it as the selected channel
  configurations.forEach((config) => {
    if (ccc?.config?.target_id === config.configId || ccc?.config?.current_id === config.configId) {
      cccConfigSelectedValue = {
        label: config.configName,
        value: config.configId,
      };
    }
  });

  return (
    <div>
      {vsg && vsgChannelSelectedValue ? (
        <MobileVehicleDeviceWrapper>
          <h4>VSG</h4>
          <FormLabelAnimated animated firmware>
            Firmware Channel
          </FormLabelAnimated>
          {otaFirmwareEditPermissions ? (
            <StyledReactSelect
              name="vsgFwChannel"
              options={vsgChannelOptions}
              defaultValue={vsgChannelSelectedValue}
              isClearable={false}
              isSearchable={false}
              onChange={generateHandleSelectChange(vsg.uid, 'firmware')}
            />
          ) : (
            <>{vsgChannelSelectedValue.label}</>
          )}
        </MobileVehicleDeviceWrapper>
      ) : (
        <div>
          No VSG is currently associated with this vehicle
          <br />
          <br />
        </div>
      )}
      {ccc && cccFirmwareChannelSelectedValue ? (
        <MobileVehicleDeviceWrapper>
          <h4>{categorizedDevices.cenComCore[0].product_name}</h4>
          <FormLabelAnimated animated firmware>
            Firmware Channel
          </FormLabelAnimated>
          {otaFirmwareEditPermissions ? (
            <StyledReactSelect
              name="cccFwChannel"
              options={cccFirmwareChannelOptions}
              defaultValue={cccFirmwareChannelSelectedValue}
              isClearable={false}
              isSearchable={false}
              onChange={generateHandleSelectChange(ccc.uid, 'firmware')}
            />
          ) : (
            <>{cccFirmwareChannelSelectedValue.label}</>
          )}

          <FormLabelAnimated animated config>
            Configuration Channel
          </FormLabelAnimated>
          {configurations.length !== 0 ? (
            otaConfigEditPermissions ? (
              <StyledReactSelect
                name="cccConfigChannel"
                options={cccConfigOptions}
                defaultValue={cccConfigSelectedValue}
                isClearable={false}
                isSearchable={false}
                onChange={generateHandleSelectChange(ccc.uid, 'configuration')}
              />
            ) : (
              <>{cccConfigSelectedValue.label}</>
            )
          ) : (
            '---'
          )}
        </MobileVehicleDeviceWrapper>
      ) : (
        <div>No Control System is currently associated with this vehicle</div>
      )}
    </div>
  );
};
