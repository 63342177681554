import { useState } from 'react';

import RecipientGroupsExportButton from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/buttons/RecipientGroupsExportButton';
import ManageRecipientGroupsSummaryCardContainer from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/mobile/ManageRecipientGroupsSummaryCardContainer';
import ManageRecipientGroupsSummaryTable from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/tables/ManageRecipientGroupsSummaryTable';
import useFetchRecipientGroupsQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchRecipientGroupsQuery';
import IsFetchingWrapper from 'legacy/shared/v1/components/IsFetchingWrapper';
import ListingCount from 'legacy/shared/v1/components/ListingCount';
import MediaWrapper from 'legacy/shared/v1/components/MediaWrapper';
import { ExportDiv, ExportWrapper } from 'legacy/shared/v1/styles/components/PageList';

const ManageRecipientGroupsSummary = () => {
  const [recipientGroups, recipientGroupsQueryKey] = useFetchRecipientGroupsQuery();
  const [listingCount, setListingCount] = useState(recipientGroups?.length);

  return (
    <IsFetchingWrapper queryKeys={[recipientGroupsQueryKey]}>
      {recipientGroups?.length > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <RecipientGroupsExportButton recipientGroups={recipientGroups} />
          </ExportDiv>
        </ExportWrapper>
      )}
      {recipientGroups && (
        <>
          <ListingCount
            listingCount={listingCount}
            totalCount={recipientGroups?.length}
            itemTypeName={'Recipient Group'}
          />

          <MediaWrapper
            mobileComponent={
              <ManageRecipientGroupsSummaryCardContainer recipientGroups={recipientGroups} />
            }
            nonMobileComponent={
              <ManageRecipientGroupsSummaryTable
                recipientGroups={recipientGroups}
                handleUpdateListingCount={(listingCount) => setListingCount(listingCount)}
              />
            }
          />
        </>
      )}
    </IsFetchingWrapper>
  );
};

export default ManageRecipientGroupsSummary;
