import { DescriptionTextArea, FieldError, NotesDiv } from 'legacy/shared/v1/styles/components/Form';
import { NotesWrapperDiv } from 'legacy/shared/v1/styles/components/Maintenance';

const MaintenanceNotesField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <>
      <NotesDiv>Resolution/Notes</NotesDiv>
      <NotesWrapperDiv>
        <DescriptionTextArea
          smallerTextMobile
          name="notes"
          vehicleNotes
          value={values.notes}
          touched={touched.notes}
          validationError={errors.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={10}
        />
      </NotesWrapperDiv>
      <FieldError>{errors.notes}</FieldError>
    </>
  );
};

export default MaintenanceNotesField;
