import React, { useState, useContext } from 'react';

import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  CardIconWrapper,
  CardCollapsedInfo,
  CardExpandedInfo,
  CardCheckbox,
  CheckboxLabelSpan,
  PermissionListing,
} from 'legacy/shared/v1/styles/components/MobileCard';
import { TableAvatar } from 'legacy/shared/v1/styles/components/TableAvatar';
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { OrgUserPermissionsContextApi } from 'legacy/features/organizations/helpers/context';

export default function PermissionsCard({ user, localUserPermissions }) {
  const [expanded, setExpanded] = useState(false);
  const { updatelocalUserPermissions, onChangeViewCheckbox, onChangeEditCheckbox } = useContext(
    OrgUserPermissionsContextApi,
  );
  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <CardOuterWrapper singleColumn>
      <CardContainer permissionsExpanded={expanded}>
        <CardIconWrapper>
          <TableAvatar profilePicture={user.picture} userCard />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName title={user.username}>{user.username}</CardName>
            </CardRow>
            <CardRow>
              <CardLabel>Role:</CardLabel>
              <CardValue>{user.userRole}</CardValue>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded={expanded}>
          <CardRow>
            <PermissionListing>
              <CardLabel>FLEET</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="View Fleet">
                    <CheckboxInput
                      id={`viewFleet_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewfleet)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewfleet,
                          permissionData.editfleet,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewFleet_${user.username}`}>
                      <CheckboxLabelSpan>View</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
                <CardCheckbox>
                  <Checkbox singleCheck title="Edit Fleet">
                    <CheckboxInput
                      id={`editFleet_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editfleet)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewfleet,
                          permissionData.editfleet,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editFleet_${user.username}`}>
                      <CheckboxLabelSpan>Edit</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>OTA FIRMWARE</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="View OTA Firmware">
                    <CheckboxInput
                      id={`viewOTAFirmware_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewotafirmware)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewotafirmware,
                          permissionData.editotafirmware,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewOTAFirmware_${user.username}`}>
                      <CheckboxLabelSpan>View</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
                <CardCheckbox>
                  <Checkbox singleCheck title="Edit OTA Firmware">
                    <CheckboxInput
                      id={`editOTAFirmware_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editotafirmware)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewotafirmware,
                          permissionData.editotafirmware,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editOTAFirmware_${user.username}`}>
                      <CheckboxLabelSpan>Edit</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>OTA CONFIG</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="View OTA Config">
                    <CheckboxInput
                      id={`viewOTAConfig_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewotaconfig)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewotaconfig,
                          permissionData.editotaconfig,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewOTAConfig_${user.username}`}>
                      <CheckboxLabelSpan>View</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
                <CardCheckbox>
                  <Checkbox singleCheck title="Edit OTA Config">
                    <CheckboxInput
                      id={`editOTAConfig_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editotaconfig)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewotaconfig,
                          permissionData.editotaconfig,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editOTAConfig_${user.username}`}>
                      <CheckboxLabelSpan>Edit</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>ANALYTICS</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="View Analytics">
                    <CheckboxInput
                      id={`viewAnalytics_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewanalytics)}
                      onChange={() => {
                        if (localUserPermissions.has(user.username)) {
                          let permissions = localUserPermissions.get(user.username);
                          let enabled = permissions.some(
                            (pName) => pName === permissionData.viewanalytics,
                          );

                          localUserPermissions.set(
                            user.username,
                            enabled
                              ? [...permissions.filter((p) => p !== permissionData.viewanalytics)]
                              : [...permissions, permissionData.viewanalytics],
                          );

                          updatelocalUserPermissions(localUserPermissions);
                        }
                      }}
                    />
                    <CheckboxLabel htmlFor={`viewAnalytics_${user.username}`}>
                      <CheckboxLabelSpan>View</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>VEHICLE PLAYBACK</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox className="checkbox" singleCheck title="View Vehicle Playback">
                    <CheckboxInput
                      id={`viewVehiclePlayback_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewVehiclePlayback)}
                      onChange={() => {
                        if (localUserPermissions.has(user.username)) {
                          let permissions = localUserPermissions.get(user.username);
                          let enabled = permissions.some(
                            (pName) => pName === permissionData.viewVehiclePlayback,
                          );

                          localUserPermissions.set(
                            user.username,
                            enabled
                              ? [
                                  ...permissions.filter(
                                    (p) => p !== permissionData.viewVehiclePlayback,
                                  ),
                                ]
                              : [...permissions, permissionData.viewVehiclePlayback],
                          );

                          updatelocalUserPermissions(localUserPermissions);
                        }
                      }}
                    />
                    <CheckboxLabel htmlFor={`viewVehiclePlayback_${user.username}`}>
                      <CheckboxLabelSpan>View</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>MANAGE ALERTS</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="Edit Alerts">
                    <CheckboxInput
                      id={`editAlerts_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editAlerts)}
                      onChange={() => {
                        if (localUserPermissions.has(user.username)) {
                          let permissions = localUserPermissions.get(user.username);
                          let enabled = permissions.some(
                            (pName) => pName === permissionData.editAlerts,
                          );

                          localUserPermissions.set(
                            user.username,
                            enabled
                              ? [...permissions.filter((p) => p !== permissionData.editAlerts)]
                              : [...permissions, permissionData.editAlerts],
                          );

                          updatelocalUserPermissions(localUserPermissions);
                        }
                      }}
                    />
                    <CheckboxLabel htmlFor={`editAlerts_${user.username}`}>
                      <CheckboxLabelSpan>Edit</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>GEOFENCES</CardLabel>
              <span>
                <CardCheckbox>
                  <Checkbox singleCheck title="Edit Geofences">
                    <CheckboxInput
                      id={`editGeofence_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editgeofence)}
                      onChange={() => {
                        if (localUserPermissions.has(user.username)) {
                          let permissions = localUserPermissions.get(user.username);
                          let enabled = permissions.some(
                            (pName) => pName === permissionData.editgeofence,
                          );

                          localUserPermissions.set(
                            user.username,
                            enabled
                              ? [...permissions.filter((p) => p !== permissionData.editgeofence)]
                              : [...permissions, permissionData.editgeofence],
                          );

                          updatelocalUserPermissions(localUserPermissions);
                        }
                      }}
                    />
                    <CheckboxLabel htmlFor={`editGeofence_${user.username}`}>
                      <CheckboxLabelSpan>Edit</CheckboxLabelSpan>
                    </CheckboxLabel>
                  </Checkbox>
                </CardCheckbox>
              </span>
            </PermissionListing>
          </CardRow>
          <CardRow>
            <PermissionListing>
              <CardLabel>LIVE MAP</CardLabel>
              <CardCheckbox>
                <Checkbox singleCheck title="View Live Map">
                  <CheckboxInput
                    id={`viewMap_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.viewmap)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some((pName) => pName === permissionData.viewmap);

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [...permissions.filter((p) => p !== permissionData.viewmap)]
                            : [...permissions, permissionData.viewmap],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`viewMap_${user.username}`}>
                    <CheckboxLabelSpan>View</CheckboxLabelSpan>
                  </CheckboxLabel>
                </Checkbox>
              </CardCheckbox>
            </PermissionListing>
          </CardRow>
        </CardExpandedInfo>
      </CardContainer>

      <ButtonWrapper>
        <ShowCollapseButton onClick={(e) => handleToggleShowCollapse(e)}>
          {expanded ? 'COLLAPSE' : 'SEE DETAILS'}
        </ShowCollapseButton>
      </ButtonWrapper>
    </CardOuterWrapper>
  );
}
