export const vehicleYearValidator = (nextValidator) => (value) => {
  const valueAsNumber = Number(value);
  const yearLimit = new Date().getFullYear() + 2;
  if (!Number.isInteger(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber < 1950 || valueAsNumber > yearLimit) {
    return 'Please enter a valid year after 1950';
  }

  if (typeof nextValidator === 'function') {
    return nextValidator(value);
  }
};
