import GroupNameField from 'legacy/features/vehicleGroups/ui/form/fields/text/GroupNameField';
import { useDuplicateGroupMutation } from 'legacy/features/vehicleGroups/hooks/v1/useDuplicateGroupMutation';
import { FormRow } from 'legacy/shared/v1/styles/components/Form';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import GenericFormModal from 'legacy/shared/v1/ui/modals/GenericFormModal';
import { validateGroup } from 'legacy/shared/v1/utilities/validators';

const DuplicateGroupModal = ({ modalData: { show, vehicleIds, groupName }, handleCloseModal }) => {
  const duplicateGroupMutation = useDuplicateGroupMutation();

  return (
    show && (
      <GenericFormModal
        icon={<IconSvgComponent svgFileName="plus" alt="Create" />}
        title={'Duplicate Group'}
        initialValues={{
          groupName,
        }}
        submitHandler={async (values, formikActions) => {
          let result = await duplicateGroupMutation.mutateAsync({
            groupName: values.groupName,
            vehicleIds,
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateGroup}
        handleCloseModal={handleCloseModal}
        errorMessage={duplicateGroupMutation.isError ? duplicateGroupMutation.error : null}
        submitButtonText={'Duplicate'}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <FormRow>
            <GroupNameField formikProps={formikProps} />
          </FormRow>
        )}
      </GenericFormModal>
    )
  );
};

export default DuplicateGroupModal;
