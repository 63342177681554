import { alertsMapping } from 'legacy/features/alerts/services/mapping/definitions/alertsMapping';
import { mapToFrontend } from 'legacy/shared/v2/utilities/mapping/mapper';
import { apiCall } from 'legacy/core/api/API';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';

export const getAlertsApi = async ({ organizationId }) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/alerts`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  let alerts = apiResponse.data.message.map((alert) => mapToFrontend(alert, alertsMapping, false));
  alerts = alerts?.sort((a1, a2) =>
    sortAscendingAlpha(a1.alertDetails.alertInfo.alertName, a2.alertDetails.alertInfo.alertName),
  );

  return alerts;
};
