import MapFocusButton from 'legacy/features/livemap/ui/mapControls/MapFocusButton';
import useGetVehicleMapBounds from 'legacy/shared/v1/hooks/useGetVehicleMapBounds';
import useMapboxCustomControl from 'legacy/shared/v1/hooks/useMapboxCustomControl';

const useMapFocusButton = ({ mapboxApi, isMapboxLoaded, vehicles }) => {
  const bounds = useGetVehicleMapBounds({ vehicles });

  useMapboxCustomControl({
    renderComponent: <MapFocusButton mapboxApi={mapboxApi} bounds={bounds} />,
    mapboxApi,
    isMapboxLoaded,
    position: 'bottom-right',
  });
};

export default useMapFocusButton;
