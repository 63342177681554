import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';

export const fetchConfigurationUpdatesError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting configuration updates', e),
  });

export const fetchConfigurationsError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting configurations', e),
  });

export const updateConfigurationSuccess = () =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Successfully started configuration update(s)',
    ),
  });

export const configurationsOtaLogsFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Logs', err),
  });
