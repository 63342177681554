import { useFormik } from 'formik';

import useCreateAlertMutation from 'legacy/features/alerts/services/reactQuery/mutations/useCreateAlertMutation';
import { validateAlert } from 'legacy/features/alerts/services/formik/validation/validateAlert';

const useCreateAlertForm = () => {
  const mutation = useCreateAlertMutation();

  const formik = useFormik({
    initialValues: {},
    validate: validateAlert,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await mutation.mutateAsync({ alert: values.alert });
    },
  });

  return formik;
};

export default useCreateAlertForm;
