import { useState, useEffect } from 'react';
import React from 'react';
import { BlueButton } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import CreateRecipientGroupFromAlertModal from 'legacy/features/alerts/ui/components/AlertForms/shared/modals/CreateRecipientGroupFromAlertModal';

const CreateRecipientGroupButton = ({ alertNotificationGuid, recipientsToGroup }) => {
  const [createRecipientGroupModalData, setCreateRecipientGroupModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setCreateRecipientGroupModalData({
      show: false,
      recipientsToGroup,
    });
  }, [recipientsToGroup]);

  return (
    <>
      <BlueButton
        type="button"
        addRecipient
        onClick={() =>
          setCreateRecipientGroupModalData((prevState) => ({ ...prevState, show: true }))
        }
      >
        <IconSvgComponent svgFileName="plus-white" alt="Create a New Recipient Group" />
        Create a New Recipient Group
      </BlueButton>
      <>
        <CreateRecipientGroupFromAlertModal
          alertNotificationGuid={alertNotificationGuid}
          modalData={createRecipientGroupModalData}
          handleCloseModal={() =>
            setCreateRecipientGroupModalData((prevState) => ({ ...prevState, show: false }))
          }
        />
      </>
    </>
  );
};

export default CreateRecipientGroupButton;
