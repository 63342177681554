import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import RecipientRowContainer from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/containers/RecipientRowContainer';
import NoAlertRecipientsView from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/views/NoAlertRecipientsView';

const RecipientRowsListContainer = ({ notificationIndex }) => {
  const {
    state: {
      alert: { alertNotifications },
    },
  } = useAlertFormsProviderContext();
  // get the parent notification data
  const alertNotification = alertNotifications[notificationIndex];

  let rowCount = alertNotification.alertRecipientRows.length;

  return rowCount === 0 ? (
    <NoAlertRecipientsView />
  ) : (
    alertNotification.alertRecipientRows.map((r, i) => (
      <RecipientRowContainer
        key={i}
        alertNotification={{
          ...alertNotification,
          // decorate the parent notification with positional information
          isPrimaryNotification: notificationIndex === 0,
          alertNotificationIndex: notificationIndex,
        }} // parent notification
        recipientRow={{
          ...r,
          // decorate recipient row with positional information
          isFirstRecipientRow: rowCount === 1,
          isLastRecipientRow: i === rowCount - 1,
        }}
      />
    ))
  );
};

export default RecipientRowsListContainer;
