import ManageAlertsActionMenu from 'legacy/features/alerts/ui/components/AlertsSummary/menus/ManageAlertsActionMenu';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import AlertSummaryCard from 'legacy/features/alerts/ui/components/AlertsSummary/mobile/AlertSummaryCard';

const ManageAlertsSummaryCardContainer = ({ alerts }) => {
  const getActionMenu = (alert) => {
    return <ManageAlertsActionMenu alert={alert} />;
  };
  return (
    <div>
      <Cards>
        {alerts.map((a) => (
          <AlertSummaryCard alert={a} key={a.id} actionMenuComponent={() => getActionMenu(a)} />
        ))}
      </Cards>
    </div>
  );
};

export default ManageAlertsSummaryCardContainer;
