import { getMaintenanceStatusDropdownObject } from 'legacy/features/maintenance/data/maintenanceStatusDropdownData';
import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';
import MaintenanceOverviewExportButton from 'legacy/features/maintenance/components/MaintenanceSummary/buttons/MaintenanceOverviewExportButton';
import MaintenanceSummaryFilterCheckbox from 'legacy/features/maintenance/components/MaintenanceSummary/checkboxes/MaintenanceSummaryFilterCheckbox';
import MaintenanceSummaryFilters from 'legacy/features/maintenance/components/MaintenanceSummary/filters/MaintenanceSummaryFilters';
import MaintenanceSummaryCardContainer from 'legacy/features/maintenance/components/MaintenanceSummary/mobile/MaintenanceSummaryCardContainer';
import MaintenanceSummaryTable from 'legacy/features/maintenance/components/MaintenanceSummary/tables/MaintenanceSummaryTable';
import MaintenanceAlertBox from 'legacy/features/maintenance/components/MaintenanceSummary/widgets/MaintenanceAlertBox';
import MaintenanceSummaryContextProvider from 'legacy/features/maintenance/providers/MaintenanceSummaryContextProvider/MaintenanceSummaryContextProvider';
import ListingCount from 'legacy/shared/v1/components/ListingCount';
import MediaWrapper from 'legacy/shared/v1/components/MediaWrapper';
import { ExportDiv, ExportWrapper } from 'legacy/shared/v1/styles/components/PageList';
import { getVehicleNameDisplay } from 'legacy/features/maintenance/utilities/mapping';
import { useParams } from 'react-router-dom';

const MaintenanceSummary = () => {
  const { vehicleId } = useParams();

  return (
    <MaintenanceSummaryContextProvider routeVehicleId={vehicleId}>
      {({
        state: {
          allVehicles,
          allGroups,
          selectedVehicle,
          selectedStatus,
          selectedGroup,
          selectedType,
          filterOnlyNextScheduledEvent,
          filteredMaintenanceEvents,
          listingCount,
          totalVehiclesNeedingAttentionCount,
          totalCheckEngineCount,
          totalDueForWorkCount,
          totalDueInSoonCount,
        },
        actions: { setMaintenanceSummaryFiltersAction, setMaintenanceSummaryListingCountAction },
      }) => {
        return (
          <>
            {totalVehiclesNeedingAttentionCount > 0 && (
              <MaintenanceAlertBox
                totalVehicles={totalVehiclesNeedingAttentionCount}
                checkEngine={totalCheckEngineCount}
                dueInSoon={totalDueInSoonCount}
                dueForWork={totalDueForWorkCount}
                handleClickNeedsAttention={() =>
                  setMaintenanceSummaryFiltersAction({
                    selectedStatus: getMaintenanceStatusDropdownObject({
                      value: maintenanceStatusConstants.NEEDS_ATTENTION,
                    }),
                  })
                }
              />
            )}

            <MaintenanceSummaryFilters
              vehicles={allVehicles}
              groups={allGroups}
              setMaintenanceSummaryFilters={setMaintenanceSummaryFiltersAction}
              selectedVehicle={selectedVehicle}
              selectedStatus={selectedStatus}
              selectedGroup={selectedGroup}
              selectedType={selectedType}
            />

            {filteredMaintenanceEvents?.length > 0 && (
              <ExportWrapper>
                <ExportDiv>
                  <MaintenanceOverviewExportButton maintenanceEvents={filteredMaintenanceEvents} />
                </ExportDiv>
              </ExportWrapper>
            )}

            {filteredMaintenanceEvents && (
              <>
                <ListingCount
                  listingCount={listingCount}
                  totalCount={filteredMaintenanceEvents.length}
                  itemTypeName={'Maintenance Event'}
                />

                <MaintenanceSummaryFilterCheckbox
                  checked={filterOnlyNextScheduledEvent}
                  handleChecked={() =>
                    setMaintenanceSummaryFiltersAction({
                      filterOnlyNextScheduledEvent: !filterOnlyNextScheduledEvent,
                    })
                  }
                />

                <MediaWrapper
                  mobileComponent={
                    <MaintenanceSummaryCardContainer
                      maintenanceEvents={getVehicleNameDisplay(filteredMaintenanceEvents)}
                    />
                  }
                  nonMobileComponent={
                    <MaintenanceSummaryTable
                      maintenanceEvents={getVehicleNameDisplay(filteredMaintenanceEvents)}
                      setMaintenanceSummaryListingCount
                      handleUpdateListingCount={(listingCount) =>
                        setMaintenanceSummaryListingCountAction({ listingCount })
                      }
                    />
                  }
                />
              </>
            )}
          </>
        );
      }}
    </MaintenanceSummaryContextProvider>
  );
};

export default MaintenanceSummary;
