import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';
import { mapRecipientUserAlertMethod } from 'legacy/features/alerts/services/mapping/helpers/mapRecipientUserAlertMethod';
import { determineCustomAlertMethod } from 'legacy/shared/v2/utilities/determineCustomAlertMethod';
import { v4 as uuidv4 } from 'uuid';

export const alertRecipientMapping = {
  username: {
    frontendProperty: 'recipientUserName',
  },
  given_name: {
    frontendProperty: 'firstName',
  },
  family_name: {
    frontendProperty: 'lastName',
  },
  email: {
    frontendProperty: 'email',
  },
  sms: {
    frontendProperty: 'sms',
  },

  decorateFrontend: (alertRecipient) => {
    const { firstName, lastName, email, sms } = alertRecipient;

    alertRecipient.recipientUserFullName = `${firstName} ${lastName}`;
    alertRecipient.recipientUserAlertMethod = mapRecipientUserAlertMethod({ email, sms });

    return alertRecipient;
  },

  preBackendTransform: (alertRecipient) => {
    alertRecipient.sms =
      alertRecipient.recipientUserAlertMethod === alertMethodConstants.SMS ||
      alertRecipient.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL;

    alertRecipient.email =
      alertRecipient.recipientUserAlertMethod === alertMethodConstants.EMAIL ||
      alertRecipient.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL;

    return alertRecipient;
  },
};
