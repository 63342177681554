import React from 'react';

import {
  HeaderDiv,
  HeaderText,
  MapHeaderItem,
  MapHeaderItemsDiv,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';

const AnalyticsHeatmapHeader = ({ startDate, endDate }) => {
  return (
    <HeaderDiv>
      <MapHeaderItemsDiv>
        <MapHeaderItem></MapHeaderItem>
        {startDate ? (
          <HeaderText>
            {startDate} to {endDate}
          </HeaderText>
        ) : null}
      </MapHeaderItemsDiv>
    </HeaderDiv>
  );
};

export default AnalyticsHeatmapHeader;
