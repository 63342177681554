import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';
import {
  findRecipientRowSelector,
  findRecipientRowSelectorV2,
} from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { determineCustomAlertMethod } from 'legacy/shared/v2/utilities/determineCustomAlertMethod';
import { getAggregateAlertMethodFromRecipientList } from 'legacy/shared/v2/utilities/getAggregateAlertMethodFromRecipientList';
import { validateAndExtractPayload } from 'legacy/shared/v2/utilities/reducerHelpers/validateAndExtractPayload';

// this will save all of the custom alert options from each row in the custom alerts modal
// and also determine the overall option for the group row
export const persistCustomAlertMethodsReducer = (state, payload) => {
  const { recipientRowGuid, updatedGroupMembers } = validateAndExtractPayload({
    payload,
    mustInclude: ['recipientRowGuid', 'updatedGroupMembers'],
  });

  // find the alert recipient with the recipientRowGuid
  const recipientRow = findRecipientRowSelectorV2(state, recipientRowGuid);

  // update recipient group members
  recipientRow.recipientGroup.recipientGroupMembers.forEach((existingMember, i) => {
    // find the recipient group member in the list of recipient group members
    let matchingUpdatedMember = updatedGroupMembers.find(
      (updatedMember) => updatedMember.recipientUserName === existingMember.recipientUserName,
    );

    recipientRow.recipientGroup.recipientGroupMembers[i] = {
      ...existingMember,
      ...matchingUpdatedMember,
    };
  });

  const allAlertMethods = new Set(
    recipientRow.recipientGroup.recipientGroupMembers.map((r) => r.recipientUserAlertMethod),
  );

  recipientRow.recipientRowAlertMethod =
    allAlertMethods.size === 1
      ? allAlertMethods.values().next().value
      : alertMethodConstants.CUSTOM;

  console.log('persist recipientRow', recipientRow);

  return { ...state };
};
