import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroups } from 'legacy/core/api/groups';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { groupFetchError } from 'legacy/features/vehicleGroups/helpers/notifications';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';

export const useFetchGroupsQuery = ({ enabled = true } = {}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const fetchGroupsQuery = useQuery({
    queryKey: ['fetchGroupsQuery'],
    queryFn: () => getGroups(filteredOrganizationId),
    enabled,
    select: ({ response: { message: groups } }) => {
      groups = groups
        .sort((g1, g2) => sortAscendingAlpha(g1.group_name, g2.group_name))
        .map((group) => ({
          ...group,
          vehicle_count: group.vehicle_id?.length || 0,
        }));
      return groups;
    },
    onError: (err) => {
      logWcpError(err);
      dispatch(groupFetchError({ err }));
    },
  });

  return [fetchGroupsQuery, fetchGroupsQuery?.data];
};
