import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { getConfigurationUpdates, updateConfigurations } from 'legacy/core/api/configuration';
import { getVehicles } from 'legacy/core/api/vehicles';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { submitLogout } from 'legacy/core/redux/user/actions';
import { vehicleFetchError } from 'legacy/features/vehicles/helpers/notifications';
import ConfigurationUpdateSummaryItem from 'legacy/features/configurations/components/ConfigurationUpdateSummaryItem';
import ConfigurationUpdateTable from 'legacy/features/configurations/components/ConfigurationUpdateTable';
import { updateConfigurationSuccess } from 'legacy/features/configurations/helpers/notifications';
import ConfigOtaUpdateCard from 'legacy/features/configurations/mobile/ConfigOtaUpdateCard';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import {
  OtaSummaryItemWrapperDiv,
  UpdateAllWrapperDiv,
} from 'legacy/shared/v1/styles/components/OtaUpdates';
import PageListWrapper, { PageListHead } from 'legacy/shared/v1/styles/components/PageList';
import {
  SectionBody,
  SectionInner,
  SectionTitle,
} from 'legacy/shared/v1/styles/components/Section';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';
import { loginIsFresh } from 'legacy/shared/v1/utilities/localStore';
import { sizes } from 'legacy/shared/v1/utilities/media';

const ConfigurationUpdateDetailsPage = () => {
  const { productId, configurationId } = useParams();
  const currentOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const [configUpdateData, setConfigUpdateData] = useState(null);
  const [vehicleStatuses, setVehicleStatuses] = useState(null);
  const [updateAllLoading, setUpdateAllLoading] = useState(null);
  const [configUpdatePostLoading, setConfigUpdatePostLoading] = useState(null);

  const [devicesToUpdate, setDevicesToUpdate] = useState(null);
  const firstUpdate = devicesToUpdate && devicesToUpdate[0];

  useEffect(() => {
    if (configUpdateData) {
      let data =
        configUpdateData &&
        configUpdateData[productId] &&
        configUpdateData[productId][configurationId];

      setDevicesToUpdate(data?.sort((v1, v2) => sortAscendingAlpha(v1.label, v2.label)));
    }
  }, [configUpdateData]);

  const getHeader = () => {
    return `${firstUpdate?.product_name} Update`;
  };

  const checkLogin = () => {
    if (loginIsFresh()) {
      return true;
    } else {
      dispatch(submitLogout({ isTimedOut: true }));
      return false;
    }
  };

  // update firmware
  const updateConfigMutation = useMutation({
    mutationFn: ({ configurationId, vehicleIds, organizationId }) =>
      updateConfigurations({
        configurationId,
        vehicleIds,
        organizationId,
      }),

    onSuccess: ({ response: { message: responseMessage } }) => {
      responseMessage.forEach((update) => {
        let device = devicesToUpdate.find((d) => update.vehicle_id);
        device.config_status = update.result_state;
      });
      setDevicesToUpdate(devicesToUpdate);

      dispatch(updateConfigurationSuccess());
      setUpdateAllLoading(null);
    },
    onError: (err) => dispatch(fetchConfigurationUpdatesError({ err })),
  });

  // gets config updates
  const fetchConfigUpdatesQuery = useQuery({
    queryKey: ['fetchConfigUpdates'],
    queryFn: () => getConfigurationUpdates(currentOrganizationId),
    enabled: checkLogin(),
    select: ({ response: { message: data } }) => {
      return data;
    },
    onError: (err) => dispatch(fetchConfigurationUpdatesError({ err })),
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (fetchConfigUpdatesQuery.data) {
      setConfigUpdateData(fetchConfigUpdatesQuery.data);
    }
  }, [fetchConfigUpdatesQuery.data]);

  // gets vehicles
  const fetchVehiclesQuery = useQuery({
    queryKey: ['fetchVehicles'],
    queryFn: () =>
      getVehicles({
        organizationId: currentOrganizationId,
        apiFlags: {
          addons: false,
          cell: false,
          device: true,
          gps: true,
          meta: true,
          maintenance: false,
          obd: false,
        },
      }),

    enabled: checkLogin(),
    select: ({ response: { message: data } }) => {
      return data;
    },
    onError: (err) => dispatch(vehicleFetchError({ err })),
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (fetchVehiclesQuery.data) {
      setVehicleStatuses(
        fetchVehiclesQuery.data.reduce(
          (o, v) => ({
            ...o,
            [v.vehicle_id]: v.gps
              ? {
                  isActive: v.meta.online,
                  isResponding: v.gps.fr_mode_enabled === 1,
                }
              : {
                  isActive: false,
                  isResponding: false,
                },
          }),
          {},
        ),
      );
    }
  }, [fetchVehiclesQuery.data]);
  let firstFetchHasNotCompleted = fetchConfigUpdatesQuery.isLoading || fetchVehiclesQuery.isLoading;

  return firstFetchHasNotCompleted ||
    !vehicleStatuses ||
    !configUpdateData ||
    // this should never stay null
    devicesToUpdate === null ? (
    <LoadingOverlay />
  ) : devicesToUpdate === undefined ? (
    // we get here when the user is on a completed update detail page, and they change the config channel to create a new update, rendering the old page dead and we should redirect
    <Navigate to="/configurations/updates" />
  ) : (
    <PageListWrapper>
      <PageListHead>
        <SectionTitle size="27">{getHeader()}</SectionTitle>
        <UpdateAllWrapperDiv>
          <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
            <ButtonWithLoader
              loadingStyleProp={'submittingWithSpinner'}
              disabled={
                devicesToUpdate.filter((d) => d.config_status === 'READY_TO_UPDATE').length === 0 ||
                !devicesToUpdate.some(
                  (d) =>
                    vehicleStatuses[d.vehicle_id].isActive ||
                    vehicleStatuses[d.vehicle_id].isResponding,
                )
              }
              notLoadingStyleProp={'updates'}
              confirmText={'Update All Devices'}
              isLoading={updateAllLoading}
              clickHandler={() => {
                setUpdateAllLoading(true);
                updateConfigMutation.mutate({
                  configurationId: configurationId,
                  vehicleIds: devicesToUpdate.map((d) => d.vehicle_id),
                  organizationId: currentOrganizationId,
                });
              }}
            />
          </PermissionProtectedElement>
        </UpdateAllWrapperDiv>
      </PageListHead>
      <SectionInner>
        <OtaSummaryItemWrapperDiv>
          <ConfigurationUpdateSummaryItem
            showTotals={false}
            configurationName={firstUpdate.config_name}
            productName={firstUpdate.product_name}
            description={firstUpdate.description}
            uploadedDate={firstUpdate.creation_date}
          />
        </OtaSummaryItemWrapperDiv>
      </SectionInner>

      <SectionBody>
        <Media
          queries={{
            tablet: { maxWidth: sizes.tablet },
            mobile: { maxWidth: sizes.mobile },
          }}
        >
          {(matches) =>
            matches.tablet ? (
              <div>
                <Cards ota>
                  {devicesToUpdate.map((device) => (
                    <ConfigOtaUpdateCard
                      device={device}
                      vehicleStatuses={vehicleStatuses}
                      updatePostLoading={configUpdatePostLoading}
                      handleUpdateConfiguration={async ({ configId, vehicleId }) => {
                        setConfigUpdatePostLoading(vehicleId);
                        updateConfigMutation.mutate({
                          configurationId: configId,
                          vehicleIds: [vehicleId],
                          devicesToUpdate: devicesToUpdate.filter((d) =>
                            d.firmware_status?.includes(
                              'READY_TO_UPDATE',
                              'ERROR_BOOTLOADING',
                              'ERROR_DOWNLOADING',
                            ),
                          ),
                          organizationId: currentOrganizationId,
                        });
                      }}
                    />
                  ))}
                </Cards>
              </div>
            ) : (
              <ConfigurationUpdateTable
                configUpdatePostLoading={configUpdatePostLoading}
                deviceUpdates={devicesToUpdate}
                vehicleStatuses={vehicleStatuses}
                handleUpdateConfiguration={async ({ configId, vehicleId }) => {
                  setConfigUpdatePostLoading(vehicleId);
                  updateConfigMutation.mutate({
                    configurationId: configId,
                    vehicleIds: [vehicleId],
                    organizationId: currentOrganizationId,
                  });
                }}
              />
            )
          }
        </Media>
      </SectionBody>
    </PageListWrapper>
  );
};

export default ConfigurationUpdateDetailsPage;
