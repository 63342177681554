import { useContext, useState } from 'react';
import { connect } from 'react-redux';

import { toggleFullscreenMapMode } from 'legacy/core/redux/ui/actions';
import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import { LiveMapHeaderDateTime } from 'legacy/features/livemap/ui/header/LiveMapHeaderDateTime';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
} from 'legacy/shared/v1/constants/vehicle';
import { Button } from 'legacy/shared/v1/styles/components/Button';
import {
  FullScreenMapToggle,
  HeaderDiv,
  HeaderText,
  MapHeaderItem,
  MapHeaderItemsDiv,
  RespondingDot,
  TimestampWrapperDiv,
  ToggleWrapper,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';

const LivemapHeader = ({ livemapRef, vehicles, filteredVehicles, toggleFullscreenMapMode }) => {
  const [exitButtonIsVisible, setExitButtonIsVisible] = useState(false);
  const {
    actions: { setFullScreenMode },
  } = useContext(LivemapApi);

  const respondingVehicles = vehicles.filter(
    (v) => v.respondingStatus == vehicleRespondingStatusViewData.RESPONDING.id,
  );

  const onlineVehicles = vehicles.filter(
    (v) => v.onlineStatus == vehicleOnlineStatusViewData.ACTIVE.id,
  );

  const handleFullScreenToggle = () => {
    toggleFullscreenMapMode(true);
    setFullScreenMode(true);
    livemapRef.current.requestFullscreen();
    setExitButtonIsVisible(true);
  };

  const handleFullScreenExit = () => {
    toggleFullscreenMapMode(false);
    setFullScreenMode(false);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setExitButtonIsVisible(false);
  };
  return (
    <HeaderDiv>
      <MapHeaderItemsDiv>
        <MapHeaderItem textCentered>
          <HeaderText>
            {filteredVehicles ? (
              <>
                {onlineVehicles.length} of {vehicles.length}
                {` Vehicle${vehicles.length > 1 ? 's' : ''}`} Online
                {respondingVehicles.length > 0 ? (
                  <RespondingDot> {respondingVehicles.length} Responding</RespondingDot>
                ) : null}
              </>
            ) : (
              <LoadingOverlay />
            )}
          </HeaderText>
        </MapHeaderItem>
        <HeaderText>
          <TimestampWrapperDiv>
            <LiveMapHeaderDateTime />
          </TimestampWrapperDiv>
        </HeaderText>
        <ToggleWrapper>
          <FullScreenMapToggle
            title="Enter full screen map mode. (You will be signed out of your current session and need to log back in upon exiting full screen mode.)"
            onClick={handleFullScreenToggle}
            exitButtonIsVisible={exitButtonIsVisible}
          >
            <IconSvgComponent svgFileName="fullScreen" alt="Full Screen Map" />
          </FullScreenMapToggle>
          <Button
            exitButton
            exitButtonIsVisible={exitButtonIsVisible}
            onClick={handleFullScreenExit}
            title="Exit Full Screen Map Mode (you will be prompted to sign back in)."
          >
            LOG OUT &amp; EXIT
          </Button>
        </ToggleWrapper>
      </MapHeaderItemsDiv>
    </HeaderDiv>
  );
};

export default connect(null, (dispatch) => ({
  toggleFullscreenMapMode: (setFullscreen) => dispatch(toggleFullscreenMapMode(setFullscreen)),
}))(LivemapHeader);
