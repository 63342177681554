import React, { Fragment, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Downshift, { useCombobox } from 'downshift';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

import DropdownMenu, * as D from 'legacy/shared/v1/styles/components/DropdownMenu';

import colors from 'legacy/shared/v1/constants/colors';
import {
  TruncatedText,
  TruncatedTextWithWrap,
} from 'legacy/shared/v1/styles/components/TruncatedText';

import { ClearFilterIcon } from 'legacy/shared/v1/styles/components/Icons';
import { FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';
import { createSearchResultItemName } from 'legacy/shared/v1/utilities/search';

const Combobox = ({
  dropdownOptions,
  restrictedOptions,
  labels,
  handleItemSelect,
  defaultSelectedItem,
  disabled,
  restrictedHeightResults,
  retainSelection,
  handleCreateNewItem,
}) => {
  dropdownOptions = restrictedOptions
    ? dropdownOptions?.filter((o) => !restrictedOptions.some((r) => o === r))
    : dropdownOptions;

  // Focus on search input when opened
  const searchInput = useRef(null);

  const [dropdownItems, setDropdownItems] = useState(dropdownOptions);
  const [userInput, setUserInput] = useState('');
  const [selectedItem, setSelectedItem] = useState(labels.dropdownPlaceholder);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox(
    {
      items: dropdownItems,
      selectedItem,
      inputValue: userInput,

      onSelectedItemChange: ({ selectedItem }) => {
        if (retainSelection) setSelectedItem(selectedItem);

        setDropdownItems([]);
        handleItemSelect(selectedItem);
      },
      onInputValueChange: ({ inputValue }) => {
        if (inputValue !== labels.dropdownPlaceholder) setUserInput(inputValue);

        setDropdownItems(
          dropdownOptions.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase())),
        );
      },
    },
    [dropdownItems],
  );

  const closeSearchInput = () => {
    // hacky reset of search input which is not tied to downshift lifecycle :/
    searchInput.current.focus();
    searchInput.current.blur();
  };

  const resetSelectionFilter = () => {
    setSelectedItem(defaultSelectedItem ? defaultSelectedItem : labels.dropdownPlaceholder);
    setUserInput('');
  };

  const isFiltered = () => {
    return (
      selectedItem &&
      selectedItem !== defaultSelectedItem &&
      selectedItem !== labels.dropdownPlaceholder
    );
  };
  useEffect(() => {
    setSelectedItem(defaultSelectedItem ? defaultSelectedItem : labels.dropdownPlaceholder);
  }, [defaultSelectedItem]);

  useEffect(() => {
    if (searchInput.current) {
      isOpen ? searchInput.current.focus() : searchInput.current.blur();
    }
  }, [isOpen]);
  return (
    <div>
      <FormLabelAnimated animated {...getLabelProps()}>
        {labels.fieldTitleText}
      </FormLabelAnimated>
      <DropdownMenu active={isOpen} {...getComboboxProps()} $truncateText disabled={disabled}>
        {/* Dropdown Selection */}
        <D.CurrentSelectionContainer
          filterDropdownContainer
          {...getToggleButtonProps({
            onClick: (e) => {
              setUserInput('');
              setDropdownItems(dropdownOptions);
              e.preventDefault();
            },
          })}
        >
          <D.CurrentSelectionDisplay>
            <TruncatedText title={selectedItem}>{selectedItem}</TruncatedText>
          </D.CurrentSelectionDisplay>
          <>
            {retainSelection && isFiltered() && (
              <ClearFilterIcon
                onClick={(e) => {
                  resetSelectionFilter();
                  setDropdownItems(dropdownOptions);
                  e.preventDefault();
                }}
                svgFileName={'red-x'}
                title="Clear selection"
                alt="Clear selection"
              />
            )}
            <IconSvgComponent
              svgFileName={'dropdown-caret'}
              svgStyle={styles.searchToggleButton}
              title="Change selection"
              alt="Change selection"
            />
          </>
        </D.CurrentSelectionContainer>
        {/* Dropdown Results */}
        <D.DropdownMenuContent filterdropdown="true">
          <D.DropdownMenuBody style={styles.filterDropdownBody}>
            <IconSvgComponent svgStyle={styles.searchIcon} svgFileName={'search'} alt="Search" />
            <D.SearchContainer>
              <input
                {...getInputProps({
                  onKeyPress: (e) => {
                    if (e.key === 'Enter') {
                      if (dropdownItems.length === 0) {
                        handleCreateNewItem(userInput);
                        closeSearchInput();
                        resetSelectionFilter();
                      }
                      e.preventDefault();
                    }
                  },
                  style: styles.searchInput,
                  placeholder: labels.searchInputPlaceholder,
                  value: userInput,
                  ref: searchInput,
                })}
              />

              <D.SearchResultsList
                $restrictedHeightResults={restrictedHeightResults}
                {...getMenuProps({})}
              >
                {isOpen && (
                  <>
                    {dropdownItems &&
                      dropdownItems.map((item, index) => (
                        <>
                          <li
                            {...getItemProps({
                              key: `${item}${index}`,
                              index,
                              item,
                              style: styles.searchResultItem(index, highlightedIndex),
                            })}
                          >
                            <TruncatedTextWithWrap title={item}>
                              {createSearchResultItemName(userInput, item)}
                            </TruncatedTextWithWrap>
                          </li>
                        </>
                      ))}
                    {typeof handleCreateNewItem === 'function' && userInput.length > 0 ? (
                      <D.CreateNewItemOption
                        onClick={() => {
                          handleCreateNewItem(userInput);
                          closeSearchInput();
                          resetSelectionFilter();
                        }}
                      >
                        {userInput} ({labels.handleCreateNewItemText})
                      </D.CreateNewItemOption>
                    ) : null}
                  </>
                )}
              </D.SearchResultsList>
            </D.SearchContainer>
          </D.DropdownMenuBody>
        </D.DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default Combobox;

const styles = {
  searchToggleButton: {
    cursor: 'pointer',
  },
  searchIcon: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  filterDropdownBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    width: '300px',
    height: '70px',
  },

  searchInput: {
    border: 'none',
    height: '100%',
    width: '100%',
    color: `${colors.midnight}`,
    fontSize: '1.1rem',
  },
  searchResultItem: (index, highlightedIndex) => ({
    listStyleType: 'none',
    color: '#8a909c',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    fontWeight: 'normal',
    borderTop: index === 0 ? '1px solid #EEEFF0' : 'none',
    borderBottom: '1px solid #EEEFF0',
    padding: '18px 10px 18px 20px',
    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
  }),
};
