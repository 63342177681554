import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Container,
  Title,
  CloseButton,
  ActionsContainer,
  ModalText,
} from 'legacy/shared/v1/styles/components/Modal';
import CancelButton, { Button } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import colors from 'legacy/shared/v1/constants/colors';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';

export default function ConfigurationLockConfirmationModal({
  bodyText,
  title,
  handleRequestClose,
  confirmHandler,
  confirmText,
  onSuccess,
  cancelHandler,
  cancelText,
  svgFileName,
  iconDimensions,
  noIcon,
  simpleOk,
  smallHeight,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Container autoHeight maxWidth={475}>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
      </CloseButton>
      <Title confirmationModal smallHeight noIcon={noIcon}>
        {svgFileName ? (
          <IconSvgComponent
            svgFileName={svgFileName}
            beforeInjection={(svg) => {
              if (iconDimensions) {
                // eslint-disable-next-line
                iconDimensions.height ? svg.setAttribute('height', iconDimensions.height) : null;
                // eslint-disable-next-line
                iconDimensions.width ? svg.setAttribute('width', iconDimensions.width) : null;
              }
              // svg
              //   .querySelectorAll("use")
              //   .forEach(path => path.setAttribute("fill", Colors.midnight));
            }}
            style={styles.icon}
          />
        ) : null}
        {title}
      </Title>
      <ModalText smallHeight={smallHeight}> {bodyText} </ModalText>
      <ActionsContainer>
        {cancelText && cancelHandler ? (
          <CancelButton onClick={cancelHandler} withRightSpacer mediumAlt default>
            {cancelText}
          </CancelButton>
        ) : null}
        <ButtonWithLoader
          autoFocus
          isLoading={isSubmitting}
          confirmText={confirmText}
          loadingStyleProp={'submittingWithSpinnerModal'}
          notLoadingStyleProp={simpleOk ? 'simpleOk' : 'formConfirm'}
          clickHandler={async () => {
            setIsSubmitting(true);
            const success = await confirmHandler();
            if (success) {
              onSuccess();
            }
            setIsSubmitting(false);
          }}
        />
      </ActionsContainer>
    </Container>
  );
}

const styles = {
  icon: {
    marginRight: '10px',
  },
};
