import AlertFormsProvider from 'legacy/features/alerts/services/providers/AlertFormsProvider/AlertFormsProvider';
import CreateAlertForm from 'legacy/features/alerts/ui/components/AlertForms/CreateAlertForm/CreateAlertForm';
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';

const CreateAlertPage = () => {
  return (
    <PageListWrapper>
      <PageListHead stackable>
        <PageListTitle>Create New Alert</PageListTitle>
      </PageListHead>
      <AlertFormsProvider>
        <CreateAlertForm />
      </AlertFormsProvider>
    </PageListWrapper>
  );
};

export default CreateAlertPage;
