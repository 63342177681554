import {
  NavButtonIcon,
  NavButtonText,
  StyledNavLink,
  StyledNavigationUl,
} from 'legacy/shared/v2/styles/NavigationStyles';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { useLocation } from 'react-router-dom';
const NavigationLink = ({
  item,
  itemKey,
  selectedItemKey,
  sidebarIsCollapsed,
  handleNavigationItemClick,
}) => {
  const { pathname } = useLocation();

  return (
    <StyledNavLink
      //if we got to the current page outside of the nav bar, make sure the path includes the selectedItemKey
      current={selectedItemKey === itemKey && pathname.includes(selectedItemKey.toLowerCase())}
      to={item.link}
      sidebarIsCollapsed={sidebarIsCollapsed}
      onClick={() => handleNavigationItemClick({ itemKey, link: item.link })}
    >
      <NavButtonIcon>
        <IconSvgComponent
          svgFileName={item.icon_path}
          style={{ marginLeft: item.leftOffset, marginRight: item.rightOffset }}
        />
      </NavButtonIcon>
      <NavButtonText>{item.title}</NavButtonText>
    </StyledNavLink>
  );
};

export default NavigationLink;
