import React, { useState } from 'react';
import {
  SimpleDropdownItem,
  SimpleDropdownBtn,
} from 'legacy/shared/v1/styles/components/DropdownMenu';
import { getGuardedObjectProp } from 'legacy/shared/v1/utilities/general';
import { vehicleLockStatusViewData } from 'legacy/shared/v1/constants/vehicle';

const ToggleLockVehicleMenuItem = ({ vehicle, onClick }) => {
  const lockedStatus = getGuardedObjectProp('device.CenComCoreAccess', vehicle);
  let displayLockOption = lockedStatus && lockedStatus !== 'UNAVAILABLE' && lockedStatus !== 'NA';
  return displayLockOption ? (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onClick}>
        {vehicleLockStatusViewData[lockedStatus].commandTitle}
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  ) : null;
};

export default ToggleLockVehicleMenuItem;
