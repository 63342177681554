import { useState } from 'react';

import AnalyticsDashboardQueryFilter from 'legacy/features/analyticsDashboard/components/AnalyticsDashboardQueryFilter';
import AnalyticsDashboardResultsSummary from 'legacy/features/analyticsDashboard/components/AnalyticsDashboardResultsSummary';
import AnalyticsDashboardResultsTable from 'legacy/features/analyticsDashboard/components/AnalyticsDashboardResultsTable';
import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';

const AnalyticsDashboardPage = () => {
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    key: 'AnalyticsDashboardPage',
    apiFlags: {
      addons: false,
      cell: true,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();

  const [dashboardQueryResults, setDashboardQueryResults] = useState(null);
  const [startAndEndDate, setStartAndEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return fetchVehiclesQuery.isFetching || fetchGroupsQuery.isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Analytics Dashboard</PageListTitle>
        </PageListHead>
        <AnalyticsDashboardQueryFilter
          groups={groups}
          vehicles={vehicles}
          setDashboardQueryResults={setDashboardQueryResults}
          setStartAndEndDate={setStartAndEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        {!dashboardQueryResults ? (
          <div>{/* no results message here*/}</div>
        ) : (
          <>
            <AnalyticsDashboardResultsSummary
              dashboardQueryResults={dashboardQueryResults}
              startAndEndDate={startAndEndDate}
            />
            <AnalyticsDashboardResultsTable
              vehicleReportData={dashboardQueryResults.vehicles}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
      </PageListWrapper>
    </>
  );
};

export default AnalyticsDashboardPage;
