import { vehicleLockStatusViewData } from 'legacy/shared/v1/constants/vehicle';
import { EMPTY_STRING } from 'legacy/shared/v1/constants/api';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';

export const getGuardedObjectProp = (path, object) => {
  if (object) {
    const pathSegments = path.split('.');
    const lastSegment = pathSegments.shift();
    const nextSegmentValue =
      pathSegments.length > 0
        ? object.hasOwnProperty(lastSegment) &&
          getGuardedObjectProp(pathSegments.join('.'), object[lastSegment])
        : object[lastSegment];
    if (nextSegmentValue) {
      return nextSegmentValue;
    }
  }
};

export const sortAscendingAlpha = (value1, value2) => {
  if (typeof value1 === 'string') {
    value1 = value1.toLowerCase();
  }
  if (typeof value2 === 'string') {
    value2 = value2.toLowerCase();
  }
  return !value1 ? -1 : !value2 ? 1 : value1 < value2 ? -1 : 1;
};
export const sortDescendingAlpha = (value1, value2) => {
  if (typeof value1 === 'string') {
    value1 = value1.toLowerCase();
  }
  if (typeof value2 === 'string') {
    value2 = value2.toLowerCase();
  }
  return !value1 ? 1 : !value2 ? -1 : value1 < value2 ? 1 : -1;
};

const getComparisonValue = (device) =>
  device && device.CenComCore && device.CenComCoreAccess
    ? device.CenComCoreAccess === vehicleLockStatusViewData.UNLOCKED.id
      ? 2
      : 1
    : 3;

export const sortAscendingLockStatus = (device1, device2) => {
  const lockStatus1 = getComparisonValue(device1);
  const lockStatus2 = getComparisonValue(device2);
  return lockStatus1 === 3 ? 1 : lockStatus1 < lockStatus2 ? 1 : -1;
};

export const sortDescendingLockStatus = (device1, device2) => {
  const lockStatus1 = getComparisonValue(device1);
  const lockStatus2 = getComparisonValue(device2);
  return lockStatus1 === 3 ? 1 : lockStatus1 > lockStatus2 ? 1 : -1;
};

export const maskedInputChangeHandler = (newState) => {
  const invalidStringMatches = newState.value.match(/\+[_\s()-]+(\d+)/);
  if (invalidStringMatches) {
    const numericInputMatches = newState.value.match(/(\d+)/);
    return {
      value: numericInputMatches[0],
      selection: {
        start: 1 + numericInputMatches[0].length,
        end: 1 + numericInputMatches[0].length,
      },
    };
  }
  return newState;
};

// To be removed when a more generic EMPTY_STRING replacement is implemented
export const replaceEmptyString = (input) => {
  return input !== EMPTY_STRING && input !== 'undefined' ? input : '';
};

// To be removed when a more generic EMPTY_STRING replacement is implemented
export const replaceWithEmptyString = (input) => {
  return input ? input : EMPTY_STRING;
};

export const compareValues = (objA, objB, sortIndex, order) => {
  // do this is property to sort by is nested
  // currently supports only 1 level of nesting, will need to change if we add more levels
  if (sortIndex) {
    let result1 = Array.isArray(objA[sortIndex]);
    if (result1) {
      objA = objA.orgdata;
    }
    let result2 = Array.isArray(objB[sortIndex]);
    if (result2) {
      objB = objB.orgdata;
    }
    let compoundSortIndex = sortIndex.split('.');
    if (compoundSortIndex.length === 2) {
      sortIndex = compoundSortIndex.pop();
      objA = objA[compoundSortIndex];
      objB = objB[compoundSortIndex];
    }
  }
  let propOne = objA[sortIndex];
  let propTwo = objB[sortIndex];
  if (typeof propOne === 'string') {
    propOne = propOne.toLowerCase();
  }
  if (typeof propTwo === 'string') {
    propTwo = propTwo.toLowerCase();
  }
  if (propOne < propTwo || (propOne === propTwo && order === SORT_ORDER.ASC) || !propOne) {
    return -1;
  }
  if (propOne > propTwo || (propOne === propTwo && order === SORT_ORDER.DESC) || !propTwo) {
    return 1;
  }
  if (order === SORT_ORDER.ASC) {
    return -1;
  }
  return 1;
};
