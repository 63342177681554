import { Link, useLocation } from 'react-router-dom';

import MaintenanceActionMenu from 'legacy/features/maintenance/components/MaintenanceSummary/menus/MaintenanceActionMenu';
import {
  determineBoldDisplay,
  determineStatusDisplay,
} from 'legacy/features/maintenance/utilities/formatting';
import { EventColumn } from 'legacy/shared/v1/styles/components/Maintenance';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { getVehicleIcon } from 'legacy/shared/v1/utilities/vehicle';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';
import { maintenanceSummaryTableColumns } from 'legacy/features/maintenance/components/MaintenanceSummary/tables/MaintenanceSummaryTableColumns';

const MaintenanceSummaryTable = ({ maintenanceEvents, handleUpdateListingCount }) => {
  return (
    maintenanceEvents && (
      <WcpTable
        columns={maintenanceSummaryTableColumns}
        tableSourceData={maintenanceEvents}
        emptyText="No maintenance events are currently scheduled for this organization"
        setListingCount={handleUpdateListingCount}
      />
    )
  );
};

export default MaintenanceSummaryTable;
