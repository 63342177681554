export const geofenceLogsTableColumns = [
  {
    title: 'Timestamp',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '25%',
  },
  {
    title: 'Vehicle Name',
    displayProperty: 'vehicle_label',
    width: '25%',
  },
  {
    displayProperty: 'action',
    width: '25%',
    render({ rowData, cellJsx }) {
      return <span title={'Action: ' + rowData.action + ' Geofence'}>{cellJsx} Geofence</span>;
    },
  },
  {
    title: 'Geofence Status',
    displayProperty: 'activeDisplay',
    width: '25%',
  },
];
