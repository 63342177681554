import React from 'react';

import {
  FilteredDropdownSelectorDiv,
  InnerWrapper,
} from 'legacy/shared/v2/styles/FilteredDropdownStyles';
import { ClearFilterIcon } from 'legacy/shared/v1/styles/components/Icons';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const FilteredDropdownBox = ({
  // styles
  narrowWidth,
  showClearSelectionButton,
  placeholderLabel,
  selectedOption,
  onClearFilter,
  fullWidth,
}) => {
  const clearFilterEnabled =
    showClearSelectionButton && selectedOption && selectedOption?.value !== null;
  const displayValue = selectedOption?.label || placeholderLabel;

  return (
    <FilteredDropdownSelectorDiv
      filterDropdownContainer
      narrowWidth={narrowWidth}
      fullWidth={fullWidth}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <InnerWrapper fullWidth={fullWidth}>
        <TruncatedText title={displayValue}>{displayValue}</TruncatedText>
      </InnerWrapper>
      <>
        {clearFilterEnabled && (
          <div
            // this wouldn't work on ClearFilterIcon so I wrapped in a div
            onClick={(e) => {
              onClearFilter();
              e.preventDefault();
            }}
          >
            <ClearFilterIcon svgFileName={'red-x'} title="Clear selection" alt="Clear selection" />
          </div>
        )}

        <IconSvgComponent
          svgFileName={'dropdown-caret'}
          svgStyle={{
            cursor: 'pointer',
          }}
          title="Change selection"
          alt="Change selection"
        />
      </>
    </FilteredDropdownSelectorDiv>
  );
};

export default FilteredDropdownBox;
