/**
 * @ External Dependecies
 */
import { createGlobalStyle } from 'styled-components';

/**
 * @ Internal Dependecies
 */
import theme from './theme';
import colors from 'legacy/shared/v1/constants/colors';

export const GlobalBaseStyles = createGlobalStyle`


	body {
		margin: 0;
		padding: 0;
		background: ${colors.cotton};
		font-family: ${theme.font};
		font-size: ${theme.font_size_base};
		line-height: ${theme.line_height_base};
		color: ${colors.midnight};
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	a:hover,
	a[href^="tel"] {
		text-decoration: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: calc(${theme.line_height_base}em / 2);
	}

	h1 {
		font-size: ${theme.font_size.h1};
	}

	h2 {
		font-size: ${theme.font_size.h2};
	}

	h3 {
		font-size: ${theme.font_size.h3};
	}

	h4 {
		font-size: ${theme.font_size.h4};
	}

	h5 {
		font-size: ${theme.font_size.h5};
	}

	h6 {
		font-size: ${theme.font_size.h6};
	}

	p,
	ul,
	ol,
	dl,
	hr,
	table,
	blockquote {
		margin-bottom: ${theme.line_height_base}em;
	}

	h1[class]:last-child,
	h2[class]:last-child,
	h3[class]:last-child,
	h4[class]:last-child,
	h5[class]:last-child,
	h6[class]:last-child,
	h1:last-child,
	h2:last-child,
	h3:last-child,
	h4:last-child,
	h5:last-child,
	h6:last-child,
	p:last-child,
	ul:last-child,
	ol:last-child,
	dl:last-child,
	table:last-child,
	blockquote:last-child {
		margin-bottom: 0;
	}

	.svg-icon {
		display: inline-block;
		vertical-align: middle;
		font-size: 0;

		svg,
		div {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.text-highlight {
		letter-spacing: 0.1px;
		color: ${colors.midnight};
		background: transparent;
	}

	@keyframes loadingSkeleton {
		0% { background-color: #EAEAEA; }
		50% { background-color: #F7F5F5; }
		100% { background-color: #EAEAEA; }
	}

	@-webkit-keyframes spinner {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	}
	@keyframes spinner {
	from {
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	}

	.mapboxgl-popup {
	padding: 20px;
	max-width: 300px;
	background-color: white;
	}


`;
