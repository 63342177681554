import { addNotification } from 'legacy/core/redux/ui/actions';

export const LEVELS = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};
export const getNotificationId = (() => {
  let nextNotificationId = 1;
  return () => {
    const notificationId = nextNotificationId;
    nextNotificationId += 1;
    return notificationId;
  };
})();
export const createNotification = (level, title, m, dismiss) => {
  // when the error is a type error, extract the type error message
  const message = m instanceof TypeError ? m.message : m;

  return {
    uid: getNotificationId(),
    title,
    message: message ? message.toString() : '',
    level,
    dismissible: 'button',
    // default to a 10 second autoDismiss time
    // autoDimiss === 0 => notification remains until manually dismissed
    autoDismiss: dismiss || 10,
  };
};

export function dispatchDeviceUpdateToasts(dispatch, response) {
  const normalizedResponse = response.message.map((device) => ({
    ...device,
    status: device.status || device.status_code,
    device_id: device.device_id || device.device_uid,
  }));
  if (normalizedResponse.some((device) => device.status === 200)) {
    dispatch(
      addNotification({
        notification: createNotification(
          LEVELS.SUCCESS,
          `The following devices have successfully been authorized to update: ${normalizedResponse
            .filter((device) => device.status === 200)
            .map((device) => device.device_id)
            .join(',')}`,
        ),
      }),
    );
  }
  if (normalizedResponse.some((device) => device.status.toString().indexOf('4') === 0)) {
    dispatch(
      addNotification({
        notification: createNotification(
          LEVELS.ERROR,
          `Failed to authorize an update for the following devices: ${normalizedResponse
            .filter((device) => device.status.toString().indexOf('4') === 0)
            .map((device) => device.device_id)
            .join(',')}`,
        ),
      }),
    );
  }
}
