import { RemoveButton, TextButton } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { AlertRecipientRemoveButtonWrapper } from 'legacy/shared/v1/styles/components/ManageAlerts';
import { MobileRemoveLineItemButtonWrapper } from 'legacy/shared/v1/styles/components/VehicleAddons';

const RemoveRecipientUserButton = ({ recipientUserName, handleRemoveRecipientUserFromGroup }) => {
  return (
    <AlertRecipientRemoveButtonWrapper>
      <RemoveButton
        type="button"
        lineItem
        title="Remove"
        onClick={(e) => handleRemoveRecipientUserFromGroup(recipientUserName)}
      >
        <IconSvgComponent svgFileName={'remove-feature'} alt="Remove" />
      </RemoveButton>
      <MobileRemoveLineItemButtonWrapper recipientGroupPage>
        <TextButton
          type="button"
          alt="Remove Recipient"
          largeFont
          onClick={(e) => handleRemoveRecipientUserFromGroup(recipientUserName)}
        >
          Remove Recipient
        </TextButton>
      </MobileRemoveLineItemButtonWrapper>
    </AlertRecipientRemoveButtonWrapper>
  );
};

export default RemoveRecipientUserButton;
