import React from 'react';

import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ConfirmationModal from 'legacy/shared/v1/modals/ConfirmationModal';

const ConfirmNullVsgIdModal = ({ handleUserResponse }) => {
  return (
    <ModalPortal
      onRequestClose={() => {
        handleUserResponse(false);
      }}
    >
      <ConfirmationModal
        bodyText={
          <>
            <p>
              No VSG Registration ID has been selected for this vehicle. You can still create the
              vehicle but it will remain inactive in WCP until a VSG Registration ID and
              Subscription Type is selected.
            </p>
            <h6>Do you want to continue?</h6>
          </>
        }
        cancelHandler={() => {
          handleUserResponse(false);
        }}
        confirmHandler={() => {
          handleUserResponse(true);
        }}
        cancelText={'Cancel'}
        confirmText={'Continue'}
        title={'VSG Registration ID'}
      />
    </ModalPortal>
  );
};

export default ConfirmNullVsgIdModal;
