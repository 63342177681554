import React, { useState } from 'react';
import {
  SimpleDropdownItem,
  SimpleDropdownBtn,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

export default ({ title, onClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onClick}>{title}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};
