import { createSelector } from 'reselect';
import { sortAscendingAlphaLabel } from 'legacy/shared/v1/utilities/vehicle';
import { whelenGpsCoordinates } from 'legacy/shared/v1/constants/map';

export const getHydratedVehiclesByGroup = (stateVehicles, stateGroup) => {
  if (stateGroup) {
    return stateVehicles
      .filter((v) => stateGroup.vehicle_id.includes(v.vehicle_id))
      .sort(sortAscendingAlphaLabel);
  }
  return [];
};

// single non-memoized usage
export const getFilteredVehicleMapBounds = (filteredVehicles) => {
  if (filteredVehicles.length > 0) {
    const filteredVehicleLats = filteredVehicles.map((vehicle) => vehicle.gps.latitude);
    const filteredVehicleLngs = filteredVehicles.map((vehicle) => vehicle.gps.longitude);
    const minLat = Math.min(...filteredVehicleLats);
    const maxLat = Math.max(...filteredVehicleLats);
    const minLng = Math.min(...filteredVehicleLngs);
    const maxLng = Math.max(...filteredVehicleLngs);
    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  } else {
    // default to the Chester, CT plant
    return whelenGpsCoordinates;
  }
};

// Reselect memoized version
const getMinLats = (state) => {
  const lats = Object.values(state.vehicles.vehicles)
    .filter((v) => v.gps)
    .map((v) => v.gps.latitude);
  const minLat = Math.min(...lats);
  return minLat;
};
const getMaxLats = (state) => {
  const lats = Object.values(state.vehicles.vehicles)
    .filter((v) => v.gps)
    .map((v) => v.gps.latitude);
  const maxLat = Math.max(...lats);
  return maxLat;
};

const getMinLngs = (state) => {
  const lngs = Object.values(state.vehicles.vehicles)
    .filter((v) => v.gps)
    .map((v) => v.gps.longitude);
  const minLng = Math.min(...lngs);
  return minLng;
};

const getMaxLngs = (state) => {
  const lngs = Object.values(state.vehicles.vehicles)
    .filter((v) => v.gps)
    .map((v) => v.gps.longitude);
  const maxLng = Math.max(...lngs);
  return maxLng;
};

export const selectMapBounds = createSelector(
  [getMinLats, getMaxLats, getMinLngs, getMaxLngs],
  (minLat, maxLat, minLng, maxLng) => {
    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  },
);
