import {
  FieldError,
  FormAsterisk,
  FormLabelAnimated,
} from 'legacy/shared/v1/styles/components/Form';
import { IntervalInputDiv } from 'legacy/shared/v1/styles/components/Maintenance';
import StyledDayPickerInput from 'legacy/shared/v1/ui/StyledDayPickerInput';
import { getShortDateFromDatePicker } from 'legacy/shared/v1/utilities/time';

const DueDateField = ({ formikProps: { values, errors, setFieldValue } }) => {
  return (
    <IntervalInputDiv date>
      <FormLabelAnimated animated>
        Due Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        name="dueDate"
        placeholder={values.dueDate || 'Select a Date'}
        onDayChange={(date) => {
          const formattedDate = getShortDateFromDatePicker(date);
          setFieldValue('dueDate', formattedDate);
        }}
      />
      <FieldError maintenanceEvents>{errors.dueDate}</FieldError>
    </IntervalInputDiv>
  );
};

export default DueDateField;
