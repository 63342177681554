import { useState, useEffect } from 'react';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import CreateGroupButton from 'legacy/features/vehicleGroups/ui/buttons/CreateGroupButton';
import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import { permissionData } from 'legacy/shared/v1/constants/users';
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import React from 'react';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import ListingCount from 'legacy/shared/v1/components/ListingCount';
import { groupsListComponentTableColumns } from 'legacy/features/vehicleGroups/ui/GroupsListComponentTableColumns';

const GroupsListComponent = (props) => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [listingCount, setListingCount] = useState(groups?.length);

  useEffect(() => {
    setListingCount(groups?.length);
  }, [groups]);
  let groupTableData = groups?.map((group) => {
    return {
      groupName: group.group_name,
      groupId: group.group_id,
      vehicleCount: group.vehicle_count.toString(),
      organizationId: group.organization_id,
      vehicleId: group.vehicle_id,
    };
  });

  return fetchGroupsQuery.isFetched && groups ? (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Groups</PageListTitle>
          <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
            <CreateGroupButton />
          </PermissionProtectedElement>
        </PageListHead>

        <ListingCount
          listingCount={listingCount}
          totalCount={groups?.length}
          itemTypeName={'Group'}
        />

        <WcpTable
          tableClassName="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableSourceData={groupTableData ? groupTableData : []}
          columns={groupsListComponentTableColumns}
          rowKey={(record, i) => i}
          setListingCount={setListingCount}
          emptyText="No groups are currently associated with this organization"
        />
      </PageListWrapper>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default GroupsListComponent;
