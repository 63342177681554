import ExportCsvButton from 'legacy/shared/v1/widgets/ExportCsvButton';
import { mapAlertLogsDataToCsv } from 'legacy/features/alerts/services/mapping/definitions/alertLogsMapping';

const AlertLogsExportButton = ({ alertLogs }) => {
  const exportData = mapAlertLogsDataToCsv({ alertLogs });
  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={`wcp_alert_logs_export_for_${alertLogs[0].alertName}`}
    />
  );
};

export default AlertLogsExportButton;
