import { findRecipientRowSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findRecipientRowSelector';
import { getValueOrFallbackIfUndefined } from 'legacy/shared/v2/utilities/reducerHelpers/getValueOrFallbackIfUndefined';
import { validateAndExtractPayload } from 'legacy/shared/v2/utilities/reducerHelpers/validateAndExtractPayload';

// updateAlertRecipientAlertMethodAction → updateAlertRecipientAlertMethodReducer
export const updateAlertRecipientAlertMethodReducer = (state, payload) => {
  const { alertNotificationGuid, recipientRowGuid, alertMethod } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertNotificationGuid', 'recipientRowGuid', 'alertMethod'],
  });

  // find the recipient with the recipientRowGuid
  let recipientRow = findRecipientRowSelector(state, alertNotificationGuid, recipientRowGuid);

  // update the alertMethod
  recipientRow.recipientRowAlertMethod = getValueOrFallbackIfUndefined(
    alertMethod,
    recipientRow.recipientRowAlertMethod,
  );

  if (recipientRow.recipientGroup) {
    // change the alertMethod for each recipientGroupMember
    recipientRow.recipientGroup.recipientGroupMembers.forEach((member) => {
      member.recipientUserAlertMethod = getValueOrFallbackIfUndefined(
        alertMethod,
        member.recipientUserAlertMethod,
      );
    });
  } else if (recipientRow.recipientUser) {
    recipientRow.recipientUser.recipientUserAlertMethod = getValueOrFallbackIfUndefined(
      alertMethod,
      recipientRow.recipientUser.recipientUserAlertMethod,
    );
  } else {
    // this only happens when creating new recipient row and selecting alert method first, seems ok
    //console.error('Recipient not found TODO', recipientRow);
  }
  // return state
  return {
    ...state,
  };
};
