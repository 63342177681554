import {
  resetPasswordPath,
  getForgottenPasswordCodePath,
  firstSigninResetPath,
  getForgottenUsernamePath,
} from 'legacy/shared/v1/constants/api';
import {
  updateAuthStore,
  getAccessToken,
  getRefreshToken,
} from 'legacy/shared/v1/utilities/localStore';
import { apiCall } from 'legacy/core/api/API';
import store from 'legacy/core/redux/store';
import { showNewReleaseNotification } from 'legacy/core/redux/ui/actions';

export const login = async ({ username, password }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/login',
    method: 'post',
    body: {
      Username: username,
      Password: password,
    },
  });
};

export const fsMapModeLogin = async ({ accessToken, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/maplogin',
    method: 'post',
    headers: {
      Authorization: accessToken,
    },
    body: {
      organization_id: organizationId,
    },
  });
};

export const loginWithMfa = async ({ username, session, mfaType, userMfaCode }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/loginwithmfa',
    method: 'post',
    body: {
      Username: username,
      Session: session,
      ChallengeName: mfaType,
      AuthCode: userMfaCode,
    },
  });
};

export const firstSigninResetPassword = async ({ session, username, password }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: firstSigninResetPath,
    method: 'post',
    body: {
      Session: session,
      Username: username,
      Password: password,
    },
  });
};

export const getPasswordResetCode = async ({ username }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: getForgottenPasswordCodePath(username),
    method: 'post',
  });
};

export const getForgottenUsername = async ({ email }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: getForgottenUsernamePath,
    method: 'post',
    body: {
      email: email,
    },
  });
};

export const resetForgottenPassword = async ({ resetToken, newPassword }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/resetpwd',
    method: 'post',
    body: {
      token: resetToken,
      password: newPassword,
    },
  });
};

export const resetPassword = async ({ accessToken, password, newPassword }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: resetPasswordPath,
    method: 'post',
    body: {
      AccessToken: accessToken,
      password: password,
      newPassword: newPassword,
    },
  });
};

export const logout = async ({ accessToken }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/logout',
    method: 'post',
    body: {
      AccessToken: accessToken,
    },
    headers: {
      Authorization: accessToken,
    },
  });
};

export const fsMapModeLogout = async ({ accessToken }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/maplogout',
    method: 'post',
    body: {
      AccessToken: accessToken,
    },
    headers: {
      Authorization: accessToken,
    },
  });
};

export const refreshToken = async () => {
  /// temporary fix for WCP-2171
  let reduxState = store.getState();
  if (reduxState.ui.fullscreenMapMode) {
    console.log('ERROR: tried to get refresh token in fs map mode! Why?');
    console.log('State', reduxState);

    return true;
  }
  //// end temporary fix
  const tokenString = getRefreshToken();
  try {
    if (tokenString) {
      const { response, headers } = await apiCall({
        endpoint: 'cloudfrontAPI',
        path: '/refreshtoken',
        method: 'post',
        body: {
          RefreshToken: tokenString,
        },
      });

      const releaseVersion = headers?.release_version;

      if (releaseVersion && releaseVersion !== localStorage.getItem('release_version')) {
        localStorage.setItem('release_version', releaseVersion);

        store.dispatch(showNewReleaseNotification({ releaseVersion }));
      }

      if (response && response.message && response.message.AuthenticationResult) {
        updateAuthStore(response.message.AuthenticationResult);
      }
    }
  } catch (e) {
    console.log('error refreshing access token', e);
  }
};
