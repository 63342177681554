/**
 * @ External Dependencies
 */
import React, { useState } from 'react';
/**
 * @ Components
 */
import OrganizationUserActionMenu from 'legacy/features/organizations/components/OrganizationUserActionMenu';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardType,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  CardIconWrapper,
  CardCollapsedInfo,
  ActionMenuBtnWrapper,
  CardExpandedInfo,
} from 'legacy/shared/v1/styles/components/MobileCard';
import { TableAvatar } from 'legacy/shared/v1/styles/components/TableAvatar';

export default function OrganizationCard({
  user,
  isFSE,
  currentUserUsername,
  activeTab,
  organization,
  userRole,
  handleToggleEditModal,
  handleDeactivateUser,
  handleActivateUser,
  handleDisassociateFSE,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <CardOuterWrapper>
      <CardContainer userExpanded={expanded}>
        <ActionMenuBtnWrapper>
          <OrganizationUserActionMenu
            user={user}
            currentUserUsername={currentUserUsername}
            activeTab={activeTab}
            organization={organization}
            userRole={userRole}
            handleToggleEditModal={handleToggleEditModal}
            handleDeactivateUser={handleDeactivateUser}
            handleActivateUser={handleActivateUser}
            handleDisassociateFSE={handleDisassociateFSE}
          />
        </ActionMenuBtnWrapper>
        <CardIconWrapper>
          <TableAvatar profilePicture={user.picture} userCard />
          <CardCollapsedInfo>
            <CardRow header userCardHeader>
              <CardName title={user.username}>{user.username}</CardName>
            </CardRow>
            <CardRow header userCardHeader title={user.userTitle}>
              <CardType>{user.userTitle}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded={expanded}>
          <CardRow potentialLongValue title={user.givenName}>
            <CardLabel>First Name:</CardLabel>
            <CardValue>{user.givenName}</CardValue>
          </CardRow>
          <CardRow potentialLongValue title={user.familyName}>
            <CardLabel>Last Name:</CardLabel>
            <CardValue>{user.familyName}</CardValue>
          </CardRow>
          <CardRow potentialLongValue title={user.email}>
            <CardLabel>Email:</CardLabel>
            <CardValue>{user.email}</CardValue>
          </CardRow>
          {!isFSE && (
            <CardRow>
              <CardLabel>Role:</CardLabel>
              <CardValue>{user.role}</CardValue>
            </CardRow>
          )}
          <CardRow>
            <CardLabel>Status:</CardLabel>
            <CardValue>{user.active === true ? 'Enabled' : 'Disabled'}</CardValue>
          </CardRow>
        </CardExpandedInfo>
      </CardContainer>

      <ButtonWrapper>
        <ShowCollapseButton onClick={(e) => handleToggleShowCollapse(e)}>
          {expanded ? 'COLLAPSE' : 'SEE DETAILS'}
        </ShowCollapseButton>
      </ButtonWrapper>
    </CardOuterWrapper>
  );
}
