import React from 'react';

import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ConfirmationModal from 'legacy/shared/v1/modals/ConfirmationModal';

const ResetVsgRegistrationModal = ({ handleUserResponse }) => {
  return (
    <ModalPortal
      onRequestClose={() => {
        handleUserResponse(false);
      }}
    >
      <ConfirmationModal
        bodyText={
          <>
            <p>
              Changing the VSG Registration ID will reset all selected preferences for the VSG,
              CenCom Core, and any connected peripherals. Any channel or configuration preferences
              set for CenCom Core or connected peripherals that have already taken effect via OTA
              will remain until a new preference is selected.
            </p>
            <p>
              If there is an update pending for this vehicle, changing the VSG Registration ID
              before this update has been processed will cause this vehicle to not receive this
              update.
            </p>
            <h6>Do you want to continue?</h6>
          </>
        }
        cancelHandler={() => {
          handleUserResponse(false);
        }}
        confirmHandler={() => {
          handleUserResponse(true);
        }}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        title={'Reset VSG Preferences'}
      />
    </ModalPortal>
  );
};

export default ResetVsgRegistrationModal;
