import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import ConfigurationLibraryCard from 'legacy/features/configurations/mobile/ConfigurationLibraryCard';
import ConfigurationLibraryActionMenu from 'legacy/features/configurations/menus/ConfigurationLibraryActionMenu';

const ConfigurationLibraryCardContainer = ({ configurationList }) => {
  const getActionMenu = (configData) => {
    return <ConfigurationLibraryActionMenu configData={configData} />;
  };
  return (
    <div>
      <Cards>
        {configurationList.map((c) => (
          <ConfigurationLibraryCard
            configuration={c}
            key={c.configId}
            actionMenuComponent={() => getActionMenu(c)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default ConfigurationLibraryCardContainer;
