import React from 'react';
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';

const GroupNameField = ({ formikProps: { values, touched, errors, handleChange, handleBlur } }) => {
  return (
    <AnimatedField
      name="groupName"
      placeholder="Group Name"
      validationError={errors.groupName}
      touched={touched.groupName}
      value={values.groupName}
      handleChange={handleChange}
      handleBlur={handleBlur}
      style={styles.groupNameInput}
      required
    />
  );
};

export default GroupNameField;

const styles = {
  groupNameInput: {
    marginBottom: 26,
  },
};
