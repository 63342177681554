import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Container,
  Title,
  CloseButton,
  ActionsContainer,
  ModalText,
  BoldedItem,
} from 'legacy/shared/v1/styles/components/Modal';
import CancelButton, { Button } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import colors from 'legacy/shared/v1/constants/colors';

import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';

/* WILL BE DEPRECATED, use SimpleConfirmationModal.js instead */
export default function ConfirmationModalWithInnerModalPortal({
  bodyText,
  secondaryBodyText,
  boldedItem,
  title,
  submitHandler,
  confirmText,
  onSuccess,
  onFailure,
  cancelHandler,
  cancelText,
  svgFileName,
  iconDimensions,
  onCloseModal,
  width,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <ModalPortal onRequestClose={onCloseModal}>
      <Container autoHeight maxWidth={width ? width : 475}>
        <CloseButton onClick={onCloseModal}>
          <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
        </CloseButton>
        <Title style={styles.title}>
          {svgFileName && (
            <IconSvgComponent
              svgFileName={svgFileName}
              beforeInjection={(svg) => {
                if (iconDimensions) {
                  iconDimensions.height ? svg.setAttribute('height', iconDimensions.height) : null;
                  iconDimensions.width ? svg.setAttribute('width', iconDimensions.width) : null;
                }
              }}
              style={styles.icon}
            />
          )}
          {title}
        </Title>
        <ModalText>
          {' '}
          {bodyText} <BoldedItem>{boldedItem}</BoldedItem>
        </ModalText>
        <ModalText> {secondaryBodyText} </ModalText>
        <ActionsContainer>
          {cancelText && cancelHandler && (
            <CancelButton onClick={cancelHandler} withRightSpacer mediumAlt default type="button">
              {cancelText}
            </CancelButton>
          )}

          <ButtonWithLoader
            autoFocus
            isLoading={isSubmitting}
            confirmText={confirmText}
            loadingStyleProp={'submittingWithSpinnerModal'}
            notLoadingStyleProp={'formConfirm'}
            clickHandler={async () => {
              setIsSubmitting(true);
              const success = await submitHandler();
              if (success) {
                onSuccess();
              } else {
                onFailure();
              }
              setIsSubmitting(false);
            }}
          />
        </ActionsContainer>
      </Container>
    </ModalPortal>
  );
}

const styles = {
  title: {
    marginBottom: '42px',
  },
  icon: {
    marginRight: '10px',
  },
};
