import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { AnalyticsHeatmapApi } from 'legacy/features/analyticsHeatmap/helpers/AnalyticsHeatmapApiContext';
import {
  SidebarAside,
  SidebarShowToggle,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import AnalyticsHeatmapSidebarControls from 'legacy/features/analyticsHeatmap/components/sidebar/AnalyticsHeatmapSidebarControls';

const AnalyticsHeatmapSidebarContainer = ({ isOpen }) => {
  const { setHeatmapSidebarOpen } = useContext(AnalyticsHeatmapApi);
  return (
    <SidebarAside expanded={isOpen}>
      {
        <Scrollbars
          style={{
            width: isOpen ? '100%' : 0,
            height: '100%',
            zIndex: 1,
            backgroundColor: 'white',
            transition: 'all 0.3s',
            transitionDelay: isOpen ? '-.05s' : '.05s',
          }}
        >
          <AnalyticsHeatmapSidebarControls />
        </Scrollbars>
      }
      <SidebarShowToggle isOpen={isOpen} onClick={() => setHeatmapSidebarOpen(!isOpen)}>
        {isOpen ? null : (
          <IconSvgComponent
            svgFileName="heatmapSidebar"
            title="Show map filter"
            alt="Show map filter"
          />
        )}
      </SidebarShowToggle>
    </SidebarAside>
  );
};

export default AnalyticsHeatmapSidebarContainer;
