import { useEffect, useState } from 'react';

import RecipientGroupUsersContainer from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/containers/RecipientGroupUsersContainer';
import RecipientGroupNameField from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/fields/RecipientGroupNameField';
import CreateRecipientGroupFormSubmit from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/submit/CreateRecipientGroupFormSubmit';
import { RecipientsFormWrapper } from 'legacy/shared/v1/styles/components/RecipientGroups';
import { WcpForm } from 'legacy/shared/v2/components/controls/WcpForm/WcpForm';

const CreateRecipientGroupForm = ({ formik }) => {
  // render
  return (
    <WcpForm
      formik={formik}
      customOnSubmit={(e) => {
        formik.values.recipientGroupName = formik.values.recipientGroupNameField;
        formik.values.recipientGroupMembers = formik.values.recipientGroupMembersField;

        return formik.handleSubmit(e);
      }}
    >
      <RecipientsFormWrapper>
        <RecipientGroupNameField
          formik={formik}
          recipientGroupName={formik.values.recipientGroupNameField || ''}
        />
        <RecipientGroupUsersContainer
          formik={formik}
          // /recipients={formik.values.recipientGroupMembersField}
        />
        <CreateRecipientGroupFormSubmit formik={formik} />
      </RecipientsFormWrapper>
    </WcpForm>
  );
};

export default CreateRecipientGroupForm;
