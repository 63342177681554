import { useEffect } from 'react';

import { getVehicleGpsCoordinates } from 'legacy/shared/v1/utilities/map/MapAnimationUtils';

// This is a custom hook that is used to follow a vehicle on the map or fly to a set of coordinates
const useFollowVehicle = ({ mapboxApi, selectedVehicle, visibleMapVehicles, animatedVehicles }) => {
  // follow selected vehicle
  useEffect(() => {
    // if vehicle is selected, reframe map around vehicle every fetch
    if (mapboxApi && selectedVehicle) {
      const vehicle = animatedVehicles.find((v) => v.vehicle_id === selectedVehicle.vehicle_id);
      // don't try to perfom a flyto if lat and long are undefined (race condition on performance mode)
      if (vehicle?.gps?.latitude && vehicle?.gps?.longitude) {
        mapboxApi.flyTo({
          center: getVehicleGpsCoordinates(vehicle),
          padding: 50,
          maxZoom: 12,
        });
      }
    }
  }, [visibleMapVehicles]);
};

export default useFollowVehicle;
