import dateFnsFormat from 'date-fns/format';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { generateAccessToken } from 'legacy/core/api/organizations';
import { modalFormChanged } from 'legacy/core/redux/ui/actions';
import colors from 'legacy/shared/v1/constants/colors';
import { stateOptionsArray } from 'legacy/shared/v1/constants/states';
import {
  FIELD_SOLUTION_ENGINEER,
  MAP_ANALYST,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/v1/constants/users';
import ConfirmationModal from 'legacy/shared/v1/modals/ConfirmationModal';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';
import Button, { GenerateCodeButton } from 'legacy/shared/v1/styles/components/Button';
import { Checkbox } from 'legacy/shared/v1/styles/components/Checkbox';
import FormWrapper, {
  CheckboxTextSpan,
  DateRangeValidationDiv,
  FieldError,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormLabelAnimated,
  FormLegend,
  FormSectionLabel,
  FormMessage,
  OrganizationDateDiv,
  HaasStatus,
  HassIntegrationWrapperDiv,
  OptInFeatureWrapper,
  IntegrationTextFieldWrapper,
} from 'legacy/shared/v1/styles/components/Form';
import { CloseButton, Container, Title } from 'legacy/shared/v1/styles/components/Modal';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import MaskedField from 'legacy/shared/v1/ui/fields/MaskedField';
import SelectField from 'legacy/shared/v1/ui/fields/SelectField';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import StyledDayPickerInput from 'legacy/shared/v1/ui/StyledDayPickerInput';
import { handleSelectBlur, handleSelectChange } from 'legacy/shared/v1/utilities/form';
import { replaceEmptyString, replaceWithEmptyString } from 'legacy/shared/v1/utilities/general';
import { validateOrganization } from 'legacy/features/vehicles.prebilling/validators/preBillingValidators';
import { customerTypeDropdownOptions } from 'legacy/shared/v1/constants/organizations';
import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/FilteredDropdown';
import { timeZoneDropdownOptions } from 'legacy/features/geofences/geofences/constants/dropdownOptions';

class OrganizationForm extends Component {
  static MODE = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
  };

  static FORM_TITLE = {
    CREATE: 'Create Organization',
    EDIT: 'Edit Organization',
  };

  static FORM_ICON = {
    CREATE: 'plus',
    EDIT: 'edit-pencil-blue',
  };

  state = {
    haasChecked: this.props.organization ? this.props.organization.haasEnabled : false,
    thruGreenEnabled: this.props.organization ? this.props.organization.thruGreenEnabled : false,
    appliedInformationEnabled: this.props.organization
      ? this.props.organization.appliedInformationEnabled
      : false,
    alertsActiveChecked: this.props.organization ? !this.props.organization.alertsActive : false,
    haasActivationMessage: !this.props.organization
      ? null
      : this.props.organization.haasEnabled
        ? this.props.organization.haasSetup
          ? 'WCP/HAAS Integration is currently enabled'
          : 'WCP/HAAS Integration is currently pending'
        : null,
    modalFormMessage: null,
    gttAccessKeyModalActive: false,
    gttAccessKey: null,
    regenerateGttAccessKeyModalActive: false,
  };

  setGlobalFormDirty = (innerHandler) => (e) => {
    this.props.modalFormChanged(e);
    innerHandler(e);
  };

  confirmHandler = async (values, formikActions) => {
    const { confirmHandler, handleRequestClose } = this.props;
    const success = await confirmHandler({
      ...values,
      haasEnabled: this.state.haasChecked,
      thruGreenEnabled: this.state.thruGreenEnabled,
      //flip value because we are muting alerts
      alertsActive: !this.state.alertsActiveChecked,
      contactPhone: (values.contactPhone && `+${values.contactPhone.replace(/\D/g, '')}`) || '',
      organizationId: this.props.organization ? this.props.organization.organizationId : null,
      contactPhoneExtension: replaceWithEmptyString(values.contactPhoneExtension),
      vehicleLimit: +values.vehicleLimit,
    });
    formikActions.setSubmitting(false);
    if (success) {
      handleRequestClose();
    }
  };

  confirmKeyGeneration = () => {
    if (this.props.organization.gttSetup) {
      this.setState({
        regenerateGttAccessKeyModalActive: true,
        gttAccessKey: null,
      });
    } else {
      this.generateAccess();
    }
  };

  hasPermissionsForGtt = (userRole) =>
    userRole === userRoles[ORGANIZATION_ADMIN].value ||
    userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  hasPermissionsForHaas = (userRole) =>
    userRole === userRoles[ORGANIZATION_ADMIN].value ||
    userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  hasPermissionsForThruGreen = (userRole) => userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  hasPermissionsForAppliedInformation = (userRole) =>
    userRole === userRoles[FIELD_SOLUTION_ENGINEER].value;

  generateAccess = async () => {
    try {
      let { response } = await generateAccessToken({
        organizationId: this.props.organization.organizationId,
      });
      if (response.message) {
        this.setState({
          regenerateGttAccessKeyModalActive: false,
          gttAccessKeyModalActive: true,
          gttAccessKey: response.message.gtt_access_id,
        });
      }
    } catch (e) {}
  };

  getMuteAlertsOption = () => {
    return (
      <>
        <FormSectionLabel gtt>Organization Alerts</FormSectionLabel>
        <OptInFeatureWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={this.state.alertsActiveChecked}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              onClick={() => {
                this.setState({
                  alertsActiveChecked: !this.state.alertsActiveChecked,
                });
              }}
            />
          </Checkbox>

          <CheckboxTextSpan>Mute Alerts</CheckboxTextSpan>
        </OptInFeatureWrapper>
      </>
    );
  };

  render() {
    const { organization, mode, handleRequestClose, userRole, modalFormMessage, closeGlobalModal } =
      this.props;
    const { gttAccessKeyModalActive, gttAccessKey, regenerateGttAccessKeyModalActive } = this.state;

    const isReadOnly =
      userRole !== userRoles[SYSTEM_ADMIN].value &&
      userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
        ? true
        : false;

    return (
      <Container autoHeight maxWidth={750}>
        {gttAccessKeyModalActive ? (
          <ModalPortal
            onRequestClose={() =>
              this.setState({
                gttAccessKeyModalActive: false,
              })
            }
          >
            <ConfirmationModal
              confirmHandler={() =>
                this.setState({
                  gttAccessKeyModalActive: false,
                })
              }
              bodyText={
                <>
                  <span>
                    This is your one time access key. Please make sure to copy it for use in the
                    Miovision cloud
                  </span>
                  <div style={styles.accessKeyText}>{gttAccessKey}</div>
                </>
              }
              confirmText={'Got it'}
              title={'Miovision Access Key'}
            />
          </ModalPortal>
        ) : null}
        {regenerateGttAccessKeyModalActive ? (
          <ModalPortal
            onRequestClose={() =>
              this.setState({
                regenerateGttAccessKeyModalActive: false,
              })
            }
          >
            <ConfirmationModal
              confirmHandler={() => {
                this.generateAccess();
              }}
              bodyText={
                <>
                  <span>
                    Do you really want to generate a new access key? This cannot be undone. You will
                    need to replace your old key in the Miovision cloud with the new one that will
                    be generated.
                  </span>
                  <div style={styles.accessKeyText}>{gttAccessKey}</div>
                </>
              }
              cancelHandler={() =>
                this.setState({
                  regenerateGttAccessKeyModalActive: false,
                })
              }
              cancelText={'Cancel'}
              confirmText={'Confirm and Generate'}
              title={'Generate New Key'}
            />
          </ModalPortal>
        ) : null}
        <CloseButton onClick={handleRequestClose}>
          <IconSvgComponent svgFileName={'x'} />
        </CloseButton>

        <Title style={styles.title}>
          <IconSvgComponent style={styles.icon} svgFileName={OrganizationForm.FORM_ICON[mode]} />
          {OrganizationForm.FORM_TITLE[mode]}
        </Title>

        <FormWrapper>
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={
              (organization && {
                organizationName: organization.organizationName,
                contactName: organization.contactName,
                contactPhone: organization.contactPhone,
                contactPhoneExtension: replaceEmptyString(organization.contactPhoneExtension),
                contactEmail: organization.contactEmail,
                customerType: organization.customerType,
                address1: organization.address1,
                address2: organization.address2,
                city: organization.city,
                state: organization.state,
                vehicleLimit: organization.vehicleLimit,
                startDate: organization.startDate,
                endDate: organization.endDate,
                gttEnabled: organization.gttEnabled,
                gttSetup: organization.gttSetup,
                haasEnabled: organization.haasEnabled,
                thruGreenEnabled: organization.thruGreenEnabled,
                appliedInformationEnabled: organization.appliedInformationEnabled,
                haasSetup: organization.haasSetup,
                wimAccountId: organization.wimAccountId,
                appliedInformationBusinessId: organization.appliedInformationBusinessId,
                timeZone: organization.timeZone,
              }) || {
                organizationName: '',
                contactName: '',
                contactPhone: '1',
                contactPhoneExtension: '',
                contactEmail: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                vehicleLimit: '',
                startDate: dateFnsFormat(new Date(), 'MM/dd/yyyy'),
                endDate: dateFnsFormat(new Date(), 'MM/dd/yyyy'),
                gttEnabled: false,
                gttSetup: false,
                haasEnabled: false,
                thruGreenEnabled: false,
                appliedInformationEnabled: false,
                haasSetup: false,
                wimAccountId: '',
                appliedInformationBusinessId: '',
              }
            }
            validate={validateOrganization}
            onSubmit={this.confirmHandler}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              handleChange: formikHandleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              setTouched,
              isSubmitting,
            }) => {
              const handleChange = dirty
                ? formikHandleChange
                : this.setGlobalFormDirty(formikHandleChange);
              return (
                <Form onSubmit={submitForm}>
                  <FormBody>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="organizationName"
                          id="organizationNameTextInput"
                          placeholder="Organization Name"
                          value={values.organizationName}
                          touched={touched.organizationName}
                          validationError={errors.organizationName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>
                      <FormCol>
                        <AnimatedField
                          name="contactName"
                          id="contactNameTextInput"
                          placeholder="Contact Name"
                          value={values.contactName}
                          touched={touched.contactName}
                          validationError={errors.contactName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <div>
                          <MaskedField
                            name="contactPhone"
                            id="contactPhoneTextInput"
                            placeholder="Contact Phone"
                            value={values.contactPhone}
                            touched={touched.contactPhone}
                            validationError={errors.contactPhone}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            disabled={false}
                            required
                            mask="+9 (999) 999-9999"
                            containerStyles={styles.phoneNumber}
                          />
                          <AnimatedField
                            name="contactPhoneExtension"
                            id="contactPhoneExtensionTextInput"
                            placeholder="Extension"
                            value={values.contactPhoneExtension}
                            touched={touched.contactPhoneExtension}
                            validationError={errors.contactPhoneExtension}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            containerStyles={styles.phoneNumber}
                          />
                        </div>
                      </FormCol>

                      <FormCol>
                        <AnimatedField
                          name="contactEmail"
                          id="contactEmailTextInput"
                          placeholder="Contact Email"
                          value={values.contactEmail}
                          touched={touched.contactEmail}
                          validationError={errors.contactEmail}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <OrganizationDateDiv>
                          <FormLabelAnimated animated>
                            Start Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                          </FormLabelAnimated>
                          {isReadOnly ? (
                            <div>{values.startDate}</div>
                          ) : (
                            <>
                              <StyledDayPickerInput
                                name="startDate"
                                validateOnChange={false}
                                placeholder={values.startDate}
                                onDayChange={(day) => {
                                  setFieldValue('startDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                                }}
                              />
                              <DateRangeValidationDiv>{errors.startDate}</DateRangeValidationDiv>
                            </>
                          )}
                        </OrganizationDateDiv>
                      </FormCol>

                      <FormCol>
                        <OrganizationDateDiv>
                          <FormLabelAnimated animated>
                            End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                          </FormLabelAnimated>
                          {isReadOnly ? (
                            <div>{values.endDate}</div>
                          ) : (
                            <>
                              <StyledDayPickerInput
                                name="endDate"
                                placeholder={values.endDate}
                                onDayChange={(day) => {
                                  setFieldValue('endDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                                }}
                              />
                              <DateRangeValidationDiv>{errors.endDate}</DateRangeValidationDiv>
                            </>
                          )}
                        </OrganizationDateDiv>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="address1"
                          id="address1TextInput"
                          placeholder="Address 1"
                          value={values.address1}
                          touched={touched.address1}
                          validationError={errors.address1}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>

                      <FormCol>
                        <AnimatedField
                          name="address2"
                          id="address2TextInput"
                          placeholder="Address 2"
                          value={values.address2}
                          touched={touched.address2}
                          validationError={errors.address2}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="city"
                          id="cityTextInput"
                          placeholder="City"
                          value={values.city}
                          touched={touched.city}
                          validationError={errors.city}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={false}
                          required
                        />
                      </FormCol>

                      <FormCol>
                        <SelectField
                          name="licenseState"
                          placeholder={'State'}
                          defaultValue={values.state}
                          disabled={false}
                          onChange={handleSelectChange(setFieldValue, 'state')}
                          onBlur={handleSelectBlur(setTouched, 'state', touched)}
                          isClearable={false}
                          isSearchable={false}
                          options={stateOptionsArray}
                          field="true"
                          required
                        />
                        <FieldError>{touched.state && errors.state}</FieldError>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <AnimatedField
                          name="vehicleLimit"
                          placeholder="Vehicle Limit"
                          value={values.vehicleLimit}
                          touched={touched.vehicleLimit}
                          validationError={errors.vehicleLimit}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>
                      <FormCol>
                        <AnimatedField
                          name="wimAccountId"
                          id="wimAccountIdTextInput"
                          placeholder="WIM Account ID"
                          value={values.wimAccountId}
                          touched={touched.wimAccountId}
                          validationError={errors.wimAccountId}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          disabled={
                            userRole !== userRoles[SYSTEM_ADMIN].value &&
                            userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
                          }
                          required
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow noBottom>
                      <FormCol>
                        <FilteredDropdown
                          fieldLabel={'Customer Type'}
                          domId="customerTypeDropdown"
                          placeholderLabel="Select a Customer Type..."
                          styleOptions={{
                            showClearSelectionButton: false,
                            fullWidth: true,
                            required: true,
                          }}
                          setup={{
                            dropdownOptions: customerTypeDropdownOptions,
                            preselectedValue: values.customerType,
                            handleSelect: (selectedOption) => {
                              setFieldValue('customerType', selectedOption.value);
                            },
                          }}
                        />
                        <FieldError snugSpacing>{errors.customerType}</FieldError>
                      </FormCol>
                      <FormCol>
                        <FilteredDropdown
                          fieldLabel={'Time Zone'}
                          domId="timeZoneDropdown"
                          placeholderLabel="Select a Time Zone..."
                          styleOptions={{
                            showClearSelectionButton: false,
                            fullWidth: true,
                            required: true,
                          }}
                          setup={{
                            dropdownOptions: timeZoneDropdownOptions,
                            preselectedValue: values.timeZone,
                            handleSelect: (selectedOption) => {
                              setFieldValue('timeZone', selectedOption.value);
                            },
                          }}
                        />
                        <FieldError snugSpacing>{errors.timeZone}</FieldError>
                      </FormCol>
                      <FormCol></FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      {/* TEMP ROLE CHECK UNTIL PHASE 2 OF GTT */}
                      <FormCol>
                        <div>
                          <FormSectionLabel gtt>Miovision Integration</FormSectionLabel>
                          <Checkbox
                            singleCheck
                            id="GTTIntegrationCheckbox"
                            disabled={!this.hasPermissionsForGtt(userRole)}
                          >
                            <CheckboxInner
                              isChecked={values.gttEnabled}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              disabledStyleProp={!this.hasPermissionsForGtt(userRole)}
                              onClick={() => setFieldValue('gttEnabled', !values.gttEnabled)}
                            />
                          </Checkbox>
                          <CheckboxTextSpan>Enable Miovision Integration</CheckboxTextSpan>
                          {this.hasPermissionsForGtt(userRole) && (
                            <div>
                              {this.props.organization && values.gttEnabled && (
                                <GenerateCodeButton
                                  type="button"
                                  id="generateAccessKeyButton"
                                  onClick={this.confirmKeyGeneration}
                                >
                                  Generate Access Key
                                </GenerateCodeButton>
                              )}
                            </div>
                          )}
                        </div>
                      </FormCol>
                      <FormCol>
                        <>
                          <HassIntegrationWrapperDiv>
                            <FormSectionLabel gtt>HAAS Integration</FormSectionLabel>
                            <Checkbox
                              singleCheck
                              id="haasIntegrationCheckbox"
                              disabled={!this.hasPermissionsForHaas(userRole)}
                            >
                              <CheckboxInner
                                isChecked={this.state.haasChecked}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabledStyleProp={!this.hasPermissionsForHaas(userRole)}
                                onClick={() => {
                                  this.setState({
                                    haasChecked: !this.state.haasChecked,
                                  });
                                }}
                              />
                            </Checkbox>
                            <CheckboxTextSpan>Enable HAAS Integration</CheckboxTextSpan>
                            <FormLegend>
                              <HaasStatus>{this.state.haasActivationMessage}</HaasStatus>
                            </FormLegend>
                          </HassIntegrationWrapperDiv>
                        </>
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <>
                          <FormSectionLabel gtt>Integrations</FormSectionLabel>
                          <OptInFeatureWrapper>
                            <Checkbox
                              singleCheck
                              id="enableThruGreenIntegration"
                              disabled={!this.hasPermissionsForThruGreen(userRole)}
                            >
                              <CheckboxInner
                                isChecked={this.state.thruGreenEnabled}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabledStyleProp={!this.hasPermissionsForThruGreen(userRole)}
                                onClick={() => {
                                  this.setState({
                                    thruGreenEnabled: !this.state.thruGreenEnabled,
                                  });
                                }}
                              />
                            </Checkbox>

                            <CheckboxTextSpan>Enable ThruGreen Integration</CheckboxTextSpan>
                          </OptInFeatureWrapper>
                          <OptInFeatureWrapper>
                            <Checkbox
                              singleCheck
                              id="enableAppliedInformationIntegration"
                              disabled={!this.hasPermissionsForAppliedInformation(userRole)}
                            >
                              <CheckboxInner
                                isChecked={values.appliedInformationEnabled}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabledStyleProp={
                                  !this.hasPermissionsForAppliedInformation(userRole)
                                }
                                onClick={() =>
                                  setFieldValue(
                                    'appliedInformationEnabled',
                                    !values.appliedInformationEnabled,
                                  )
                                }
                              />
                            </Checkbox>

                            <CheckboxTextSpan>
                              Enable Applied Information Integration
                            </CheckboxTextSpan>
                            <IntegrationTextFieldWrapper
                              show={
                                values.appliedInformationEnabled &&
                                this.hasPermissionsForAppliedInformation(userRole)
                              }
                            >
                              <AnimatedField
                                name="appliedInformationBusinessId"
                                id="appliedInformationBusinessIdTextInput"
                                placeholder="Applied Information Buisness ID"
                                value={values.appliedInformationBusinessId}
                                touched={touched.appliedInformationBusinessId}
                                validationError={errors.appliedInformationBusinessId}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabled={!this.hasPermissionsForAppliedInformation(userRole)}
                                required
                              />
                            </IntegrationTextFieldWrapper>
                          </OptInFeatureWrapper>
                        </>
                      </FormCol>
                      {userRole === userRoles[FIELD_SOLUTION_ENGINEER].value ||
                      userRole === userRoles[SYSTEM_ADMIN].value ||
                      userRole === userRoles[ORGANIZATION_ADMIN].value ? (
                        <FormCol>{this.getMuteAlertsOption()}</FormCol>
                      ) : null}
                    </FormGridRow>
                    <FormMessage message={modalFormMessage}>{modalFormMessage}</FormMessage>
                  </FormBody>

                  <FormFoot>
                    <FormLegend>
                      <FormAsterisk>*</FormAsterisk> &mdash; required fields
                    </FormLegend>

                    <FormActions>
                      <Button
                        onClick={handleRequestClose}
                        withRightSpacer
                        mediumAlt
                        default
                        type="button"
                        id="cancelButton"
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoader
                        domId="saveOrganizationButton"
                        isLoading={isSubmitting}
                        confirmText={OrganizationForm.FORM_TITLE[mode]}
                        loadingStyleProp={'submittingWithSpinnerModal'}
                        notLoadingStyleProp={'mediumAlt'}
                        clickHandler={submitForm}
                      />
                    </FormActions>
                  </FormFoot>
                </Form>
              );
            }}
          </Formik>
        </FormWrapper>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    modalFormMessage: state.ui.modalFormMessage,
  }),
  { modalFormChanged },
)(OrganizationForm);

const styles = {
  title: {
    marginBottom: '42px',
  },
  icon: {
    marginRight: '10px',
  },
  acountForm: {
    minWidth: '700',
  },
  phoneNumber: {
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    marginBottom: '10px',
  },
  accessKeyText: {
    color: colors.midnight,
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 30,
  },
};
