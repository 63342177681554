import CreateRecipientGroupForm from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/CreateRecipientGroupForm';
import useCreateRecipientGroupForm from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/useCreateRecipientGroupForm';
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import { FormikProvider } from 'formik';

const CreateRecipientGroupPage = () => {
  const formik = useCreateRecipientGroupForm();
  return (
    <PageListWrapper>
      <PageListHead stackable noBottomMargin>
        <PageListTitle>Create Recipient Group</PageListTitle>
      </PageListHead>
      <FormikProvider value={formik}>
        <CreateRecipientGroupForm formik={formik} />
      </FormikProvider>
    </PageListWrapper>
  );
};

export default CreateRecipientGroupPage;
