import { createAction } from 'redux-actions';
import {
  getUsers,
  createUserAPI,
  updateUserAPI,
  decorateUserObject,
  fetchFSEUsers as fetchFSEUsersAPI,
  activateUserAPI,
  deactivateUserAPI,
  getUserAPI,
} from 'legacy/core/api/users';
import { receiveUser, requestUser, updateUserPassword } from 'legacy/core/redux/user/actions';
import { fetchOrganizations } from 'legacy/core/redux/organizations/actions';
import { closeGlobalModal, toggleUserCreationActiveLimitModal } from 'legacy/core/redux/ui/actions';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';
import { allAdminUserRoles } from 'legacy/shared/v1/constants/users';
import { submitLogout } from 'legacy/core/redux/user/actions';

export const requestUsers = createAction('REQUEST_USERS');
export const receiveUsers = createAction('RECEIVE_USERS');
export const rejectUsers = createAction('REJECT_USERS');
export const requestFSEUsers = createAction('REQUEST_FSE_USERS');
export const receiveFSEUsers = createAction('RECEIVE_FSE_USERS');
export const rejectFSEUsers = createAction('REJECT_FSE_USERS');
export const requestCreateUser = createAction('REQUEST_CREATE_USER');
export const successCreateUser = createAction('SUCCESS_CREATE_USER');
export const rejectCreateUser = createAction('REJECT_CREATE_USER');
export const requestDeactivateUser = createAction('REQUEST_DEACTIVATE_USER');
export const successDeactivateUser = createAction('SUCCESS_DEACTIVATE_USER');
export const rejectDeactivateUser = createAction('REJECT_DEACTIVATE_USER');
export const requestActivateUser = createAction('REQUEST_ACTIVATE_USER');
export const successActivateUser = createAction('SUCCESS_ACTIVATE_USER');
export const rejectActivateUser = createAction('REJECT_ACTIVATE_USER');
export const requestEditUser = createAction('REQUEST_EDIT_USER');
export const successEditUser = createAction('SUCCESS_EDIT_USER');
export const rejectEditUser = createAction('REJECT_EDIT_USER');
export const requestRenameUser = createAction('REQUEST_RENAME_USER');
export const successRenameUser = createAction('SUCCESS_RENAME_USER');
export const rejectRenameUser = createAction('REJECT_RENAME_USER');

export const fetchUsers = ({ organizationId }) => {
  return async (dispatch, getState) => {
    dispatch(requestUsers());

    try {
      const { response } = await getUsers({ organizationId });
      if (response.errorMessage) throw response.errorMessage;
      dispatch(receiveUsers(response.message.map((user) => decorateUserObject({ user }))));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectUsers(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Users', e),
        }),
      );
      return false;
    }
  };
};

export const createUser = ({
  username,
  email,
  phoneNumber,
  phoneExtension,
  givenName,
  familyName,
  userTitle,
  active,
  userRole,
  picture,
  organizationId,
  twoFactorAuthMethod,
}) => {
  console.log(organizationId);
  return async (dispatch, getState) => {
    dispatch(requestCreateUser());

    try {
      const { response } = await createUserAPI({
        username,
        email,
        phoneNumber,
        phoneExtension,
        givenName,
        familyName,
        userTitle,
        active,
        userRole,
        picture,
        organizationId,
        twoFactorAuthMethod,
      });

      dispatch(successCreateUser(response.message));
      dispatch(fetchUsers({ organizationId }));
      dispatch(closeGlobalModal());

      const limitMsg =
        'Successfully created the user! The user now is inactive cause the organization has reached the user limit.';

      if (response.message === limitMsg) {
        dispatch(toggleUserCreationActiveLimitModal({ username }));
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectCreateUser({ errorMessage: e }));
    }
  };
};

export const activateUser = (user) => {
  return async (dispatch, getState) => {
    dispatch(requestActivateUser(user.username));

    try {
      const { response } = await activateUserAPI(user.username);

      if (response && response.message) {
        dispatch(fetchUsers({ organizationId: user.organizationId }));
      }
      dispatch(successActivateUser(response.message));
    } catch (e) {
      console.log(e);
      dispatch(rejectActivateUser(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Activating User', e),
        }),
      );
    }
  };
};

export const deactivateUser = ({ userModalOrganizationId, userModalUsername }) => {
  console.log({ userModalOrganizationId, userModalUsername });
  return async (dispatch) => {
    dispatch(requestDeactivateUser(userModalUsername));

    try {
      const { response } = await deactivateUserAPI(userModalUsername);

      if (response && response.message) {
        dispatch(fetchUsers({ organizationId: userModalOrganizationId }));
      }

      dispatch(successDeactivateUser(response.message));
      dispatch(closeGlobalModal());
    } catch (e) {
      console.log(e);
      dispatch(rejectDeactivateUser(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Deactivating User', e),
        }),
      );
    }
  };
};

export const fetchUser = ({ username }) => {
  return async (dispatch, getState) => {
    dispatch(requestUser(username));
    try {
      const { response } = await getUserAPI({ username });
      if (response && response.message) {
        const decoratedUserObject = decorateUserObject({ user: response.message });
        dispatch(receiveUser({ user: decoratedUserObject }));
      }
    } catch (e) {
      console.log('error getting user:', e);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error getting user', e),
        }),
      );
    }
  };
};

export const editUser = ({
  username,
  email,
  phoneNumber,
  phoneExtension,
  givenName,
  familyName,
  userTitle,
  active,
  userRole,
  picture,
  organizationId,
  twoFactorAuthMethod,
  resetUser,
  optOutOfEmails,
}) => {
  return async (dispatch, getState) => {
    const userState = getState().user;
    let currentEmail = userState.user.email;
    dispatch(requestEditUser(username));

    try {
      const { response } = await updateUserAPI({
        username,
        email,
        phoneNumber,
        phoneExtension,
        givenName,
        familyName,
        userTitle,
        active,
        userRole,
        picture,
        organizationId,
        twoFactorAuthMethod,
        resetUser,
        optOutOfEmails,
      });

      if (response.message) {
        dispatch(successEditUser(response.message));
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              'User Edit',
              'User Successfully Edited',
            ),
          }),
        );
        if (currentEmail !== email && username === userState.user.username) {
          // logout user and redirect to login oage with message about email
          dispatch(submitLogout({ isTimedOut: false, isEmailChange: true }));
        } else if (allAdminUserRoles.includes(userState.user.userRole)) {
          dispatch(fetchOrganizations({ organizationIds: [organizationId] }));
          await dispatch(fetchUsers({ organizationId }));
          if (userState.user.organizationId === organizationId) {
            dispatch(
              receiveUser({
                user: getState().users.users.find(
                  (user) => user.username === userState.user.username,
                ),
              }),
            );
          }
        } else if (username === userState.user.username) {
          dispatch(fetchUser({ username }));
        }
        dispatch(closeGlobalModal());
        return true;
      }

      // for modal
      dispatch(closeGlobalModal());

      return response;
    } catch (e) {
      console.log(e);

      // for modal
      dispatch(rejectEditUser({ errorMessage: e }));

      // for page
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'User Edited Failed', e),
        }),
      );
    }
    return false;
  };
};

export const fetchFSEUsers = () => {
  return async (dispatch, getState) => {
    dispatch(requestFSEUsers());
    try {
      const { response } = await fetchFSEUsersAPI();
      if (response && response.message) {
        dispatch(receiveUsers(response.message.map((user) => decorateUserObject({ user }))));
        return response;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectFSEUsers(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error fetching Field Solution Engineers',
            e,
          ),
        }),
      );
    }
  };
};
