import { createContext, useEffect } from 'react';

import { alertRecipientMapping } from 'legacy/features/alerts/services/mapping/definitions/alertRecipientMapping';
import { alertsActionReducerMap } from 'legacy/features/alerts/services/providers/AlertFormsProvider/mapping/alertsActionReducerMap';
import { generateEmptyAlert } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/generateEmptyAlert';
import useFetchAlertByIdQuery from 'legacy/features/alerts/services/reactQuery/queries/useFetchAlertByIdQuery';
import useFetchOrganizationUsersQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchOrganizationUsersQuery';
import useFetchRecipientGroupsQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchRecipientGroupsQuery';
import useFetchVehicleGroupsQuery from 'legacy/features/vehicleGroups/hooks/v2/useFetchVehicleGroupsQuery';
import useFetchVehiclesQuery from 'legacy/features/vehicles/hooks/queries/useFetchVehiclesQuery';
import IsFetchingWrapper from 'legacy/shared/v1/components/IsFetchingWrapper';
import useProviderState from 'legacy/shared/v1/hooks/useProviderState';
import { mapToFrontend } from 'legacy/shared/v2/utilities/mapping/mapper';

// create context
export const EditAlertContext = createContext(null);

const AlertFormsProvider = ({ children, alertId }) => {
  // create provider (state and actions)
  const [state, actions] = useProviderState(
    {
      actionReducerMap: alertsActionReducerMap,
      initialState: {
        alert: null,
        allRecipientGroups: [],
        vehicles: [],
        vehicleGroups: [],
        organizationUsers: [],
      },
    },
    true, // use v2 style action dispatchers
  );

  // fetch initial data
  const [fetchedOrganizationUsers, organizationUsersQueryKey] = useFetchOrganizationUsersQuery();
  const [fetchedAlert, _, alertQueryKey] = useFetchAlertByIdQuery({ alertId });
  const [fetchedVehicleGroups, vehicleGroupsQueryKey] = useFetchVehicleGroupsQuery();
  const [fetchedRecipientGroups, recipientGroupsQueryKey] = useFetchRecipientGroupsQuery();
  const [fetchedVehicles, vehiclesQueryKey] = useFetchVehiclesQuery({
    apiFlags: {
      addons: false,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  useEffect(() => {
    // set api data in state if data is returned from api
    actions.setApiDataAction({
      vehicles: fetchedVehicles,
      vehicleGroups: fetchedVehicleGroups,
      organizationUsers: fetchedOrganizationUsers,
      allRecipientGroups: fetchedRecipientGroups?.map((g) => {
        // slight mapping correction
        // TODO - fix general recipientGroup mapping so we can remove this

        let rgm = {
          recipientGroupId: g.recipientGroupId,
          recipientGroupName: g.recipientGroupName,
          recipientGroupMembers: g.recipientGroupMembers.map((u) =>
            mapToFrontend(
              {
                username: u.userName,
                given_name: u.firstName,
                family_name: u.lastName,
                // newly fetched group members do not have alert methods yet
                email: null,
                sms: null,
              },
              alertRecipientMapping,
            ),
          ),
        };
        return rgm;
      }),
    });
  }, [fetchedVehicles, fetchedVehicleGroups, fetchedRecipientGroups, fetchedOrganizationUsers]);

  useEffect(() => {
    if (
      fetchedVehicleGroups &&
      fetchedVehicles &&
      fetchedRecipientGroups &&
      fetchedOrganizationUsers
    ) {
      if (fetchedAlert) {
        actions.setApiDataAction({
          alert: fetchedAlert,
        });
      }
      // if no alert is fetched, and if there is no alert in state, set an empty alert
      else if (!state.alert) {
        actions.setApiDataAction({
          alert: generateEmptyAlert(),
        });
      }
    }
  }, [
    fetchedAlert,
    fetchedVehicles,
    fetchedVehicleGroups,
    fetchedRecipientGroups,
    fetchedOrganizationUsers,
  ]);

  // this shouldbe integrated with the provider query keys
  let queryKeysForFetching = [
    alertQueryKey,
    vehiclesQueryKey,
    vehicleGroupsQueryKey,
    organizationUsersQueryKey,
    //recipientGroupsQueryKey,
  ].filter(Boolean);

  // render provider children wrapped with loading indicator
  return (
    <EditAlertContext.Provider
      value={{
        queryKeys: {
          alertQueryKey,
          //recipientGroupsQueryKey,
          vehiclesQueryKey,
          vehicleGroupsQueryKey,
          organizationUsersQueryKey,
        },
        actions,
        state,
      }}
    >
      <IsFetchingWrapper
        queryKeys={queryKeysForFetching || []}
        stillLoadingOverride={
          !state.organizationUsers ||
          !state.alert ||
          !state.vehicles ||
          !state.vehicleGroups ||
          !state.allRecipientGroups
        }
      >
        {children}
      </IsFetchingWrapper>
    </EditAlertContext.Provider>
  );
};

export default AlertFormsProvider;
