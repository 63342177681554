import FilteredDropdownPopup from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/FilteredDropdownPopup';
import FilteredDropdownSelector from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/FilteredDropdownSelector';
import useFilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/useFilteredDropdown';
import useClickOutsideContainerHandler from 'legacy/shared/v2/hooks/dom/useClickOutsideContainerHandler';
import { FilteredDropdownWrapper } from 'legacy/shared/v2/styles/FilteredDropdownStyles';
import { FormAsterisk, FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';

// LEGACY/V1 FD
const FilteredDropdown = ({
  styleOptions: {
    disabled = false,
    hideClearX = false,
    restrictHeight = true,
    required = false,
    narrowWidth = false,
  } = {},
  title,
  defaultItem,
  dropdownOptions,
  placeholderLabel = 'Make a selection',
  handleItemSelect,
  clearOnSelection,
}) => {
  // defaultItem must be an object with a label and value property or null
  if (defaultItem === undefined || (defaultItem && !defaultItem.label && !defaultItem.value)) {
    throw new Error(
      `FilteredDropdown: defaultItem must be null or an object with a label and value property. Received ${defaultItem}`,
    );
  }

  const containerRef = useClickOutsideContainerHandler({ handler: () => setIsOpen(false) });

  const {
    selectedItem,
    setSelectedItem,
    options,
    isOpen,
    setIsOpen,
    searchText,
    setSearchText,
    hoveredItem,
    setHoveredItem,
    openStyle,
  } = useFilteredDropdown({
    defaultItem,
    dropdownOptions,
    handleItemSelect,
    clearOnSelection,
  });
  return (
    <FilteredDropdownWrapper disabled={disabled}>
      <div ref={containerRef} style={openStyle}>
        <FormLabelAnimated animated>
          {title}
          {required && <FormAsterisk>*</FormAsterisk>}
        </FormLabelAnimated>
        <div>
          <FilteredDropdownSelector
            narrowWidth={narrowWidth}
            hideClearX={hideClearX}
            placeholderLabel={placeholderLabel}
            // parent state
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedItem={clearOnSelection ? '' : selectedItem}
            setSelectedItem={setSelectedItem}
            onClearFilter={() => {
              setSelectedItem(null);
            }}
          />
          {isOpen && (
            <FilteredDropdownPopup
              restrictHeight={restrictHeight}
              searchText={searchText}
              setSearchText={setSearchText}
              options={options}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
            />
          )}
        </div>
      </div>
    </FilteredDropdownWrapper>
  );
};

export default FilteredDropdown;
