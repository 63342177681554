import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptions';

export const customerTypeData = {
  AMBULANCE: 'ambulance',
  DISTRIBUTOR: 'distributor',
  EMERGENCY_RESPONSE: 'emergency response',
  FIRE: 'fire',
  MAINTENANCE: 'maintenance',
  MUNICIPALITY: 'municipality',
  POLICE: 'police',
  TOWING: 'towing',
};

export const customerTypeDictionary = [
  { value: customerTypeData.AMBULANCE, label: 'Ambulance' },
  { value: customerTypeData.DISTRIBUTOR, label: 'Distributor' },
  { value: customerTypeData.EMERGENCY_RESPONSE, label: 'Emergency Response' },
  { value: customerTypeData.FIRE, label: 'Fire' },
  { value: customerTypeData.MAINTENANCE, label: 'Maintenance' },
  { value: customerTypeData.MUNICIPALITY, label: 'Municipality' },
  { value: customerTypeData.POLICE, label: 'Police' },
  { value: customerTypeData.TOWING, label: 'Towing' },
];

export const customerTypeDropdownOptions = new DropdownOptions(
  customerTypeDictionary.filter(t => t.value !== customerTypeData.EMERGENCY_RESPONSE),
); //dont let the user choose Emergency Response as a customer type, it is for backend compatability only
