import { Link, useLocation } from 'react-router-dom';

import { getSubscriptionWarningIcon } from 'legacy/features/billing/services/utilities/getSubscriptionWarningIcon';
import DevicesAndBillingActionMenu from 'legacy/features/billing/ui/components/DevicesAndBillingSummary/menus/DevicesAndBillingActionMenu';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';
import {
  DeviceColumn,
  PremiumTagDiv,
  ExpiredIconWrapper,
} from 'legacy/shared/v1/styles/components/DevicesAndBilling';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';
import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import VisualDebug from 'tools/debugging/VisualDebug';

const DevicesAndBillingSummaryTable = ({ handleUpdateListingCount }) => {
  const {
    state: { filteredDeviceSubscriptions },
  } = useDevicesAndBillingSummaryProviderContext();

  const { pathname } = useLocation();

  let filteredDeviceSubscriptionsTableData =
    filteredDeviceSubscriptions?.map((deviceSubscription) => {
      return {
        formattedDeviceId: deviceSubscription.decorated.formattedDeviceId,
        vehicleLabelDisplay: deviceSubscription.decorated.vehicleLabelDisplay,
        formattedShipDate: deviceSubscription.decorated.formattedShipDate,
        formattedStartDate: deviceSubscription.decorated.formattedStartDate,
        formattedEndDate: deviceSubscription.decorated.formattedEndDate,
        subscriptionType: deviceSubscription.subscriptionType,
        cellNetworkType: deviceSubscription.cellNetworkType,
        vsgDuid: deviceSubscription.vsgDuid,
        aboutToExpire: deviceSubscription.decorated.aboutToExpire,
        isExpired: deviceSubscription.decorated.isExpired,
        vehicleId: deviceSubscription.vehicleId,
      };
    }) || [];

  const columns = [
    {
      title: 'Device ID',
      displayProperty: 'formattedDeviceId',
      className: 'name',
      width: '30%',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={'Device ID: ' + cellValue}
          >
            <TruncatedTextWithWrap>
              {rowData.vehicleId ? (
                <Link
                  id="vsgDuidlinkToVehicleEditPage"
                  to={{
                    pathname: `/vehicle/${encodeURIComponent(rowData.vehicleId)}`,
                    state: pathname,
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  {cellJsx}
                </Link>
              ) : (
                cellJsx
              )}
            </TruncatedTextWithWrap>
          </DeviceColumn>
        );
      },
    },
    {
      title: 'Associated Vehicle',
      displayProperty: 'vehicleLabelDisplay',
      width: '20%',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={'Associated Vehicle: ' + cellValue}
          >
            <TruncatedTextWithWrap>
              {rowData.vehicleId ? (
                <Link
                  id="vehicleNamelinkToVehicleEditPage"
                  to={{
                    pathname: `/vehicle/${encodeURIComponent(rowData.vehicleId)}`,
                    state: pathname,
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  {cellJsx}
                </Link>
              ) : (
                cellJsx
              )}
            </TruncatedTextWithWrap>
          </DeviceColumn>
        );
      },
    },

    {
      title: 'Shipped On',
      displayProperty: 'formattedShipDate',
      width: '10%',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={`Shipped On: ${cellValue}`}
          >
            {cellJsx}
          </DeviceColumn>
        );
      },
    },

    {
      title: 'Start Date',
      displayProperty: 'formattedStartDate',
      width: '10%',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={`Start Date: ${cellValue} `}
          >
            {cellJsx}
          </DeviceColumn>
        );
      },
    },

    {
      title: 'End Date',
      displayProperty: 'formattedEndDate',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={`End Date: ${cellValue} `}
          >
            {cellJsx}
          </DeviceColumn>
        );
      },
    },

    {
      title: 'Subscription',
      displayProperty: 'subscriptionType',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={`Subscription: ${cellValue}`}
          >
            {rowData.aboutToExpire || rowData.isExpired
              ? getSubscriptionWarningIcon({ decorated: rowData })
              : null}
            {cellValue === 'Premium' ? <PremiumTagDiv>{cellJsx}</PremiumTagDiv> : cellJsx}
          </DeviceColumn>
        );
      },
    },

    {
      title: 'Cell Network',
      displayProperty: 'cellNetworkType',
      render({ rowData, cellJsx, cellValue }) {
        return (
          <DeviceColumn
            dueForRenewal={rowData.aboutToExpire}
            highAlert={rowData.isExpired}
            title={`Cell Network: ${cellValue} `}
          >
            {cellJsx}
          </DeviceColumn>
        );
      },
    },

    {
      title: '',
      displayProperty: 'vsgDuid',
      className: 'action',
      searchEnabled: false,
      key: 'a',
      render({ rowData }) {
        return (
          <DevicesAndBillingActionMenu
            vsgDuid={rowData.vsgDuid}
            id={'devicesAndSubscriptionsActionMenu' + rowData.rowClassName}
          />
        );
      },
    },
  ];

  return (
    <WcpTable
      id="devicesAndSubscriptionsSummaryTable"
      columns={columns}
      tableSourceData={filteredDeviceSubscriptionsTableData}
      emptyText="No vehicles are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default DevicesAndBillingSummaryTable;
