import { addNotification } from 'legacy/core/redux/ui/actions';
import { useDispatch } from 'react-redux';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';

const useDispatchSuccessNotification = () => {
  const dispatch = useDispatch();

  const dispatchSuccessToast = ({ message = 'Success!' }) =>
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.SUCCESS, message),
      }),
    );

  return [dispatchSuccessToast];
};

export default useDispatchSuccessNotification;
