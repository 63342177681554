import {
  requiredValidator,
  characterLimitValidator,
  regexValidator,
  alphanumericExtendedRegex,
  alphanumericExtendedRegexError,
  vinRegex,
  vinRegexError,
  deviceIdRegex,
  deviceIdRegexError,
  asciiRegex,
  asciiRegexError,
} from 'legacy/shared/v1/utilities/validators';
import { vehicleYearValidator } from 'legacy/features/vehicles/validators/vehicleYearValidator';

export const vehicleValidators = {
  label: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  vin: requiredValidator(regexValidator(vinRegex, vinRegexError)),
  type: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  make: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  model: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licensePlate: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  licenseState: requiredValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
  duid: regexValidator(deviceIdRegex, deviceIdRegexError),
  year: requiredValidator(vehicleYearValidator()),
  notes: characterLimitValidator(regexValidator(asciiRegex, asciiRegexError)),
  alias: characterLimitValidator(
    regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
  ),
};
