import { userMapping } from 'legacy/features/users/utilities/userMapping';
import { mapToFrontend } from 'legacy/shared/v2/utilities/mapping/mapper';
import { apiCall } from 'legacy/core/api/API';

export const getOrganizationUsersApi = async ({ organizationId }) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/user`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  const users = apiResponse.data.message.map((user) => mapToFrontend(user, userMapping));

  return users;
};
