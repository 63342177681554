import { mapGeofenceActivityLogsToCsv } from 'legacy/features/geofences/geofences/utilities/mapping';
import ExportCsvButton from 'legacy/shared/v1/widgets/ExportCsvButton';

const GeofenceActivityExportButton = ({ geofenceLogsData, geofenceName }) => {
  const exportData = mapGeofenceActivityLogsToCsv({ geofenceLogsData });
  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={`wcp_geofence_log_export_for_ ${geofenceName}`}
    />
  );
};

export default GeofenceActivityExportButton;
