import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { createGroup } from 'legacy/core/api/groups';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

export const useCreateGroupMutation = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const queryClient = useQueryClient();
  const createGroupMutation = useMutation({
    mutationFn: ({ groupName }) =>
      createGroup({
        organizationId: filteredOrganizationId,
        groupName,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery']);
    },
    onError: (err) => {
      logWcpError(err);
    },
  });

  return createGroupMutation;
};
