import { handleActions } from 'redux-actions';

import {
  receiveActivityUser,
  receiveUserActivityLogs,
} from 'legacy/core/redux/userActivityLogs/actions';
import { DateTime } from 'luxon';
import { getLongDateFromUnixTimestamp } from 'legacy/shared/v1/utilities/time';

const defaultState = {
  userActivityLogs: [],
  user: {},
};

const userActivityLogs = handleActions(
  {
    [receiveActivityUser]: (state, { payload: user }) => {
      return {
        ...state,
        user: user,
      };
    },
    [receiveUserActivityLogs]: (state, { payload: userActivityLogs }) => ({
      ...state,
      userActivityLogs: userActivityLogs?.map((userActivityLog) => ({
        ...userActivityLog,
        formattedTime: getLongDateFromUnixTimestamp(userActivityLog.timestamp),
      })),
    }),
  },
  defaultState,
);

export default userActivityLogs;
