import { useEffect } from 'react';

import useFetchPlaybackDataQuery from 'legacy/features/playback/services/reactQuery/queries/useFetchPlaybackDataQuery';
import { mapPlaybackFormToRequestParams } from 'legacy/features/playback/state/provider/mapping/mapPlaybackFormToRequestParams';
import useFetchVehiclesQuery from 'legacy/features/vehicles/hooks/queries/useFetchVehiclesQuery';

const useLoadVehiclePlaybackStateWithApiData = ({
  playbackRequestForm,
  setPlaybackApiDataAction,
}) => {
  // fetch vehicles
  const [allVehicles, fetchVehiclesQueryKey, fetchVehiclesResult] = useFetchVehiclesQuery({
    apiFlags: {
      addons: false,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  // map form to request params
  const requestParams = mapPlaybackFormToRequestParams({
    playbackRequestForm,
  });

  // fetch playback data using request params
  const {
    queryResult,
    queryData: { vehicle, locationData, summary },
    queryRefetch: refetchPlaybackData,
    queryKey: fetchPlaybackDataQueryKey,
  } = useFetchPlaybackDataQuery({
    requestParams,
    autoRun: false, // only fetches when refetchPlaybackData is called
  });

  // set state with vehicles and playback data from API
  useEffect(() => {
    if (allVehicles)
      setPlaybackApiDataAction({
        allVehicles,
        associatedVehicleId: vehicle?.vehicleId,
        locationData,
        summary,
      });
  }, [allVehicles, vehicle, locationData, summary]);

  return {
    fetchPlaybackDataResult: queryResult,
    fetchVehiclesResult,
    refetchPlaybackData,
    queryKeys: { fetchVehiclesQueryKey, fetchPlaybackDataQueryKey },
  };
};

export default useLoadVehiclePlaybackStateWithApiData;
