import {
  MapFocusControlsDiv,
  MapLayersControlsDiv,
  MapZoomControlsDiv,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const MapControls = ({
  map,
  showSatelliteLayerToggle,
  showFocusToggle,
  toggleSatelliteView,
  focusBounds,
}) => {
  return (
    <div>
      <MapLayersControlsDiv showSatelliteLayerToggle={showSatelliteLayerToggle}>
        <div onClick={() => toggleSatelliteView()}>
          <IconSvgComponent
            svgFileName={'map-layers'}
            alt="Toggle Streets/Satellite Layers"
            title="Toggle Streets/Satellite Layers"
          />
        </div>
      </MapLayersControlsDiv>
      <MapFocusControlsDiv showFocusToggle={showFocusToggle}>
        <div onClick={() => focusBounds()}>
          <IconSvgComponent svgFileName={'map-focus'} alt="Focus" title="Focus" />
        </div>
      </MapFocusControlsDiv>

      <MapZoomControlsDiv>
        <div onClick={() => map.zoomIn()}>
          <IconSvgComponent svgFileName={'zoom-in'} alt="Zoom in" />
        </div>
        <div onClick={() => map.zoomOut()}>
          <IconSvgComponent svgFileName={'zoom-out'} alt="Zoom out" />
        </div>
      </MapZoomControlsDiv>
    </div>
  );
};

export default MapControls;
