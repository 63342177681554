import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  PlayPauseCheckbox,
  PlayPauseCheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import {
  HeaderDiv,
  HeaderText,
  MapHeaderItem,
  MapHeaderItemsDiv,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import { PlaybackToggleHeaderDiv } from 'legacy/shared/v1/styles/components/VehiclePlayback';
import { formatISOTimestampToLongDateWithTime } from 'legacy/shared/v1/utilities/time';

const VehiclePlaybackHeader = () => {
  const context = useVehiclePlaybackProviderContext();

  const { setPlaybackAnimationControlsAction, setPlaybackSidebarAction } = context.actions;
  const {
    playbackAnimationControls: { playbackToggle, selectedPlaybackSpeed, currentPlaybackDataIndex },
    playbackData: { locationData },
  } = context.state;

  return (
    <HeaderDiv>
      {locationData?.length > 1 ? (
        <MapHeaderItemsDiv>
          <MapHeaderItem>
            <PlaybackToggleHeaderDiv>
              <PlayPauseCheckbox
                checked={playbackToggle}
                playPauseHeader
                id="headerSwitch"
                onChange={(e) => {
                  setPlaybackAnimationControlsAction({
                    playbackToggle: e.target.checked,
                    selectedPlaybackSpeed,
                  });
                  setPlaybackSidebarAction({
                    isOpen: false,
                  });
                }}
              />
              <PlayPauseCheckboxLabel playPauseHeader for="headerSwitch" title="Play" />
            </PlaybackToggleHeaderDiv>
          </MapHeaderItem>
          <HeaderText>
            {locationData.length > 1
              ? formatISOTimestampToLongDateWithTime(locationData[currentPlaybackDataIndex]?.time)
              : null}
          </HeaderText>
        </MapHeaderItemsDiv>
      ) : null}
    </HeaderDiv>
  );
};

export default VehiclePlaybackHeader;
