import { maintenanceIntervalDropdownData } from 'legacy/features/maintenance/data/maintenanceIntervalDropdownData';
import { maintenanceIntervalConstants } from 'legacy/features/maintenance/data/maintenanceIntervalConstants';
import { FormAsterisk, FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';
import { IntervalTypeSelectionDiv } from 'legacy/shared/v1/styles/components/Maintenance';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';

const NextIntervalTypeDropdown = ({
  formikProps: { values, setFieldValue },
  noOdometer,
  noEngineHours,
}) => {
  const getIntervalTypeOptions = () => {
    let options = maintenanceIntervalDropdownData;
    if (noOdometer) {
      options = options.filter(
        (t) =>
          t.value !== maintenanceIntervalConstants.MILEAGE &&
          t.value !== maintenanceIntervalConstants.TOTAL_MILEAGE,
      );
    }
    if (noEngineHours) {
      options = options.filter((t) => t.value !== maintenanceIntervalConstants.ENGINE_HOURS);
    }
    return options;
  };

  return (
    <IntervalTypeSelectionDiv>
      <FormLabelAnimated animated>
        Interval Type<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledReactSelect
        name="nextIntervalType"
        placeholder={'Interval Type'}
        isDisabled={false}
        isClearable={false}
        isSearchable={false}
        options={getIntervalTypeOptions()}
        defaultValue={{
          label: values.nextIntervalType?.label || 'Select an Interval Type',
        }}
        onChange={(selectedOption) => {
          let nextIntervalType = maintenanceIntervalDropdownData.find(
            (t) => t.value === selectedOption.value,
          );
          return setFieldValue('nextIntervalType', nextIntervalType);
        }}
      />
    </IntervalTypeSelectionDiv>
  );
};

export default NextIntervalTypeDropdown;
