import {
  FilteredDropdownPopupDiv,
  FilteredDropdownSearchInput,
  FilteredDropdownSearchResultListItem,
  FilteredDropdownSearchResultsList,
} from 'legacy/shared/v2/styles/FilteredDropdownStyles';
import {
  findStartingIndex,
  highlightSearchText,
} from 'legacy/shared/v2/utilities/search/highlightSearchText';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const FilteredDropdownSearchBox = ({
  restrictHeight,
  options,
  searchText,
  hoveredItem,
  setSearchText,
  setHoveredItem,
  setSelectedOption,
  fullWidth,
  disableSearch,
  optionsDomId,
}) => {
  console.log('FilteredDropdownSearchBox', disableSearch);
  return (
    <FilteredDropdownPopupDiv hideSearch={disableSearch} fullWidth={fullWidth}>
      {!disableSearch && (
        <IconSvgComponent
          svgStyle={{
            height: '22px',
            width: '22px',
            marginRight: '10px',
          }}
          svgFileName={'search'}
          alt="Search"
        />
      )}
      <div>
        {!disableSearch && (
          <FilteredDropdownSearchInput
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
            value={searchText}
          />
        )}
        <div>
          <FilteredDropdownSearchResultsList
            hideSearch={disableSearch}
            restrictHeight={restrictHeight}
            fullWidth={fullWidth}
            id={optionsDomId}
          >
            {options.map((item, index) =>
              searchText === '' || findStartingIndex(searchText, item.label) > -1 ? (
                <FilteredDropdownSearchResultListItem
                  key={`${item.value}${index}`}
                  highlight={index === hoveredItem}
                  showTopBorder={index === 0}
                  hideSearch={disableSearch}
                  onMouseEnter={() => setHoveredItem(index)}
                  onClick={() => setSelectedOption(item)}
                >
                  <TruncatedTextWithWrap title={item.label}>
                    {highlightSearchText(searchText, item.label)}
                  </TruncatedTextWithWrap>
                </FilteredDropdownSearchResultListItem>
              ) : null,
            )}
          </FilteredDropdownSearchResultsList>
        </div>
      </div>
      {/* TODO Create New Item Option */}
    </FilteredDropdownPopupDiv>
  );
};

export default FilteredDropdownSearchBox;
