import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { updateGroupAPI } from 'legacy/core/api/groups';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

export const useUpdateGroupMutation = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();

  const updateGroupMutation = useMutation({
    mutationFn: ({ groupId, groupName }) =>
      updateGroupAPI({
        organizationId: filteredOrganizationId,
        groupId: groupId,
        groupName: groupName,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery']);
    },
    onError: (err) => {
      logWcpError(err);
    },
  });

  return updateGroupMutation;
};
