import { SORT_ORDER } from 'legacy/shared/v1/constants/table';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { maintenanceLogsTableColumns } from 'legacy/features/maintenance/components/MaintenanceLogs/table/MaintenanceLogsTableColumns';

const MaintenanceLogsTable = ({ records, handleSetListingCount }) => {
  return (
    <WcpTable
      tableClassName="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={maintenanceLogsTableColumns}
      tableSourceData={records || []}
      rowKey={(record, i) => i}
      emptyText="No maintenance logs are currently associated with this vehicle"
      defaultSortOrder={SORT_ORDER.DESC}
      setListingCount={handleSetListingCount}
    />
  );
};

export default MaintenanceLogsTable;
