import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { addVehiclesToGroupAPI, createGroup, updateGroupAPI } from 'legacy/core/api/groups';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';

export const useAddVehiclesToGroupMutation = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();

  const addVehiclesToGroupMutation = useMutation({
    mutationFn: ({ groupId, vehicleIds }) =>
      addVehiclesToGroupAPI({
        filteredOrganizationId,
        groupId,
        vehicleIds,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery']);
    },
    onError: (err) => {
      logWcpError(err);
    },
  });

  return addVehiclesToGroupMutation;
};
