import {
  maintenanceStatusDropdownData,
  maintenanceStatusDropdownOptions,
} from 'legacy/features/maintenance/data/maintenanceStatusDropdownData';
import {
  maintenanceTypeDropdownData,
  maintenanceTypeDropdownOptions,
} from 'legacy/features/maintenance/data/maintenanceTypeDropdownData';
import { MaintenanceFilters } from 'legacy/shared/v1/styles/components/Maintenance';
import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/FilteredDropdown';
import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptions';

const MaintenanceSummaryFilters = ({
  vehicles,
  groups,
  selectedVehicle,
  selectedStatus,
  setMaintenanceSummaryFilters,
}) => {
  const defaultSelectedVehicle = vehicles?.find((v) => v.vehicleId === selectedVehicle?.vehicleId)
    ?.meta.label;
  const defaultSelectedStatus = maintenanceStatusDropdownData?.find(
    (d) => d === selectedStatus,
  )?.value;

  const groupOptions = groups?.map((group) => ({
    label: group.group_name,
    value: group.group_name,
  }));
  const groupDropdownOptions = new DropdownOptions(groupOptions);

  const vehicleOptions = vehicles?.map((vehicle) => ({
    label: vehicle.meta.label,
    value: vehicle.meta.label,
  }));
  const vehicleDropdownOptions = new DropdownOptions(vehicleOptions);

  return (
    <MaintenanceFilters>
      <FilteredDropdown
        fieldLabel={'Group'}
        domId="groupDropdown"
        placeholderLabel={'All Groups'}
        styleOptions={{}}
        setup={{
          dropdownOptions: groupDropdownOptions,
          handleSelect: (selectedOption) => {
            setMaintenanceSummaryFilters({
              selectedGroup: selectedOption.value
                ? groups.find((g) => g.group_name === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Type'}
        domId="typeDropdown"
        placeholderLabel={'All Types'}
        styleOptions={{}}
        setup={{
          dropdownOptions: maintenanceTypeDropdownOptions,
          handleSelect: (selectedOption) => {
            setMaintenanceSummaryFilters({
              selectedType: selectedOption.value
                ? maintenanceTypeDropdownData.find((t) => t.value === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Status'}
        domId="statusDropdown"
        placeholderLabel={'All Statuses'}
        styleOptions={{}}
        setup={{
          dropdownOptions: maintenanceStatusDropdownOptions,
          preselectedValue: defaultSelectedStatus,
          handleSelect: (selectedOption) => {
            setMaintenanceSummaryFilters({
              selectedStatus: selectedOption.value
                ? maintenanceStatusDropdownData.find((s) => s.value === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Vehicle'}
        domId="vehicleDropdown"
        placeholderLabel={'All Vehicles'}
        styleOptions={{}}
        setup={{
          dropdownOptions: vehicleDropdownOptions,
          preselectedValue: defaultSelectedVehicle,
          handleSelect: (selectedOption) => {
            const selectedVehicle = selectedOption.value
              ? vehicles.find((v) => v.meta.label === selectedOption.value)
              : null;
            setMaintenanceSummaryFilters({
              selectedVehicle,
            });
          },
        }}
      />
    </MaintenanceFilters>
  );
};

export default MaintenanceSummaryFilters;
