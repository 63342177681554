import MaintenanceTypeField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/MaintenanceTypeField';
import OccurredAtField from 'legacy/features/maintenance/components/MaintenanceBuilder/fields/OccurredAtField';

import {
  MaintenanceControlOuterWrapperDiv,
  MaintenanceTypeItemDiv,
  ReadOnlySpacer,
} from 'legacy/shared/v1/styles/components/Maintenance';
import { FormLabelAnimated } from 'legacy/shared/v1/styles/components/Form';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import { CheckEngineReadOnlyDiv } from 'legacy/shared/v1/styles/components/Maintenance';

const ReadOnlyMaintenanceItem = ({
  maintenanceTypeLabel,
  createdDate,
  diagnosticCode,
  diagnosticCodeDescription,
}) => {
  const displayText = `${diagnosticCode}: ${diagnosticCodeDescription}`;
  // render read only diagnostic event
  return (
    <MaintenanceTypeItemDiv checkEngine>
      <MaintenanceTypeField maintenanceTypeLabel={maintenanceTypeLabel} />
      <MaintenanceControlOuterWrapperDiv checkEngine>
        <OccurredAtField createdDate={createdDate} />

        <CheckEngineReadOnlyDiv>
          <FormLabelAnimated animated>{`Code(s)`}</FormLabelAnimated>
          <TruncatedText checkEngine title={displayText}>
            {displayText}
          </TruncatedText>
        </CheckEngineReadOnlyDiv>
      </MaintenanceControlOuterWrapperDiv>
      <ReadOnlySpacer />
    </MaintenanceTypeItemDiv>
  );
};

export default ReadOnlyMaintenanceItem;
