import styled from 'styled-components';
import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';
import LinkButton from 'legacy/shared/v1/ui/buttons/LinkButton';

export default styled(LinkButton)`
  display: inline-block;
  padding: 10px 24px;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${colors.midnight};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  color: ${colors.midnight};
  background: ${colors.cotton};
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  &:hover {
    background: ${colors.midnight};
    color: #fff;
  }
`;

export const ViewLogsButton = styled(LinkButton)`
  display: inline-block;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${colors.midnight};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  background: ${colors.midnight};
  color: ${colors.cotton};
  text-align: center;
  padding: 10px 31px;
  min-width: 180px;
  font-size: 11px;
  margin-right: 0px;

  &:hover {
    background: ${colors.midnight};
    color: #fff;
  }

  @media ${device.medium} {
    margin-right: 20px;
  }
`;

export const HeaderLinkButton = styled(LinkButton)`
  display: inline-block;
  text-decoration: none;
  border-radius: 21px;
  border: 2px solid ${colors.midnight};
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.59px;
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  background: ${colors.midnight};
  color: ${colors.cotton};
  text-align: center;

  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 22px;
  min-width: 200px;
  display: block;
  height: 34px;

  &:hover {
    background: ${colors.midnight};
    color: #fff;
  }

  @media ${device.medium} {
    padding: 8px 42px;
    min-width: 180px;
    margin-right: 0px;
    margin-left: 20px;
  }
`;
