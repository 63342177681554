import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { cooldownPeriodDropdownData } from 'legacy/features/alerts/data/ui/dropdown/cooldownPeriodDropdownData';

// Gets the cooldown options for the cooldown dropdown
export const getCooldownOptions = (alertType) => {
  // If the alert is a maintenance due alert, check engine light alert, or offline time exceeds alert, then the cooldown options should not include 5 minutes
  if (
    alertType === alertTypeConstants.MAINTENANCE_DUE ||
    alertType === alertTypeConstants.CHECK_ENGINE_LIGHT ||
    alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS
  ) {
    let options = cooldownPeriodDropdownData.map((c) => c.label);
    return options.filter((o) => o !== 'Every 2 minutes').filter((o) => o !== 'Every 5 minutes');
  } else {
    return cooldownPeriodDropdownData.map((c) => c.label);
  }
};
