import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptionsLegacy';

export const vehicleAddonDictionary = {
  code1: { displayName: 'Code 1', defaultAlertLevel: 'None' },
  code2: { displayName: 'Code 2', defaultAlertLevel: 'None' },
  code3: { displayName: 'Code 3', defaultAlertLevel: 'High' },
  doorFrontDriver: { displayName: 'Door Open - Front Driver', defaultAlertLevel: 'None' },
  doorFrontPass: { displayName: 'Door Open - Front Passenger', defaultAlertLevel: 'None' },
  doorHatch: { displayName: 'Door Open - Hatch', defaultAlertLevel: 'None' },
  doorRearDriver: { displayName: 'Door Open - Rear Driver', defaultAlertLevel: 'None' },
  doorRearPass: { displayName: 'Door Open - Rear Passenger', defaultAlertLevel: 'None' },
  faultInput: { displayName: 'Fault Input', defaultAlertLevel: 'None' },
  felonyStop: { displayName: 'Felony Stop', defaultAlertLevel: 'High' },
  gunlockUnlocked: { displayName: 'GunLock Unlocked', defaultAlertLevel: 'High' },
  k9Release: { displayName: 'K9 Released', defaultAlertLevel: 'High' },
  k9TempSensor: { displayName: 'K9 Temp Sensor', defaultAlertLevel: 'High' },
  messageBoard: { displayName: 'Message Board', defaultAlertLevel: 'None' },
  narcoticsBox: { displayName: 'Narcotics Box Open', defaultAlertLevel: 'High' },
  officerDown: { displayName: 'Officer Down', defaultAlertLevel: 'High' },
  opticomEmitter: { displayName: 'Opticom Emitter', defaultAlertLevel: 'None' },
  plow: { displayName: 'Plow Down', defaultAlertLevel: 'None' },
  saltSpreader: { displayName: 'Salt Spreader On', defaultAlertLevel: 'None' },
  seatbeltFrontDriver: {
    displayName: 'Seatbelt Unbuckled - Front Driver',
    defaultAlertLevel: 'None',
  },
  seatbeltFrontPass: {
    displayName: 'Seatbelt Unbuckled - Front Passenger',
    defaultAlertLevel: 'None',
  },
  seatbeltRearDriver: {
    displayName: 'Seatbelt Unbuckled - Rear Driver',
    defaultAlertLevel: 'None',
  },
  seatbeltRearPass: {
    displayName: 'Seatbelt Unbuckled - Rear Passenger',
    defaultAlertLevel: 'None',
  },
  siren: { displayName: 'Siren On', defaultAlertLevel: 'None' },
  sirenDvm: { displayName: 'DVM On', defaultAlertLevel: 'None' },
  stopSign: { displayName: 'Stop Sign Deployed', defaultAlertLevel: 'None' },
  streetSweeper: { displayName: 'Sweeper Engaged', defaultAlertLevel: 'None' },
  stretcherOut: { displayName: 'Stretcher Out', defaultAlertLevel: 'None' },
  taLeft: { displayName: 'Traffic Advisor Left', defaultAlertLevel: 'None' },
  taRight: { displayName: 'Traffic Advisor Right', defaultAlertLevel: 'None' },
  taSplit: { displayName: 'Traffic Advisor Split', defaultAlertLevel: 'None' },
  tempWarning: { displayName: 'Temp Warning', defaultAlertLevel: 'None' },
  turnSignalLeft: { displayName: 'Left Turn Signal', defaultAlertLevel: 'None' },
  turnSignalRight: { displayName: 'Right Turn Signal', defaultAlertLevel: 'None' },
  vehiclePark: { displayName: 'Vehicle in Park', defaultAlertLevel: 'None' },
  engineRunning: { displayName: 'Engine Running', defaultAlertLevel: 'None' },
  haasPublicAlerting: { displayName: 'Public Alerting', defaultAlertLevel: 'None' },
  hardBraking: { displayName: 'Hard Braking', defaultAlertLevel: 'None' },
  hardAcceleration: { displayName: 'Hard Acceleration', defaultAlertLevel: 'None' },
  serviceBrake: { displayName: 'Service Brake', defaultAlertLevel: 'None' },
  emergencyBrake: { displayName: 'Emergency Brake', defaultAlertLevel: 'None' },
  trafficAdvisor: { displayName: 'Traffic Advisor', defaultAlertLevel: 'None' },
  redLights: { displayName: 'Red Lights', defaultAlertLevel: 'None' },

  lowVoltage: { displayName: 'Low Voltage', defaultAlertLevel: 'Low' },
  //NOT the emissions o2 sensor, its for the k9
  o2Sensor: { displayName: 'O2 Sensor', defaultAlertLevel: 'High' },
  coSensor: { displayName: 'Carbon Monoxide Sensor', defaultAlertLevel: 'High' },
  k9Reminder: { displayName: 'K9 Reminder', defaultAlertLevel: 'Medium' },
  k9TempCritical: { displayName: 'K9 Temp Critical', defaultAlertLevel: 'High' },
  tempLevel1: { displayName: 'Temp Level 1', defaultAlertLevel: 'None' },
  tempLevel2: { displayName: 'Temp Level 2', defaultAlertLevel: 'None' },
  tempLevel3: { displayName: 'Temp Level 3', defaultAlertLevel: 'None' },
  tempLevel4: { displayName: 'Temp Level 4', defaultAlertLevel: 'None' },
};

export const vehicleAddonOptions = new DropdownOptions(
  Object.entries(vehicleAddonDictionary).map(([key, value]) => ({
    value: key,
    label: value.displayName,
  })),
);
