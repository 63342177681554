import { useFormikContext } from 'formik';

import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';
import {
  FieldError,
  FormAsterisk,
  FormLabelAnimated,
} from 'legacy/shared/v1/styles/components/Form';
import { UnitOfMeasureDiv } from 'legacy/shared/v1/styles/components/ManageAlerts';
import SelectField from 'legacy/shared/v1/styles/components/SelectField';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { activeInactiveDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/activeInactiveDropdownOptions';

const AlertTriggerValueDropdown = () => {
  const {
    state: { alert },
    actions: { updateAlertTriggerValueAction },
  } = useAlertFormsProviderContext();

  const { alertDetails } = alert;
  const { alertType, activeInactiveDropdownOption } = alertDetails.decorated;

  console.log(activeInactiveDropdownOption);

  const formik = useFormikContext();
  useMakeFormikField({
    fieldName: 'activeInactiveDropdownValueField',
    fieldValue: activeInactiveDropdownOption?.value,
    triggerValidation: true,
  });

  return (
    <UnitOfMeasureDiv>
      <FormLabelAnimated animated>
        Value<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>

      <SelectField
        mediumTextMobile
        defaultValue={activeInactiveDropdownOption}
        placeholder={'Select a value'}
        options={activeInactiveDropdownOptions.getAllOptions(alertType)}
        onChange={(selectedItem) => {
          updateAlertTriggerValueAction({
            triggerValue: selectedItem.value,
          });
        }}
      />
      <FieldError topSpacing>
        {formik.touched.activeInactiveDropdownValueField &&
          formik.errors.activeInactiveDropdownValueField}
      </FieldError>
    </UnitOfMeasureDiv>
  );
};

export default AlertTriggerValueDropdown;
