import { getValueOrFallbackIfUndefined } from 'legacy/shared/v2/utilities/reducerHelpers/getValueOrFallbackIfUndefined';

export const mergePayloadWithState = (inputValues, defaultValues) => {
  const output = {};

  for (const key in defaultValues) {
    output[key] = getValueOrFallbackIfUndefined(inputValues[key], defaultValues[key]);
  }

  return output;
};
