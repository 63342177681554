import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { updateGroupHaas } from 'legacy/core/api/groups';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { updateHaasSuccess } from 'legacy/features/vehicleGroups/helpers/notifications';

export const useUpdateGroupHaasMutation = ({ groupName }) => {
  const dispatch = useDispatch();
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();

  const updateGroupHaasMutation = useMutation({
    mutationFn: ({ groupId, haasIntegrationEnabled, haasPublicAlerting }) =>
      updateGroupHaas({
        organizationId: filteredOrganizationId,
        groupId,
        haasIntegrationEnabled,
        haasPublicAlerting,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery', 'fetchVehiclesQuery']);
      dispatch(updateHaasSuccess({ groupName }));
    },
    onError: (err) => {
      logWcpError(err);
    },
  });

  return updateGroupHaasMutation;
};
