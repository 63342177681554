import { apiCall } from 'legacy/core/api/API';

export const getVehiclesApi = async ({ organizationId, apiFlags }) => {
  const queryStringParameters = { organization_id: organizationId, ...apiFlags };
  const vehicleData = await apiCall({
    path: '/vehicles',
    method: 'get',
    queryStringParameters,
  });
  return vehicleData;
};
