import { LOCK_OPERATION } from 'legacy/features/vehicles/hooks/useLockVehicleMutation';
import { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { getOrganization } from 'legacy/core/redux/organizations/selectors';
import { checkForUserPermission, selectUserRole } from 'legacy/core/redux/user/selectors';
import { SimBanner } from 'legacy/features/vehicles/components/forms/custom/SimBanner';
import VehicleCard from 'legacy/features/vehicles/components/mobile/VehicleCard';
import DeleteVehicleModal from 'legacy/features/vehicles/components/modals/DeleteVehicleModal';
import RemoveVehicleFromGroupModal from 'legacy/features/vehicles/components/modals/RemoveVehicleFromGroupModal';
import ResetVehicleModal from 'legacy/features/vehicles/components/modals/ResetVehicleModal';
import ToggleLockVehicleModal from 'legacy/features/vehicles/components/modals/ToggleLockVehicleModal';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { vehicleLockStatusViewData } from 'legacy/shared/v1/constants/vehicle';
import { Badge } from 'legacy/shared/v1/styles/components/Badge';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import PageListWrapper, {
  IconSpacerSpan,
  PageListActionButtonContainer,
  PageListHead,
} from 'legacy/shared/v1/styles/components/PageList';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { sizes } from 'legacy/shared/v1/utilities/media';
import { getVehicleIcon, getVehicleIconTitle } from 'legacy/shared/v1/utilities/vehicle';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';

/* TODO - rewire following patterns GroupListComponent to encapsulate action menus and modals */
const VehicleList = ({
  vehicles,
  leftHeaderComponent,
  rightHeaderComponent,
  actionMenuComponent,
  editAccess,
  organization,
  vehiclesFetching,
}) => {
  const navigate = useNavigate();
  // TODO convert these to the new modal pattern below
  const [editVehicleModalData, setEditVehicleModalData] = useState(null);
  const [resetModalData, setResetModalData] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [removeFromGroupModalData, setRemoveFromGroupModalData] = useState(null);
  const [listingCount, setListingCount] = useState(vehicles?.length);
  const [matchingColumns, setMatchingColumns] = useState([]);

  // new modal pattern
  const [toggleLockModalData, setToggleLockModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setListingCount(vehicles?.length);
    setToggleLockModalData((prevState) => ({
      ...prevState,
      vehicles,
    }));
    setMatchingColumns(vehicles);
  }, [vehicles]);

  const getActionMenu = (vehicle, commands) => {
    return actionMenuComponent(vehicle, {
      showEditVehicleModal: (vehicle) => {
        setEditVehicleModalData({ vehicle });
      },
      showToggleLockModal: (vehicle) => {
        let lockedStatus = vehicle?.device?.CenComCoreAccess;
        setToggleLockModalData((prevState) => ({
          ...prevState,
          lockOperation:
            lockedStatus === vehicleLockStatusViewData.UNLOCKED.id
              ? LOCK_OPERATION.LOCK
              : LOCK_OPERATION.UNLOCK,
          // single vehicle
          vehiclesOrVehicle: vehicle,
          show: true,
        }));
      },
      showResetModal: (vehicle) => {
        setResetModalData({ vehicle });
      },
      showDeleteModal: (vehicle) => {
        setDeleteModalData({ vehicle });
      },
      showRemoveFromGroupModal: ({ vehicle, group }) => {
        setRemoveFromGroupModalData({ vehicle, group });
      },
    });
  };

  let vehicleTableData =
    vehicles?.map((vehicle) => {
      const { vehicle_id, onlineStatus, respondingStatus, meta, device, gps } = vehicle;

      return {
        vehicleId: vehicle_id,
        onlineStatus,
        respondingStatus,

        // meta
        vehicleName: meta.label,
        formattedVehicleType: meta.formatted_vehicle_type,
        duid: meta.formatted_device_id,
        year: meta.mfg_year,
        make: meta.make,
        model: meta.model,
        licensePlate: meta.license_plate,
        outOfService: meta.out_of_service,
        lockStatus: meta.lock_status,
        gttEnabled: meta.gtt_enabled,
        notes: meta.notes,
        device: device,
        gps: gps,
        subscriptionStatus: meta.subscription_status,
        // cell
        cell: vehicle.cell,

        // haas
        haasEnabled: meta.haas_enabled,
        haasPublicAlerting: meta.haas_public_alerting && meta.haas_public_alerting !== 'ALWAYS_OFF',
      };
    }) || [];

  let columns = [
    {
      //type: 'DISPLAY_COLUMN',
      displayProperty: 'vehicleName',
      className: 'name',
      width: '15%',
      render({ cellJsx, cellValue, rowData }) {
        const { vehicleId, outOfService, onlineStatus, respondingStatus, subscriptionStatus } =
          rowData;

        return (
          <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + cellValue}>
            <IconSvgComponent
              title={getVehicleIconTitle(outOfService, subscriptionStatus)}
              svgFileName={
                outOfService
                  ? 'out-of-service-small'
                  : getVehicleIcon(onlineStatus, respondingStatus, subscriptionStatus)
              }
            />
            <PermissionProtectedElement
              requiredPermissions={[permissionData.editfleet]}
              alternativeComponent={<>{cellJsx}</>}
            >
              <Link
                to={{
                  pathname: `/vehicle/${encodeURIComponent(vehicleId)}`,
                }}
                style={{ textDecoration: 'none' }}
              >
                {cellJsx}
              </Link>
            </PermissionProtectedElement>
          </span>
        );
      },
    },
    {
      title: 'VSG Registration ID',
      displayProperty: 'duid',
      className: 'connectivity',
      width: '25%',
      render({ cellJsx, cellValue, rowData }) {
        return (
          <span className="vehicleNameAndIcon">
            <SimBanner
              cellState={rowData.cell?.state}
              cellDuid={rowData.cell?.duid}
              duid={rowData.duid}
              simMessage={false}
            />
            <TruncatedText title={'VSG Registration ID: ' + cellValue}>{cellJsx}</TruncatedText>
          </span>
        );
      },
    },
    {
      title: 'Vehicle Type',
      displayProperty: 'formattedVehicleType',
      className: 'type',
      width: '7%',
    },
    {
      title: 'Vehicle Year',
      className: 'year',
      width: '5%',
      displayProperty: 'year',
    },
    {
      title: 'Vehicle Make',
      className: 'make',
      displayProperty: 'make',
    },
    {
      title: 'Vehicle Model',
      className: 'model',
      displayProperty: 'model',
    },
    {
      title: 'License Plate',
      className: 'plate',
      width: '5%',
      displayProperty: 'licensePlate',
    },
    {
      displayProperty: 'notes',
      hideHeaderContent: true,
      sortingEnabled: false,
      searchOnly: true,
      render: () => (
        <IconSvgComponent
          svgFileName={'notes'}
          alt="The current search is matching content in the Notes field of this vehicle"
          title="The current search is matching content in the Notes field of this vehicle"
        />
      ),
    },
    {
      title: 'Security Lock',
      render: ({ rowData }) => (
        <Badge
          active={rowData.lockStatus === vehicleLockStatusViewData.LOCKED.id}
          unavailable={rowData.lockStatus === vehicleLockStatusViewData.UNAVAILABLE.id}
          na={rowData.lockStatus === vehicleLockStatusViewData.NA.id}
          title="Locked Status"
        >
          {vehicleLockStatusViewData[rowData.lockStatus]?.title}
        </Badge>
      ),
    },
    {
      title: 'HAAS Integration',
      render({ rowData }) {
        return (
          rowData.haasEnabled && (
            <div>
              <IconSvgComponent
                svgFileName={'check-success'}
                alt="HAAS integration is enabled for this vehicle"
                title="HAAS integration is enabled for this vehicle"
              />
              {rowData.publicAlerting && (
                <IconSpacerSpan>
                  <IconSvgComponent
                    svgFileName={'haas-alerting'}
                    alt="HAAS alerting is enabled for this vehicle"
                    title="HAAS alerting is enabled for this vehicle"
                  />
                </IconSpacerSpan>
              )}
            </div>
          )
        );
      },
    },

    {
      title: 'Miovision Integration',
      displayProperty: 'gttEnabled',
      className: 'action',
      searchEnabled: false,
      render({ rowData }) {
        return (
          rowData.gttEnabled && <IconSvgComponent svgFileName={'check-success'} alt="GTT Enabled" />
        );
      },
    },
    {
      render({ rowData }) {
        return getActionMenu(rowData);
      },
    },
  ];

  const filterColumns = () => {
    if (!editAccess) {
      let index = columns.findIndex((c) => c.key === 'i');
      if (index !== -1) {
        columns.splice(
          columns.findIndex((c) => c.key === 'i'),
          1,
        );
      }
    }
    if (organization && !organization.gttEnabled) {
      let index = columns.findIndex((c) => c.key === 'j');
      if (index !== -1) {
        columns.splice(
          columns.findIndex((c) => c.key === 'j'),
          1,
        );
      }
    }

    if ((organization && !organization.haasEnabled) || !organization.haasSetup) {
      let index = columns.findIndex((c) => c.key === 'k');
      if (index !== -1) {
        columns.splice(
          columns.findIndex((c) => c.key === 'k'),
          1,
        );
      }
    }

    return columns;
  };

  return (
    <>
      {' '}
      <PageListWrapper>
        <PageListHead>
          <>{leftHeaderComponent}</>
          <>
            <PageListActionButtonContainer>
              <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
                {rightHeaderComponent({})}
              </PermissionProtectedElement>
            </PageListActionButtonContainer>
          </>
        </PageListHead>
        <h5>
          {listingCount === vehicles?.length
            ? `Total of ${listingCount} Vehicle${listingCount !== 1 ? 's' : ''}`
            : `${listingCount} of ${vehicles?.length} Vehicle${vehicles?.length !== 1 ? 's' : ''}`}
        </h5>

        <Media
          queries={{
            tablet: { maxWidth: sizes.tablet },
            mobile: { maxWidth: sizes.mobile },
          }}
        >
          {(matches) =>
            matches.tablet ? (
              <div>
                <Cards>
                  {vehicles?.map((vehicle) => (
                    <VehicleCard
                      vehicle={vehicle}
                      actionMenuComponent={() => (editAccess ? getActionMenu(vehicle) : null)}
                    />
                  ))}
                </Cards>
              </div>
            ) : (
              <div style={styles.vehiclesBody}>
                <WcpTable
                  columns={filterColumns()}
                  tableSourceData={vehicleTableData}
                  emptyText="No vehicles are currently associated with this organization"
                  setListingCount={setListingCount}
                  matchingColumns={matchingColumns}
                  setMatchingColumns={setMatchingColumns}
                />
              </div>
            )
          }
        </Media>
      </PageListWrapper>
      <>
        <ToggleLockVehicleModal
          modalData={toggleLockModalData}
          handleCloseModal={() =>
            setToggleLockModalData((prevState) => ({ ...prevState, show: false }))
          }
        />

        {resetModalData && (
          <ResetVehicleModal
            vehicle={resetModalData.vehicle}
            onCloseModal={() => setResetModalData(null)}
          />
        )}
        {deleteModalData && (
          <DeleteVehicleModal
            vehicle={deleteModalData.vehicle}
            onCloseModal={() => setDeleteModalData(null)}
          />
        )}
        {removeFromGroupModalData && (
          <RemoveVehicleFromGroupModal
            vehicle={removeFromGroupModalData.vehicle}
            group={removeFromGroupModalData.group}
            onCloseModal={() => setRemoveFromGroupModalData(null)}
          />
        )}
      </>
    </>
  );
};

const styles = {
  vehiclesBody: {
    padding: '0 4px',
  },
};
export default connect((state) => ({
  userRole: selectUserRole(state.user.user),
  loadingVehicles: state.vehicles.requestingVehicles,
  editAccess: checkForUserPermission(state.user, permissionData.editfleet),
  organization: getOrganization(state, state.user.filteredOrganizationId),
}))(VehicleList);
