import ReactSelect from 'react-select';
import styled from 'styled-components';

import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';

const StyledReactSelect = styled(ReactSelect)`
  height: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
  @media ${device.large} {
    font-size: 12px;
    margin-top: -26px;

    ${(props) =>
      props.geofenceCard &&
      `
    margin-top: -28px;
    `}
  }
  ${(props) =>
    props.granularity &&
    `
      margin-bottom: 25px;
    `}

  [class*='-control'] {
    width: 100%;
    padding: 0px;
    border: none;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: ${colors.haze};
    background: transparent;
    box-shadow: none;

    ${(props) =>
      props.liveMapFilter &&
      `
    margin-right: 30px;
    margin-top: 2px;
    @media ${device.large} {
      margin-top:20px;
      margin-bottom:10px;
      }
    `}
  }

  // since we can't target ValueContainer because of minification, this will access it
  [class*='-control'] > div {
    padding: 0px;
    margin-bottom: -12px;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    @media ${device.large} {
      line-height: 18px;
      font-size: 14px;
    }

    ${(props) =>
      props.mediumTextMobile &&
      `
				font-size: 18px ;

        @media ${device.large} {
          font-size: 14px;
        }
		`}
  }

  /*   [class*='-ValueContainer'] {
    padding: 0px;
    margin-bottom: -12px;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    @media ${device.large} {
      line-height: 18px;
      font-size: 14px;
    }

    ${(props) =>
    props.mediumTextMobile &&
    `
				font-size: 18px ;

        @media ${device.large} {
          font-size: 14px;
        }
		`}
  } */

  // since we can't target IndicatorsContainer because of minification, this will access it
  [class*='-control']:nth-child(2) {
    margin-top: 17px;
    margin-bottom: 5px;
    margin-right: -5px;
    font-size: 22px;
    line-height: 35px;

    @media ${device.large} {
      margin-top: 12px;
      margin-bottom: 5px;
      line-height: 18px;
      font-size: 14px;
    }
  }

  /*   [class*='-IndicatorsContainer'] {
    margin-top: 17px;
    margin-bottom: 5px;
    margin-right: -5px;
    font-size: 22px;
    line-height: 35px;

    @media ${device.large} {
      margin-top: 12px;
      margin-bottom: 5px;
      line-height: 18px;
      font-size: 14px;
    }
  } */

  [class*='-indicatorSeparator'] {
    display: none;
  }

  [class*='-menu'] {
    margin-top: 18px;
  }

  [class*='-indicatorContainer'] {
    color: ${colors.haze};
    padding: 0px;
  }

  // since we can't target Svg because of minification, this will access it
  [class*='-indicatorContainer'] > svg {
    height: 15px;
  }

  /*  [class*='-Svg'] {
    height: 15px;
  } */

  [class*='-singleValue'] {
    margin-left: 0px;
  }

  /*   [class*='-ValueContainer'] {
    padding: 0px;
    margin-bottom: -12px;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    @media ${device.large} {
      line-height: 18px;
      font-size: 14px;
    }

    ${(props) =>
    props.mediumTextMobile &&
    `
				font-size: 18px ;

        @media ${device.large} {
          font-size: 14px;
        }
		`}
  } */

  [class*='-control']:hover {
    width: 100%;
    padding: 0px;
    border: none;
    border-bottom: 1px solid rgba(138, 144, 156, 0.25);
    border-radius: 0;
    letter-spacing: -0.01em;
    color: ${colors.haze};
    background: transparent;
  }
  [class*='-placeholder'] {
    font-size: 22px;
    margin-right: -25px;
    line-height: 35px;
    @media ${device.large} {
      line-height: 18px;
      font-size: 14px;
    }

    ${(props) =>
      props.mediumTextMobile &&
      `
				font-size: 18px ;

        @media ${device.large} {
          font-size: 14px;
        }
		`}
  }
`;

export default (props) => <StyledReactSelect {...props} />;
