import { getMaintenanceLogsApi } from 'legacy/features/maintenance/api/get/getMaintenanceLogsApi';
import useGenericQuery from 'legacy/shared/v2/hooks/reactQuery/useGenericQuery';

const useFetchMaintenanceLogsQuery = ({ startDate, endDate, vehicleId }) => {
  const [queryResult, queryKey] = useGenericQuery({
    apiFn: getMaintenanceLogsApi,
    queryKeyPrefix: 'getMaintenanceLogsApi',
    autoRun: false,
    params: { startDate, endDate, vehicleId },
  });
  return [queryResult.data, queryResult.refetch, queryKey];
};

export default useFetchMaintenanceLogsQuery;
