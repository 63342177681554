import { delayDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/delayDropdownOptions';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

import NewFilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/NewFilteredDropdown';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';
import { CooldownDelayDiv } from 'legacy/shared/v1/styles/components/ManageAlerts';

const DelayDropdown = ({ notificationIndex }) => {
  const {
    actions: { updateAlertNotificationDetailsAction },
    state: {
      alert: { alertNotifications },
    },
  } = useAlertFormsProviderContext();

  const { delayMinutes } = alertNotifications[notificationIndex];
  useMakeFormikField({ fieldName: 'delayField', fieldValue: delayMinutes });

  return (
    <CooldownDelayDiv>
      <NewFilteredDropdown
        domId={`waitToSendDropdown-${notificationIndex}`}
        styleOptions={{
          hideClearX: true,
        }}
        title={'Wait To Send Alert'}
        placeholderLabel={delayDropdownOptions.getLabelByValue(delayMinutes)}
        setup={{
          initialValue: delayMinutes,
          dropdownOptions: delayDropdownOptions,
          customSort: true,
          onSelect: ({ value }) => {
            updateAlertNotificationDetailsAction({ notificationIndex, delayMinutes: value });
          },
        }}
      />
    </CooldownDelayDiv>
  );
};

export default DelayDropdown;
