import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Container,
  Title,
  CloseButton,
  ActionsContainer,
  ModalText,
  BoldedItem,
} from 'legacy/shared/v1/styles/components/Modal';
import CancelButton, { Button } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import colors from 'legacy/shared/v1/constants/colors';

import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import CustomModal from 'legacy/shared/v1/ui/modals/CustomModal';

export default function SimpleConfirmationModal({
  bodyText,
  secondaryBodyText,
  boldedItem,
  secondaryLineBoldedItem,
  title,
  icon,
  confirmText,
  cancelText,
  handleCloseModal,
  customWidth,
  submitHandler,
  isLoading,
}) {
  return (
    <CustomModal
      title={title}
      icon={icon}
      handleCloseModal={handleCloseModal}
      customWidth={customWidth}
    >
      <ModalText>
        {' '}
        {bodyText} <BoldedItem>{boldedItem}</BoldedItem>
      </ModalText>
      <ModalText>
        <BoldedItem>{secondaryLineBoldedItem}</BoldedItem>
      </ModalText>
      <ModalText> {secondaryBodyText}</ModalText>

      <ActionsContainer>
        {cancelText && (
          <CancelButton onClick={handleCloseModal} withRightSpacer mediumAlt default type="button">
            {cancelText}
          </CancelButton>
        )}

        <ButtonWithLoader
          autoFocus
          isLoading={isLoading}
          confirmText={confirmText}
          loadingStyleProp={'submittingWithSpinnerModal'}
          notLoadingStyleProp={'formConfirm'}
          clickHandler={submitHandler}
        />
      </ActionsContainer>
    </CustomModal>
  );
}
