import { createContext, useEffect } from 'react';

import useLivemapReducer from 'legacy/features/livemap/hooks/useLivemapReducer';
import { getVehicleGpsCoordinates } from 'legacy/shared/v1/utilities/map/MapAnimationUtils';

export const LivemapApi = createContext(null);

const LivemapContextProvider = ({ navigateToVehicleId, vehicles, children }) => {
  const [state, actions] = useLivemapReducer();

  const {
    setSelectedVehicle,
    setLivemapSidebarStates,
    setFlyToCoordinates,
    setIncomingMapVehicles,
  } = actions;

  // handle vehicle click
  // closes the sidebar, sets the selected vehicle and brings it into view and brings up the vehicle detail card
  const handleVehicleClick = (id) => {
    id && setSelectedVehicle(id);

    setLivemapSidebarStates({
      livemapMainSidebarOpen: false,
    });
  };

  // called when vehicles are updated
  useEffect(() => {
    setIncomingMapVehicles(vehicles);
  }, [vehicles]);

  // if there is a vehicle id to navigate to, set the selected vehicle and fly to it
  useEffect(() => {
    const vehicle = vehicles.find((v) => v.vehicle_id === navigateToVehicleId);
    if (vehicle) {
      setSelectedVehicle(vehicle.vehicle_id);
      setFlyToCoordinates(getVehicleGpsCoordinates(vehicle));
    }
    return () => {
      setLivemapSidebarStates({
        livemapMainSidebarOpen: false,
      });
    };
  }, [navigateToVehicleId]);

  return (
    <LivemapApi.Provider
      value={{
        actions,
        helpers: {
          handleVehicleClick,
        },
        state,
      }}
    >
      {children(actions, state)}
    </LivemapApi.Provider>
  );
};
export default LivemapContextProvider;
