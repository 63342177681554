import React from 'react';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ConfirmationModal from 'legacy/shared/v1/modals/ConfirmationModal';
import styled from 'styled-components';

export const StyledConfirmationModalList = styled.div`
margin-top: 20,
margin-left: 14,
list-style: 'none',
line-height: 1.5,
`;

const VinPrefillConfirmationModal = ({ vinFillConfirmHandler, clearVinField, vinFillValues }) => {
  return (
    <ModalPortal onRequestClose={() => clearVinField()}>
      <ConfirmationModal
        confirmHandler={vinFillConfirmHandler}
        svgFileName={'vin-lookup'}
        bodyText={
          <>
            Do you want to populate the vehicle form with the following information found by the VIN
            Lookup service?
            <StyledConfirmationModalList>
              <li>Make: {vinFillValues.make}</li>
              <li>Model: {vinFillValues.model}</li>
              <li>Year: {vinFillValues.year}</li>
            </StyledConfirmationModalList>
          </>
        }
        cancelHandler={() => clearVinField()}
        cancelText={'Cancel'}
        confirmText={'Populate Form'}
        title={'Populate Vehicle Information'}
      />
    </ModalPortal>
  );
};

export default VinPrefillConfirmationModal;
