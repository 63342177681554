import { useFormikContext } from 'formik';

import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';
import alertMethodDropdownOptions from 'legacy/features/alerts/data/ui/dropdown/alertMethodDropdownOptions';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import NewFilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/NewFilteredDropdown';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';

const AlertRecipientAlertMethodDropdown = ({ alertNotification, recipientRow, disabled }) => {
  let { alertNotificationGuid } = alertNotification;
  let { recipientRowGuid, recipientRowAlertMethod } = recipientRow;

  const {
    actions: { updateAlertRecipientAlertMethodAction },
  } = useAlertFormsProviderContext();

  const formik = useFormikContext();

  useMakeFormikField({
    fieldName: 'alertMethodField',
    fieldValue: recipientRowAlertMethod,
    triggerValidation: true,
  });

  return (
    <div>
      <NewFilteredDropdown
        title={'Alert Method'}
        placeholderLabel="Select a method..."
        styleOptions={{
          disabled,
          restrictHeight: true,
          required: true,
          hideClearX: true,
        }}
        setup={{
          initialValue: recipientRowAlertMethod || null,

          dropdownOptions: alertMethodDropdownOptions,

          filterOptions: (option) => option.value !== alertMethodConstants.CUSTOM, // hide custom option

          onSelect: (selectedOption) => {
            updateAlertRecipientAlertMethodAction({
              alertNotificationGuid,
              recipientRowGuid,
              alertMethod: selectedOption.value,
            });
          },
        }}
      />

      {/* <FieldError>{formik.touched.alertMethodField && formik.errors.alertMethodField}</FieldError> */}
    </div>
  );
};

export default AlertRecipientAlertMethodDropdown;
