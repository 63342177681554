import { EditAlertContext } from 'legacy/features/alerts/services/providers/AlertFormsProvider/AlertFormsProvider';
import { useContext } from 'react';

const useAlertFormsProviderContext = () => {
  const context = useContext(EditAlertContext);

  if (!context) {
    throw 'useAlertFormsProviderContext must be used within a EditAlertContext';
  }

  return context;
};

export default useAlertFormsProviderContext;
