import { useContext } from 'react';

import { activityFilterOptions } from 'legacy/features/analyticsHeatmap/constants/AnalyticsHeatmapConstants';
import { AnalyticsHeatmapApi } from 'legacy/features/analyticsHeatmap/helpers/AnalyticsHeatmapApiContext';
import {
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
} from 'legacy/shared/v1/styles/components/Radio';

const AnalyticsHeatmapRespondingFilter = () => {
  const { selectedActivityFilterOption, setSelectedActivityFilterOption } =
    useContext(AnalyticsHeatmapApi);
  return (
    <>
      <RadioButtonContainer
        heatmap
        onClick={() => setSelectedActivityFilterOption(activityFilterOptions.ALL)}
      >
        <RadioButton
          type="radio"
          value="1"
          checked={selectedActivityFilterOption === activityFilterOptions.ALL}
          onChange={(e) => e.preventDefault()}
        />
        <RadioButtonLabel>All Activity</RadioButtonLabel>
      </RadioButtonContainer>
      <RadioButtonContainer
        heatmap
        onClick={() => setSelectedActivityFilterOption(activityFilterOptions.RESPONDING)}
      >
        <RadioButton
          type="radio"
          value="2"
          checked={selectedActivityFilterOption === activityFilterOptions.RESPONDING}
          onChange={(e) => e.preventDefault()}
        />
        <RadioButtonLabel>Responding</RadioButtonLabel>
      </RadioButtonContainer>
      <RadioButtonContainer
        heatmap
        onClick={() => setSelectedActivityFilterOption(activityFilterOptions.NOT_RESPONDING)}
      >
        <RadioButton
          type="radio"
          value="3"
          checked={selectedActivityFilterOption === activityFilterOptions.NOT_RESPONDING}
          onChange={(e) => e.preventDefault()}
        />
        <RadioButtonLabel>Not Responding</RadioButtonLabel>
      </RadioButtonContainer>
    </>
  );
};

export default AnalyticsHeatmapRespondingFilter;
