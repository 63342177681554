import { DropdownLink } from 'legacy/shared/v1/styles/components/DropdownMenu';

const EditRecipientGroupMenuItem = ({ recipientGroupId }) => {
  return (
    <DropdownLink to={`/editrecipientgroup/${encodeURIComponent(recipientGroupId)}`}>
      Edit Recipient Group
    </DropdownLink>
  );
};

export default EditRecipientGroupMenuItem;
