import { useEffect, useState } from 'react';
import Media from 'react-media';

import AnalyticsLogsCard from 'legacy/features/analyticsLogs/components/mobile/AnalyticsLogsCard';
import { AnalyticsCheckboxFilterWrapper } from 'legacy/shared/v1/styles/components/Analytics';
import { Checkbox } from 'legacy/shared/v1/styles/components/Checkbox';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';
import { SimpleDropdownItem } from 'legacy/shared/v1/styles/components/DropdownMenu';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import { RespondingDot } from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import { TableFilterSpan } from 'legacy/shared/v1/styles/components/Table';
import ViewVehiclePlayback from 'legacy/shared/v1/ui/menus/ViewVehiclePlayback';
import ActionMenu from 'legacy/shared/v1/ui/table/ActionMenu';
import { sizes } from 'legacy/shared/v1/utilities/media';
import {
  convertDuration,
  formatISOTimestampToLongDateWithTime,
} from 'legacy/shared/v1/utilities/time';
import { useSelector } from 'react-redux';
import {
  FIELD_SOLUTION_ENGINEER,
  permissionData,
  userRoleOptions as userRoles,
} from 'legacy/shared/v1/constants/users';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';

const AnalyticsLogsResultsTable = ({ logsQueryResults, selectedVehicle }) => {
  const [vehicleId, setVehicleId] = useState(selectedVehicle);
  const user = useSelector((state) => state.user);

  //FSE org permissions live in a different place than other use types (and sys admins can see everything)
  const isViewPlaybackAllowed =
    user.user.userRole === userRoles[FIELD_SOLUTION_ENGINEER].value
      ? user.orgPermissions.permissions.includes(permissionData.viewVehiclePlayback)
      : user.user.permissions.includes(permissionData.viewVehiclePlayback);

  const getActionMenu = ({ timestamp, duration, status }) => {
    const overTwentyFourHours = duration > 86400;
    const offlineEvent = status === 'Off';
    const lessThanFiveSeconds = duration < 5;
    return (
      <ActionMenu>
        <SimpleDropdownItem
          style={
            offlineEvent || overTwentyFourHours || lessThanFiveSeconds || !isViewPlaybackAllowed
              ? { cursor: 'not-allowed' }
              : null
          }
        >
          <ViewVehiclePlayback
            vehicleId={vehicleId}
            timestamp={timestamp}
            duration={duration}
            offlineEvent={offlineEvent}
            overTwentyFourHours={overTwentyFourHours}
            lessThanFiveSeconds={lessThanFiveSeconds}
            isViewPlaybackAllowed={isViewPlaybackAllowed}
          />
        </SimpleDropdownItem>
      </ActionMenu>
    );
  };
  const [filterOfflineEventsCheckboxIsChecked, setFilterOfflineEventsCheckboxIsChecked] =
    useState(false);

  const [filteredReportData, setFilteredReportData] = useState(logsQueryResults.events);

  useEffect(() => {
    let filteredLogs = logsQueryResults.events;
    filteredLogs.forEach((log) => {
      log.formattedTime = formatISOTimestampToLongDateWithTime(log.timestamp);
      log.convertedDuration = convertDuration(log.duration);
      log.distanceTraveledDisplay = log.distance_traveled
        ? `${log.distance_traveled} Miles`
        : 'N/A';
      log.averageSpeedDisplay = log.avg_speed.toString();
      log.topSpeedDisplay = log.top_speed.toString();
    });
    if (filterOfflineEventsCheckboxIsChecked) {
      filteredLogs = logsQueryResults.events.filter((l) => l.status !== 'Off');

      setFilteredReportData(filteredLogs);
    } else {
      setFilteredReportData(filteredLogs);
    }
    selectedVehicle && setVehicleId(selectedVehicle.vehicle_id);
  }, [logsQueryResults, filterOfflineEventsCheckboxIsChecked]);

  const columns = [
    {
      title: 'Timestamp',
      displayProperty: 'formattedTime',
      sortProperty: 'timestamp',
    },
    {
      title: 'Status',
      displayProperty: 'status',
      className: 'name',
    },
    {
      title: 'Responding',
      displayProperty: 'responding',
      className: 'name',
      render({ cellValue }) {
        return (
          <span title={'Responding: ' + cellValue}>
            {cellValue ? (
              <span title={'Responding: ' + 'Responding'}>
                <RespondingDot logs>Responding</RespondingDot>
              </span>
            ) : (
              <span title={'Responding: '}></span>
            )}
          </span>
        );
      },
    },
    {
      title: 'Duration',
      displayProperty: 'convertedDuration',
      sortProperty: 'duration',
    },
    {
      title: 'Distance Traveled',
      displayProperty: 'distanceTraveledDisplay',
      sortProperty: 'distance_traveled',
    },
    {
      title: 'Average Speed',
      displayProperty: 'averageSpeedDisplay',
      sortProperty: 'avg_speed',
    },
    {
      title: 'Top Speed',
      displayProperty: 'topSpeedDisplay',
      sortProperty: 'top_speed',
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) =>
        getActionMenu({
          timestamp: rowData.timestamp,
          duration: rowData.duration,
          status: rowData.status,
        }),
    },
  ];
  return (
    <>
      <div>
        <AnalyticsCheckboxFilterWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={filterOfflineEventsCheckboxIsChecked}
              onClick={() =>
                setFilterOfflineEventsCheckboxIsChecked(!filterOfflineEventsCheckboxIsChecked)
              }
            />
          </Checkbox>
          <TableFilterSpan>Hide offline events</TableFilterSpan>
        </AnalyticsCheckboxFilterWrapper>
        {filteredReportData && (
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {(matches) =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {filteredReportData.map((event) => (
                      <AnalyticsLogsCard
                        event={event}
                        actionMenuComponent={() =>
                          getActionMenu({
                            timestamp: event.timestamp,
                            duration: event.duration,
                            status: event.status,
                          })
                        }
                      />
                    ))}
                  </Cards>
                </div>
              ) : (
                <div>
                  <WcpTable
                    rowClassName={(record, i) => `row-${i}`}
                    columns={columns}
                    tableSourceData={filteredReportData}
                    defaultSortOrder={SORT_ORDER.DESC}
                    rowKey={(record) => record.timestamp}
                    emptyText="No data to display. Please click calculate to proceed."
                  />
                </div>
              )
            }
          </Media>
        )}
      </div>
    </>
  );
};
export default AnalyticsLogsResultsTable;
