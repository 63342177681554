import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import SimpleConfirmationModal from 'legacy/shared/v1/ui/modals/SimpleConfirmationModal';
import { useDeleteAlertMutation } from 'legacy/features/alerts/services/reactQuery/mutations/useDeleteAlertMutation';

const DeleteAlertModal = ({ modalData: { show, alertId, alertName }, handleCloseModal }) => {
  const deleteAlertMutation = useDeleteAlertMutation();
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={deleteAlertMutation.isPending}
        submitHandler={async () => {
          try {
            let result = await deleteAlertMutation.mutateAsync({
              alertId,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to delete this alert:`}
        boldedItem={`${alertName}?`}
        title={`Delete Alert`}
        confirmText="Delete"
        cancelText="Cancel"
      />
    )
  );
};

export default DeleteAlertModal;
