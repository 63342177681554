import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import AuthenticatedRoute from 'legacy/core/routes/AuthenticatedRoute';
import NonExistentRoute from 'legacy/core/routes/NonExistentRoute';
import UnauthenticatedRoute from 'legacy/core/routes/UnauthenticatedRoute';
import CreateAlertPage from 'legacy/features/alerts/ui/pages/CreateAlertPage';
import EditAlertPage from 'legacy/features/alerts/ui/pages/EditAlertPage';
import ManageAlertsLogsPage from 'legacy/features/alerts/ui/pages/ManageAlertsLogsPage';
import ManageAlertsPage from 'legacy/features/alerts/ui/pages/ManageAlertsPage';
import AnalyticsDashboardPage from 'legacy/features/analyticsDashboard/pages/AnalyticsDashboardPage';
import AnalyticsHeatmapPage from 'legacy/features/analyticsHeatmap/pages/AnalyticsHeatmapPage';
import AnalyticsLogsPage from 'legacy/features/analyticsLogs/pages/AnalyticsLogsPage';
import ConfigurationLibraryPage from 'legacy/features/configurations/pages/ConfigurationLibraryPage';
import ConfigurationUpdateDetailsPage from 'legacy/features/configurations/pages/ConfigurationUpdateDetailsPage';
import ConfigurationUpdatesLogsPage from 'legacy/features/configurations/pages/ConfigurationUpdatesLogsPage';
import ConfigurationUpdatesSummaryPage from 'legacy/features/configurations/pages/ConfigurationUpdatesSummaryPage';
import FirmwareUpdateDetailsForVehicle from 'legacy/features/firmware/pages/FirmwareUpdateDetailsForVehicle';
import FirmwareUpdateDetailsPage from 'legacy/features/firmware/pages/FirmwareUpdateDetailsPage';
import FirmwareUpdatesLogsPage from 'legacy/features/firmware/pages/FirmwareUpdatesLogsPage';
import FirmwareUpdatesSummaryPage from 'legacy/features/firmware/pages/FirmwareUpdatesSummaryPage';
import GeofenceLogsPage from 'legacy/features/geofences/pages/GeofenceLogsPage';
import GeofencesPage from 'legacy/features/geofences/pages/GeofencesPage';
import HelpPage from 'legacy/features/help/pages/HelpPage';

import LivemapPage from 'legacy/features/livemap/pages/LivemapPage';
import PrebillingLivemapPage from 'legacy/features/livemap.prebilling/pages/LivemapPage';

import MaintenanceLogsPage from 'legacy/features/maintenance/pages/MaintenanceLogsPage';
import MaintenanceSchedulerPage from 'legacy/features/maintenance/pages/MaintenanceSchedulerPage';
import MaintenanceSummaryPage from 'legacy/features/maintenance/pages/MaintenanceSummaryPage';
import OrganizationDetailPage from 'legacy/features/organizations/pages/OrganizationDetailPage';
import OrganizationsPage from 'legacy/features/organizations/pages/OrganizationsPage';
import OrgUserPermissionsPage from 'legacy/features/permissions/pages/OrgUserPermissionsPage';
import VehiclePlaybackPage from 'legacy/features/playback/ui/pages/VehiclePlaybackPage';
import CreateRecipientGroupPage from 'legacy/features/recipientGroups/pages/CreateRecipientGroupPage';
import EditRecipientGroupPage from 'legacy/features/recipientGroups/pages/EditRecipientGroupPage';
import ManageRecipientGroupsPage from 'legacy/features/recipientGroups/pages/ManageRecipientGroupsPage';
import UserActivityLogsPage from 'legacy/features/users/pages/UserActivityLogsPage';
import UserDetailPage from 'legacy/features/users/pages/UserDetailPage';
import VehicleGroupAddonsPage from 'legacy/features/vehicleGroups/pages/VehicleGroupAddonsPage';
import VehicleGroupDetailPage from 'legacy/features/vehicleGroups/pages/VehicleGroupDetailPage';
import VehicleGroupListingPage from 'legacy/features/vehicleGroups/pages/VehicleGroupListingPage';

import VehicleCreatePage from 'legacy/features/vehicles/pages/VehicleCreatePage';
import VehicleEditPage from 'legacy/features/vehicles/pages/VehicleEditPage';
import VehicleListingPage from 'legacy/features/vehicles/pages/VehicleListingPage';

import PrebillingVehicleCreatePage from 'legacy/features/vehicles.prebilling/pages/VehicleCreatePage';
import PrebillingVehicleEditPage from 'legacy/features/vehicles.prebilling/pages/VehicleEditPage';
import PrebillingVehicleListingPage from 'legacy/features/vehicles.prebilling/pages/VehicleListingPage';

import CreateWhitelistPage from 'legacy/features/whitelists/pages/CreateWhitelistPage';
import WhitelistsPage from 'legacy/features/whitelists/pages/WhitelistsPage';
import UnsubscribePage from 'legacy/features/unsubscribe/pages/UnsubscribePage';
import Login from 'legacy/core/components/authentication/pages/Login';
import ResetPassword from 'legacy/core/components/authentication/pages/ResetPassword';
import TroubleLoggingIn from 'legacy/core/components/authentication/pages/TroubleLoggingIn';
import TwoFactorSelection from 'legacy/core/components/authentication/pages/TwoFactorSelection';
import TwoFactorVerification from 'legacy/core/components/authentication/pages/TwoFactorVerification';
import { getOrganization } from 'legacy/core/redux/organizations/selectors';
import {
  FIELD_SOLUTION_ENGINEER,
  FLEET_ANALYST,
  MAP_ANALYST,
  ORGANIZATION_ADMIN,
  permissionData,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'legacy/shared/v1/constants/users';
import { loginIsFresh } from 'legacy/shared/v1/utilities/localStore';
import DevicesAndSubscriptionsPage from 'legacy/features/billing/ui/pages/DevicesAndSubscriptionsPage';
import DeviceLogsPage from 'legacy/features/billing/ui/pages/DeviceLogsPage';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getUserOrganization = (organizationId, organizations) => {
  return organizations
    ? organizations.find((organization) => organization.organizationId === organizationId)
    : null;
};

const RouterSetup = () => {
  const { ldBillingEnabled } = useFlags();

  const userOrganization = useSelector((state) =>
    getUserOrganization(state.user.user.organizationId, state.organizations.organizations),
  );

  const filteredOrganization = useSelector((state) =>
    getOrganization(state, state.user.filteredOrganizationId),
  );

  const isLoggedIn = loginIsFresh();

  return (
    <Routes>
      {/**********************************************************\}
       * Unauthenticated Routes
       ***********************************************************/}
      <Route
        exact
        path={'/'}
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path={'/login'}
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path={'/troubleloggingin'}
        element={
          <UnauthenticatedRoute>
            <TroubleLoggingIn />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path={'/resetpwd/:token'}
        element={
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * 2 Factor Setup Routes
       ***********************************************************/}
      <Route path={'/twofactorselection'} element={<TwoFactorSelection />} />
      <Route path={'/twofactorverification'} element={<TwoFactorVerification />} />
      {/**********************************************************\}
       * Livemap
       ***********************************************************/}
      <Route
        path={'/livemap'}
        element={
          <AuthenticatedRoute>
            {ldBillingEnabled ? <LivemapPage /> : <PrebillingLivemapPage />}
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/livemap/:vehicleId`}
        element={
          <AuthenticatedRoute>
            {ldBillingEnabled ? <LivemapPage /> : <PrebillingLivemapPage />}
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Vehicles
       ***********************************************************/}
      <Route
        path={`/vehicles`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.viewfleet}>
            {ldBillingEnabled ? <VehicleListingPage /> : <PrebillingVehicleListingPage />}
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/vehicle/:vehicleId`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            {ldBillingEnabled ? <VehicleEditPage /> : <PrebillingVehicleEditPage />}
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/createvehicle/`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            {ldBillingEnabled ? <VehicleCreatePage /> : <PrebillingVehicleCreatePage />}
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Groups
       ***********************************************************/}
      <Route
        path={`/groups`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.viewfleet}>
            <VehicleGroupListingPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/groups/:groupName`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.viewfleet}>
            <VehicleGroupDetailPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/groups/:groupId/addons`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.viewfleet}>
            <VehicleGroupAddonsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Maintenance
       ***********************************************************/}
      <Route
        path={`/maintenance`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            <MaintenanceSummaryPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/maintenance/:vehicleId`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            <MaintenanceSummaryPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/schedulemaintenance`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            <MaintenanceSchedulerPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/maintenancelogs/:vehicleId`}
        element={
          <AuthenticatedRoute requiredPermission={permissionData.editfleet}>
            <MaintenanceLogsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Organizations
       ***********************************************************/}
      <Route
        path={`/organizations`}
        element={
          <AuthenticatedRoute
            requiredRole={[userRoles[SYSTEM_ADMIN].value, userRoles[FIELD_SOLUTION_ENGINEER].value]}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
              [userRoles[ORGANIZATION_ADMIN].value]: `/organizations/${
                userOrganization && userOrganization.organizationId
              }`,
            }}
          >
            <OrganizationsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/organizations/:organizationId`}
        element={
          <AuthenticatedRoute
            requiredRole={[
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
            }}
          >
            <OrganizationDetailPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/permissions/:organizationId`}
        element={
          <AuthenticatedRoute
            requiredRole={[userRoles[ORGANIZATION_ADMIN].value]}
            redirectTo={{
              [userRoles[SYSTEM_ADMIN].value]: '/livemap',
              [userRoles[FIELD_SOLUTION_ENGINEER].value]: '/livemap',
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
            }}
          >
            <OrgUserPermissionsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Alerts
       ***********************************************************/}
      <Route
        path={`/managealerts`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <ManageAlertsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/managerecipientgroups`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <ManageRecipientGroupsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/createrecipientgroup`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <CreateRecipientGroupPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/editrecipientgroup/:recipientGroupId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <EditRecipientGroupPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/createalert`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <CreateAlertPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/editalert/:alertId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <EditAlertPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/alertlogs/:alertId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editAlerts}
            requiredRole={[
              userRoles[MAP_ANALYST].value,
              userRoles[FLEET_ANALYST].value,
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            <ManageAlertsLogsPage />
          </AuthenticatedRoute>
        }
      />

      {/**********************************************************\}
       * Billing
       ***********************************************************/}

      <Route
        path={`/devicesandsubscriptions`}
        element={
          <AuthenticatedRoute
            requiredRole={[
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
            }}
          >
            {ldBillingEnabled ? <DevicesAndSubscriptionsPage /> : <LivemapPage />}
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/devicelogs/:vsgDuid`}
        element={
          <AuthenticatedRoute
            requiredRole={[
              userRoles[ORGANIZATION_ADMIN].value,
              userRoles[SYSTEM_ADMIN].value,
              userRoles[FIELD_SOLUTION_ENGINEER].value,
            ]}
          >
            {ldBillingEnabled ? <DeviceLogsPage /> : <LivemapPage />}
          </AuthenticatedRoute>
        }
      />

      {/**********************************************************\}
       * Firmware
       ***********************************************************/}
      <Route
        path={`/updates`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotafirmware}
            redirectIfSubscriptionExpired={true}
          >
            <FirmwareUpdatesSummaryPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/updates/:vehicleId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotafirmware}
            redirectIfSubscriptionExpired={true}
          >
            <FirmwareUpdateDetailsForVehicle />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/updates/:productId/:updateChannel/:updateVersion`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotafirmware}
            redirectIfSubscriptionExpired={true}
          >
            <FirmwareUpdateDetailsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/firmware/updates/logs`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotafirmware}
            redirectIfSubscriptionExpired={true}
          >
            <FirmwareUpdatesLogsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Configurations
       ***********************************************************/}
      <Route
        path={`/configurations`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotaconfig}
            redirectIfSubscriptionExpired={true}
          >
            <ConfigurationLibraryPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/configurations/updates`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotaconfig}
            redirectIfSubscriptionExpired={true}
          >
            <ConfigurationUpdatesSummaryPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/configurations/updates/:productId/:configurationId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotaconfig}
            redirectIfSubscriptionExpired={true}
          >
            <ConfigurationUpdateDetailsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/configurations/updates/logs`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewotaconfig}
            redirectIfSubscriptionExpired={true}
          >
            <ConfigurationUpdatesLogsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Whitelists
       ***********************************************************/}
      <Route
        path={`/whitelists`}
        element={
          <AuthenticatedRoute
            requiredPermission={null}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
              [userRoles[ORGANIZATION_ADMIN].value]: '/livemap',
            }}
            redirectIfSubscriptionExpired={true}
            requiredRole={[userRoles[SYSTEM_ADMIN].value, userRoles[FIELD_SOLUTION_ENGINEER].value]}
          >
            <WhitelistsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/whitelists/edit`}
        element={
          <AuthenticatedRoute
            requiredPermission={null}
            redirectIfSubscriptionExpired={true}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
              [userRoles[ORGANIZATION_ADMIN].value]: '/livemap',
            }}
            requiredRole={[userRoles[SYSTEM_ADMIN].value, userRoles[FIELD_SOLUTION_ENGINEER].value]}
          >
            <CreateWhitelistPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/whitelists/edit/:productId`}
        element={
          <AuthenticatedRoute
            requiredPermission={null}
            redirectIfSubscriptionExpired={true}
            requiredRole={[userRoles[SYSTEM_ADMIN].value, userRoles[FIELD_SOLUTION_ENGINEER].value]}
            redirectTo={{
              [userRoles[MAP_ANALYST].value]: '/livemap',
              [userRoles[FLEET_ANALYST].value]: '/livemap',
              [userRoles[ORGANIZATION_ADMIN].value]: '/livemap',
            }}
          >
            <CreateWhitelistPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * User
       ***********************************************************/}
      <Route
        path={`/user`}
        element={
          <AuthenticatedRoute>
            <UserDetailPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/activityLogs/:userName`}
        element={
          <AuthenticatedRoute>
            <UserActivityLogsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Analytics
       ***********************************************************/}
      <Route
        path={`/analyticsdashboard`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewanalytics}
            redirectIfSubscriptionExpired={true}
          >
            <AnalyticsDashboardPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/analytics/:vehicleId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewanalytics}
            redirectIfSubscriptionExpired={true}
          >
            <AnalyticsDashboardPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/analyticslogs`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewanalytics}
            redirectIfSubscriptionExpired={true}
          >
            <AnalyticsLogsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/analyticslogs/:vehicleId`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewanalytics}
            redirectIfSubscriptionExpired={true}
          >
            <AnalyticsLogsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/analyticsheatmap`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewanalytics}
            redirectIfSubscriptionExpired={true}
          >
            <AnalyticsHeatmapPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Geofences
       ***********************************************************/}
      <Route
        path={`/geofences`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.editgeofence}
            redirectIfSubscriptionExpired={true}
          >
            <GeofencesPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/geofences/logs/:geofenceId`}
        element={
          <AuthenticatedRoute redirectIfSubscriptionExpired={true}>
            <GeofenceLogsPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Playback
       ***********************************************************/}
      <Route
        path={`/playback`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewVehiclePlayback}
            redirectIfSubscriptionExpired={true}
          >
            <VehiclePlaybackPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={`/playback/:vehicleId/:startDateTime/:duration`}
        element={
          <AuthenticatedRoute
            requiredPermission={permissionData.viewVehiclePlayback}
            redirectIfSubscriptionExpired={true}
          >
            <VehiclePlaybackPage />
          </AuthenticatedRoute>
        }
      />
      {/**********************************************************\}
       * Unsubscribe
       ***********************************************************/}
      <Route
        path={`/unsubscribe/:token`}
        element={
          isLoggedIn ? (
            <AuthenticatedRoute>
              <UnsubscribePage />
            </AuthenticatedRoute>
          ) : (
            <UnauthenticatedRoute>
              <UnsubscribePage />
            </UnauthenticatedRoute>
          )
        }
      />

      {/**********************************************************\}
       * Help
       ***********************************************************/}
      <Route
        path={`/help`}
        element={
          <AuthenticatedRoute>
            <HelpPage />
          </AuthenticatedRoute>
        }
      />
      <Route path="*" element={<NonExistentRoute />} />
    </Routes>
  );
};

export default RouterSetup;
