import { useEffect, useState } from 'react';

import useFetchPlaybackDataQuery from 'legacy/features/playback/services/reactQuery/queries/useFetchPlaybackDataQuery';
import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';

const useFetchVehiclePlaybackAndRefreshProviderState = () => {
  const {
    actions: { setPlaybackApiDataAction, setPlaybackAnimationControlsAction },
    state: {
      playbackRequestForm: { selectedVehicleId },
    },
  } = useVehiclePlaybackProviderContext();

  const [apiParams, setApiParams] = useState();

  // triggers fetch of the playback data and updates the provider state
  const {
    queryResult,
    queryData: { locationData, summary },
    queryRefetch,
    querykey,
  } = useFetchPlaybackDataQuery({
    requestParams: apiParams,
    autoRun: false,
  });

  useEffect(() => {
    if (apiParams) {
      queryRefetch();
    }
  }, [apiParams]);

  // update provider state after data is fetched
  useEffect(() => {
    // set provider state with playback data (sorted by time)
    setPlaybackApiDataAction({
      locationData,
      summary,
      associatedVehicleId: selectedVehicleId,
    });

    // update provider state with playback animation state
    setPlaybackAnimationControlsAction({
      currentPlaybackDataIndex: 0,
      selectedPlaybackDataIndex: null,
    });
  }, [locationData, summary]);

  return {
    fetch: setApiParams,
    isLoading: queryResult.isFetching,
  };
};

export default useFetchVehiclePlaybackAndRefreshProviderState;
