import NavigationDropdownMenu from 'legacy/core/navigation/components/NavigationDropdownMenu';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

import {
  NavButton,
  NavButtonIcon,
  NavButtonText,
  NavTopLevelItemWrapper,
} from 'legacy/shared/v2/styles/NavigationStyles';
const NavigationDropdownButton = ({
  item,
  itemKey,
  userRole,
  permissions,
  sidebarIsCollapsed,
  activatedItemKey,
  selectedItemKey,
  handleNavigationItemClick,
}) => {
  const { pathname } = useLocation();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  // If the item has a dropdown, check if it should be open
  useEffect(() => {
    if (item.dropdown) {
      setIsOpenMenu(
        !sidebarIsCollapsed &&
          (activatedItemKey === itemKey ||
            (isActiveParent(item.dropdown) && activatedItemKey === null)),
      );
    }
  }, [selectedItemKey, activatedItemKey, sidebarIsCollapsed]);

  // determine if the dropdown item is active
  const isActiveParent = (dropdown) => {
    return Object.values(dropdown).some((item) => pathname.includes(item.link));
  };

  return (
    <NavTopLevelItemWrapper>
      <NavButton
        sidebarIsCollapsed={sidebarIsCollapsed}
        hasDropdown={!!item.dropdown}
        onClick={() => handleNavigationItemClick({ itemKey })}
        isActiveParent={isActiveParent(item.dropdown, selectedItemKey)}
      >
        <NavButtonIcon>
          <IconSvgComponent
            svgFileName={item.icon_path}
            style={{ marginLeft: item.leftOffset, marginRight: item.rightOffset }}
          />
        </NavButtonIcon>
        <NavButtonText>{item.title}</NavButtonText>
      </NavButton>
      {item.dropdown && (
        <NavigationDropdownMenu
          dropdownItems={item.dropdown}
          isOpen={isOpenMenu}
          sidebarIsCollapsed={sidebarIsCollapsed}
          userRole={userRole}
          permissions={permissions}
          handleNavigationItemClick={handleNavigationItemClick}
          selectedItemKey={selectedItemKey}
        />
      )}
    </NavTopLevelItemWrapper>
  );
};

export default NavigationDropdownButton;
