import { DropdownLink } from 'legacy/shared/v1/styles/components/DropdownMenu';

const ViewVehiclePlayback = ({
  vehicleId,
  timestamp,
  duration,
  offlineEvent,
  overTwentyFourHours,
  notValidAlertType,
  lessThanFiveSeconds,
  isViewPlaybackAllowed,
}) => {
  // move the following this check into a utility in the proper folder structure when we refactor this feature

  const getViewPlaybackOptionText = () => {
    if (!isViewPlaybackAllowed) {
      return 'You do not have permission to view this vehicle playback';
    } else if (offlineEvent) {
      return 'No playback to view for an offline event';
    } else if (overTwentyFourHours) {
      return 'Events that span over 24 hours are not available for playback';
    } else if (notValidAlertType) {
      return 'No playback available for this alert condition';
    } else if (lessThanFiveSeconds) {
      return 'Events less than 5 seconds in duration are not available for playback';
    } else {
      return 'View Playback';
    }
  };
  return (
    <DropdownLink
      disabled={
        offlineEvent ||
        overTwentyFourHours ||
        notValidAlertType ||
        lessThanFiveSeconds ||
        !isViewPlaybackAllowed
      }
      to={`/playback/${encodeURIComponent(vehicleId)}/${encodeURIComponent(
        timestamp,
      )}/${encodeURIComponent(duration)}`}
    >
      {getViewPlaybackOptionText()}
    </DropdownLink>
  );
};

export default ViewVehiclePlayback;
