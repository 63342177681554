import React from 'react';
import { ActionButtonWithIcon } from 'legacy/shared/v1/styles/components/Button';
import { createExportDownload, getCsvFileName } from 'legacy/shared/v1/utilities/csvExport';
import { ExportWrapper } from 'legacy/shared/v1/styles/components/Analytics';
import { ExportDiv } from 'legacy/shared/v1/styles/components/PageList';

const ExportCsvButton = ({ exportData, fileNamePrefix }) => {
  return (
    <ExportWrapper>
      <ActionButtonWithIcon
        onClick={() => {
          const fileName = getCsvFileName({ fileNamePrefix });
          createExportDownload(exportData, fileName);
        }}
        export
      >
        Export to .csv
      </ActionButtonWithIcon>
    </ExportWrapper>
  );
};

export default ExportCsvButton;
