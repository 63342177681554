import { mapToFrontend } from 'legacy/shared/v2/utilities/mapping/mapper';

export const userMapping = {
  Username: {
    frontendProperty: 'userName',
  },
  email: {
    frontendProperty: 'email',
  },
  phone_number: {
    frontendProperty: 'phoneNumber',
  },
  given_name: {
    frontendProperty: 'firstName',
  },
  family_name: {
    frontendProperty: 'lastName',
  },
  user_title: {
    frontendProperty: 'userTitle',
  },
  active: {
    frontendProperty: 'userActive',
  },
  user_role: {
    frontendProperty: 'userRole',
  },
  picture: {
    frontendProperty: 'profilePicture',
  },
  organization_id: {
    frontendProperty: 'homeOrganizationId',
  },
  two_factor_auth_method: {
    frontendProperty: 'mfaMethod',
  },
  permissions: {
    frontendProperty: 'userPermissions',
  },
};
