import ManageRemindersModalButton from 'legacy/features/maintenance/components/MaintenanceSummary/buttons/ManageRemindersModalButton';
import MaintenanceSummary from 'legacy/features/maintenance/components/MaintenanceSummary/MaintenanceSummary';
import { useParams } from 'react-router-dom';
import { HeaderLinkButton } from 'legacy/shared/v1/styles/components/LinkButton';
import { MaintenanceActionButtonsDiv } from 'legacy/shared/v1/styles/components/Maintenance';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';

const MaintenanceSummaryPage = () => {
  return (
    <PageListWrapper>
      <PageListHead stackable noBottomMargin>
        <PageListTitle>Maintenance</PageListTitle>
        <PageListActionButtonContainer>
          <MaintenanceActionButtonsDiv>
            <ManageRemindersModalButton />
            <HeaderLinkButton to={`/schedulemaintenance`}>Schedule Maintenance</HeaderLinkButton>
          </MaintenanceActionButtonsDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      <MaintenanceSummary />
    </PageListWrapper>
  );
};

export default MaintenanceSummaryPage;
