import { Formik } from 'formik';
import { useState } from 'react';

import TwoFactorSubtitle from 'legacy/core/components/authentication/components/TwoFactorSubtitle';
import { mfaTypes } from 'legacy/shared/v1/constants/users';
import Button from 'legacy/shared/v1/styles/components/Button';
import {
  ActionsContainer,
  CloseButton,
  Container,
  Title,
} from 'legacy/shared/v1/styles/components/Modal';
import {
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
} from 'legacy/shared/v1/styles/components/Radio';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

export default ({ data, actions, handleRequestClose }) => {
  const {
    redirectToVerifyMfa,
    username,
    mfaType,
    mfaDestination,
    authenticationAppSecretCode,
    smsVerificationCode,
  } = data;
  const { setTwoFactorMethod, verifySoftwareTokenMfa, verifySmsMfa } = actions;

  const [selectedOption, selectOption] = useState(mfaTypes.SMS);

  const submitVerification = async (values, actions) => {
    const { userMfaCode } = values;
    let success;

    // user profile change 2fa login: Auth App
    if (authenticationAppSecretCode) {
      success = await verifySoftwareTokenMfa({
        userMfaCode,
      });
    }

    // user profile change 2fa login: SMS
    else if (smsVerificationCode) {
      success = await verifySmsMfa({
        smsVerificationCode,
        userMfaCode,
      });
    } else {
      // smoke and mirrors
      verifySmsMfa({ bypass: true });
    }

    // can use success variable here if needed for redirects or whatever
    // currently not using it for anything
    actions.setSubmitting(false);
    handleRequestClose();
  };
  const renderTwoFactorSelection = () => (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        setTwoFactorMethod({ twoFactorMethod: selectedOption });

        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <form style={styles.formBody}>
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.SMS)}>
            <RadioButton
              type="radio"
              value={mfaTypes.SMS}
              checked={selectedOption === mfaTypes.SMS}
            />
            <RadioButtonLabel>Text me a code</RadioButtonLabel>
          </RadioButtonContainer>
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.AUTHENTICATION_APP)}>
            <RadioButton
              type="radio"
              value={mfaTypes.AUTHENTICATION_APP}
              checked={selectedOption === mfaTypes.AUTHENTICATION_APP}
            />
            <RadioButtonLabel>Use an authenticator app</RadioButtonLabel>
          </RadioButtonContainer>
          <ActionsContainer>
            <Button onClick={handleRequestClose} withRightSpacer default>
              Cancel
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              confirmText={'Continue'}
              loadingStyleProp={'submittingWithSpinner'}
              notLoadingStyleProp={'mediumAlt'}
              clickHandler={submitForm}
            />
          </ActionsContainer>
        </form>
      )}
    </Formik>
  );

  const renderTwoFactorVerification = () => (
    <Formik
      initialValues={{
        userMfaCode: '',
      }}
      onSubmit={submitVerification}
    >
      {({ values, errors, touched, dirty, isSubmitting, handleChange, handleBlur, submitForm }) => (
        <form style={styles.formBody}>
          <AnimatedField
            id="userMfaCode"
            name="userMfaCode"
            type="text"
            touched={touched.userMfaCode}
            validationError={errors.userMfaCode}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder="Enter Code"
            value={values.userMfaCode}
            required
          />
          <ActionsContainer>
            <Button onClick={handleRequestClose} withRightSpacer default type="button">
              Cancel
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              confirmText={'Continue'}
              loadingStyleProp={'submittingWithSpinnerModal'}
              notLoadingStyleProp={'mediumAlt'}
              clickHandler={submitForm}
            />
          </ActionsContainer>
        </form>
      )}
    </Formik>
  );

  return (
    <Container autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <IconSvgComponent style={styles.editIcon} svgFileName={'edit-pencil-blue'} alt="Edit" />
        Change Two-Factor Authentication
      </Title>

      {redirectToVerifyMfa === true ? (
        <>
          <TwoFactorSubtitle
            mfaType={mfaType}
            username={username}
            mfaDestination={mfaDestination}
            authenticationAppSecretCode={authenticationAppSecretCode}
          />
          {renderTwoFactorVerification()}
        </>
      ) : (
        <>{renderTwoFactorSelection()}</>
      )}
    </Container>
  );
};

const styles = {
  title: {
    marginBottom: '42px',
  },
  editIcon: {
    marginRight: '10px',
  },
  formBody: {
    padding: '0px 20px 0px 20px',
  },
};
