import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';

import { submitLogin } from 'legacy/core/redux/user/actions';
import {
  Wrapper,
  StyledFormWrapper,
  LoginWrapper,
  StyledBody,
  StyledTitle,
  StyledIconWrapper,
  StyledFormRow,
  StyledFoot,
  StyledText,
  StyledLink,
  StyledExternalLink,
  WhelenLogo,
  WCPLogo,
  LoginMessage,
  PreLogoText,
} from 'legacy/shared/v1/styles/components/LoginSignup';
import whelenLogo from 'assets/images/whelenlogo@2x.png';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import wcpLogo from 'assets/images/wcpLogo@2x.png';

const LoginForm = ({
  loginFormMessage,
  loggingIn,
  timedOut,
  redirectToVerifyMfa,
  redirectToSelectMfa,
  submitLogin,
  resetEmail,
}) => {
  let refUsernameInput = useRef();
  let refPasswordInput = useRef();

  useEffect(() => {
    if (refUsernameInput.current) {
      refUsernameInput.current.focus();
    }
  }, []);

  const submitHandler = () => {
    submitLogin({
      username: refUsernameInput.current.value.toLowerCase().trim(),
      password: refPasswordInput.current.value,
    });
  };

  const onPasswordEnterUp = () => (e) => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  return (
    <Wrapper>
      {redirectToVerifyMfa && <Navigate to={'/twofactorverification'} />}
      {redirectToSelectMfa && <Navigate to={'/twofactorselection'} />}
      <LoginWrapper>
        <StyledFormWrapper>
          <StyledIconWrapper>
            <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform®" />
          </StyledIconWrapper>

          <StyledTitle>Log In to Whelen Cloud Platform®</StyledTitle>
          {timedOut ? (
            <LoginMessage>
              You have been logged out of Whelen Cloud Platform® due to inactivity. Please login
              again.
            </LoginMessage>
          ) : null}
          {resetEmail && (
            <LoginMessage>
              An email has been sent to your new address. For security purposes you will need to
              reset your password. Please click the link in the email to reset your password.
            </LoginMessage>
          )}
          {loginFormMessage && <LoginMessage>{loginFormMessage}</LoginMessage>}

          <StyledBody>
            <form>
              <StyledFormRow>
                <AnimatedField
                  innerRef={refUsernameInput}
                  placeholder="Username"
                  type="text"
                  id="username"
                  required
                />
              </StyledFormRow>

              <StyledFormRow>
                <AnimatedField
                  innerRef={refPasswordInput}
                  placeholder="Password"
                  type="password"
                  id="password"
                  handleKeyUp={onPasswordEnterUp()}
                  required
                />
              </StyledFormRow>
            </form>
          </StyledBody>

          <StyledFoot>
            <ButtonWithLoader
              isLoading={loggingIn}
              confirmText={'Log In'}
              loadingStyleProp={'loginWithSpinner'}
              notLoadingStyleProp={'loginButton'}
              clickHandler={submitHandler}
            />
            <StyledText>
              <StyledLink to="/troubleloggingin">Trouble logging in?</StyledLink>
              <br />
              <br />
              <StyledExternalLink href="https://status.cloud.whelen.com/">
                Site Status
              </StyledExternalLink>
            </StyledText>
            <PreLogoText>POWERED BY</PreLogoText>
            <WhelenLogo src={whelenLogo} alt="Whelen" />
            <StyledText>
              <StyledExternalLink href="https://releasenotes.cloud.whelen.com/">
                View Release Notes
              </StyledExternalLink>
            </StyledText>
          </StyledFoot>
        </StyledFormWrapper>
      </LoginWrapper>
    </Wrapper>
  );
};

const Login = connect(
  (state) => ({
    userPool: state.user.userPool,
    loginFormMessage: state.ui.loginMessage,
    loggingIn: state.user.loggingIn,
    timedOut: state.user.timedOut,
    resetEmail: state.user.resetEmail,
    redirectToVerifyMfa: state.user.redirectToVerifyMfa,
    redirectToSelectMfa: state.user.redirectToSelectMfa,
  }),
  (dispatch) => ({
    submitLogin: ({ username, password }) => dispatch(submitLogin({ username, password })),
  }),
)(LoginForm);

export default Login;
