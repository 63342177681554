import { findAlertNotificationSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findAlertNotificationSelector';

export const findRecipientRowSelector = (state, alertNotificationGuid, recipientRowGuid) => {
  let alertNotification = findAlertNotificationSelector(state, alertNotificationGuid);

  let recipient = alertNotification.alertRecipientRows.find(
    (r) => r.recipientRowGuid === recipientRowGuid,
  );

  if (!recipient) throw new Error(`Recipient with recipientRowGuid ${recipientRowGuid} not found`);

  return recipient;
};

export const findRecipientRowSelectorV2 = (state, recipientRowGuid) => {
  let recipient = state.alert.alertNotifications
    .flatMap((alertNotification) => alertNotification.alertRecipientRows)
    .find((r) => r.recipientRowGuid === recipientRowGuid);

  if (!recipient) throw new Error(`Recipient with recipientRowGuid ${recipientRowGuid} not found`);

  return recipient;
};
