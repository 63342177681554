import React from 'react';

import { haasPublicAlertingDropdownOptions } from 'legacy/shared/v1/constants/vehicle';
import { FieldError } from 'legacy/shared/v1/styles/components/Form';
import SelectField from 'legacy/shared/v1/ui/fields/SelectField';
import { handleSelectBlur, handleSelectChange } from 'legacy/shared/v1/utilities/form';

const VehicleHaasAlertingSelect = ({
  formikProps: { values, touched, errors, options, setFieldValue, setTouched },
}) => {
  return (
    <>
      <SelectField
        name="haasPublicAlerting"
        placeholder={'Public Alerting'}
        defaultValue={
          haasPublicAlertingDropdownOptions.find((h) => h.value === values.haasPublicAlerting).label
        }
        disabled={false}
        onChange={handleSelectChange(setFieldValue, 'haasPublicAlerting')}
        onBlur={handleSelectBlur(setTouched, 'haasPublicAlerting', touched)}
        isClearable={false}
        isSearchable={false}
        options={haasPublicAlertingDropdownOptions}
        field="true"
      />
    </>
  );
};

export default VehicleHaasAlertingSelect;
