import { setApiDataReducer } from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/reducers/setApiDataReducer';
import { updateAlertsListingCountReducer } from 'legacy/features/alerts/services/providers/AlertsSummaryProvider/reducers/updateAlertsListingCountReducer';

// actions
export const SET_API_DATA = 'setApiDataAction';

export const UPDATE_ALERTS_LISTING_COUNT = 'updateAlertsListingCountAction';

// map actions to reducers
export const alertsSummaryActionReducerMap = {
  [SET_API_DATA]: setApiDataReducer,
  [UPDATE_ALERTS_LISTING_COUNT]: updateAlertsListingCountReducer,
};
