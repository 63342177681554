import {
  requiredValidator,
  regexValidator,
  alphanumericExtendedRegex,
  alphanumericExtendedRegexError,
  characterLimitValidator,
} from 'legacy/shared/v1/utilities/validators';

export const validateRecipientGroupFromAlert = (values) => {
  const recipientGroupNameErrors = recipientGroupValidators.recipientGroupNameField(
    values.recipientGroupNameField,
    36,
  );
  return {
    ...(recipientGroupNameErrors ? { recipientGroupNameField: recipientGroupNameErrors } : {}),
  };
};

const recipientGroupValidators = {
  recipientGroupNameField: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
};
