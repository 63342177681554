import { MapFocusControlsDiv } from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const MapFocusButton = ({ mapboxApi, bounds }) => {
  return (
    <MapFocusControlsDiv showFocusToggle liveMap>
      <div
        onClick={() => {
          mapboxApi.cameraForBounds(bounds, { padding: 50 });
          mapboxApi.fitBounds(bounds, { padding: 50, maxZoom: 15 });
        }}
      >
        <IconSvgComponent svgFileName={'map-focus'} alt="Focus" title="Focus" />
      </div>
    </MapFocusControlsDiv>
  );
};

export default MapFocusButton;
