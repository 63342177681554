import React from 'react';
import { connect } from 'react-redux';

import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

import { editConfiguration } from 'legacy/core/redux/configurations/actions';

/**
 * Styled Components
 */
import {
  Container,
  Title,
  CloseButton,
  ActionsContainer,
  ModalText,
} from 'legacy/shared/v1/styles/components/Modal';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';

export default connect(null, (dispatch) => ({
  archiveConfiguration: (configuration, configurationId) =>
    dispatch(editConfiguration(configuration, configurationId)),
}))(({ onSuccess, handleRequestClose, archiveConfiguration, configuration, isSubmitting }) => {
  return (
    <Container maxWidth={474} autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
      </CloseButton>
      <Title style={styles.title}>Archive Configuration</Title>
      <ModalText>
        Archiving this Configuration will remove it as a selectable option for Vehicles. This action
        does not remove this Configuration from Vehicles. Would you like to continue archiving?
      </ModalText>
      <ActionsContainer>
        <ButtonWithLoader
          isLoading={isSubmitting}
          confirmText={'Confirm'}
          loadingStyleProp={'submittingWithSpinner'}
          notLoadingStyleProp={'mediumAlt'}
          clickHandler={async () => {
            const success = await archiveConfiguration(
              {
                configName: configuration.configName,
                description: configuration.description,
                archived: 'true',
                action: 'Archive',
              },
              configuration.configId,
            );

            if (success) {
              onSuccess();
            }
          }}
        />
      </ActionsContainer>
    </Container>
  );
});

const styles = {
  title: {
    marginBottom: '30px',
  },
};
