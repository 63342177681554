import AddAlertRecipientRowButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/AddAlertRecipientRowButton';
import CreateRecipientGroupButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/CreateRecipientGroupButton';
import CustomAlertsButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/CustomAlertsButton';
import React from 'react';

const AlertRecipientButtonManager = ({ alertNotification, recipientRow }) => {
  let { alertNotificationGuid, alertRecipientRows } = alertNotification;
  let { recipientRowGuid, isFirstRecipientRow, isLastRecipientRow, recipientGroup, recipientUser } =
    recipientRow;

  if (recipientGroup) {
    return <CustomAlertsButton recipientRow={recipientRow} />;
  }

  // show add recipient group button if there is a recipient user name and is the last recipient row of more than one recipient row
  const showAddRecipientGroupButton =
    recipientUser?.recipientUserName && !isFirstRecipientRow && isLastRecipientRow;

  if (isLastRecipientRow) {
    return (
      <>
        {/* only show once a recipient is chosen */}
        {recipientUser?.recipientUserName && (
          <AddAlertRecipientRowButton
            alertNotificationGuid={alertNotificationGuid}
            recipientRowGuid={recipientRowGuid}
            title={'Add Another Recipient'}
          />
        )}
        {/* only show when multiple individual recipients are present */}
        {showAddRecipientGroupButton && (
          <CreateRecipientGroupButton
            alertNotificationGuid={alertNotificationGuid}
            recipientsToGroup={alertRecipientRows?.map((r) => r.recipientUser.recipientUserName)}
          />
        )}
      </>
    );
  }

  return null;
};

export default AlertRecipientButtonManager;
