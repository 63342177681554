import { Link } from 'react-router-dom';
import ManageRecipientGroupsActionMenu from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/ManageRecipientGroupsActionMenu';

export const manageRecipientGroupsSummaryTableColumns = [
  {
    title: 'Recipient Group Name',
    displayProperty: 'recipientGroupName',
    className: 'name',
    width: '30%',
    render({ rowData, cellJsx }) {
      return (
        <span
          className="vehicleNameAndIcon"
          title={'Recipient Group Name: ' + rowData.recipientGroupName}
        >
          <Link
            to={{
              pathname: `/editrecipientgroup/${encodeURIComponent(rowData.recipientGroupId)}`,
            }}
            style={{ textDecoration: 'none' }}
          >
            {cellJsx}
          </Link>
        </span>
      );
    },
  },
  {
    title: 'Members',
    displayProperty: 'formattedUserDisplayNames',
  },

  {
    title: '',
    className: 'action',
    searchEnabled: false,
    render({ rowData }) {
      return <ManageRecipientGroupsActionMenu recipientGroup={rowData} />;
    },
  },
];
