import NewMaintenanceItemForm from 'legacy/features/maintenance/components/MaintenanceBuilder/forms/NewMaintenanceItemForm';
import ReadOnlyMaintenanceItem from 'legacy/features/maintenance/components/MaintenanceBuilder/forms/ReadOnlyMaintenanceItem';
import { maintenanceTypeConstants } from 'legacy/features/maintenance/data/maintenanceTypeConstants';

const MaintenanceItemContainer = ({
  maintenanceItem,
  handleUpdateMaintenanceItem,
  handleRemoveMaintenanceItem,
  handleIsMaintenceBuilderValid,
  vehicle,
  orgUsesEngineHours,
  orgUsesEnhancedTelematics,
}) => {
  return maintenanceItem?.maintenanceType?.value === maintenanceTypeConstants.DIAGNOSTIC ? (
    <ReadOnlyMaintenanceItem
      maintenanceTypeLabel={maintenanceItem.maintenanceType?.label}
      createdDate={maintenanceItem.createdDate}
      diagnosticCode={maintenanceItem.diagnosticCode}
      diagnosticCodeDescription={maintenanceItem.diagnosticCodeDescription}
    />
  ) : (
    <NewMaintenanceItemForm
      maintenanceItem={maintenanceItem}
      handleUpdateMaintenanceItem={handleUpdateMaintenanceItem}
      handleRemoveMaintenanceItem={() => handleRemoveMaintenanceItem(maintenanceItem.id)}
      handleIsMaintenceBuilderValid={handleIsMaintenceBuilderValid}
      vehicle={vehicle}
      orgUsesEngineHours={orgUsesEngineHours}
      orgUsesEnhancedTelematics={orgUsesEnhancedTelematics}
    />
  );
};

export default MaintenanceItemContainer;
