/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependecies
 */
import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';

const ProfileWidget = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  float: right;
`;

export const ProfileWidgetHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;

  ${(props) =>
    props.borderleft &&
    `
		border-left: 1px solid rgba(151, 151, 151, 0.15);
  `};
  ${(props) =>
    props.notificationCount &&
    `
		padding: 0px 20px;
  `};

  ${(props) =>
    props.organizationName &&
    `
    min-width: 0;
  `};

  @media ${device.medium} {
    min-width: 80px;
    min-height: 70px;
    padding: 0px 20px;
  }
`;

export const ProfileWidgetGivenName = styled.span`
  color: ${colors.midnight};
  margin-right: 10px;
  display: none;

  @media ${device.medium} {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;

export const ProfileWidgetAvatar = styled.button`
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  font-weight: 900;
`;

export const ProfileWidgetNotification = styled.button`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  background: none;
  border: none;

  span {
    position: absolute;
    right: -15px;
    top: -15px;
  }
`;

export const ProfileWidgetCount = styled.span`
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 2px solid ${colors.cotton};
  background: ${colors.midnight};
  font-size: 10px;
  padding-top: 5px;
  font-weight: 700;
  text-align: center;
  color: ${colors.cotton};
`;

export const ProfileMenuAccount = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #1c1c1c;
  color: ${colors.cotton};
  padding: 20px;
`;

export const ProfileMenuButton = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 20px;
  background-color: ${colors.cotton};

  &:hover {
    background-color: ${colors.midnight};
    color: ${colors.cotton};
  }
`;

export default ProfileWidget;
