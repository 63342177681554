import { useContext } from 'react';

import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import VehicleOnlineTime from 'legacy/features/livemap/ui/VehicleOnlineTime.js';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'legacy/shared/v1/constants/vehicle';
import { ActionButtonText, TextButton } from 'legacy/shared/v1/styles/components/Button';
import Result, {
  HideIconDiv,
  ResultHead,
  ResultType,
  ShowHideDiv,
  VehicleNameDiv,
  VehicleNameText,
  VehicleTypeText,
} from 'legacy/shared/v1/styles/components/Result';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { getVehicleGpsCoordinates } from 'legacy/shared/v1/utilities/map/MapAnimationUtils';
import { createSearchResultItemName } from 'legacy/shared/v1/utilities/search';
import { sortAscendingAlphaAliasAndLabel } from 'legacy/shared/v1/utilities/vehicle';

const LivemapSidebarVehicleFilterResultsList = ({ vehicleSearchText, vehicles }) => {
  const {
    state: { hiddenVehicleIds },
    actions: {
      setSelectedVehicle,
      setFlyToCoordinates,
      setHiddenVehicleIds,
      setLivemapSidebarStates,
    },
  } = useContext(LivemapApi);

  const showVehicle = (vehicleId) => {
    setHiddenVehicleIds(hiddenVehicleIds.filter((v) => v !== vehicleId));
  };

  const hideVehicle = (vehicleId) => {
    setHiddenVehicleIds([...hiddenVehicleIds, vehicleId]);
  };
  const formatResult = (textToFormat) => {
    if (textToFormat.toLowerCase().includes(vehicleSearchText.toLowerCase())) {
      return createSearchResultItemName(vehicleSearchText, textToFormat);
    } else {
      return <span>{textToFormat}</span>;
    }
  };
  const getStatusMessage = (vehicle) => {
    return vehicle.meta.out_of_service !== true ? (
      <>
        {vehicleOnlineStatusViewData[vehicle.onlineStatus].title}
        {vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id &&
          ' / ' + vehicleRespondingStatusViewData[vehicle.respondingStatus].title}
      </>
    ) : (
      <>Out of Service</>
    );
  };

  return vehicles.sort(sortAscendingAlphaAliasAndLabel).map((vehicle) => (
    <Result
      style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
      key={vehicle.vehicle_id}
      onClick={(e) => {
        setSelectedVehicle(vehicle.vehicle_id);
        setFlyToCoordinates(getVehicleGpsCoordinates(vehicle));

        setLivemapSidebarStates({
          livemapMainSidebarOpen: false,
        });
        showVehicle(vehicle.vehicle_id);
      }}
    >
      <ResultHead>
        <ResultType
          statusStyle={
            vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id
              ? vehicleRespondingStatusViewData[vehicle.respondingStatus].style
              : vehicleOnlineStatusViewData[vehicle.onlineStatus].style
          }
        >
          {vehicle.searching_for_gps === undefined || vehicle.searching_for_gps ? (
            <span>Searching for GPS signal...</span>
          ) : (
            getStatusMessage(vehicle)
          )}

          <ShowHideDiv>
            <TextButton
              onClick={(e) => {
                hiddenVehicleIds.includes(vehicle.vehicle_id)
                  ? showVehicle(vehicle.vehicle_id)
                  : hideVehicle(vehicle.vehicle_id);
                e.stopPropagation();
              }}
            >
              <ActionButtonText
                title={
                  hiddenVehicleIds.includes(vehicle.vehicle_id)
                    ? 'Show this vehicle on the live map.'
                    : 'Hide this vehicle from the live map.'
                }
              >
                {hiddenVehicleIds.includes(vehicle.vehicle_id) ? 'SHOW' : 'HIDE'}{' '}
              </ActionButtonText>
            </TextButton>
          </ShowHideDiv>
        </ResultType>
        <VehicleNameDiv>
          <VehicleNameText
            title={
              vehicle.meta.alias
                ? `Alias: ${vehicle.meta.alias || ''}, Vehicle Name: ${vehicle.meta.label}`
                : ` Vehicle Name: ${vehicle.meta.label}`
            }
          >
            {vehicleSearchText
              ? formatResult(
                  vehicle.meta.alias
                    ? `${vehicle.meta.alias} (${vehicle.meta.label})`
                    : vehicle.meta.label,
                )
              : vehicle.meta.alias
                ? `${vehicle.meta.alias} (${vehicle.meta.label})`
                : vehicle.meta.label}
          </VehicleNameText>
          {hiddenVehicleIds.includes(vehicle.vehicle_id) ? (
            <HideIconDiv
              onClick={(e) => e.stopPropagation()}
              title="This vehicle is currently hidden from the live map."
            >
              <IconSvgComponent svgFileName={'hide-icon'} alt="Search" />
            </HideIconDiv>
          ) : null}
        </VehicleNameDiv>
        <VehicleTypeText>
          {vehicleSearchText
            ? formatResult(vehicleTypeViewData[vehicle.meta.vehicle_type].title)
            : vehicleTypeViewData[vehicle.meta.vehicle_type].title}
        </VehicleTypeText>
        <VehicleOnlineTime
          isOnline={vehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id}
          onlineStartTime={vehicle.gps.current_state_start_time}
          lastSeen={vehicle.gps.timestamp_gps}
        />
      </ResultHead>
    </Result>
  ));
};

export default LivemapSidebarVehicleFilterResultsList;
