import { getVehiclePlaybackDataApi } from 'legacy/features/playback/services/api/get/getVehiclePlaybackDataApi';
import useGenericQuery from 'legacy/shared/v2/hooks/reactQuery/useGenericQuery';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';

const useFetchPlaybackDataQuery = ({ requestParams, autoRun }) => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getVehiclePlaybackData',
    apiFn: getVehiclePlaybackDataApi,
    autoRun,
    params: requestParams,
  });

  return {
    queryResult,
    queryData: {
      locationData: queryResult?.data?.locationData?.sort((p1, p2) =>
        sortAscendingAlpha(p1.time, p2.time),
      ),
      summary: queryResult?.data?.summary,
    },
    queryRefetch: queryResult?.refetch,
    queryKey,
  };
};

export default useFetchPlaybackDataQuery;
