import React, { useState, useEffect, useRef } from 'react';

/**
 * @ Styled Components
 */

import { VehicleEditQRScannerButton } from 'legacy/shared/v1/styles/components/Button';

/**
 * @ Components
 */
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

import QRCodeReader from 'legacy/shared/v1/ui/qr/QRCodeReader';
import MaskedField from 'legacy/shared/v1/ui/fields/MaskedField';
import ResetVsgRegistrationModal from 'legacy/features/vehicles/components/modals/ResetVsgRegistrationModal';
import VerifyVsgIdModal from 'legacy/features/vehicles/components/modals/VerifyVsgIdModal';

const VehicleDeviceIdField = ({
  formikProps: { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldError },
  resetPreferencesModalActive,
  handleConfirmVsgResetPrefencesAccepted,
  handleDeviceIdDirty,
  handleConfirmVerifyVsgIdAccepted,
  verifyVsgIdModalActive,
}) => {
  const deviceIdRef = useRef();
  const [isDeviceScannerOpen, setDeviceScannerOpen] = useState(false);

  const toggleDeviceScanner = () => setDeviceScannerOpen((prevState) => !prevState);

  const handleQRCodeScan = (setFieldValue, fieldName, ref, toggleHandler) => (scannedResult) => {
    scannedResult = scannedResult.replace(/(\r\n|\n|\r)/gm, '');
    setFieldValue(fieldName, scannedResult, false);
    setFieldError(fieldName, false);
    toggleHandler();
  };

  return (
    <>
      {resetPreferencesModalActive ? (
        <ResetVsgRegistrationModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVsgResetPrefencesAccepted(isAccepted);
          }}
        />
      ) : null}
      {verifyVsgIdModalActive ? (
        <VerifyVsgIdModal
          handleUserResponse={(isAccepted) => {
            handleConfirmVerifyVsgIdAccepted(isAccepted);
          }}
        />
      ) : null}
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: '4' }}>
          <MaskedField
            name="duid"
            placeholder="VSG Registration ID"
            value={values.duid.toLowerCase()}
            disabled={false}
            touched={touched.duid}
            validationError={errors.duid}
            handleChange={(ev) => {
              handleDeviceIdDirty(ev.target.value !== values.duid);
              return handleChange(ev);
            }}
            handleBlur={handleBlur}
            mask="********-****-****-****-************"
            required
            smallerTextMobile={true}
            suffixIcon={true}
            innerRef={deviceIdRef}
            forceAnimated={!!values.duid}
          />
        </div>
        <div>
          {(values.duid.length < 9 || values.duid === '________-____-____-____-____________') && (
            <VehicleEditQRScannerButton type="button" onClick={toggleDeviceScanner}>
              <IconSvgComponent
                svgFileName={'qr-scanner'}
                title="Scan QR code"
                alt="Scan QR code"
              />
            </VehicleEditQRScannerButton>
          )}
        </div>
      </div>
      <QRCodeReader
        isOpen={isDeviceScannerOpen}
        handleScan={handleQRCodeScan(setFieldValue, 'duid', deviceIdRef, toggleDeviceScanner)}
      />
    </>
  );
};

export default VehicleDeviceIdField;
