import RemoveAdditionalNotificationButton from 'legacy/features/alerts/ui/components/AlertNotificationBuilder/buttons/RemoveAdditionalNotificationButton';
import {
  AdditionalNoficationInfoAndRemoveButtonWrapper,
  AdditionalNotificationHeaderWrapper,
  InfoIconWrapper,
  NotificationHeader,
} from 'legacy/shared/v1/styles/components/ManageAlerts';
import { SectionDivider } from 'legacy/shared/v1/styles/components/SectionDivider';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const AdditionalNotificationHeader = ({ notificationIndex }) => (
  <AdditionalNotificationHeaderWrapper>
    <SectionDivider />
    <AdditionalNoficationInfoAndRemoveButtonWrapper>
      <NotificationHeader nonPrimary>Additional Notification</NotificationHeader>
      <InfoIconWrapper>
        <IconSvgComponent
          svgFileName="info"
          title="Additional notifications are sent when the alert condition is true"
        />
      </InfoIconWrapper>
      <RemoveAdditionalNotificationButton notificationIndex={notificationIndex} />
    </AdditionalNoficationInfoAndRemoveButtonWrapper>
  </AdditionalNotificationHeaderWrapper>
);

export default AdditionalNotificationHeader;
