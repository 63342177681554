import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  recipientGroupDeleteError,
  recipientGroupDeleteSuccess,
} from 'legacy/features/recipientGroups/utilities/notifications';
import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { deleteRecipientGroupsApi } from 'legacy/features/recipientGroups/api/delete/deleteRecipientGroupsApi';
import useGenericMutation from 'legacy/shared/v2/hooks/reactQuery/useGenericMutation';

export const useDeleteRecipientGroupMutation = () => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const mutation = useGenericMutation({
    apiFn: deleteRecipientGroupsApi,
    onSuccessCallback: () => {
      dispatch(recipientGroupDeleteSuccess());
      queryClient.invalidateQueries('ManageRecipientGroupsSummary'), dispatch(alertDeleteSuccess());
    },

    onErrorCallback: (err) => {
      dispatch(recipientGroupDeleteError());
      logWcpError(err);
    },
  });

  return mutation;
};

export default useDeleteRecipientGroupMutation;
