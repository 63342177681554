import { Checkbox } from 'legacy/shared/v1/styles/components/Checkbox';
import CheckboxInner from 'legacy/shared/v1/styles/components/CheckboxInner';
import { TableCheckboxFilterWrapper } from 'legacy/shared/v1/styles/components/PageList';
import { TableFilterSpan } from 'legacy/shared/v1/styles/components/Table';

const DevicesSummaryFilterCheckbox = ({ checked, handleChecked }) => {
  return (
    <TableCheckboxFilterWrapper maintenance>
      <Checkbox singleCheck>
        <CheckboxInner isChecked={checked} onClick={handleChecked} />
      </Checkbox>
      <TableFilterSpan>Only show unallocated devices</TableFilterSpan>
    </TableCheckboxFilterWrapper>
  );
};

export default DevicesSummaryFilterCheckbox;
