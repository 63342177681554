import { useState } from 'react';

import ManageRemindersModal from 'legacy/features/maintenance/components/MaintenanceSummary/modals/ManageRemindersModal';
import { ActionButtonText, ActionButtonWithIcon } from 'legacy/shared/v1/styles/components/Button';

const ManageRemindersModalButton = () => {
  // modals
  const [manageRemindersModalData, setManageRemindersModalData] = useState({
    show: false,
  });
  return (
    <>
      <ActionButtonWithIcon
        noIcon
        onClick={() =>
          setManageRemindersModalData((prevState) => ({
            ...prevState,
            show: true,
          }))
        }
      >
        <ActionButtonText>Manage Reminders </ActionButtonText>
      </ActionButtonWithIcon>
      <ManageRemindersModal
        modalData={manageRemindersModalData}
        handleCloseModal={() =>
          setManageRemindersModalData((prevState) => ({ ...prevState, show: false }))
        }
      />
    </>
  );
};

export default ManageRemindersModalButton;
