import { createAction } from 'redux-actions';

import { fsMapModeLogin } from 'legacy/core/api/login';
import { submitLogout } from 'legacy/core/redux/user/actions';
import { variableScopeAllowedUserRoles } from 'legacy/shared/v1/constants/users';
import {
  getAccessToken,
  setLastMapLocationInLocal,
  updateAuthStore,
} from 'legacy/shared/v1/utilities/localStore';

// global modal
export const toggleOverlay = createAction('TOGGLE_OVERLAY');
export const hideOverlay = createAction('HIDE_OVERLAY');
export const displayOverlay = createAction('DISPLAY_OVERLAY');
export const toggleGlobalOverlay = createAction('TOGGLE_GLOBAL_OVERLAY');
export const closeModal = createAction('CLOSE_MODAL');
export const closeGlobalModal = createAction('CLOSE_GLOBAL_MODAL');
export const closeDefaultModal = createAction('CLOSE_DEFAULT_MODAL');
export const modalFormChanged = createAction('MODAL_FORM_CHANGED');

// Organizations //
export const toggleCreateOrganizationModal = createAction('TOGGLE_CREATE_ORGANIZATION_MODAL');
export const toggleRenameOrganizationModal = createAction('TOGGLE_RENAME_ORGANIZATION_MODAL');
export const toggleEditOrganizationModal = createAction('TOGGLE_EDIT_ORGANIZATION_MODAL');
export const toggleDeleteOrganizationModal = createAction('TOGGLE_DELETE_ORGANIZATION_MODAL');
export const toggleDeactivateOrganizationModal = createAction(
  'TOGGLE_DEACTIVATE_ORGANIZATION_MODAL',
);

// Users //
export const toggleCreateUserModal = createAction('TOGGLE_CREATE_USER_MODAL');
export const toggleEditUserModal = createAction('TOGGLE_EDIT_USER_MODAL');
export const toggleDeleteUserModal = createAction('TOGGLE_DELETE_USER_MODAL');
export const toggleDeactivateUserModal = createAction('TOGGLE_DEACTIVATE_USER_MODAL');
export const toggleUserLimitModal = createAction('TOGGLE_USER_LIMIT_MODAL');
export const toggleUserCreationActiveLimitModal = createAction('TOGGLE_USER_CREATE_LIMIT_MODAL');

// Notifications //
export const addNotification = createAction('ADD_NOTIFICATION');
export const successAddNotification = createAction('SUCCESS_ADD_NOTIFICATION');
export const successRemoveNotification = createAction('SUCCESS_REMOVE_NOTIFICATION');

// Alert Bar //
export const setSubscriptionExpiryAlertBar = createAction('SET_EXPIRY_ALERT_BAR');
export const setFirmwareAlertCount = createAction('SET_FIRMWARE_ALERT_COUNT');
//export const dismissAlert = createAction('DISMISS_ALERT');

// Login Message //
export const updateLoginMessage = createAction('UPDATE_LOGIN_MESSAGE');
export const clearLoginMessage = createAction('CLEAR_LOGIN_MESSAGE');

// Global //
export const toggleSidebarAlerts = createAction('TOGGLE_SIDEBAR_ALERTS');

// fs map mode
export const setFullscreenMapModeOn = createAction('SET_FULLSCREEN_MAP_MODE_ON');
export const setFullscreenMapModeOff = createAction('SET_FULLSCREEN_MAP_MODE_OFF');

// new release notification
export const showNewReleaseNotification = createAction('SHOW_NEW_RELEASE_NOTIFICATION');
export const hideNewReleaseNotification = createAction('HIDE_NEW_RELEASE_NOTIFICATION');

export const showSessionTimeoutNotification = createAction('SHOW_TIMEOUT_NOTIFICATION');
export const hideSessionTimeoutNotification = createAction('HIDE_TIMEOUT_NOTIFICATION');

export const toggleFullscreenMapMode = (setFullscreen) => {
  return async (dispatch, getState) => {
    if (setFullscreen) {
      try {
        // get current token
        const accessToken = getAccessToken();

        const userState = getState().user;
        const filteredOrganizationId = variableScopeAllowedUserRoles.includes(
          userState.user.userRole,
        )
          ? userState.filteredOrganizationId
          : userState.user.organizationId;

        // login with current access token (this also revokes original user token)
        const { response } = await fsMapModeLogin({
          accessToken,
          organizationId: filteredOrganizationId,
        });

        // // revoke original user token
        // await dispatch(submitLogout({ isTimedOut: false }));

        await dispatch(setFullscreenMapModeOn());

        // save "map user" credentials
        if (response.message.AuthenticationResult) {
          const { AuthenticationResult } = response.message;
          updateAuthStore({ ...AuthenticationResult });
          setLastMapLocationInLocal(null); // reset map location
        }

        return true;
      } catch (e) {
        console.log(e);

        return false;
      }
    } else {
      // TODO trigger "are you sure" modal

      // revoke map token
      await dispatch(submitLogout({ isTimedOut: false }));
      await dispatch(setFullscreenMapModeOff());
    }
  };
};
