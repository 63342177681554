/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';

const NavTrigger = styled.button`
  position: relative;
  z-index: 10;
  width: 20px;
  height: 14px;
  margin-left: 18px;
  margin-right: 20px;
  border: none;
  background: none;
  transition: all 0.3s;

  span:nth-child(1) {
    top: 0;
    height: 3px;
  }

  span:nth-child(2) {
    top: 6px;
    height: 3px;
  }

  span:nth-child(3) {
    top: 12px;
    height: 3px;
  }

  @media ${device.medium} {
    margin-right: 40px;
  }
`;

export const NavTriggerItem = styled.span`
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
  background: ${colors.midnight};
  transition:
    all 0.3s,
    top 0.2s 0.25s,
    left 0.2s 0.25s,
    opacity 0.2s 0.25s;
  -webkit-transform: 0.2s 0s;
  -ms-transform: 0.2s 0s;
  transform: 0.2s 0s;
`;

export default NavTrigger;
