import { granularityOptions } from 'legacy/features/playback/data/ui/dropdown/granularityOptions';
import { playbackSpeedOptions } from 'legacy/features/playback/data/ui/dropdown/playbackSpeedOptions';
import { vehiclePlaybackActionReducerMap } from 'legacy/features/playback/state/provider/mapping/vehiclePlaybackActionReducerMap';
import { getYesterday } from 'legacy/shared/v1/utilities/time';

// get yesterday's date
let yesterday = getYesterday();

export const vehiclePlaybackProviderInitialSetup = {
  actionReducerMap: vehiclePlaybackActionReducerMap,
  initialState: {
    // all vehicles
    allVehicles: null,

    // sidebar
    playbackSidebar: {
      isOpen: true,
    },

    // data from API
    playbackData: {
      selectedVehicle: null,
      locationData: null,
      summary: null,
    },

    // controls for playback feature
    playbackAnimationControls: {
      currentPlaybackDataIndex: 0,
      selectedPlaybackDataIndex: null,
      selectedPlaybackSpeed: playbackSpeedOptions.find((o) => o.label === 'Normal'),
      playbackSpeed: null,
      playbackToggle: false,
    },

    // the form for requesting playback data
    playbackRequestForm: {
      readOnly: false,
      selectedVehicleId: null,
      selectedGranularity: granularityOptions[0].value,
      startDate: yesterday,
      endDate: yesterday,
      startTime: 0,
      endTime: 23,
    },
  },
};
