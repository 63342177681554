import { getAlertLogsApi } from 'legacy/features/alerts/services/api/get/getAlertLogsApi';
import useGenericQuery from 'legacy/shared/v2/hooks/reactQuery/useGenericQuery';

const useFetchAlertLogsQuery = ({ startDate, endDate, alertId }) => {
  const [queryResult, queryKey] = useGenericQuery({
    apiFn: getAlertLogsApi,
    queryKeyPrefix: 'getAlertLogsApi',
    autoRun: false,
    params: { startDate, endDate, alertId },
  });
  return [queryResult.data, queryResult.refetch, queryKey];
};

export default useFetchAlertLogsQuery;
