const { apiCall } = require('legacy/core/api/API');

export const getSubscriptionDevices = async (filteredOrganizationId) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/subscriptions/devices`,
    queryStringParameters: {
      organization_id: `${filteredOrganizationId}`,
    },
  });

  return apiResponse.data.message;
};
