import useVehiclePlaybackProviderContext from 'legacy/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { ExportButtonWrapper } from 'legacy/shared/v1/styles/components/VehiclePlayback';
import ExportCsvButton from 'legacy/shared/v1/widgets/ExportCsvButton';
import { getLocalTimeStringFromZuluTimeString } from 'legacy/shared/v1/utilities/time';

const VehiclePlaybackExportCsvButton = () => {
  const {
    state: {
      playbackData: { selectedVehicle, locationData },
    },
  } = useVehiclePlaybackProviderContext();

  return (
    selectedVehicle && (
      <ExportButtonWrapper>
        <ExportCsvButton
          fileNamePrefix={`PlaybackDataFor_${selectedVehicle.meta.label}`}
          exportData={locationData.map(({ lat, long, time, speed, status }) => ({
            UTC_Time: time,
            Local_Time: getLocalTimeStringFromZuluTimeString(time),
            Latitude: lat,
            Longitude: long,
            Speed: speed,
            Status: status,
          }))}
        />
      </ExportButtonWrapper>
    )
  );
};

export default VehiclePlaybackExportCsvButton;
