export const configurationUpdatesLogsTableColumns = [
  {
    title: 'Timestamp',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '25%',
  },
  {
    title: 'Vehicle Name',
    displayProperty: 'vehicle_name',
    width: '15%',
  },
  {
    title: 'Config Name',
    displayProperty: 'config_name',
    width: '25%',
  },
  {
    displayProperty: 'statusDisplayName',
    width: '15%',
  },
  {
    title: 'Authorizer',
    displayProperty: 'authorizerDisplay',
    width: '20%',
  },
];
