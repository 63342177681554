import { mapDevicesAndBillingDataToCsv } from 'legacy/features/billing/services/mapping/devicesAndBillingExportMapping';
import useDevicesAndBillingSummaryProviderContext from 'legacy/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import ExportCsvButton from 'legacy/shared/v1/widgets/ExportCsvButton';

const DevicesAndBillingExportButton = () => {
  const {
    state: { deviceSubscriptions },
  } = useDevicesAndBillingSummaryProviderContext();

  const exportData = mapDevicesAndBillingDataToCsv({ deviceSubscriptions });

  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={'wcp_devices_and_billing_export'}
      id="devicesAndSubscriptionsExportButton"
    />
  );
};

export default DevicesAndBillingExportButton;
