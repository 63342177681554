import { findAlertNotificationSelector } from 'legacy/features/alerts/services/providers/AlertFormsProvider/utilities/findAlertNotificationSelector';
import { validateAndExtractPayload } from 'legacy/shared/v2/utilities/reducerHelpers/validateAndExtractPayload';

// removeAlertRecipientAction → removeAlertRecipientReducer
export const removeAlertRecipientReducer = (state, payload) => {
  const { alertNotificationGuid, recipientRowGuid } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertNotificationGuid', 'recipientRowGuid'],
  });

  // find the alert notification
  const alertNotification = findAlertNotificationSelector(state, alertNotificationGuid);

  // find the recipient row and remove it
  alertNotification.alertRecipientRows = alertNotification.alertRecipientRows.filter(
    (r) => r.recipientRowGuid !== recipientRowGuid,
  );

  return {
    ...state,
  };
};
