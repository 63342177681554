import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getConfigTypeLabel } from 'legacy/features/configurations/utilities/configUtilities';

import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import Button from 'legacy/shared/v1/styles/components/Button';
import FormWrapper, {
  Form,
  FormHead,
  FormIcon,
  FormTitle,
  FormRow,
  FormMessage,
  FieldError,
} from 'legacy/shared/v1/styles/components/Form';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import SelectField from 'legacy/shared/v1/ui/fields/SelectField';
import { ModalText } from 'legacy/shared/v1/styles/components/Modal';
import { fetchConfigurations } from 'legacy/core/redux/configurations/actions';
import useAPIData from 'legacy/shared/v1/hooks/useAPIData';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import { handleSelectChange, handleSelectBlur } from 'legacy/shared/v1/utilities/form';
import { validateSelectedConfiguration } from 'legacy/shared/v1/utilities/validators';
import { sortAscendingAlphaOptions } from 'legacy/shared/v1/utilities/vehicle';
import { getGuardedObjectProp } from 'legacy/shared/v1/utilities/general';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getConfigurations } from 'legacy/core/api/configuration';

const AssignConfigurationToGroupContent = ({ formikProps, vehicles, group, setDisabled }) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const fetchConfigurationsQuery = useQuery({
    queryKey: ['fetchConfigurationsQuery'],
    queryFn: async () => await getConfigurations(filteredOrganizationId),
    select: (response) =>
      response.response.message.map((c) => ({
        ...c,
        deviceCount:
          c.supportedDevices?.length !== null && c.supportedDevices?.length !== undefined
            ? c.supportedDevices?.length
            : 0,
      })),
  });
  const configurations = fetchConfigurationsQuery.data;
  const configurationsLoaded =
    fetchConfigurationsQuery.data && !fetchConfigurationsQuery.isFetching;
  const groupVehicles = Object.values(vehicles).filter((v) => {
    return group?.vehicleId?.includes(v.vehicle_id);
  });
  let totalIncompatibleVehicles = [];
  useEffect(() => {
    setDisabled(totalIncompatibleVehicles.length === groupVehicles.length);
  }, [totalIncompatibleVehicles, groupVehicles]);

  const renderInfoMessage = (values) => {
    let selectedConfig = configurations.find((c) => c.configId === values.selectedConfiguration);
    if (!selectedConfig) return '';

    let vehiclesWithControlSystem = groupVehicles.filter((v) =>
      getGuardedObjectProp('device.CenComCore.product_id', v),
    );

    let vehiclesWithoutControlSystem = groupVehicles.filter(
      (v) => !getGuardedObjectProp('device.CenComCore.product_id', v),
    );
    console.log('vehiclesWithoutControlSystem', vehiclesWithoutControlSystem);

    let inCompatibleVehicles = [];

    if (selectedConfig?.acmProductId) {
      if (selectedConfig.acmProductId === 13) {
        // core
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          (v) => v.device?.CenComCore?.product_id !== 13,
        );
      } else if (selectedConfig.acmProductId === 38) {
        // core-r
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          (v) => v.device?.CenComCore?.product_id !== 38,
        );
      } else if (selectedConfig.acmProductId === 51) {
        // core-sh
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          (v) => v.device?.CenComCore?.product_id !== 51,
        );
      } else if (selectedConfig.acmProductId === 52) {
        // core-s 24v
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          (v) => v.device?.CenComCore?.product_id !== 52,
        );
      } else if (selectedConfig.acmProductId === 43) {
        // core-s
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          (v) => v.device?.CenComCore?.product_id !== 43,
        );
      }
    }

    totalIncompatibleVehicles = [...vehiclesWithoutControlSystem, ...inCompatibleVehicles];
    return (
      <div title={totalIncompatibleVehicles.map((v) => v.meta.label).join(', ')}>
        <span style={{ fontWeight: 'bold' }}>ⓘ </span>
        {totalIncompatibleVehicles.length}
        <span> of </span> {groupVehicles.length} <span>vehicles will</span>{' '}
        <span style={{ fontWeight: 'bold' }}>NOT</span>{' '}
        <span>
          receive this configuration because either the control system is not compatible with the
          selected configuration, or the control system is not recognized by the VSG.
        </span>
      </div>
    );
  };
  const filteredOptions =
    configurations?.length > 0
      ? configurations
          .filter((configuration) => configuration.archived !== 'true')
          .filter((configuration) => configuration.acmProductId)
          .map((configuration) => ({
            value: configuration.configId,
            label: `${configuration.configName} (${getConfigTypeLabel(
              configuration.acmProductId,
            )})`,
          }))
          .sort(sortAscendingAlphaOptions)
      : [];
  return configurationsLoaded ? (
    <ModalText>
      <FormRow>
        <SelectField
          name="configuration"
          placeholder={'Configuration'}
          options={filteredOptions}
          onChange={handleSelectChange(formikProps.setFieldValue, 'selectedConfiguration')}
          onBlur={handleSelectBlur(
            formikProps.setTouched,
            'selectedConfiguration',
            formikProps.touched,
          )}
          validationError={formikProps.errors.selectedConfiguration}
          isClearable={false}
          isSearchable={false}
          field="true"
          required
        />
      </FormRow>
      <FieldError>
        {formikProps.touched.selectedConfiguration && formikProps.errors.selectedConfiguration}
      </FieldError>
      Assigning the above configuration will schedule an OTA update for each vehicle in the
      following group:
      <TruncatedText style={styles.groupNameText}>{group.group_name}</TruncatedText>{' '}
      <div>{renderInfoMessage(formikProps.values)}</div>
    </ModalText>
  ) : (
    <LoadingOverlay />
  );
};

export default AssignConfigurationToGroupContent;

const styles = {
  formHead: {
    marginBottom: 42,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  groupNameText: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 30,
  },
};
