import { useEffect, useState } from 'react';

import { MAPBOX_STYLE } from 'legacy/shared/v1/constants/map';
import { MapLayersControlsDiv } from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const MapStyleToggleButton = ({ setMapStyle }) => {
  const [mapStyleState, setMapStyleState] = useState();

  const toggleMapStyle = () => {
    let newMapStyle =
      mapStyleState === MAPBOX_STYLE.SATELLITE_MODE_URL
        ? MAPBOX_STYLE.STREET_MODE_URL
        : MAPBOX_STYLE.SATELLITE_MODE_URL;
    setMapStyleState(newMapStyle);
  };

  useEffect(() => {
    if (mapStyleState) setMapStyle(mapStyleState);
  }, [mapStyleState]);

  return (
    <MapLayersControlsDiv showSatelliteLayerToggle liveMap>
      <div onClick={toggleMapStyle}>
        <IconSvgComponent
          svgFileName={'map-layers'}
          alt="Toggle Streets/Satellite Layers"
          title="Toggle Streets/Satellite Layers"
        />
      </div>
    </MapLayersControlsDiv>
  );
};

export default MapStyleToggleButton;
