import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import ProgressBar from 'legacy/shared/v1/components/ProgressBar';
import { firmwareStatus } from 'legacy/shared/v1/constants/firmwareStatus';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { disabledCondition } from 'legacy/features/firmware/constants/constants';
import { disabledConditionToMessage } from 'legacy/features/firmware/utilities/disabledLogic';

const FirmwareUpdateTableByVehicle = ({
  deviceUpdates,
  handleUpdateFirmware,
  updatePostLoading,
  vehicle,
  handleUpdateListingCount,
  updatesInProgress,
}) => {
  deviceUpdates.forEach((du) => {
    du.fwStatusDisplayName = firmwareStatus[du.firmware_status].displayName;
    du.fwStatusProgress = firmwareStatus[du.firmware_status].progress;
    du.fwStatusButtonText = firmwareStatus[du.firmware_status].buttonText
      ? firmwareStatus[du.firmware_status].buttonText
      : null;
  });

  return (
    <>
      <div>
        <WcpTable
          tableClassName="table-simple"
          tableSourceData={deviceUpdates}
          rowKey={(record, i) => i}
          emptyText="No data to display."
          setListingCount={handleUpdateListingCount}
          columns={[
            {
              title: 'Device Type',
              displayProperty: 'product_name',
            },
            {
              title: 'Device Id',
              displayProperty: 'device_id',
            },
            {
              title: 'Initial Firmware Version',
              displayProperty: 'version_initial',
            },

            {
              title: 'Target Firmware Version',
              displayProperty: 'version',
            },
            {
              title: 'Firmware Status',
              displayProperty: 'fwStatusDisplayName',
            },
            {
              title: 'Update Progress',
              displayProperty: 'fwStatusProgress',
              width: '15%',
              render: ({ rowData }) => {
                if (rowData.has_never_started_ota) {
                  return <></>;
                } else {
                  return <ProgressBar progress={rowData.fwStatusProgress} />;
                }
              },
            },
            {
              title: 'Update Action',
              displayProperty: 'fwStatusButtonText',
              className: 'action',
              searchEnabled: false,
              render: ({ rowData }) => {
                let disabledReason = !vehicle?.meta.online
                  ? disabledCondition.OFFLINE
                  : vehicle?.gps.fr_mode_enabled === 1
                    ? disabledCondition.RESPONDING
                    : updatesInProgress || updatePostLoading
                      ? disabledCondition.UPDATING
                      : null;
                return rowData.fwStatusButtonText !== null && rowData.fwStatusButtonText !== '' ? (
                  <PermissionProtectedElement
                    requiredPermissions={[permissionData.editotafirmware]}
                  >
                    <ButtonWithLoader
                      disabled={
                        !vehicle?.meta.online ||
                        vehicle?.gps.fr_mode_enabled === 1 ||
                        updatesInProgress ||
                        updatePostLoading
                      }
                      loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                      notLoadingStyleProp={'otaUpdate'}
                      confirmText={rowData.fwStatusButtonText}
                      isLoading={rowData.device_id === updatePostLoading}
                      clickHandler={async () => {
                        await handleUpdateFirmware(rowData);
                      }}
                      title={disabledConditionToMessage[disabledReason]}
                    />
                  </PermissionProtectedElement>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
export default FirmwareUpdateTableByVehicle;
