import {
  addHoursToDateTime,
  getShortDateInIsoFormat,
  getUtc,
} from 'legacy/shared/v1/utilities/time';

export const convertDateField = (dateField, timeField) => {
  return getUtc(
    addHoursToDateTime({
      dateTime: getShortDateInIsoFormat(dateField),
      hours: timeField,
    }),
  );
};
