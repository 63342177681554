import { useQueryClient } from '@tanstack/react-query';
import { deleteConfiguration } from 'legacy/core/api/configuration';
import useGenericMutation from 'legacy/shared/v2/hooks/reactQuery/useGenericMutation';
export const useDeleteConfigMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useGenericMutation({
    apiFn: deleteConfiguration,
    onSuccessCallback: () => {},

    onErrorCallback: (err) => {},
  });

  return mutation;
};
