import React, { useState } from 'react';
import { LockUnlockButton } from 'legacy/shared/v1/styles/components/Icons';
import { LockButtonWrapper } from 'legacy/shared/v1/styles/components/PageList';
import { LOCK_OPERATION } from 'legacy/features/vehicles/hooks/useLockVehicleMutation';
import ToggleLockVehicleModal from 'legacy/features/vehicles/components/modals/ToggleLockVehicleModal';

const ToggleLockWidget = ({ vehiclesOrVehicle }) => {
  const [toggleLockModalData, setToggleLockModalData] = useState({
    show: false,
    vehiclesOrVehicle,
  });
  return (
    <>
      <LockButtonWrapper>
        <LockUnlockButton
          title="Lock All Vehicles"
          alt="Lock All Vehicles"
          svgFileName={'config-lock'}
          onClick={() =>
            setToggleLockModalData((prevState) => ({
              ...prevState,
              lockOperation: LOCK_OPERATION.LOCK,
              vehiclesOrVehicle,
              show: true,
            }))
          }
        />
        <LockUnlockButton
          title="Unlock All Vehicles"
          alt="Unlock All Vehicles"
          svgFileName={'config-unlock'}
          onClick={() =>
            setToggleLockModalData((prevState) => ({
              ...prevState,
              lockOperation: LOCK_OPERATION.UNLOCK,
              vehiclesOrVehicle,
              show: true,
            }))
          }
        />
      </LockButtonWrapper>

      <ToggleLockVehicleModal
        modalData={toggleLockModalData}
        handleCloseModal={() =>
          setToggleLockModalData((prevState) => ({
            ...prevState,
            show: false,
            vehiclesOrVehicle,
          }))
        }
      />
    </>
  );
};

export default ToggleLockWidget;
