import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';

export const firmwareUpdatesLogsTableColumns = [
  {
    title: 'Timestamp',
    displayProperty: 'formattedTime',
    sortProperty: 'timestamp',
    width: '25%',
  },
  {
    title: 'Vehicle Name',
    displayProperty: 'vehicle_name',
    width: '15%',
  },
  {
    title: 'Device Name',
    displayProperty: 'product_name',
    width: '20%',
  },
  {
    title: 'Device ID',
    displayProperty: 'device_id',
    width: '10%',
    render({ rowData, cellJsx }) {
      return (
        <span title={'Device ID: ' + rowData.device_id}>
          <TruncatedTextWithWrap>{cellJsx} </TruncatedTextWithWrap>
        </span>
      );
    },
  },
  {
    title: 'Previous Version',
    displayProperty: 'version_starting',
    width: '10%',
  },
  {
    title: 'Version',
    displayProperty: 'version',
    width: '10%',
  },
  {
    displayProperty: 'statusDisplayName',
    width: '15%',
  },
  {
    title: 'Authorizer',
    displayProperty: 'authorizerDisplay',
    width: '20%',
  },
];
