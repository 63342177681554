import React, { useState, useEffect, useContext } from 'react';
import SortableTableWithoutPagination from 'legacy/shared/v1/ui/table/SortableTableWithoutPagination';
import {
  permissionData,
  userRoleOptionsArray as userRoleOptions,
} from 'legacy/shared/v1/constants/users';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import { TableAvatar } from 'legacy/shared/v1/styles/components/TableAvatar';
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from 'legacy/shared/v1/styles/components/Checkbox';
import { OrgUserPermissionsContextApi } from 'legacy/features/organizations/helpers/context';

const PermissionsTable = ({ organizationUsers, localUserPermissions }) => {
  const { updatelocalUserPermissions, onChangeViewCheckbox, onChangeEditCheckbox } = useContext(
    OrgUserPermissionsContextApi,
  );

  const [selectAllVMap, setSelectAllVMap] = useState(false);
  const [selectAllVFleet, setSelectAllVFleet] = useState(false);
  const [selectAllEFleet, setSelectAllEFleet] = useState(false);
  const [selectAllVFware, setSelectAllVFware] = useState(false);
  const [selectAllEFware, setSelectAllEFware] = useState(false);
  const [selectAllVConfig, setSelectAllVConfig] = useState(false);
  const [selectAllEConfig, setSelectAllEConfig] = useState(false);
  const [selectAllVLogs, setSelectAllVLogs] = useState(false);
  const [selectAllVVehiclePlay, setselectAllVVehiclePlay] = useState(false);
  const [selectAllEGeofence, setSelectAllEGeofence] = useState(false);
  const [selectAllEAlerts, setSelectAllEAlerts] = useState(false);
  const [activeUsers, setActiveusers] = useState(organizationUsers);

  useEffect(() => {
    let vFleet = false;
    let eFleet = false;
    let vFirmware = false;
    let eFirmware = false;
    let vConfig = false;
    let eConfig = false;
    let vLogs = false;
    let vMap = false;
    let eGeofence = false;
    let vPlay = false;
    let eAlerts = false;
    for (let [, permissions] of localUserPermissions) {
      if (!permissions.some((p) => p === permissionData.viewmap)) {
        vMap = true;
      }
      if (!permissions.some((p) => p === permissionData.editgeofence)) {
        eGeofence = true;
      }
      if (!permissions.some((p) => p === permissionData.viewfleet)) {
        vFleet = true;
      }
      if (!permissions.some((p) => p === permissionData.editfleet)) {
        eFleet = true;
      }
      if (!permissions.some((p) => p === permissionData.viewotafirmware)) {
        vFirmware = true;
      }
      if (!permissions.some((p) => p === permissionData.editotafirmware)) {
        eFirmware = true;
      }
      if (!permissions.some((p) => p === permissionData.viewotaconfig)) {
        vConfig = true;
      }
      if (!permissions.some((p) => p === permissionData.editotaconfig)) {
        eConfig = true;
      }
      if (!permissions.some((p) => p === permissionData.viewanalytics)) {
        vLogs = true;
      }
      if (!permissions.some((p) => p === permissionData.viewVehiclePlayback)) {
        vPlay = true;
      }
      if (!permissions.some((p) => p === permissionData.editAlerts)) {
        eAlerts = true;
      }
    }
    setSelectAllVMap(!vMap);
    setSelectAllVFleet(!vFleet);
    setSelectAllEFleet(!eFleet);
    setSelectAllVFware(!vFirmware);
    setSelectAllEFware(!eFirmware);
    setSelectAllVConfig(!vConfig);
    setSelectAllEConfig(!eConfig);
    setSelectAllVLogs(!vLogs);
    setselectAllVVehiclePlay(!vPlay);
    setSelectAllEGeofence(!eGeofence);
    setSelectAllEAlerts(!eAlerts);
  }, [localUserPermissions]);

  useEffect(() => {
    if (activeUsers.length > 0) {
      let filteredUsers = activeUsers.filter((u) => u.active !== false);
      setActiveusers(filteredUsers);
    }
  }, []);

  const onChangeViewAllCheckbox = (selectAll, vPermission, ePermission) => {
    if (selectAll) {
      for (let [username, permissions] of localUserPermissions) {
        let eEnabled = permissions.some((pName) => pName === ePermission);
        let vEnabled = permissions.some((pName) => pName === vPermission);
        permissions = eEnabled ? [...permissions.filter((p) => p !== ePermission)] : permissions;
        permissions = vEnabled ? [...permissions.filter((p) => p !== vPermission)] : permissions;
        localUserPermissions.set(username, permissions);
      }
      updatelocalUserPermissions(new Map(localUserPermissions));
    } else {
      for (let [, permissions] of localUserPermissions) {
        if (!permissions.some((p) => p === vPermission)) {
          permissions.push(vPermission);
        }
      }
      updatelocalUserPermissions(new Map(localUserPermissions));
    }
  };
  const onChangeEditAllCheckbox = (selectAll, vPermission, ePermission) => {
    if (selectAll) {
      for (let [username, permissions] of localUserPermissions) {
        localUserPermissions.set(
          username,
          permissions.filter((p) => p !== ePermission),
        );
      }
      updatelocalUserPermissions(new Map(localUserPermissions));
    } else {
      for (let [username, permissions] of localUserPermissions) {
        let eEnabled = permissions.some((pName) => pName === ePermission);
        let vEnabled = permissions.some((pName) => pName === vPermission);
        permissions = !eEnabled ? [...permissions, ePermission] : permissions;
        permissions = !vEnabled ? [...permissions, vPermission] : permissions;
        localUserPermissions.set(username, permissions);
      }
      updatelocalUserPermissions(new Map(localUserPermissions));
    }
  };
  return (
    <div>
      <SortableTableWithoutPagination
        className="table-simple permission-table-simple"
        columns={[
          {
            title: '',
            dataIndex: 'picture',
            className: 'picture',
            key: 'picture',
            render(value) {
              return <TableAvatar profilePicture={value} />;
            },
          },
          {
            title: 'Username',
            dataIndex: 'username',
            className: 'username',
            key: 'username',
            render(value, permission) {
              return (
                <span>
                  <TruncatedText
                    userName
                    title={
                      'Username: ' +
                      value +
                      ', ' +
                      'First Name: ' +
                      permission.givenName +
                      ', ' +
                      'Last Name: ' +
                      permission.familyName
                    }
                  >
                    {value}
                  </TruncatedText>
                </span>
              );
            },
          },
          {
            title: 'Role',
            dataIndex: 'userRole',
            className: 'role',
            key: 'role',
            render(value) {
              const role = userRoleOptions.find((userRole) => userRole.value === value);
              return <span title={'Role: ' + value}>{role ? role.label : ''}</span>;
            },
          },

          {
            title: (
              <>
                FLEET
                <br />
                <Checkbox className="checkbox first-check-header" tableHeader>
                  <CheckboxInput
                    id={'selectAllVFleet'}
                    onChange={() =>
                      onChangeViewAllCheckbox(
                        selectAllVFleet,
                        permissionData.viewfleet,
                        permissionData.editfleet,
                      )
                    }
                    checked={selectAllVFleet}
                  />
                  <CheckboxLabel htmlFor={'selectAllVFleet'}>View</CheckboxLabel>
                </Checkbox>
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllEFleet'}
                    onChange={() =>
                      onChangeEditAllCheckbox(
                        selectAllEFleet,
                        permissionData.viewfleet,
                        permissionData.editfleet,
                      )
                    }
                    checked={selectAllEFleet}
                  />
                  <CheckboxLabel htmlFor={'selectAllEFleet'}>Edit</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'i',
            render(user) {
              return (
                <>
                  <Checkbox className="checkbox first-check-row" singleCheck title="View Fleet">
                    <CheckboxInput
                      id={`viewFleet_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewfleet)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewfleet,
                          permissionData.editfleet,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewFleet_${user.username}`} />
                  </Checkbox>
                  <Checkbox className="checkbox" singleCheck title="Edit Fleet">
                    <CheckboxInput
                      id={`editFleet_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editfleet)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewfleet,
                          permissionData.editfleet,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editFleet_${user.username}`} />
                  </Checkbox>
                </>
              );
            },
          },
          {
            title: (
              <>
                OTA FIRMWARE
                <br />
                <Checkbox className="checkbox first-check-header" tableHeader>
                  <CheckboxInput
                    id={'selectAllVFware'}
                    onChange={() =>
                      onChangeViewAllCheckbox(
                        selectAllVFware,
                        permissionData.viewotafirmware,
                        permissionData.editotafirmware,
                      )
                    }
                    checked={selectAllVFware}
                  />
                  <CheckboxLabel htmlFor={'selectAllVFware'}>View</CheckboxLabel>
                </Checkbox>
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllEFware'}
                    onChange={() =>
                      onChangeEditAllCheckbox(
                        selectAllEFware,
                        permissionData.viewotafirmware,
                        permissionData.editotafirmware,
                      )
                    }
                    checked={selectAllEFware}
                  />
                  <CheckboxLabel htmlFor={'selectAllEFware'}>Edit</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'j',
            render(user) {
              return (
                <>
                  <Checkbox
                    className="checkbox first-check-row"
                    singleCheck
                    title="View OTA Firmware"
                  >
                    <CheckboxInput
                      id={`viewOTAFirmware_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewotafirmware)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewotafirmware,
                          permissionData.editotafirmware,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewOTAFirmware_${user.username}`} />
                  </Checkbox>
                  <Checkbox className="checkbox" singleCheck title="Edit OTA Firmware">
                    <CheckboxInput
                      id={`editOTAFirmware_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editotafirmware)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewotafirmware,
                          permissionData.editotafirmware,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editOTAFirmware_${user.username}`} />
                  </Checkbox>
                </>
              );
            },
          },
          {
            title: (
              <>
                OTA CONFIG
                <br />
                <Checkbox className="checkbox first-check-header" tableHeader>
                  <CheckboxInput
                    id={'selectAllVConfig'}
                    onChange={() =>
                      onChangeViewAllCheckbox(
                        selectAllVConfig,
                        permissionData.viewotaconfig,
                        permissionData.editotaconfig,
                      )
                    }
                    checked={selectAllVConfig}
                  />
                  <CheckboxLabel htmlFor={'selectAllVConfig'}>View</CheckboxLabel>
                </Checkbox>
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllEConfig'}
                    onChange={() =>
                      onChangeEditAllCheckbox(
                        selectAllEConfig,
                        permissionData.viewotaconfig,
                        permissionData.editotaconfig,
                      )
                    }
                    checked={selectAllEConfig}
                  />
                  <CheckboxLabel htmlFor={'selectAllEConfig'}>Edit</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'k',
            render(user) {
              return (
                <>
                  <Checkbox
                    className="checkbox first-check-row"
                    singleCheck
                    title="View OTA Config"
                  >
                    <CheckboxInput
                      id={`viewOTAConfig_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.viewotaconfig)}
                      onChange={() =>
                        onChangeViewCheckbox(
                          user.username,
                          permissionData.viewotaconfig,
                          permissionData.editotaconfig,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`viewOTAConfig_${user.username}`} />
                  </Checkbox>
                  <Checkbox className="checkbox" singleCheck title="Edit OTA Config">
                    <CheckboxInput
                      id={`editOTAConfig_${user.username}`}
                      checked={localUserPermissions
                        .get(user.username)
                        .some((pName) => pName === permissionData.editotaconfig)}
                      onChange={() =>
                        onChangeEditCheckbox(
                          user.username,
                          permissionData.viewotaconfig,
                          permissionData.editotaconfig,
                        )
                      }
                    />
                    <CheckboxLabel htmlFor={`editOTAConfig_${user.username}`} />
                  </Checkbox>
                </>
              );
            },
          },
          {
            title: (
              <>
                ANALYTICS
                <br />
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllVLogs'}
                    onChange={() => {
                      if (selectAllVLogs) {
                        for (let [username, permissions] of localUserPermissions) {
                          localUserPermissions.set(
                            username,
                            permissions.filter((p) => p !== permissionData.viewanalytics),
                          );
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      } else {
                        for (let [, permissions] of localUserPermissions) {
                          if (!permissions.some((p) => p === permissionData.viewanalytics)) {
                            permissions.push(permissionData.viewanalytics);
                          }
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      }
                    }}
                    checked={selectAllVLogs}
                  />

                  <CheckboxLabel htmlFor={'selectAllVLogs'}>View</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'l',
            width: '10%',
            render(user) {
              return (
                <Checkbox className="checkbox" singleCheck title="View Analytics">
                  <CheckboxInput
                    id={`viewAnalytics_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.viewanalytics)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some(
                          (pName) => pName === permissionData.viewanalytics,
                        );

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [...permissions.filter((p) => p !== permissionData.viewanalytics)]
                            : [...permissions, permissionData.viewanalytics],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`viewAnalytics_${user.username}`} />
                </Checkbox>
              );
            },
          },
          {
            title: (
              <>
                VEHICLE PLAYBACK
                <br />
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllVVehiclePlay'}
                    onChange={() => {
                      if (selectAllVVehiclePlay) {
                        for (let [username, permissions] of localUserPermissions) {
                          localUserPermissions.set(
                            username,
                            permissions.filter((p) => p !== permissionData.viewVehiclePlayback),
                          );
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      } else {
                        for (let [, permissions] of localUserPermissions) {
                          if (!permissions.some((p) => p === permissionData.viewVehiclePlayback)) {
                            permissions.push(permissionData.viewVehiclePlayback);
                          }
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      }
                    }}
                    checked={selectAllVVehiclePlay}
                  />

                  <CheckboxLabel htmlFor={'selectAllVVehiclePlay'}>View</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'n',
            render(user) {
              return (
                <Checkbox className="checkbox" singleCheck title="View Vehicle Playback">
                  <CheckboxInput
                    id={`viewVehiclePlayback_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.viewVehiclePlayback)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some(
                          (pName) => pName === permissionData.viewVehiclePlayback,
                        );

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [
                                ...permissions.filter(
                                  (p) => p !== permissionData.viewVehiclePlayback,
                                ),
                              ]
                            : [...permissions, permissionData.viewVehiclePlayback],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`viewVehiclePlayback_${user.username}`} />
                </Checkbox>
              );
            },
          },
          {
            title: (
              <>
                Manage Alerts
                <br />
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllEAlerts'}
                    onChange={() => {
                      if (selectAllEAlerts) {
                        for (let [username, permissions] of localUserPermissions) {
                          localUserPermissions.set(
                            username,
                            permissions.filter((p) => p !== permissionData.editAlerts),
                          );
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      } else {
                        for (let [, permissions] of localUserPermissions) {
                          if (!permissions.some((p) => p === permissionData.editAlerts)) {
                            permissions.push(permissionData.editAlerts);
                          }
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      }
                    }}
                    checked={selectAllEAlerts}
                  />

                  <CheckboxLabel htmlFor={'selectAllEAlerts'}>Edit</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'alerts',
            render(user) {
              return (
                <Checkbox className="checkbox" singleCheck title="Edit Alerts">
                  <CheckboxInput
                    id={`editAlerts_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.editAlerts)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some(
                          (pName) => pName === permissionData.editAlerts,
                        );

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [...permissions.filter((p) => p !== permissionData.editAlerts)]
                            : [...permissions, permissionData.editAlerts],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`editAlerts_${user.username}`} />
                </Checkbox>
              );
            },
          },
          {
            title: (
              <>
                Geofences
                <br />
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllEGeofence'}
                    onChange={() => {
                      if (selectAllEGeofence) {
                        for (let [username, permissions] of localUserPermissions) {
                          localUserPermissions.set(
                            username,
                            permissions.filter((p) => p !== permissionData.editgeofence),
                          );
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      } else {
                        for (let [, permissions] of localUserPermissions) {
                          if (!permissions.some((p) => p === permissionData.editgeofence)) {
                            permissions.push(permissionData.editgeofence);
                          }
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      }
                    }}
                    checked={selectAllEGeofence}
                  />

                  <CheckboxLabel htmlFor={'selectAllEGeofence'}>Edit</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'p',
            render(user) {
              return (
                <Checkbox className="checkbox" singleCheck title="Edit Geofences">
                  <CheckboxInput
                    id={`editGeofence_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.editgeofence)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some(
                          (pName) => pName === permissionData.editgeofence,
                        );

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [...permissions.filter((p) => p !== permissionData.editgeofence)]
                            : [...permissions, permissionData.editgeofence],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`editGeofence_${user.username}`} />
                </Checkbox>
              );
            },
          },
          {
            title: (
              <>
                Live Map
                <br />
                <Checkbox className="checkbox" tableHeader>
                  <CheckboxInput
                    id={'selectAllVMap'}
                    onChange={() => {
                      if (selectAllVMap) {
                        for (let [username, permissions] of localUserPermissions) {
                          localUserPermissions.set(
                            username,
                            permissions.filter((p) => p !== permissionData.viewmap),
                          );
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      } else {
                        for (let [, permissions] of localUserPermissions) {
                          if (!permissions.some((p) => p === permissionData.viewmap)) {
                            permissions.push(permissionData.viewmap);
                          }
                        }

                        updatelocalUserPermissions(new Map(localUserPermissions));
                      }
                    }}
                    checked={selectAllVMap}
                  />

                  <CheckboxLabel htmlFor={'selectAllVMap'}>View</CheckboxLabel>
                </Checkbox>
              </>
            ),
            dataIndex: '',
            className: 'action',
            key: 'm',
            render(user) {
              return (
                <Checkbox className="checkbox" singleCheck title="View Live Map">
                  <CheckboxInput
                    id={`viewMap_${user.username}`}
                    checked={localUserPermissions
                      .get(user.username)
                      .some((pName) => pName === permissionData.viewmap)}
                    onChange={() => {
                      if (localUserPermissions.has(user.username)) {
                        let permissions = localUserPermissions.get(user.username);
                        let enabled = permissions.some((pName) => pName === permissionData.viewmap);

                        localUserPermissions.set(
                          user.username,
                          enabled
                            ? [...permissions.filter((p) => p !== permissionData.viewmap)]
                            : [...permissions, permissionData.viewmap],
                        );

                        updatelocalUserPermissions(localUserPermissions);
                      }
                    }}
                  />
                  <CheckboxLabel htmlFor={`viewMap_${user.username}`} />
                </Checkbox>
              );
            },
          },
        ]}
        data={activeUsers}
        rowKey={(user) => user.username}
        rowClassName={(record, i) => `row-${i}`}
        emptyText="No users are currently associated with this organization"
      />
    </div>
  );
};

export default PermissionsTable;
