import { apiCall } from 'legacy/core/api/API';
import { mapRecipientDataToBackend } from 'legacy/features/recipientGroups/utilities/recipientGroupMapping';

export const postRecipientGroupApi = async ({ organizationId, newRecipientGroup }) => {
  // map and massage maintenance data
  console.log(newRecipientGroup);
  const mappedRecipientGroup = mapRecipientDataToBackend(newRecipientGroup);
  console.log(mappedRecipientGroup);

  return await apiCall({
    path: `/user_group`,
    method: 'post',
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
    body: {
      ...mappedRecipientGroup,
    },
  });
};
