import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import { offlineTimeUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/offlineTimeUnitDropdownOptions';
import { speedUnitDropdownOptions } from 'legacy/features/alerts/data/ui/dropdown/speedUnitDropdownOptions';

export const getUnitOfMeasureDisplay = (unitOfMeasure, alertType, triggerValue) => {
  if (alertType) {
    switch (alertType) {
      case alertTypeConstants.SPEED_EXCEEDS:
      case alertTypeConstants.NR_SPEED_EXCEEDS:
        return speedUnitDropdownOptions.getLabelByValue(unitOfMeasure);
      case alertTypeConstants.OFFLINE_TIME_EXCEEDS:
        let displayUnit = offlineTimeUnitDropdownOptions.getLabelByValue(unitOfMeasure);
        return triggerValue === 1
          ? displayUnit.replace('(s)', '')
          : displayUnit.replace('(s)', 's');
      default:
        return '';
    }
  }
};
