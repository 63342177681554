export const getFormattedDuid = (duid) => {
  if (!duid) {
    return 'Not yet assigned';
  }

  let regEx = /([\w]{8})([\w]{4})([\w]{4})([\w]{4})([\w]{12})/;
  let stringTemplate = '$1-$2-$3-$4-$5';

  return duid.replace(regEx, stringTemplate) || 'Invalid Device ID';
};
