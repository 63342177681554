import { useEffect, useState } from 'react';
import { refreshToken } from 'legacy/core/api/login';
import { hideSessionTimeoutNotification } from 'legacy/core/redux/ui/actions';
import { submitLogout } from 'legacy/core/redux/user/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import ConfirmationModal from 'legacy/shared/v1/modals/ConfirmationModal';
import { convertDuration } from 'legacy/shared/v1/utilities/time';

const SessionTimeoutNotifier = () => {
  const dispatch = useDispatch();
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  useEffect(() => {
    if (timeRemaining !== null) {
      if (timeRemaining <= 0) {
        hideSessionTimeoutNotification();
        dispatch(submitLogout({ isTimedOut: true }));
        setTimeRemaining(null);
      }

      const intervalId = setInterval(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeRemaining]);
  useEffect(() => {
    if (modalIsVisible) {
      setTimeRemaining(300);
    }
  }, [modalIsVisible]);
  useEffect(() => {
    setModalIsVisible(sessionTimeoutNotificationVisible);
  });

  const sessionTimeoutNotificationVisible = useSelector(
    (state) => state.ui.sessionTimeoutNotificationVisible,
  );

  return (
    <>
      {sessionTimeoutNotificationVisible && (
        <ModalPortal
          onRequestClose={() => {
            setTimeRemaining(null);
            dispatch(hideSessionTimeoutNotification());
          }}
        >
          <ConfirmationModal
            bodyText={`Do you want to extend your session time? Your current session will expire in ${convertDuration(
              timeRemaining,
            )}`}
            cancelHandler={() => {
              setTimeRemaining(null);
              dispatch(hideSessionTimeoutNotification());
              dispatch(submitLogout({ isTimedOut: false }));
            }}
            confirmHandler={() => {
              setTimeRemaining(null);
              dispatch(hideSessionTimeoutNotification());
              refreshToken();
            }}
            cancelText={'No, Log Out'}
            confirmText={'Yes, Stay Logged In'}
            title={'Session Timeout'}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default SessionTimeoutNotifier;
