import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';

export const organizationFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Organization', err),
  });

export const editMaintenanceRemindersSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfuly Updated Maintenance Reminders!'),
  });
