import {
  userPath,
  userActivationPath,
  userDeactivationPath,
  userPasswordUpdatePath,
  auditPath,
} from 'legacy/shared/v1/constants/api';
import apiUrl from 'legacy/shared/v1/utilities/url';
import { apiCall, fetchAPICall, getAuthToken } from 'legacy/core/api/API';
import {
  userRoleOptions as userRoles,
  userRoleOptionsArray as userRoleOptions,
  MAP_ANALYST,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  FIELD_SOLUTION_ENGINEER,
  SYSTEM_ADMIN,
} from 'legacy/shared/v1/constants/users';

export const decorateUserObject = ({ user }) => {
  return {
    username: user.Username,
    email: user.email,
    phoneNumber: user.phone_number,
    phoneExtension: user.phone_extension,
    givenName: user.given_name,
    familyName: user.family_name,
    userTitle: user.user_title,
    active: user.active,
    userRole: user.user_role,
    picture: user.picture,
    organizationId: user.organization_id,
    twoFactorAuthMethod: user.two_factor_auth_method,
    permissions: user.permissions,
    role: userRoleOptions.find((userRole) => userRole.value === user.user_role).label,
    status: user.active === true ? 'Enabled' : 'Disabled',
    optOutOfEmails: user.opt_out_of_emails,
  };
};

export const undecorateUserObject = ({ user }) => {
  return {
    Username: user.username,
    email: user.email,
    phone_number: user.phoneNumber,
    phoneExtension: user.phone_extension,
    given_name: user.givenName,
    family_name: user.familyName,
    user_title: user.userTitle,
    active: user.active,
    user_role: user.userRole,
    picture: user.picture,
    organization_id: user.organizationId,
    two_factor_auth_method: user.twoFactorAuthMethod,
    permissions: user.permissions,
    optOutOfEmails: user.opt_out_of_emails,
  };
};

export const getUserAPI = async ({ username }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${userPath}/${username}`,
    method: 'get',
  });
};

export const getUserActivityLogs = async ({ userName, organizationId, startDate, endDate }) => {
  const queryStringParameters = {
    username: `${userName}`,
    organization_id: `${organizationId}`,
    start_date: `${startDate}`,
    end_date: `${endDate}`,
  };
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: auditPath,
    method: 'get',
    queryStringParameters,
  });
};

export const createUserAPI = async ({
  username,
  email,
  phoneNumber,
  phoneExtension,
  givenName,
  familyName,
  userTitle,
  active,
  userRole,
  picture,
  organizationId,
  twoFactorAuthMethod,
  optOutOfEmails,
}) => {
  const body = new FormData();
  body.append('Username', username);
  body.append('email', email);
  body.append('phone_number', phoneNumber);
  body.append('phone_extension', phoneExtension);
  body.append('given_name', givenName);
  body.append('family_name', familyName);
  body.append('user_title', userTitle);
  body.append('active', true);
  body.append('user_role', userRole);
  body.append('picture', picture);
  body.append('organization_id', organizationId);
  body.append('two_factor_auth_method', twoFactorAuthMethod);
  body.append('opt_out_of_emails', optOutOfEmails);

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${userPath}`,
    method: 'POST',
    headers: {
      Authorization: getAuthToken(),
    },
    body,
  });
};

export const getUsers = async ({ organizationId }) => {
  const queryStringParameters = organizationId ? { organization_id: `${organizationId}` } : '';
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userPath,
    method: 'get',
    queryStringParameters,
  });
};

export const fetchFSEUsers = async () => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userPath,
    method: 'get',
    queryStringParameters: {
      user_role: `${userRoles[FIELD_SOLUTION_ENGINEER].value}`,
    },
  });
};

export const updateUserAPI = async ({
  username,
  email,
  phoneNumber,
  phoneExtension,
  givenName,
  familyName,
  userTitle,
  active,
  userRole,
  picture,
  organizationId,
  twoFactorAuthMethod,
  resetUser,
  optOutOfEmails,
}) => {
  const body = new FormData();
  body.append('Username', username);
  body.append('email', email);
  body.append('phone_number', phoneNumber);
  body.append('phone_extension', phoneExtension);
  body.append('given_name', givenName);
  body.append('family_name', familyName);
  body.append('user_title', userTitle);
  body.append('active', active);
  body.append('user_role', userRole);
  body.append('picture', picture);
  body.append('organization_id', organizationId);
  body.append('two_factor_auth_method', twoFactorAuthMethod);
  body.append('reset_user', resetUser);
  body.append('opt_out_of_emails', optOutOfEmails);

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${userPath}/${username}`,
    method: 'PUT',
    headers: {
      Authorization: getAuthToken(),
    },
    body,
  });
};

export const setTwoFactorAPI = async (accessToken, twoFactorMethod) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/settwofactor',
    method: 'post',
    headers: {
      Authorization: accessToken,
    },
    body: {
      two_factor_auth_method: twoFactorMethod,
    },
  });
};

export const verifyMfaAuthCodeAPI = async (accessToken, userMfaCode) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/verifymfaauthcode',
    method: 'post',
    headers: {
      Authorization: accessToken,
    },
    body: {
      UserCode: userMfaCode,
    },
  });
};

export const activateUserAPI = async (username) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userActivationPath(username),
    method: 'put',
  });
};

export const deactivateUserAPI = async (username) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userDeactivationPath(username),
    method: 'put',
  });
};

export const updateUserPasswordAPI = async (oldPassword, newPassword) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: userPasswordUpdatePath,
    method: 'post',
    body: {
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
      AccessToken: getAuthToken(),
    },
  });
};
