import {
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

const EnableTestModeMenuItem = ({ isTestModeEnabled, onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>{`${
        isTestModeEnabled ? 'Disable' : 'Enable'
      } Test Mode`}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EnableTestModeMenuItem;
