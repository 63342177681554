import styled from 'styled-components';
import device from 'legacy/shared/v1/constants/sizing';

import colors from 'legacy/shared/v1/constants/colors';

export const DeviceCell = styled.div`
  padding: 14px 0;
  font-family: Muli, sans-serif;
  font-size: 14px;
  color: ${colors.haze};

  ${(props) =>
    props.tableHorizontalPadding &&
    props.nameCell &&
    `
    padding-left: ${props.tableHorizontalPadding}px;
    color: ${colors.midnight};
    font-size: 14px;
    font-weight: 700;
    font-family: Muli, sans-serif;
  `}

  ${(props) =>
    props.firmwareVersion &&
    `
    padding-left: ${props.tableHorizontalPadding}px;
    color: ${colors.haze};
    font-size: 14px;
    font-weight: 400;
    font-family: Muli, sans-serif;
  `}


  ${(props) =>
    props.maxWidth &&
    props.tableHorizontalPadding &&
    props.nameCell &&
    `
    max-width: calc(${props.maxWidth}px - ${props.tableHorizontalPadding}px);
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

export const SupportedDevicesTable = styled.table`
  table-layout: fixed;
  margin-top: 16px;
`;

export const deviceOuterCellStyles = {
  padding: '0px',
};

export const supportedDevicesHeaderCellStyles = {
  backgroundColor: `${colors.fog}`,
  fontFamily: 'Muli, sans-serif',
  fontSize: '10px',
  textTransform: 'uppercase',
  textAlign: 'left',
};

export const SortArrowSpan = styled.span`
  margin-left: 10px;
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    `
    opacity: 1;
	`};
`;

export const TableFilterSpan = styled.span`
  margin-left: 5px;
  font-size: 22px;
  @media ${device.large} {
    font-size: 16px;
  }
`;

export const SelectWrapper = styled.div`
  padding-left: 5px;
  padding-top: 15px;
`;

export const PaginationSelectWrapper = styled.div`
  margin-top: 10px;
  width: 50px;
  margin-left: -3px;
  margin-right: -3px;
`;

export const TableCellCheckboxWrapper = styled.span`
  margin-right: 15px;
  padding-top: 3px;
`;
export const SmallTableWrapper = styled.div`
  max-width: 800px;
`;
