import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import React from 'react';
import { RemoveButton } from 'legacy/shared/v1/styles/components/Button';
import { AlertRecipientRemoveButtonWrapper } from 'legacy/shared/v1/styles/components/ManageAlerts';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const RemoveAdditionalNotificationButton = ({ notificationIndex }) => {
  const {
    actions: { removeChildAlertAction },
    state: {
      alert: { alertId },
    },
  } = useAlertFormsProviderContext();

  return (
    <AlertRecipientRemoveButtonWrapper>
      <RemoveButton
        type="button"
        nonPrimary
        title="Remove"
        onClick={() => removeChildAlertAction({ alertId, notificationIndex })}
      >
        <IconSvgComponent svgFileName={'remove-feature'} alt="Remove" />
      </RemoveButton>
    </AlertRecipientRemoveButtonWrapper>
  );
};

export default RemoveAdditionalNotificationButton;
