import { useContext, useState } from 'react';

import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import { vehicleAddonAlertLevels } from 'legacy/features/vehicles/constants/vehicleAddonAlertLevels';
import { vehicleAddonDictionary } from 'legacy/features/vehicles/constants/vehicleAddonDictionary';
import device from 'legacy/shared/v1/constants/sizing';
import {
  ActivityTime,
  AddonActivityCategoryHeaderDiv,
  AddonActivityCategoryToggleDiv,
  AddonActivityContainerDiv,
  AddonActivityDiv,
  AlertDiv,
  VehicleName,
} from 'legacy/shared/v1/styles/components/Addons';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { getVehicleGpsCoordinates } from 'legacy/shared/v1/utilities/map/MapAnimationUtils';
import { convertDuration, getSecondsFromStartTime } from 'legacy/shared/v1/utilities/time';

const AddonActivityCategorySection = ({
  addonType,
  showAlert,
  showTime,
  addons,
  setViewAllAddonsModalOpen,
  viewAllAddonsModalOpen,
}) => {
  const [addonActivityCategoryIsExpanded, setAddonActivityCategoryIsExpanded] = useState(true);
  const {
    actions: {
      setSelectedVehicle,
      setFlyToCoordinates,
      setLivemapSidebarStates,
      setHiddenVehicleIds,
    },

    state: { hiddenVehicleIds },
  } = useContext(LivemapApi); // use to set state in container

  return (
    <>
      <AddonActivityCategoryHeaderDiv>
        <AddonActivityCategoryToggleDiv
          onClick={() => {
            setAddonActivityCategoryIsExpanded((current) => !current);
          }}
        >
          {addonActivityCategoryIsExpanded ? (
            <IconSvgComponent svgFileName={'expanded-toggle'} alt="Collapse " title="Collapse" />
          ) : (
            <IconSvgComponent svgFileName={'collapsed-toggle'} alt="Expand" title="Expand" />
          )}
        </AddonActivityCategoryToggleDiv>
        <AlertDiv showAlert={showAlert}>
          <IconSvgComponent svgFileName={'alert-text'} alt="ALERT" />
        </AlertDiv>
        {vehicleAddonDictionary[addonType]?.displayName}
      </AddonActivityCategoryHeaderDiv>

      <AddonActivityContainerDiv isExpanded={addonActivityCategoryIsExpanded}>
        {addons
          .sort((a, b) => {
            return new Date(b.update_time) - new Date(a.update_time);
          })
          .map((a) => (
            <AddonActivityDiv>
              <VehicleName
                vehicleAlert={a.alert_level === vehicleAddonAlertLevels[3].label && a.is_active}
                title={a.vehicle.meta.label}
                onClick={(e) => {
                  setLivemapSidebarStates({
                    livemapMainSidebarOpen: false,
                  });
                  let mobile = !window.matchMedia(device.large).matches;
                  mobile &&
                    setLivemapSidebarStates({
                      livemapAddonsSidebarOpen: false,
                    });
                  mobile && viewAllAddonsModalOpen && setViewAllAddonsModalOpen(false);
                  setSelectedVehicle(a.vehicle.vehicle_id);
                  setHiddenVehicleIds(hiddenVehicleIds.filter((v) => v !== a.vehicle.vehicle_id));
                  setFlyToCoordinates(getVehicleGpsCoordinates(a.vehicle));
                }}
              >
                {a.vehicle.meta.label}
              </VehicleName>
              {showTime && (
                <ActivityTime>
                  {convertDuration(getSecondsFromStartTime({ fromTime: a.update_time }), true)} ago
                </ActivityTime>
              )}
            </AddonActivityDiv>
          ))}
      </AddonActivityContainerDiv>
    </>
  );
};

export default AddonActivityCategorySection;
