import { useState } from 'react';

import { getVehiclesApi } from 'legacy/features/vehicles/api/get/getVehiclesApi';
import useGenericQuery from 'legacy/shared/v2/hooks/reactQuery/useGenericQuery';
import { decorateVehicle, sortAscendingAlphaLabel } from 'legacy/shared/v1/utilities/vehicle';

const useFetchVehiclesQuery = ({ apiFlags }) => {
  const [vehicles, setVehicles] = useState([]);

  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getVehiclesApi',
    apiFn: getVehiclesApi,
    params: { apiFlags },
    onSuccessCallback: ({ response: { message: vehicles } }) => {
      // TODO: add this to mapping code - i believe this will obviate the need for onSuccessCallback
      vehicles = vehicles.sort(sortAscendingAlphaLabel).map((v) => decorateVehicle(v));
      setVehicles(vehicles);
    },
  });

  return [vehicles, queryKey, queryResult];
};

export default useFetchVehiclesQuery;
