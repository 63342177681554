import { useEffect, useState } from 'react';

import useFetchOrganizationUsersQuery from 'legacy/features/recipientGroups/hooks/queries/useFetchOrganizationUsersQuery';
import { RecipientItemBlockDiv } from 'legacy/shared/v1/styles/components/RecipientGroups';
import RecipientSelectDropdown from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/dropdowns/RecipientSelectDropdown';
import AddRecipientButton from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/buttons/AddRecipientButton';
import RemoveRecipientUserButton from 'legacy/features/recipientGroups/components/ManageRecipientGroupsForms/buttons/RemoveRecipientUserButton';
import useMakeFormikField from 'legacy/shared/v2/components/controls/WcpForm/hooks/useMakeFormikField';
import IsFetchingWrapper from 'legacy/shared/v1/components/IsFetchingWrapper';
import { FieldError } from 'legacy/shared/v1/styles/components/Form';

const RecipientGroupUsersContainer = ({ recipients, formik }) => {
  // get all users from organization
  const [allUsers, allUsersQueryKey] = useFetchOrganizationUsersQuery();
  const [unsavedRecipients, setUnsavedRecipients] = useState(null);

  useEffect(() => {
    setUnsavedRecipients(recipients || [null]);
  }, [recipients]);

  useMakeFormikField({
    fieldName: 'recipientGroupMembersField',
    fieldValue: unsavedRecipients,
    triggerValidation: true,
  });

  return (
    <IsFetchingWrapper queryKeys={[allUsersQueryKey]}>
      <>
        {unsavedRecipients?.map((user, i) => (
          <RecipientItemBlockDiv>
            <RecipientSelectDropdown
              key={i}
              index={i}
              availableUsers={
                allUsers?.filter(
                  (u) =>
                    u.userActive && !unsavedRecipients.map((r) => r?.userName).includes(u.userName),
                ) || []
              }
              selectedUser={user}
              handleUserSelect={(selectedUser) => {
                // add selected user to updatedGroupUsers if not already in list
                if (selectedUser) {
                  const userNotInList =
                    unsavedRecipients.findIndex((u) => u?.userName === selectedUser.userName) < 0;

                  if (userNotInList) {
                    const userFromAllUsers = allUsers?.find(
                      (u) => u.userName === selectedUser.userName,
                    );

                    unsavedRecipients[i] = userFromAllUsers;
                    setUnsavedRecipients([...unsavedRecipients]);
                  }
                }
              }}
            />
            {unsavedRecipients.some((user) => user?.userName !== undefined) &&
              i === unsavedRecipients.length - 1 && (
                <AddRecipientButton
                  handleAddRecipient={() => setUnsavedRecipients([...unsavedRecipients, null])}
                />
              )}
            {unsavedRecipients.length > 1 && (
              <RemoveRecipientUserButton
                recipientUserName={user?.userName}
                handleRemoveRecipientUserFromGroup={(userName) => {
                  setUnsavedRecipients(unsavedRecipients?.filter((u) => u?.userName !== userName));
                }}
              />
            )}
          </RecipientItemBlockDiv>
        ))}
        {/* TEMP CATCH ALL VALIDATION UNTIL PRE INSTANCE VALIDATION IS REWRITTEN */}
        <FieldError>
          {(formik.touched.recipientGroupMembersField &&
            formik.errors.recipientGroupMembersField) ||
            formik.errors.duplicateUserNameErrors}
        </FieldError>
      </>
    </IsFetchingWrapper>
  );
};

export default RecipientGroupUsersContainer;
