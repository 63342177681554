import {
  FIELD_SOLUTION_ENGINEER,
  MAP_ANALYST,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  permissionData,
  userRoleOptions as userRoles,
  variableScopeAllowedUserRoles,
} from 'legacy/shared/v1/constants/users';

const getNavigationMenuData = ({ userOrgId, filteredOrganizationId }) => ({
  LIVEMAP: {
    title: 'Live Map',
    icon_path: 'location',
    leftOffset: '-2px',
    rightOffset: '10px',
    link: '/livemap',
    requiredRoles: [
      userRoles[MAP_ANALYST].value,
      userRoles[FLEET_ANALYST].value,
      userRoles[ORGANIZATION_ADMIN].value,
      userRoles[FIELD_SOLUTION_ENGINEER].value,
      userRoles[SYSTEM_ADMIN].value,
    ],
  },
  FLEET_DROPDOWN: {
    title: 'Fleet',
    icon_path: 'groups',
    leftOffset: '4px',
    rightOffset: '15px',
    hideIfSubscriptionExpired: true,
    requiredPermissions: [permissionData.viewfleet],

    dropdown: {
      VEHICLES: {
        title: 'Vehicles',
        icon_path: 'car',
        link: '/vehicles',
        exact: false,
        requiredPermissions: [permissionData.viewfleet],
      },
      GROUPS: {
        title: 'Groups',
        icon_path: 'tiles',
        leftOffset: '2px',
        link: '/groups',
        exact: false,
        requiredPermissions: [permissionData.viewfleet],
      },
      MAINTENANCE: {
        title: 'Maintenance',
        icon_path: 'maintenance',
        link: '/maintenance',
        exact: false,
        requiredPermissions: [permissionData.editfleet],
      },
    },
  },
  ORGANIZATIONS: {
    title: 'Organizations',
    icon_path: 'organizations',
    leftOffset: '-3px',
    rightOffset: '2px',
    link: '/organizations',
    requiredRoles: variableScopeAllowedUserRoles,
    showScopedNonAdminOrg: userOrgId !== filteredOrganizationId,
  },
  ORGANIZATION_DROPDOWN: {
    title: 'Organization',
    icon_path: 'organizations',
    leftOffset: '2px',
    rightOffset: '18px',
    showScopedNonAdminOrg: userOrgId === filteredOrganizationId,
    hideIfEmpty: true,
    requiredRoles: [
      userRoles[ORGANIZATION_ADMIN].value,
      userRoles[FIELD_SOLUTION_ENGINEER].value,
      userRoles[SYSTEM_ADMIN].value,
      userRoles[FLEET_ANALYST].value,
      userRoles[MAP_ANALYST].value,
    ],
    dropdown: {
      OVERVIEW: {
        title: 'Overview',
        icon_path: 'organizations',
        leftOffset: '2px',
        link: `/organizations/${filteredOrganizationId}`,
        exact: false,
        requiredRoles: [
          userRoles[ORGANIZATION_ADMIN].value,
          userRoles[FIELD_SOLUTION_ENGINEER].value,
          userRoles[SYSTEM_ADMIN].value,
        ],
      },
      PERMISSIONS: {
        title: 'Permissions',
        icon_path: 'lock',
        leftOffset: '0px',
        link: `/permissions/${userOrgId}`,
        exact: false,
        requiredRoles: [userRoles[ORGANIZATION_ADMIN].value],
      },
      MANAGE_ALERTS: {
        title: 'Manage Alerts',
        icon_path: 'alert-white',
        leftOffset: '2px',
        link: `/managealerts`,
        exact: false,
        mustPassRoleAndPermissionsCheck: true,
        requiredPermissions: [permissionData.editAlerts],
        requiredRoles: [
          userRoles[ORGANIZATION_ADMIN].value,
          userRoles[FIELD_SOLUTION_ENGINEER].value,
          userRoles[SYSTEM_ADMIN].value,
          userRoles[FLEET_ANALYST].value,
          userRoles[MAP_ANALYST].value,
        ],
      },
      DEVICES_AND_BILLING: {
        title: 'Devices & Subscriptions',
        icon_path: 'devices-and-billing',
        leftOffset: '-3px',
        rightOffset: '6px',
        link: `/devicesandsubscriptions`,
        exact: false,
        requiredRoles: [
          userRoles[ORGANIZATION_ADMIN].value,
          userRoles[FIELD_SOLUTION_ENGINEER].value,
          userRoles[SYSTEM_ADMIN].value,
        ],
      },
    },
  },
  OTA_DROPDOWN: {
    title: 'OTA',
    icon_path: 'cloud-small',
    leftOffset: '2px',
    rightOffset: '12px',
    hideIfSubscriptionExpired: true,
    requiredRoles: [userRoles[FIELD_SOLUTION_ENGINEER].value, userRoles[SYSTEM_ADMIN].value],
    requiredPermissions: [permissionData.viewotaconfig, permissionData.viewotafirmware],
    dropdown: {
      FIRMWARE_OTA: {
        title: 'Firmware OTA',
        icon_path: 'cloud-small',
        link: '/updates',
        exact: false,
        requiredPermissions: [permissionData.viewotafirmware],
      },
      FIRMWARE_WHITELIST: {
        title: 'Firmware Whitelist',
        icon_path: 'list',
        leftOffset: '2px',
        link: '/whitelists',
        exact: false,
        requiredRoles: variableScopeAllowedUserRoles,
      },
      CONFIGURATION_OTA: {
        title: 'Configuration OTA',
        icon_path: 'gears-grey',
        link: '/configurations/updates',
        exact: false,
        requiredPermissions: [permissionData.viewotaconfig],
      },
      CONFIGURATION_LIBRARY: {
        title: 'Configuration Library',
        icon_path: 'folder',
        link: '/configurations',
        exact: true,
        requiredPermissions: [permissionData.viewotaconfig],
      },
    },
  },
  ANALYTICS_DROPDOWN: {
    title: 'Analytics',
    icon_path: 'analytics',
    rightOffset: '18px',
    hideIfSubscriptionExpired: true,
    requiredPermissions: [permissionData.viewanalytics],
    dropdown: {
      ANALYTICS_DASHBOARD: {
        title: 'Dashboard',
        icon_path: 'analyticsDashboard',
        link: '/analyticsdashboard',
        exact: false,
        requiredPermissions: [permissionData.viewanalytics],
      },
      ANALYTICS_LOGS: {
        title: 'Logs',
        icon_path: 'logs',
        leftOffset: '2px',
        rightOffset: '8px',
        link: '/analyticslogs',
        exact: false,
        requiredPermissions: [permissionData.viewanalytics],
      },
      ANALYTICS_HEATMAPS: {
        title: 'Heatmaps',
        icon_path: 'heatmaps',
        leftOffset: '-2px',
        link: '/analyticsheatmap',
        exact: false,
        requiredPermissions: [permissionData.viewanalytics],
      },
    },
  },
  PLAYBACK: {
    title: 'Vehicle Playback',
    icon_path: 'vehicle-playback',
    link: '/playback',
    leftOffset: '-7px',
    rightOffset: '15px',
    hideIfSubscriptionExpired: true,
    requiredPermissions: [permissionData.viewVehiclePlayback],
  },
  GEOFENCES: {
    title: 'Geofences',
    icon_path: 'geofences',
    link: '/geofences',
    leftOffset: '-9px',
    rightOffset: '15px',
    hideIfSubscriptionExpired: true,
    requiredPermissions: [permissionData.editgeofence],
  },
  HELP: {
    link: '/help',
  },
});

export default getNavigationMenuData;
