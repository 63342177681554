import { useState } from 'react';

import { assignConfirgurationToGroupAPI } from 'legacy/core/api/groups';
import { useFetchGroupByIdQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupByIdQuery';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import AssignConfigurationToGroupContent from 'legacy/shared/v1/modals/AssignConfigurationToGroupContent';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import GenericFormModal from 'legacy/shared/v1/ui/modals/GenericFormModal';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { validateSelectedConfiguration } from 'legacy/shared/v1/utilities/validators';

export const AssignConfigurationsModal = ({
  modalData: { show, groupId },
  handleCloseModal,
  group,
}) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  //const fetchGroupByIdQuery = useFetchGroupByIdQuery({ groupId, enabled: show });
  //const group = fetchGroupByIdQuery.data;
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    autoRun: show,
    apiFlags: {
      addons: false,
      cell: false,
      device: true,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  return (
    show &&
    (fetchVehiclesQuery.isFetching ? (
      <LoadingOverlay />
    ) : (
      <GenericFormModal
        customWidth={550}
        icon={<IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />}
        title={'Assign Configuration'}
        initialValues={{
          groupName: group && group.group_name,
          selectedConfiguration: '',
        }}
        submitHandler={async ({ selectedConfiguration }, formikActions) => {
          // TODO convert to hook
          const { response } = await assignConfirgurationToGroupAPI({
            group,
            selectedConfiguration,
          });
          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validate={(values) => {
          let validation = validateSelectedConfiguration(values);
          return validation;
        }}
        handleCloseModal={handleCloseModal}
        // errorMessage={createGroupMutation.isError ? createGroupMutation.error : null}
        errorMessage={null}
        submitButtonText={'Assign'}
        submitButtonDisabled={disableSubmit}
      >
        {(formikProps) => (
          <AssignConfigurationToGroupContent
            vehicles={vehicles}
            group={group}
            formikProps={formikProps}
            setDisabled={setDisableSubmit}
          />
        )}
      </GenericFormModal>
    ))
  );
};

export default AssignConfigurationsModal;
