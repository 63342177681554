import { connect } from 'react-redux';

import wcpLogo from 'assets/images/wcpLogo@2x.png';
import { toggleSidebar } from 'legacy/core/redux/sidebar/actions';
import ProfileWidgetComponent from 'legacy/core/widgets/profile/ProfileWidgetComponent';
import Header, {
  HeaderAside,
  HeaderInner,
  WCPLogo,
} from 'legacy/shared/v1/styles/components/Header';
import HomeLink from 'legacy/shared/v1/styles/components/HomeLink';
import NavTrigger, { NavTriggerItem } from 'legacy/shared/v1/styles/components/NavTrigger';

const WcpHeader = ({ toggleSidebar, fullscreenMapMode }) => {
  return (
    <Header fullscreenMapMode={fullscreenMapMode}>
      <HeaderInner>
        <HeaderAside flexAlignCenter medium>
          <NavTrigger onClick={toggleSidebar}>
            <NavTriggerItem />

            <NavTriggerItem />

            <NavTriggerItem />
          </NavTrigger>

          <HomeLink to="/livemap">
            <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform®" title="Whelen Cloud Platform®" />
          </HomeLink>
        </HeaderAside>

        <HeaderAside fit>
          <ProfileWidgetComponent avatar="assets/images/profile-avatar@2x.png" />
        </HeaderAside>
      </HeaderInner>
    </Header>
  );
};

export default connect(null, {
  toggleSidebar,
})(WcpHeader);
