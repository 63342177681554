function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export const pointsToHeading = (lat1, lon1, lat2, lon2) => {
  lat1 = degreesToRadians(lat1);
  lon1 = degreesToRadians(lon1);
  lat2 = degreesToRadians(lat2);
  lon2 = degreesToRadians(lon2);
  const x = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const y =
    Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
  const θ = Math.atan2(x, y);
  const bearingInDegrees = ((θ * 180) / Math.PI + 360) % 360;
  return bearingInDegrees;
};
