import dateFnsFormat from 'date-fns/format';
import { Form, Formik } from 'formik';

import FormWrapper, {
  CalculateDiv,
  DateRangeControlsDiv,
  DateRangeValidationDiv,
  FormAsterisk,
  FormCol,
  FormLabelAnimated,
} from 'legacy/shared/v1/styles/components/Form';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import StyledDayPickerInput from 'legacy/shared/v1/ui/StyledDayPickerInput';
import {
  getEndOfDayForDateTimeInUtc,
  getShortDateInIsoFormat,
  getStartOfDayForDateTimeInUtc,
} from 'legacy/shared/v1/utilities/time';

const DateRangeSubmitForm = ({
  children,
  defaultStartDate,
  defaultEndDate,
  isLoading,
  submitHandler,
  analyticsQuery,
  validationFunction,
  disabled,
  confirmText,
}) => {
  return (
    <Formik
      initialValues={{
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      }}
      onSubmit={async ({ startDate, endDate }, formikActions) => {
        let sd = getStartOfDayForDateTimeInUtc(getShortDateInIsoFormat(startDate));
        let ed = getEndOfDayForDateTimeInUtc(getShortDateInIsoFormat(endDate));

        await submitHandler({
          startDate: sd,
          endDate: ed,
        });

        formikActions.setSubmitting(false);
      }}
      validate={validationFunction}
      validator={() => ({})}
    >
      {(formikProps) => (
        <Form>
          <FormWrapper>
            <DateRangeControlsDiv analyticsQuery={analyticsQuery}>
              <FormCol noMinWidth>
                <div>
                  <FormLabelAnimated animated>
                    Start Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                  </FormLabelAnimated>
                  <StyledDayPickerInput
                    name="startDate"
                    placeholder={defaultStartDate}
                    onDayChange={(day) => {
                      formikProps.setFieldValue('startDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                    }}
                  />
                  <DateRangeValidationDiv>{formikProps.errors.startDate}</DateRangeValidationDiv>
                </div>
              </FormCol>
              <FormCol noMinWidth>
                <div>
                  <FormLabelAnimated animated>
                    End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
                  </FormLabelAnimated>
                  <StyledDayPickerInput
                    name="endDate"
                    placeholder={defaultEndDate}
                    onDayChange={(day) => {
                      formikProps.setFieldValue('endDate', `${dateFnsFormat(day, 'MM/dd/yyyy')}`);
                    }}
                  />
                  <DateRangeValidationDiv>{formikProps.errors.endDate}</DateRangeValidationDiv>
                </div>
              </FormCol>
              {children}
              <FormCol noMinWidth>
                <CalculateDiv>
                  <ButtonWithLoader
                    isLoading={isLoading || formikProps.isSubmitting}
                    disabled={disabled}
                    confirmText={confirmText ? confirmText : 'Calculate'}
                    loadingStyleProp={'submittingWithSpinner'}
                    notLoadingStyleProp={'query'}
                    clickHandler={formikProps.submitForm}
                  />
                </CalculateDiv>
              </FormCol>
            </DateRangeControlsDiv>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default DateRangeSubmitForm;
