import { createContext, useEffect } from 'react';

import { useFetchGroupsQuery } from 'legacy/features/vehicleGroups/hooks/v1/useFetchGroupsQuery';
import { useFetchVehiclesQuery } from 'legacy/features/vehicles/hooks/useFetchVehiclesQuery';
import LoadingWrapper from 'legacy/shared/v1/components/LoadingWrapper';
import useProviderState from 'legacy/shared/v1/hooks/useProviderState';
import { setScheduleMaintenanceIntervalTypesReducer } from 'legacy/features/maintenance/providers/reducers/setScheduleMaintenanceIntervalTypesReducer';
import { setAllVehiclesReducer } from 'legacy/features/maintenance/providers/reducers/setAllVehiclesReducer';
import { setAllGroupsReducer } from 'legacy/features/maintenance/providers/reducers/setAllGroupsReducer';
import { setMaintenanceItemsReducer } from 'legacy/features/maintenance/providers/reducers/setMaintenanceItemsReducer';
import { setSelectedVehiclesReducer } from 'legacy/features/maintenance/providers/reducers/setSelectedVehiclesReducer';
import { setScheduleMaintenanceFiltersReducer } from 'legacy/features/maintenance/providers/reducers/setScheduleMaintenanceFiltersReducer';
import { setListingCountReducer } from 'legacy/features/maintenance/providers/reducers/setListingCountReducer';
import { setScheduleMaintenanceIsValidReducer } from 'legacy/features/maintenance/providers/reducers/setScheduleMaintenanceIsValidReducer';

const ScheduleMaintenanceContext = createContext(null);

export const initialState = {
  allVehicles: [],
  allGroups: [],

  filteredVehicles: [],
  selectableVehicles: [],
  selectedVehicles: [],

  maintenanceItems: [],

  selectedGroup: null,
  selectedModel: null,
  selectedIntervalTypes: null,

  listingCount: 0,
  isValid: false,
};

const scheduleMaintenanceActionReducerMap = {
  // setAllVehiclesAction
  SET_ALL_VEHICLES: setAllVehiclesReducer,
  // setAllGroupsAction
  SET_ALL_GROUPS: setAllGroupsReducer,
  // setMaintenanceItemsAction
  SET_MAINTENANCE_ITEMS: setMaintenanceItemsReducer,
  // setSelectedVehiclesAction
  SET_SELECTED_VEHICLES: setSelectedVehiclesReducer,
  // setScheduleMaintenanceFiltersAction
  SET_SCHEDULE_MAINTENANCE_FILTERS: setScheduleMaintenanceFiltersReducer,
  // setSelectedVehiclesAction
  SET_SELECTED_VEHICLES: setSelectedVehiclesReducer,
  // setScheduleMaintenanceIntervalTypesAction
  SET_SCHEDULE_MAINTENANCE_INTERVAL_TYPES: setScheduleMaintenanceIntervalTypesReducer,
  // setListingCountAction
  SET_LISTING_COUNT: setListingCountReducer,
  // setScheduleMaintenanceIsValidAction
  SET_SCHEDULE_MAINTENANCE_IS_VALID: setScheduleMaintenanceIsValidReducer,
};

const ScheduleMaintenanceContextProvider = ({ children }) => {
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    apiFlags: {
      addons: true,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: true,
      obd: true,
    },
  });
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();

  const [state, actions] = useProviderState({
    actionReducerMap: scheduleMaintenanceActionReducerMap,
    initialState,
  });

  // todo - this calls the actions multiple times one for each state change from react query
  useEffect(() => {
    // TEMP FIX
    let allVehicles = vehicles?.map((vehicle) => {
      return {
        ...vehicle,
        vehicleId: vehicle.vehicle_id || vehicle.vehicleId,
      };
    });

    actions.setAllVehiclesAction({ allVehicles });
    actions.setAllGroupsAction({ allGroups: groups });
  }, [vehicles, groups]);

  console.log('state', state);

  return (
    <LoadingWrapper isLoading={fetchVehiclesQuery.isFetching || fetchGroupsQuery.isFetching}>
      <ScheduleMaintenanceContext.Provider
        value={{
          actions,
          state,
        }}
      >
        {children(actions, state)}
      </ScheduleMaintenanceContext.Provider>
    </LoadingWrapper>
  );
};
export default ScheduleMaintenanceContextProvider;
