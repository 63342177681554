import { useFormikContext } from 'formik';

import { alertTypeConstants } from 'legacy/features/alerts/data/enums/alertTypeConstants';
import useAlertFormsProviderContext from 'legacy/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import AlertTriggerValueContainer from 'legacy/features/alerts/ui/components/AlertForms/shared/containers/AlertTriggerValueContainer';
import AlertTypeDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/AlertTypeDropdown';
import VehicleDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/VehicleDropdown';
import VehicleGroupDropdown from 'legacy/features/alerts/ui/components/AlertForms/shared/dropdowns/VehicleGroupDropdown';
import AlertNameField from 'legacy/features/alerts/ui/components/AlertForms/shared/fields/AlertNameField';
import {
  AlertTypeDetailsWrapperDiv,
  InfoTextWrapper,
} from 'legacy/shared/v1/styles/components/ManageAlerts';
import { SectionDivider } from 'legacy/shared/v1/styles/components/SectionDivider';

const AlertDetailsContainer = () => {
  const {
    state: { alert },
  } = useAlertFormsProviderContext();

  const { alertType } = alert.alertDetails.alertInfo;

  return (
    <>
      <AlertNameField forceAnimated={true} />
      <InfoTextWrapper>
        Send an alert when the following condition is true for the selected vehicle(s).
      </InfoTextWrapper>
      <AlertTypeDetailsWrapperDiv>
        <AlertTypeDropdown />
        {alertType !== alertTypeConstants.MAINTENANCE_DUE && <AlertTriggerValueContainer />}
        <SectionDivider mobileOnly />
        <VehicleGroupDropdown />
        <VehicleDropdown />
      </AlertTypeDetailsWrapperDiv>
    </>
  );
};

export default AlertDetailsContainer;
