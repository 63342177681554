import { useEffect, useState } from 'react';
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import { SectionHead } from 'legacy/shared/v1/styles/components/Section';

import { useFetchConfigurationsOtaLogsQuery } from 'legacy/features/configurations/hooks/useFetchConfigurationsOtaLogsQuery';
import DateRangeSubmitForm from 'legacy/shared/v1/components/DateRangeSubmitForm';
import {
  formatISOTimestampToShortDate,
  getNow,
  getAMonthAgo,
} from 'legacy/shared/v1/utilities/time';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';
import { decorateOtaLogData } from 'legacy/shared/v1/utilities/decorateOtaLogData';
import { LogsFiltersDiv } from 'legacy/shared/v1/styles/components/OtaUpdates';
import FilteredDropdown from 'legacy/shared/v2/components/controls/FilteredDropdown/legacy/FilteredDropdown';
import Media from 'react-media';
import { sizes } from 'legacy/shared/v1/utilities/media';
import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import OtaLogCard from 'legacy/features/configurations/mobile/OtaLogCard';
import { ActionButtonWithIcon } from 'legacy/shared/v1/styles/components/Button';
import { QueryAndExportDiv } from 'legacy/shared/v1/styles/components/OtaUpdates';
import { createExportDownload } from 'legacy/shared/v1/utilities/csvExport';
import { validateLogsRange } from 'legacy/shared/v1/utilities/validators';
import { sortDescendingAlpha } from 'legacy/shared/v1/utilities/general';
import { extractDropdownOptions } from 'legacy/shared/v2/utilities/dropdown/dropdownUtils';
import { configurationUpdatesLogsTableColumns } from 'legacy/features/configurations/pages/ConfigurationUpdatesLogsTableColumns';

const ConfigurationUpdatesLogsPage = () => {
  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  const { fetch, isLoading, data } = useFetchConfigurationsOtaLogsQuery({});

  const [filteredLogs, setFilteredLogs] = useState(null);
  const [unfilteredLogs, setUnfilteredLogs] = useState(null);

  const [vehicleNameFilter, setVehicleNameFilter] = useState(null);
  const [actionFilter, setActionFilter] = useState(null);
  const [listingCount, setListingCount] = useState(filteredLogs?.length);

  useEffect(() => {
    decorateOtaLogData(data?.logs);
    setUnfilteredLogs(data?.logs);
    setFilteredLogs(data?.logs?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)));
  }, [data?.logs]);

  useEffect(() => {
    let filteredData = unfilteredLogs
      ?.filter((l) => (vehicleNameFilter ? l.vehicle_name === vehicleNameFilter : true))
      ?.filter((l) => (actionFilter ? l.statusDisplayName === actionFilter : true));

    setFilteredLogs(
      filteredData?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)),
    );
  }, [vehicleNameFilter, actionFilter]);

  useEffect(() => {
    setListingCount(filteredLogs?.length ? filteredLogs.length : 0);
  }, [filteredLogs]);

  const prepareDataForExport = () => {
    let otaLogsForExport = unfilteredLogs.map((l) => ({
      Timestamp: l.formattedTime,
      Vehicle_Name: l.vehicle_name,
      Config_Name: l.config_name,
      Action: l.statusDisplayName,
      Authorizer: l.authorizerDisplay,
    }));

    let csvFileName = 'wcp_config_ota_logs_export';
    createExportDownload(otaLogsForExport, csvFileName);
  };

  return (
    <PageListWrapper>
      <PageListHead stackable logs>
        <SectionHead>
          <PageListTitle>Configuration OTA Logs</PageListTitle>
        </SectionHead>
      </PageListHead>
      <div>
        <QueryAndExportDiv>
          <DateRangeSubmitForm
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            isLoading={isLoading}
            submitHandler={({ startDate, endDate }) => fetch({ startDate, endDate })}
            validationFunction={validateLogsRange}
            confirmText="Generate"
          />
          {filteredLogs?.length > 0 && (
            <ActionButtonWithIcon onClick={prepareDataForExport} logsExport>
              Export to .csv
            </ActionButtonWithIcon>
          )}
        </QueryAndExportDiv>
        {unfilteredLogs && (
          <LogsFiltersDiv configs>
            <FilteredDropdown
              title={'Vehicle'}
              placeholderLabel={'All Vehicles'}
              dropdownOptions={extractDropdownOptions({
                collection: filteredLogs,
                propName: 'vehicle_name',
              })}
              handleItemSelect={(selectedVehicle) => {
                setVehicleNameFilter(selectedVehicle ? selectedVehicle.label : null);
              }}
              defaultItem={null}
              styleOptions={{ restrictHeight: false }}
            />
            <FilteredDropdown
              title={'Action'}
              placeholderLabel={'All Actions'}
              dropdownOptions={extractDropdownOptions({
                collection: filteredLogs,
                propName: 'statusDisplayName',
              })}
              handleItemSelect={(selectedAction) => {
                setActionFilter(selectedAction ? selectedAction.label : null);
              }}
              defaultItem={null}
              styleOptions={{ restrictHeight: false }}
            />
          </LogsFiltersDiv>
        )}
      </div>
      {filteredLogs && (
        <>
          <h5>
            {listingCount === unfilteredLogs?.length
              ? `Total of ${listingCount} Log${listingCount !== 1 ? 's' : ''}`
              : `${listingCount} of ${unfilteredLogs?.length} Log${
                  filteredLogs?.length !== 1 ? 's' : ''
                }`}
          </h5>
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {(matches) =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {filteredLogs.map((fld) => (
                      <OtaLogCard log={fld} config={true} />
                    ))}
                  </Cards>
                </div>
              ) : (
                <WcpTable
                  tableClassName="table-simple"
                  columns={configurationUpdatesLogsTableColumns}
                  tableSourceData={filteredLogs}
                  rowKey={(record, i) => i}
                  defaultSortOrder={SORT_ORDER.DESC}
                  setListingCount={setListingCount}
                  emptyText="No OTA logs are currently associated with this organization"
                />
              )
            }
          </Media>
        </>
      )}
    </PageListWrapper>
  );
};

export default ConfigurationUpdatesLogsPage;
