import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import { addDisplayCommas } from 'legacy/features/maintenance/utilities/formatting';

const VehicleEngineHoursField = ({
  formikProps: { values, touched, handleChange, handleBlur },
}) => {
  const engineHoursValue = addDisplayCommas(values.engineHours);
  return (
    <div>
      <AnimatedField
        name="engineHours"
        placeholder="Engine Hours"
        value={engineHoursValue}
        touched={touched.engineHours}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={true}
      />
    </div>
  );
};

export default VehicleEngineHoursField;
