import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';

export const mapRecipientUserAlertMethod = ({ email, sms }) => {
  return email && sms
    ? alertMethodConstants.SMS_AND_EMAIL
    : email
      ? alertMethodConstants.EMAIL
      : sms
        ? alertMethodConstants.SMS
        : null;
};
