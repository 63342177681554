import { apiCall } from 'legacy/core/api/API';
import { mapAndMassageMaintenanceData } from 'legacy/features/maintenance/utilities/mapping';

export const postMaintenanceApi = async ({ vehicleIds, maintenanceEvent, organizationId }) => {
  // map and massage maintenance data
  const mappedMaintenanceEvent = mapAndMassageMaintenanceData(maintenanceEvent);
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };

  return await apiCall({
    path: `/maintenance`,
    method: 'post',
    queryStringParameters,
    body: {
      vehicle_ids: vehicleIds,
      ...mappedMaintenanceEvent,
    },
  });
};
