import { addAlertRecipientReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/addAlertRecipientReducer';
import { removeAlertRecipientReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/removeAlertRecipientReducer';
import { setApiDataReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/setApiDataReducer';
import { updateAlertDetailsReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertDetailsReducer';
import { updateAlertRecipientReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertRecipientReducer';
import { updateCustomAlertMethodReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateCustomAlertMethodReducer';
import { persistCustomAlertMethodsReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/persistCustomAlertMethodsReducer';
import { setAlertRecipientRowsReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/setAlertRecipientRowsReducer';
import { updateAlertNotificationDetailsReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertNotificationDetailsReducer';
import { removeChildAlertReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/removeChildAlertReducer';
import { addAlertNotificationReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/addAlertNotificationReducer';
import { setGroupMembersReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/setGroupMembersReducer';
import { updateAlertTypeReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertTypeReducer';
import { updateAlertRecipientAlertMethodReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertRecipientAlertMethodReducer';
import { updateUnitOfMeasureReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateUnitOfMeasureReducer';
import { updateAlertAddonReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertAddonReducer';
import { updateAlertTriggerValueReducer } from 'legacy/features/alerts/services/providers/AlertFormsProvider/reducers/updateAlertTriggerValueReducer';

// map actions to reducers
export const alertsActionReducerMap = {
  // set
  ['setApiDataAction']: setApiDataReducer,
  ['setAlertRecipientRowsAction']: setAlertRecipientRowsReducer,

  // set
  ['setGroupMembersAction']: setGroupMembersReducer,

  // add
  ['addAlertNotificationAction']: addAlertNotificationReducer,
  ['addAlertRecipientAction']: addAlertRecipientReducer,

  // remove
  ['removeAlertRecipientAction']: removeAlertRecipientReducer,
  ['removeChildAlertAction']: removeChildAlertReducer,

  // update
  ['updateAlertDetailsAction']: updateAlertDetailsReducer,
  ['updateAlertTypeAction']: updateAlertTypeReducer,
  ['updateAlertNotificationDetailsAction']: updateAlertNotificationDetailsReducer,
  ['updateAlertRecipientAction']: updateAlertRecipientReducer,
  ['updateAlertRecipientAlertMethodAction']: updateAlertRecipientAlertMethodReducer,
  ['updateCustomAlertMethodAction']: updateCustomAlertMethodReducer,

  ['updateUnitOfMeasureAction']: updateUnitOfMeasureReducer,
  ['updateAlertAddonAction']: updateAlertAddonReducer,
  ['updateAlertTriggerValueAction']: updateAlertTriggerValueReducer,

  // custom
  ['persistCustomAlertMethodsAction']: persistCustomAlertMethodsReducer,
};
