import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';
class DropdownOptions {
  constructor(options) {
    this.options = options || [];
  }

  getValueByLabel(label) {
    let value = this.options.find((item) => item.label === label)?.value;
    // TEMP disable error throwing for now
    // if (value === undefined) {
    //   throw new Error('Value not found for label: ' + label);
    // }

    return value;
  }

  getLabelByValue(value) {
    let label = this.options.find((item) => item.value === value)?.label;
    // if (label === undefined) {
    //   throw new Error('Label not found for value: ' + value);
    // }

    return label;
  }

  getOptionByValue(value) {
    let option = this.options.find((item) => item.value === value);
    return option;
  }

  getOptionByLabel(label) {
    let option = this.options.find((item) => item.label === label);
    // if (option === undefined) {
    //   throw new Error('Options not found for label: ' + label);
    // }

    return option;
  }

  // sorted by default unless customSort is true, then we maintain passed in order
  getAllOptions() {
    return this.options;
  }

  getAllOptionsSortedAndFiltered({ sortFn = (x) => x, filterFn = (x) => x }) {
    console.log('this.options', this.options);
    console.log('sortFn', sortFn);
    return this.options.filter(filterFn).sort(sortFn);
  }

  getAllLabels() {
    return this.options.map((item) => item.label).sort(sortAscendingAlpha);
  }

  getAllValues() {
    return this.options.map((item) => item.value).sort(sortAscendingAlpha);
  }
}

export default DropdownOptions;
