import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import VehicleEditFormContainer from 'legacy/features/vehicles/components/forms/VehicleEditFormContainer';
import { useFetchSingleVehicleQuery } from 'legacy/features/vehicles/hooks/useFetchSingleVehicleQuery';
import useSubscriptionDevicesQuery from 'legacy/features/vehicles/hooks/queries/useSubscriptionDevicesQuery';
import LoadingWrapper from 'legacy/shared/v1/components/LoadingWrapper';
import PageListWrapper from 'legacy/shared/v1/styles/components/PageList';

const VehicleEditPage = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  const subscriptionDevicesQuery = useSubscriptionDevicesQuery();

  const fetchSingleVehicleQuery = useFetchSingleVehicleQuery({
    vehicleId,
    enableRefetchInterval: false,
    apiFlags: {
      addons: true,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: true,
      obd: true,
    },
    mappingEnabled: true,
  });

  const vehicle = fetchSingleVehicleQuery.data;
  // HELPERS
  const navigateBackToVehicleList = () => {
    navigate('/vehicles');
  };
  return (
    <PageListWrapper>
      <LoadingWrapper
        isLoading={
          fetchSingleVehicleQuery.isFetching ||
          !vehicle ||
          (subscriptionDevicesQuery.isFetching && !subscriptionDevicesQuery.data)
        }
      >
        <VehicleEditFormContainer
          handleRedirect={navigateBackToVehicleList}
          vehicle={vehicle}
          userState={userState}
          mappedVsgs={subscriptionDevicesQuery.data}
        />
      </LoadingWrapper>
    </PageListWrapper>
  );
};

export default VehicleEditPage;
