import { apiCall } from 'legacy/core/api/API';

export const getAlertsForRecipientGroupApi = async ({ organizationId, recipientGroupId }) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/alerts`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
      user_group_id: `${recipientGroupId}`,
      names_only: true,
    },
  });
  const alertNames = apiResponse.data.message.alertnames;
  return alertNames;
};
