import React from 'react';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import { CloseButton, Container, Title } from 'legacy/shared/v1/styles/components/Modal';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const CustomModal = ({ title, icon, children, handleCloseModal, customWidth }) => {
  return (
    <ModalPortal onRequestClose={handleCloseModal}>
      <Container autoHeight maxWidth={customWidth}>
        <CloseButton onClick={handleCloseModal}>
          <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
        </CloseButton>
        <Title>
          {icon && <IconSvgComponent svgFileName={icon} style={{ marginRight: 10 }} />}
          {title}
        </Title>
        {children}
      </Container>
    </ModalPortal>
  );
};

export default CustomModal;
