import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import ProgressBar from 'legacy/shared/v1/components/ProgressBar';
import { firmwareStatus } from 'legacy/shared/v1/constants/firmwareStatus';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import { permissionData } from 'legacy/shared/v1/constants/users';
import { disabledCondition } from 'legacy/features/firmware/constants/constants';
import { disabledConditionToMessage } from 'legacy/features/firmware/utilities/disabledLogic';

const FirmwareUpdateTableByDevice = ({
  deviceUpdates,
  vehicleStatuses,
  handleUpdateFirmware,
  updatePostLoading,
  handleUpdateListingCount,
}) => {
  deviceUpdates?.forEach((du) => {
    du.fwStatusDisplayName = firmwareStatus[du.firmwareStatus].displayName;
    du.fwStatusProgress = firmwareStatus[du.firmwareStatus].progress;
    du.fwStatusButtonText = firmwareStatus[du.firmwareStatus].buttonText
      ? firmwareStatus[du.firmwareStatus].buttonText
      : null;
  });
  return (
    <>
      <div>
        <WcpTable
          tableClassName="table-simple"
          tableSourceData={deviceUpdates}
          rowKey={(record, i) => i}
          emptyText="No data to display."
          setListingCount={handleUpdateListingCount}
          columns={[
            {
              title: 'Vehicle Name',
              displayProperty: 'label',
              render: ({ rowData, cellJsx }) => {
                return (
                  <>
                    <IconSvgComponent
                      svgFileName={
                        vehicleStatuses[rowData.vehicleId]?.isActive
                          ? vehicleStatuses[rowData.vehicleId]?.isResponding
                            ? 'car-outline-responding'
                            : 'car-outline-online'
                          : 'car-outline-offline'
                      }
                    />
                    &nbsp;
                    <> {cellJsx}</>
                  </>
                );
              },
            },
            {
              title: 'License Plate',
              displayProperty: 'licensePlate',
            },
            {
              title: 'Device Id',
              displayProperty: 'deviceId',
            },

            {
              title: 'Initial Firmware Version',
              displayProperty: 'versionInitial',
            },
            {
              title: 'Firmware Status',
              displayProperty: 'fwStatusDisplayName',
            },
            {
              title: 'Update Progress',
              displayProperty: 'fwStatusProgress',
              width: '15%',
              render: ({ rowData }) => {
                if (rowData.hasNeverStartedOta) {
                  return <></>;
                } else {
                  return <ProgressBar progress={rowData.fwStatusProgress} />;
                }
              },
            },
            {
              title: 'Update Action',
              displayProperty: 'fwStatusButtonText',
              className: 'action',
              searchEnabled: false,
              render: ({ rowData }) => {
                let disabledReason = !vehicleStatuses[rowData.vehicleId]?.isActive
                  ? disabledCondition.OFFLINE
                  : vehicleStatuses[rowData.vehicleId]?.isResponding
                    ? disabledCondition.RESPONDING
                    : null;
                return rowData.fwStatusButtonText !== null && rowData.fwStatusButtonText !== '' ? (
                  <PermissionProtectedElement
                    requiredPermissions={[permissionData.editotafirmware]}
                  >
                    <ButtonWithLoader
                      disabled={
                        !vehicleStatuses[rowData.vehicleId]?.isActive ||
                        vehicleStatuses[rowData.vehicleId]?.isResponding
                      }
                      loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                      notLoadingStyleProp={'otaUpdate'}
                      confirmText={rowData.fwStatusButtonText}
                      isLoading={rowData.deviceId === updatePostLoading}
                      clickHandler={async () => {
                        await handleUpdateFirmware(rowData);
                      }}
                      title={disabledConditionToMessage[disabledReason]}
                    />
                  </PermissionProtectedElement>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
export default FirmwareUpdateTableByDevice;
