import React from 'react';
import { connect } from 'react-redux';

import { getPermissions, selectUserRole } from 'legacy/core/redux/user/selectors';

const PermissionProtectedElement = ({
  requiredPermissions,
  userPermissions,
  allowedRoles,
  userRole,
  alternativeComponent = null,
  children,
}) => (
  <>
    {requiredPermissions.every((p) => userPermissions.includes(p)) &&
    (allowedRoles ? allowedRoles.includes(userRole) : true)
      ? { ...children }
      : alternativeComponent}
  </>
);
export default connect((state) => {
  const user = state.user;
  return {
    userRole: selectUserRole(state.user.user),
    userPermissions: getPermissions(user),
  };
})(PermissionProtectedElement);
