import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoggedOutForm from 'legacy/core/components/authentication/components/LoggedOutForm';
import { setTwoFactorMethod } from 'legacy/core/redux/user/actions';
import { mfaTypes } from 'legacy/shared/v1/constants/users';
import {
  RadioButton,
  RadioButtonContainer,
  RadioButtonLabel,
} from 'legacy/shared/v1/styles/components/Radio';

const TwoFactorSelection = ({ redirectToVerifyMfa, dispatchSetTwoFactorMethod }) => {
  const [selectedOption, selectOption] = useState(mfaTypes.SMS);

  const onSubmit = () => {
    dispatchSetTwoFactorMethod({ twoFactorMethod: selectedOption });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        onSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return redirectToVerifyMfa ? (
    <Navigate to={'/twofactorverification'} />
  ) : (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ isSubmitting, submitForm }) => (
        <LoggedOutForm
          formTitle={'Two-Factor Authentication'}
          formMessage={'Please choose your two-factor authentication method'}
          submitHandler={submitForm}
          submitText={'Continue'}
          isLoading={isSubmitting}
        >
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.SMS)}>
            <RadioButton
              type="radio"
              value={mfaTypes.SMS}
              checked={selectedOption === mfaTypes.SMS}
            />
            <RadioButtonLabel>Text me a code</RadioButtonLabel>
          </RadioButtonContainer>
          <RadioButtonContainer onClick={() => selectOption(mfaTypes.AUTHENTICATION_APP)}>
            <RadioButton
              type="radio"
              value={mfaTypes.AUTHENTICATION_APP}
              checked={selectedOption === mfaTypes.AUTHENTICATION_APP}
            />
            <RadioButtonLabel>Use an authenticator app</RadioButtonLabel>
          </RadioButtonContainer>
        </LoggedOutForm>
      )}
    </Formik>
  );
};

export default connect(
  (state) => ({ redirectToVerifyMfa: state.user.redirectToVerifyMfa }),
  (dispatch) => ({
    dispatchSetTwoFactorMethod: async ({ twoFactorMethod }) => {
      return dispatch(await setTwoFactorMethod({ twoFactorMethod }));
    },
  }),
)(TwoFactorSelection);
