import React from 'react';

import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

import colors from 'legacy/shared/v1/constants/colors';
export default function ({ width, style }) {
  return (
    <div
      style={{
        ...styles.dropzoneImageContainer,
        ...(width && {
          width: `${width}px`,
          display: 'inline-block',
          margin: '0 10px 0 0',
        }),
      }}
    >
      <IconSvgComponent
        style={styles.dropzoneIcon}
        svgFileName={'arrow-up'}
        alt="Upload"
        title="Upload"
        beforeInjection={(svg) => {
          svg.querySelectorAll('path')[0].setAttribute('fill', colors.midnight);
        }}
      />
      <div
        style={{
          ...styles.dropzoneCircle,
          ...(width && { borderWidth: `${width * 0.1}px` }),
          ...(width && { paddingBottom: `calc(100% - ${width * 0.2}px)` }),
        }}
      />
    </div>
  );
}

const styles = {
  dropzoneImageContainer: {
    position: 'relative',
    width: '17%',
    margin: '0 auto 14px',
  },
  dropzoneCircle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '0px',
    margin: '0 auto',
    paddingBottom: 'calc(100% - 12px)',
    borderRadius: '50%',
    borderColor: colors.midnight,
    borderWidth: '6px',
    borderStyle: 'solid',
  },
  dropzoneIcon: {
    position: 'absolute',
    width: '43.4%',
    top: '22%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
};
