import { DateTime } from 'luxon';
import { convertDuration, getSecondsFromStartTime } from 'legacy/shared/v1/utilities/time';

export const mapVehicleDataToCsv = ({ vehicles }) => {
  return vehicles?.map((v) => {
    return {
      Name: v.meta.label,
      VSG_ID: v.meta.formatted_device_id,
      Type: v.meta.formatted_vehicle_type,
      Year: v.meta.mfg_year,
      Make: v.meta.make,
      Model: v.meta.model,
      License_Plate: v.meta.license_plate,
      License_State: v.meta.license_state,
      VIN: v.meta.vin,
      Security_Lock: v.meta.formatted_lock_status,
      Last_Seen: v.gps?.timestamp_gps
        ? DateTime.fromISO(v.gps.timestamp_gps).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        : 'N/A',
      Offline_Time:
        v.gps?.timestamp_gps && v.onlineStatus && v.onlineStatus !== 'ACTIVE'
          ? convertDuration(getSecondsFromStartTime({ fromTime: v.gps.timestamp_gps }), true)
          : 'N/A',

      Notes: v.meta.notes ? v.meta.notes : '',
      Alias: v.meta.alias ? v.meta.alias : '',
      Peripherals: v.device?.CenComCore?.devices
        ? v.device.CenComCore.devices.map((p) => p.product_name).join(' | ')
        : 'N/A',
      Addons: v.addons ? v.addons.map((a) => a.type).join(' | ') : 'N/A',
    };
  });
};
