import { mapUserActivityLogsToCsv } from 'legacy/features/organizations/helpers/mapping';
import ExportCsvButton from 'legacy/shared/v1/widgets/ExportCsvButton';

const UserActivityLogsExportButton = ({ userActivityLogs, userName }) => {
  const exportData = mapUserActivityLogsToCsv({ userActivityLogs });
  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={`wcp_user_activity_log_export_for_ ${userName}`}
    />
  );
};

export default UserActivityLogsExportButton;
