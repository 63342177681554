/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'legacy/shared/v1/constants/colors';
import device from 'legacy/shared/v1/constants/sizing';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import theme from 'legacy/shared/v1/styles/global/theme';

export const DeviceColumn = styled.div`
  font-weight: 500;

  ${(props) =>
    props.dueForRenewal &&
    `
    color: ${colors.midnight};
      font-weight: 800;
  `}

  ${(props) =>
    props.highAlert &&
    `
    color: ${colors.cherry};
      font-weight: 800;
  `}
`;

export const SubscriptionAlertIconWrapper = styled.span`
  margin-right: 7px;
`;

export const SubscriptionAlertWrapper = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  padding-right: 20px;
  border: 3px solid ${colors.midnight};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: 'Col' 'Col' 'Col';
  grid-template-columns: 40px 1fr 10px;
  grid-template-rows: 1fr;

  @media ${device.medium} {
    box-sizing: border-box;
    width: fit-content;
    padding-right: 20px;
    border: 3px solid ${colors.midnight};
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
    display: grid;
    grid-gap: 10px;

    grid-template-areas: 'Col' 'Col' 'Col';
    grid-template-columns: 50px 1fr 10px;
    grid-template-rows: 1fr;
  }

  ${(props) =>
    props.highAlert &&
    `
    border: 3px solid ${colors.cherry};
    @media ${device.medium} {
        border: 3px solid ${colors.cherry};
      }
  `}
`;

export const ClearButtonWrapper = styled.div`
  padding: 3px;
`;

export const PremiumTagDiv = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${colors.haze};
  color: ${colors.cotton};
  transition: 1s translate;
  margin-top: 5px;
  word-break: break-all;
  text-transform: uppercase;
`;

export const SummaryLineWrapper = styled.div`
  margin-bottom: 3px;
`;

export const SummaryLabel = styled.label`
  font-size: 14px;
  font-weight: 800;
  color: ${colors.haze};
`;
export const SummaryValue = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.haze};
  margin-bottom: 5px;
`;

export const SubscriptionOverviewTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.midnight};
  margin-bottom: 10px;
`;

export const SubscriptionSummaryWrapper = styled.div`
  margin-bottom: 50px;
  @media ${device.largeAnalytics} {
    margin-bottom: 20px;
  }
`;
