import { RemoveButton } from 'legacy/shared/v1/styles/components/Button';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const RemoveMaintenanceItemContainerButton = ({ handleRemoveMaintenanceItem }) => {
  return (
    <RemoveButton type="button" lineItem title="Remove">
      <IconSvgComponent
        svgFileName={'remove-feature'}
        alt="Remove"
        onClick={handleRemoveMaintenanceItem}
      />
    </RemoveButton>
  );
};

export default RemoveMaintenanceItemContainerButton;
