import {
  requiredValidator,
  regexValidator,
  alphanumericExtendedRegex,
  alphanumericExtendedRegexError,
  characterLimitValidator,
  positiveIntegerRegex,
  positiveIntegerRegexError,
} from 'legacy/shared/v1/utilities/validators';

export const alertValidators = {
  alertNameFieldValidation: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  alertTypeFieldValidation: requiredValidator(),
  alertTriggerValueFieldValidation: requiredValidator(
    regexValidator(positiveIntegerRegex, positiveIntegerRegexError),
  ),
  unitOfMeasureFieldValidation: requiredValidator(),
  alertAddonFieldValidation: requiredValidator(),
  triggerDropdownValueValidation: requiredValidator(),
  alertMethodFieldValidation: requiredValidator(),
  alertRecipientFieldValidation: requiredValidator(),
};
