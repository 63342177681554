import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { LivemapApi } from 'legacy/features/livemap/provider/LivemapContextProvider';
import {
  ActivityDot,
  GeofenceActvityItemDetail,
  GeofenceActvityItemWrapperDiv,
} from 'legacy/shared/v1/styles/components/Geofences';
import {
  FilterLabel,
  LivemapRightSidebarAside,
  LiveMapRightSidebarWrapperDiv,
  SidebarHideToggle,
  SidebarShowToggle,
} from 'legacy/shared/v1/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import { formatISOTimestampToLongDateWithTime } from 'legacy/shared/v1/utilities/time';

const LivemapGeofencesSidebarContainer = ({ geofenceActivity }) => {
  const {
    actions: { setLivemapSidebarStates },
    state: { livemapGeofencesSidebarOpen, isFullScreenMode },
  } = useContext(LivemapApi);

  return (
    <LivemapRightSidebarAside expanded={livemapGeofencesSidebarOpen} fullScreen={isFullScreenMode}>
      {
        <Scrollbars
          style={{
            width: livemapGeofencesSidebarOpen ? '100%' : 0,
            height: '100%',
            zIndex: 1,
            backgroundColor: 'white',
            transition: livemapGeofencesSidebarOpen ? null : 'all 1.15s',
            transitionDelay: '.5s',
          }}
        >
          <LiveMapRightSidebarWrapperDiv>
            <FilterLabel>Geofence Activity</FilterLabel>
            {livemapGeofencesSidebarOpen && (
              <SidebarHideToggle
                onClick={() => {
                  return setLivemapSidebarStates({
                    livemapGeofencesSidebarOpen: false,
                  });
                }}
              >
                <IconSvgComponent
                  svgFileName="hide-right-side-sidebar"
                  title="Hide live geofence activity"
                  alt="Hide live geofence activity"
                />
              </SidebarHideToggle>
            )}
            {geofenceActivity.length > 0
              ? geofenceActivity
                  .slice(0)
                  .reverse()
                  .map((a) => (
                    <GeofenceActvityItemWrapperDiv>
                      <ActivityDot></ActivityDot>
                      <GeofenceActvityItemDetail
                        title={
                          a.alias
                            ? `Alias: ${a.alias || ''}, Vehicle Name: ${a.vehicle_label}`
                            : ` Vehicle Name: ${a.vehicle_label}`
                        }
                      >
                        <div>{`${formatISOTimestampToLongDateWithTime(a.timestamp)}`}</div>
                        <div>{`${a.geofence_name} ${a.action} by ${
                          a.alias ? `${a.alias} (${a.vehicle_label})` : a.vehicle_label
                        }`}</div>
                      </GeofenceActvityItemDetail>
                    </GeofenceActvityItemWrapperDiv>
                  ))
              : 'No activity'}
          </LiveMapRightSidebarWrapperDiv>
        </Scrollbars>
      }
      <SidebarShowToggle
        rightSideSidebar
        isOpen={livemapGeofencesSidebarOpen}
        onClick={() =>
          setLivemapSidebarStates({
            livemapGeofencesSidebarOpen: !livemapGeofencesSidebarOpen,
          })
        }
      >
        {livemapGeofencesSidebarOpen ? null : (
          <IconSvgComponent
            svgFileName="show-geofences-activity-sidebar"
            title="Show live geofence activity"
            alt="Show live geofence activity"
          />
        )}
      </SidebarShowToggle>
    </LivemapRightSidebarAside>
  );
};
export default LivemapGeofencesSidebarContainer;
