import { useEffect, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import MaintenanceLogsExport from 'legacy/features/maintenance/components/MaintenanceLogs/buttons/MaintenanceLogsExport';
import MaintenanceLogsCardContainer from 'legacy/features/maintenance/components/MaintenanceLogs/mobile/MaintenanceLogsCardContainer';
import MaintenanceLogsTable from 'legacy/features/maintenance/components/MaintenanceLogs/table/MaintenanceLogsTable';
import useFetchMaintenanceLogsQuery from 'legacy/features/maintenance/hooks/queries/useFetchMaintenanceLogsQuery';
import {
  addDisplayCommas,
  getIntervalDisplay,
  getMaintenanceTypeDisplay,
  getNotesContent,
} from 'legacy/features/maintenance/utilities/formatting';
import { useFetchSingleVehicleQuery } from 'legacy/features/vehicles/hooks/useFetchSingleVehicleQuery';
import ListingCount from 'legacy/shared/v1/components/ListingCount';
import LoadingWrapper from 'legacy/shared/v1/components/LoadingWrapper';
import MediaWrapper from 'legacy/shared/v1/components/MediaWrapper';
import { VehicleMakeModelTitle } from 'legacy/shared/v1/styles/components/Maintenance';
import PageListWrapper, {
  ExportDiv,
  ExportWrapper,
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import { sortDescendingAlpha } from 'legacy/shared/v1/utilities/general';
import { formatISOTimestampToLongDate } from 'legacy/shared/v1/utilities/time';
import { DateWrapper } from 'legacy/shared/v1/styles/components/PageList';
import DateRangeSubmitForm from 'legacy/shared/v1/components/DateRangeSubmitForm';
import { NoDataDiv } from 'legacy/shared/v1/styles/components/Analytics';
import {
  formatISOTimestampToShortDate,
  getAMonthAgo,
  getNow,
} from 'legacy/shared/v1/utilities/time';
import { validateLogsRange } from 'legacy/shared/v1/utilities/validators';

const MaintenanceLogs = ({ vehicleId }) => {
  const [filteredLogsData, setFilteredLogsData] = useState([]);
  const [listingCount, setListingCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const fetchSingleVehicleQuery = useFetchSingleVehicleQuery({
    vehicleId,
    enableRefetchInterval: false,
  });
  const vehicle = fetchSingleVehicleQuery.data;

  const [serverError, setServerError] = useState(false);
  const [maintenanceLogs, refetch, maintenanceLogsQueryKey] = useFetchMaintenanceLogsQuery({
    startDate,
    endDate,
    vehicleId,
  });

  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  useEffect(() => {
    if (startDate && endDate && vehicleId) {
      refetch();
    }
  }, [startDate, endDate]);
  const isFetching = useIsFetching(maintenanceLogsQueryKey);

  useEffect(() => {
    if (maintenanceLogs) {
      setListingCount(maintenanceLogs.length || 0);
      maintenanceLogs.forEach((log) => {
        log.formattedTime = formatISOTimestampToLongDate(log.completed_date);
        log.intervalDisplay = getIntervalDisplay(log);
        log.maintenanceTypeDisplay = getMaintenanceTypeDisplay(log.maintenance_type);
        log.notesDisplay = getNotesContent(log);
        log.odometerDisplay = log.completed_odometer
          ? addDisplayCommas(log.completed_odometer)
          : 'N/A';
        log.engineHoursDisplay = log.completed_engine_hours
          ? addDisplayCommas(log.completed_engine_hours)
          : 'N/A';
        log.markedCompletedByDisplay = log.marked_completed_by_given_name
          ? `${log.marked_completed_by_given_name} ${log.marked_completed_by_family_name}`
          : 'N/A';
      });
      setFilteredLogsData(
        maintenanceLogs.sort((l1, l2) => sortDescendingAlpha(l1.completed_date, l2.completed_date)),
      );
    }
  }, [maintenanceLogs]);

  return (
    <PageListWrapper>
      <PageListHead>
        <PageListTitle>
          {maintenanceLogs || isFetching
            ? `Maintenance Logs for ${vehicle ? vehicle.meta.label : ''}`
            : `Please choose a date range`}
          {vehicle && (
            <VehicleMakeModelTitle>{`${vehicle.meta.make} ${vehicle.meta.model}`}</VehicleMakeModelTitle>
          )}
        </PageListTitle>
      </PageListHead>
      <DateWrapper>
        <DateRangeSubmitForm
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          isLoading={isFetching}
          submitHandler={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          validationFunction={validateLogsRange}
          confirmText="Generate"
        />
      </DateWrapper>
      {serverError && <NoDataDiv>Server error: No message was returned from the server</NoDataDiv>}
      {filteredLogsData.length > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <MaintenanceLogsExport
              maintenanceLogs={filteredLogsData}
              vehicleName={vehicle?.meta.label}
            />
          </ExportDiv>
        </ExportWrapper>
      )}

      {filteredLogsData && (
        <>
          <ListingCount
            listingCount={listingCount}
            totalCount={filteredLogsData.length}
            itemTypeName={'Log'}
          />

          <MediaWrapper
            mobileComponent={<MaintenanceLogsCardContainer records={filteredLogsData} />}
            nonMobileComponent={
              <MaintenanceLogsTable
                records={filteredLogsData}
                handleSetListingCount={setListingCount}
              />
            }
          />
        </>
      )}
    </PageListWrapper>
  );
};

export default MaintenanceLogs;
