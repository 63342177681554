import { fetchAPICall, getAuthToken } from 'legacy/core/api/API';
import apiUrl from 'legacy/shared/v1/utilities/url';

export const sendHelpRequest = async ({ ticket, description, attachments }) => {
  const body = new FormData();
  body.append('Ticket', ticket);
  body.append('Description', description);

  if (attachments) {
    // loop through files
    for (var i = 0; i < attachments.length; i++) {
      // get item
      let file = attachments.item(i);
      body.append('Attachments', file);
    }
  }
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/ticket`,
    method: 'POST',
    headers: {
      Authorization: getAuthToken(),
    },
    body,
  });
};
