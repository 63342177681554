import { getDaysUntilExpiration } from 'legacy/shared/v1/utilities/organizations';

export const getFirmwareUpdateAlertMessage = (incompleteUpdates) => {
  const { product_name, product_type, product_id, channel, version } =
    incompleteUpdates.length > 0 ? incompleteUpdates[0] : {};
  if (incompleteUpdates.length === 0) {
    return {};
  }
  return incompleteUpdates.length > 1
    ? {
        message: `You have an update available for ${
          product_name ? product_name : product_type
        } and ${incompleteUpdates.length - 1} other product${
          incompleteUpdates.length - 1 == 1 ? '' : 's'
        }.`,
        id: 0,
        link: {
          text: 'Update Now',
          to: '/updates',
        },
      }
    : {
        message: `You have an update available for ${product_name ? product_name : product_type}.`,
        id: 1,
        link: {
          text: 'Update Now',
          to: `/updates/${product_id}/${channel}/${version}`,
        },
      };
};

export const getFirmwareUpdateAlerts = (incompleteUpdates) => {
  return incompleteUpdates.map((update) => {
    const { channel, product_name, product_type, version, product_id } = update;
    return {
      id: `${product_id}-${channel}-${version}`,
      message: `${product_name ? product_name : product_type} Update`,
      version,
      link: `/updates/${product_id}/${channel}/${version}`,
    };
  });
};

export const getSubscriptionExpiryAlertMessage = (filteredOrganization) => {
  return {
    message: `This account has no active subscriptions, please contact your Field Solutions Engineer if you wish to resubscribe.`,
    id: 2,
  };
};
