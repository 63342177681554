import React from 'react';
import {
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

const AssignConfigurationMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Assign Configuration</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default AssignConfigurationMenuItem;
