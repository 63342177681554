import React from 'react';

import { FilteredDropdownSelectorDiv } from 'legacy/shared/v2/styles/FilteredDropdownStyles';
import { ClearFilterIcon } from 'legacy/shared/v1/styles/components/Icons';
import { TruncatedText } from 'legacy/shared/v1/styles/components/TruncatedText';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

const FilteredDropdownSelector = ({
  // styles
  narrowWidth,
  hideClearX,
  placeholderLabel,

  // parent state
  isOpen,
  setIsOpen,
  selectedItem,
  onClearFilter,
}) => {
  const clearFilterEnabled = selectedItem && selectedItem !== placeholderLabel && !hideClearX;

  return (
    <FilteredDropdownSelectorDiv
      filterDropdownContainer
      narrowWidth={narrowWidth}
      onMouseDown={(e) => {
        setIsOpen(!isOpen);
        e.preventDefault();
      }}
    >
      <div
        style={{
          marginRight: '10px',
          width: '160px',
          cursor: 'pointer',
        }}
      >
        <TruncatedText title={selectedItem?.label || placeholderLabel}>
          {selectedItem?.label || placeholderLabel}
        </TruncatedText>
      </div>
      <>
        {clearFilterEnabled && (
          <div
            // this wouldn't work on ClearFilterIcon so I wrapped in a div
            onClick={(e) => {
              onClearFilter();
              e.preventDefault();
            }}
          >
            <ClearFilterIcon svgFileName={'red-x'} title="Clear selection" alt="Clear selection" />
          </div>
        )}

        <IconSvgComponent
          svgFileName={'dropdown-caret'}
          svgStyle={{
            cursor: 'pointer',
          }}
          title="Change selection"
          alt="Change selection"
        />
      </>
    </FilteredDropdownSelectorDiv>
  );
};

export default FilteredDropdownSelector;
