import React, { useState } from 'react';
import { connect } from 'react-redux';

import AnalyticsHeatmapContainer from 'legacy/features/analyticsHeatmap/components/AnalyticsHeatmapContainer';
import useAPIData from 'legacy/shared/v1/hooks/useAPIData';
import { fetchVehicles } from 'legacy/core/redux/vehicles/actions';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';

const AnalyticsHeatmapPage = ({ filteredOrganizationId, fetchVehicles }) => {
  const vehiclesLoaded = useAPIData({
    fetchAPIData: () => fetchVehicles(),
    dependencies: [filteredOrganizationId],
  });

  return vehiclesLoaded ? (
    <AnalyticsHeatmapContainer organizationId={filteredOrganizationId} />
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  (state) => ({
    filteredOrganizationId: state.user.filteredOrganizationId,
  }),
  (dispatch) => ({
    fetchVehicles: () => dispatch(fetchVehicles()),
  }),
)(AnalyticsHeatmapPage);
