import { Component } from 'react';

import {
  SimpleDropdown,
  SimpleDropdownWrapper,
} from 'legacy/shared/v1/styles/components/DropdownMenu';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

class SimpleDropdownComponent extends Component {
  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const {
      active,
      vehicleId,
      toggleEditModal,
      toggleDeleteModal,
      onClick,
      children,
      innerRef,
      toggleRef,
      disabled,
    } = this.props;

    return (
      <SimpleDropdownWrapper innerRef={innerRef}>
        <a
          style={styles.dropdownToggle}
          disabled={disabled}
          className={active ? 'active' : ''}
          href="#"
          onClick={onClick}
          ref={toggleRef}
          title="More options"
        >
          <IconSvgComponent svgFileName={'dots'} alt="More options" title="More options" />
        </a>

        {active ? <SimpleDropdown>{this.props.children}</SimpleDropdown> : null}
      </SimpleDropdownWrapper>
    );
  }
}

export default SimpleDropdownComponent;

const styles = {
  dropdownToggle: {
    backgroundColor: 'transparent',
    width: 20,
    height: 30,
    border: 'none',
  },
};
