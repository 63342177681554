import SimpleConfirmationModal from 'legacy/shared/v1/ui/modals/SimpleConfirmationModal';

const OutOfServiceModal = ({ modalData: { show }, handleCloseModal }) => {
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        submitHandler={handleCloseModal}
        bodyText={`Vehicles marked as Out of Service will be removed from the live map and not be returned in analytic calculations.`}
        title={`Out of Service`}
        confirmText="OK"
      />
    )
  );
};

export default OutOfServiceModal;
