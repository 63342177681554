import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';

export const mapboxAccessToken =
  'pk.eyJ1IjoibWV0aXN0cmVhbWVyaWMiLCJhIjoiY2pqZTduOWkzMDFtZTNxbXg0enV5dTVhdSJ9.mVZ_OLbaBNWv0nG-4X1oVw';

export const mapboxStyleUrl = 'mapbox://styles/metistreameric/cm1s17kp100oj01pdax7x35f5';
export const mapboxSatelliteStyleUrl = 'mapbox://styles/metistreameric/cm2eu46t5009w01p989399kvv';

export const MAPBOX_STYLE = {
  STREET_MODE_URL: 'mapbox://styles/metistreameric/cm1s17kp100oj01pdax7x35f5',
  SATELLITE_MODE_URL: 'mapbox://styles/metistreameric/cm2eu46t5009w01p989399kvv',
};

export const toggleMapboxStyleUrl = (styleUrl) => {
  if (styleUrl === MAPBOX_STYLE.STREET_MODE_URL) {
    return MAPBOX_STYLE.SATELLITE_MODE_URL;
  } else if (styleUrl === MAPBOX_STYLE.SATELLITE_MODE_URL) {
    return MAPBOX_STYLE.STREET_MODE_URL;
  }
};

export const MAP_ANIMATION_DURATION = 5000;

export const whelenGpsCoordinates = [
  [-72.72492999116324, 41.30582324929583],
  [-72.28259500887938, 41.46358974439414],
];

export const stLouisCoordinates = [
  // st louis
  [-89.938311, 38.596492],
  [-89.938311, 38.596492],
];
