import { useSelector } from 'react-redux';
import getNavigationMenuData from 'legacy/core/navigation/data/navigationMenuData';
import { getOrganization } from 'legacy/core/redux/organizations/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useNavigationData = () => {
  const userOrgId = useSelector((state) => state.user.user.organizationId);
  const filteredOrganizationId = useSelector(
    (state) => getOrganization(state, state.user.filteredOrganizationId)?.organizationId,
  );

  const { ldBillingEnabled } = useFlags();

  let navItemsObject = getNavigationMenuData({
    userOrgId,
    filteredOrganizationId,
  });

  if (!ldBillingEnabled) {
    delete navItemsObject['ORGANIZATION_DROPDOWN'].dropdown.DEVICES_AND_BILLING;
  }

  return navItemsObject;
};

export default useNavigationData;
