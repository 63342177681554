import { formatISOTimestampToGivenFormat, getNow } from 'legacy/shared/v1/utilities/time';

export const createExportDownload = (exportData, csvFileName) => {
  let csvRecords = JSON.parse(JSON.stringify(exportData));
  let headers = Object.keys(csvRecords[0]).join(',');

  // preserve commas within fields by adding quotes around each field to prevent breaking the csv columns
  let records = csvRecords
    .map((record) =>
      Object.values(record)
        .map((column) =>
          column === null
            ? ''
            : `"${column.toString().replaceAll(`\"`, '').replaceAll('\n', ' ')}"`,
        )
        .join(','),
    )
    .join('\n');
  let data = 'data:text/csv;charset=utf-8,' + headers + '\n' + records;
  let fileName = csvFileName + '.csv';
  var encodedUri = encodeURI(data);
  var a = document.createElement('a');
  a.setAttribute('href', encodedUri);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);

  a.click();
};

export const getCsvFileName = ({ fileNamePrefix, date = getNow() }) => `${fileNamePrefix}`;
