import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { updateAllFirmwareForSingleVehicle } from 'legacy/core/api/firmware';
import {
  partialUpdateFailure,
  updateFailure,
  updateFirmwareSuccess,
} from 'legacy/features/firmware/helpers/notifications';

const useUpdateAllFirmwareByVehicleMutation = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const updateAllFirmwareByVehicleMutation = useMutation({
    mutationFn: (vehicleId) =>
      updateAllFirmwareForSingleVehicle({
        ...vehicleId,
        ...{ organizationId: filteredOrganizationId },
      }),

    onSuccess: ({ response, status }) => {
      // { response : message [], status, headers}
      if (status === 207) {
        dispatch(partialUpdateFailure({ responseMessageArray: response.message }));
      } else dispatch(updateFirmwareSuccess());
    },
    onError: (err) => dispatch(updateFailure({ err })),
  });

  return updateAllFirmwareByVehicleMutation;
};

export default useUpdateAllFirmwareByVehicleMutation;
