import noProfilePictureIcon from 'assets/svg/4F.svg';
import styled from 'styled-components';
import colors from 'legacy/shared/v1/constants/colors';

export const TableAvatar = styled.div`
  width: 22px;
  height: 22px;
  margin: 0px auto;
  border-radius: 11px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${colors.fog};
  overflow: hidden;

  ${(props) =>
    props.profilePicture
      ? `background-image: url(${props.profilePicture});`
      : `background-image: url(${noProfilePictureIcon});
    background-size: 60%;`}

  ${(props) =>
    props.userCard &&
    `
		width: 35px;
    height: 35px;
    margin:0px 10px 20px 0px;
    border-radius: 22px;
	`}
`;
