import React from 'react';
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';
import { NameFieldDiv } from 'legacy/shared/v1/styles/components/ManageAlerts';

const RecipientGroupNameField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <NameFieldDiv>
      <AnimatedField
        name="recipientGroupNameField"
        label={'Name'}
        placeholder={'Name'}
        validationError={errors.recipientGroupNameField}
        touched={touched.recipientGroupNameField}
        value={values.recipientGroupNameField}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </NameFieldDiv>
  );
};

export default RecipientGroupNameField;
