import { initializeWcpLogger } from 'legacy/core/error/utilities/initializeWcpLogger';
import useWcpLogger from 'legacy/core/error/utilities/useWcpLogger';
import RouterSetup from 'legacy/core/routes/RouteSetup';
import { getLoggedInStatus } from 'legacy/core/redux/user/actions';
import AlertBarNotifier from 'legacy/core/widgets/notifiers/AlertBarNotifier';
import NewReleaseNotifier from 'legacy/core/widgets/notifiers/NewReleaseNotifier';
import SessionTimeoutNotifier from 'legacy/core/widgets/notifiers/SessionTimeoutNotifier';
import SidebarAlertsNotifier from 'legacy/core/widgets/notifiers/SidebarAlertsNotifier';
import ToastNotifier from 'legacy/core/widgets/notifiers/ToastNotifier';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoloModal from 'legacy/shared/v1/modals/SoloModal';
import Wrapper from 'legacy/shared/v1/styles/components/Wrapper';
import theme from 'legacy/shared/v1/styles/global/theme';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { loginIsFresh } from 'legacy/shared/v1/utilities/localStore';
import { ThemeProvider } from 'styled-components';
import { userRoleOptions } from 'legacy/shared/v1/constants/users';

import { useLDClient } from 'launchdarkly-react-client-sdk';

// console.log('***Loading WCP...***');
initializeWcpLogger();

const App = () => {
  const ldClient = useLDClient();

  const dispatch = useDispatch();
  const {
    alertsVisible,
    subscriptionExpiryAlertBar,
    subscriptionExpiryAlertBarDismissed,
    checkingSession,
    filteredOrganizationId,
    userHomeOrg,
    userRole,
  } = useSelector((state) => ({
    filteredOrganizationId: state.user.filteredOrganizationId,
    userHomeOrg: state.user.user.organizationId,
    userRole: state.user.user.userRole,
    alertsVisible: state.ui.alertsVisible,
    subscriptionExpiryAlertBar: state.ui.subscriptionExpiryAlertBar,
    checkingSession: state.user.checkingSession,
    subscriptionExpiryAlertBarDismissed:
      (state.cached.subscriptionExpiryAlertBarDismissed &&
        state.cached.subscriptionExpiryAlertBarDismissed) ||
      (state.ui.subscriptionExpiryAlertBar && state.ui.subscriptionExpiryAlertBar.dismissed),
  }));
  useWcpLogger();
  // todo: fix this from rendering App multiple times
  useEffect(() => {
    dispatch(getLoggedInStatus());
  }, []);

  const isLoggedIn = loginIsFresh();

  const shouldDisplaySubscriptionExpiryAlertBar =
    isLoggedIn && subscriptionExpiryAlertBar !== null && !subscriptionExpiryAlertBarDismissed;

  useEffect(() => {
    // for sys admin and fse roles, check that the home org is different than the filteredOrg
    // for non sys admin and fse roles, home org will always be undefined, also passing this check
    // this solves the issue where sys admin and fses roles were sending 2 different ids back to back, causing LD to fail its check properly

    const isFseOrSysAdmin =
      userRole === userRoleOptions.FIELD_SOLUTION_ENGINEER.label ||
      userRole === userRoleOptions.SYSTEM_ADMIN.label;
    const homeOrgCheck = () => {
      if (isFseOrSysAdmin) {
        return filteredOrganizationId !== userHomeOrg;
      } else {
        return true;
      }
    };
    if (filteredOrganizationId && userHomeOrg && homeOrgCheck()) {
      console.log('communicating with LD');
      ldClient.identify({
        kind: 'organization',
        key: filteredOrganizationId,
      });
    }
  }, [filteredOrganizationId, userHomeOrg]);

  return !checkingSession ? (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {shouldDisplaySubscriptionExpiryAlertBar && (
          <AlertBarNotifier alertBarData={subscriptionExpiryAlertBar} />
        )}

        <RouterSetup />
        <SidebarAlertsNotifier expanded={alertsVisible} />
        <ToastNotifier />
        <NewReleaseNotifier />
        <SessionTimeoutNotifier />
        <SoloModal />
      </Wrapper>
    </ThemeProvider>
  ) : (
    <LoadingOverlay externalStyles={{ overlay: { left: '0px' } }} />
  );
};

export default App;
