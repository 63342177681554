import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';

// GET users (alertRecipients)
export const mockFrontendUserTimmyTheTexter_GET = {
  recipientUserName: 'TimmySmith',
  firstName: 'Timmy',
  lastName: 'Smith',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserJaneTheEmailer_GET = {
  recipientUserName: 'JaneDoe',
  firstName: 'Jane',
  lastName: 'Doe',
  alertMethod: alertMethodConstants.EMAIL,
};

export const mockFrontendUserJohnTheTexterAndEmailer_GET = {
  recipientUserName: 'JohnMessageman',
  firstName: 'John',
  lastName: 'Messageman',
  alertMethod: alertMethodConstants.SMS_AND_EMAIL,
};

export const mockFrontendUserJimmyTheTexter_GET = {
  recipientUserName: 'JimmyTheTexter',
  firstName: 'Jimmy',
  lastName: 'Smith',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserSarahTheTexter_GET = {
  recipientUserName: 'SarahTheTexter',
  firstName: 'Sarah',
  lastName: 'Puit',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserChuckTheTexter_GET = {
  recipientUserName: 'ChuckTheTexter',
  firstName: 'Chuck',
  lastName: 'Nuck',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserJohnTheTexter_GET = {
  recipientUserName: 'JohnTheTexter',
  firstName: 'John',
  lastName: 'Ring',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserSammyTheTexterAndEmailer_GET = {
  recipientUserName: 'SammyTheTexterAndEmailer',
  firstName: 'Sammy',
  lastName: 'Sosa',
  alertMethod: alertMethodConstants.SMS_AND_EMAIL,
};

export const mockFrontendUserRobotTheEmailer_GET = {
  recipientUserName: 'RobotTheEmailer',
  firstName: 'Robot',
  lastName: 'Joman',
  alertMethod: alertMethodConstants.EMAIL,
};

//POST/PUT users (alertRecipients)
export const mockFrontendRecipientTimmyTheTexter_POST = {
  recipientUserName: 'TimmySmith',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendRecipientJaneTheEmailer_POST = {
  recipientUserName: 'JaneDoe',
  alertMethod: alertMethodConstants.EMAIL,
};

/* export const mockFrontendUserNoFirstLastJohnTheTexterAndEmailer_POST = {
  userName: 'JohnTheTexterAndEmailer',
  alertMethod: alertMethodConstants.SMS_AND_EMAIL,
};

export const mockFrontendUserNoFirstLastJimmyTheTexter_POST = {
  userName: 'JimmyTheTexter',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserNoFirstLastSarahTheTexter_POST = {
  userName: 'SarahTheTexter',
  alertMethod: alertMethodConstants.SMS,
};

export const mockFrontendUserNoFirstLastChuckTheTexter_POST = {
  userName: 'ChuckTheTexter',
  alertMethod: alertMethodConstants.SMS,
}; */

//////// RECIPIENT ROW DATA MOCKS

export const mockFrontendUserTimmyTheTexter_RecipientGroupData = {
  alertMethod: alertMethodConstants.SMS,
  recipientUserName: 'TimmySmith',
  recipientUserFullName: 'Timmy Smith',
  firstName: 'Timmy',
  lastName: 'Smith',
  recipientRowGuid: '1474e892-f6e6-4dcf-89f5-b23389872f15',
};

export const mockFrontendUserJaneTheEmailer_RecipientGroupData = {
  alertMethod: alertMethodConstants.EMAIL,
  recipientUserName: 'JaneDoe',
  recipientUserFullName: 'Jane Doe',
  firstName: 'Jane',
  lastName: 'Doe',
  recipientRowGuid: 'e82952df-2d70-4bcb-838c-cbde35b0a6d6',
};

export const mockFrontendUserJohnTheTexterAndEmailer_RecipientGroupData = {
  alertMethod: alertMethodConstants.SMS_AND_EMAIL,
  recipientUserName: 'JohnMessageman',
  recipientUserFullName: 'John Messageman',
  firstName: 'John',
  lastName: 'Messageman',
  recipientRowGuid: '5420a107-97a7-47d5-80a4-34032de27e19',
};
