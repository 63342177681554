import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { useFetchSingleVehicleQuery } from 'legacy/features/vehicles/hooks/useFetchSingleVehicleQuery';
import FirmwareUpdateTableByVehicle from 'legacy/features/firmware/ui/byVehicle/FirmwareUpdateTableByVehicle';
import useFetchFirmwareBySingleVehicleQuery from 'legacy/features/firmware/hooks/useFetchFirmwareBySingleVehicleQuery';
import useUpdateAllFirmwareByVehicleMutation from 'legacy/features/firmware/hooks/useUpdateAllFirmwareByVehicleMutation';
import useUpdateFirmwareMutation from 'legacy/features/firmware/hooks/useUpdateFirmwareMutation';
import OtaUpdateCardByVehicle from 'legacy/features/firmware/mobile/OtaUpdateCardByVehicle';
import {
  FIELD_SOLUTION_ENGINEER,
  userRoleOptions as userRoles,
} from 'legacy/shared/v1/constants/users';
import {
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
} from 'legacy/shared/v1/constants/vehicle';
import {
  CardLabel,
  CardRow,
  CardValue,
  Cards,
} from 'legacy/shared/v1/styles/components/MobileCard';
import {
  UpdateAllWrapperDiv,
  VehicleStatusDisplay,
} from 'legacy/shared/v1/styles/components/OtaUpdates';
import PageListWrapper, {
  PageListHead,
  TableCheckboxFilterWrapper,
} from 'legacy/shared/v1/styles/components/PageList';
import {
  SectionBody,
  SectionInner,
  SectionTitle,
} from 'legacy/shared/v1/styles/components/Section';
import ButtonWithLoader from 'legacy/shared/v1/ui/buttons/ButtonWithLoader';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import { sortAscendingAlpha } from 'legacy/shared/v1/utilities/general';
import { sizes } from 'legacy/shared/v1/utilities/media';
import ListingCount from 'legacy/shared/v1/components/ListingCount';
import { permissionData } from 'legacy/shared/v1/constants/users';
import PermissionProtectedElement from 'legacy/core/components/authorization/PermissionProtectedElement';
import { firmwareStatus } from 'legacy/shared/v1/constants/firmwareStatus';
import {
  getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
  setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
} from 'legacy/shared/v1/utilities/localStore';
import OnlyShowPendingUpdatesCheckbox from 'legacy/features/firmware/ui/controls/OnlyShowPendingUpdatesCheckbox';

const FirmwareUpdateDetailsForVehicle = () => {
  const { vehicleId } = useParams();
  // redux
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  // react query
  const fetchFirmwareBySingleVehicleQuery = useFetchFirmwareBySingleVehicleQuery({
    key: 'firmwareBySingleVehicleDetails',
    autoRun: true,
    enableRefetchInterval: true,
    vehicleId,
  });

  const firmwareData = fetchFirmwareBySingleVehicleQuery.data;

  const fetchSingleVehicleQuery = useFetchSingleVehicleQuery({
    vehicleId,
    enableRefetchInterval: true,
  });
  const singleVehicle = fetchSingleVehicleQuery.data;

  const updateFirmwareMutation = useUpdateFirmwareMutation();
  const updateAllFirmwareByVehicleMutation = useUpdateAllFirmwareByVehicleMutation();

  // local
  let onlyShowPendingUpdatesPreference = getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal();

  const [vehicle, setVehicle] = useState(null);
  const [firmwareUpdatePostLoading, setFirmwareUpdatePostLoading] = useState(null);
  const [manualFirmwareFetchLoading, setManualFirmwareFetchLoading] = useState(false);
  const [devicesToUpdate, setDevicesToUpdate] = useState([]);
  const [filteredDeviceUpdates, setFilteredDeviceUpdates] = useState([]);
  const [updateAllLoading, setUpdateAllLoading] = useState(null);
  const [listingCount, setListingCount] = useState(filteredDeviceUpdates?.length);

  const [updatesInProgress, setUpdatesInProgress] = useState(false);

  const [onlyShowPendingUpdates, setOnlyShowPendingUpdates] = useState(
    onlyShowPendingUpdatesPreference ? onlyShowPendingUpdatesPreference : false,
  );

  // side effects
  // if route changes, perform manual refetch
  // this might not be needed once we use React Router v6 as it integrates seemlessly with react-query hooks
  useEffect(() => {
    setManualFirmwareFetchLoading(true);
    fetchFirmwareBySingleVehicleQuery.refetch();
  }, [vehicleId]);

  useEffect(() => {
    setVehicle(firmwareData?.meta);
  }, [firmwareData?.meta]);

  useEffect(() => {
    if (firmwareData?.products) {
      setDevicesToUpdate(
        firmwareData.products?.sort((p1, p2) =>
          sortAscendingAlpha(p1.product_name, p2.product_name),
        ),
      );
      setUpdateAllLoading(false);
      setManualFirmwareFetchLoading(false);
    }
  }, [JSON.stringify(firmwareData?.products)]); // thank you Dan Abramov https://github.com/facebook/react/issues/14476#issuecomment-471199055
  // TODO - this will go away with new provider

  useEffect(() => {
    if (
      devicesToUpdate?.some(
        (d) =>
          d.firmware_status === firmwareStatus.BOOTLOADING.status ||
          d.firmware_status === firmwareStatus.UPDATE_AUTHORIZED.status,
      )
    ) {
      setUpdatesInProgress(true);
    } else {
      setUpdatesInProgress(false);
      setFirmwareUpdatePostLoading(null);
    }
  }, [devicesToUpdate, fetchFirmwareBySingleVehicleQuery.isFetching]);

  useEffect(() => {
    if (onlyShowPendingUpdates) {
      setFilteredDeviceUpdates(
        devicesToUpdate.filter(
          (u) => u.firmware_status !== 'UP_TO_DATE' && u.firmware_status !== 'EXTERNAL_UPDATE',
        ),
      );
    } else {
      setFilteredDeviceUpdates(devicesToUpdate);
    }
  }, [onlyShowPendingUpdates, devicesToUpdate]);

  // render helpers
  let queriesLoading =
    fetchFirmwareBySingleVehicleQuery.isLoading ||
    manualFirmwareFetchLoading ||
    fetchSingleVehicleQuery.isLoading;
  let hasUpdates = devicesToUpdate?.length > 0;

  const displayVehicleStatusText = (vehicle) => {
    if (
      vehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id &&
      vehicle.respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id
    ) {
      return `${vehicleOnlineStatusViewData[vehicle.onlineStatus].title} - ${
        vehicleRespondingStatusViewData[vehicle.respondingStatus].title
      }`;
    } else return `${vehicleOnlineStatusViewData[vehicle.onlineStatus].title}`;
  };

  // render
  return queriesLoading ? (
    <LoadingOverlay />
  ) : (
    vehicle &&
      singleVehicle &&
      (!hasUpdates ? (
        <Navigate to="/updates" />
      ) : (
        <PageListWrapper>
          <PageListHead otaByVehicleDetail>
            <SectionTitle size="27">{vehicle.label}</SectionTitle>
            <UpdateAllWrapperDiv>
              <PermissionProtectedElement
                requiredPermissions={[permissionData.editotafirmware]}
                allowedRoles={[userRoles[FIELD_SOLUTION_ENGINEER].value]}
              >
                <ButtonWithLoader
                  loadingStyleProp={'submittingWithSpinner'}
                  notLoadingStyleProp={'updates'}
                  disabled={
                    devicesToUpdate.filter((d) => d.firmware_status === 'READY_TO_UPDATE')
                      .length === 0 ||
                    !singleVehicle?.meta?.online ||
                    singleVehicle?.gps?.fr_mode_enabled === 1
                  }
                  confirmText={'Update All Devices'}
                  isLoading={updateAllLoading}
                  clickHandler={() => {
                    setUpdateAllLoading(true);
                    updateAllFirmwareByVehicleMutation.mutate({
                      vehicleId,
                    });
                  }}
                />
              </PermissionProtectedElement>
            </UpdateAllWrapperDiv>
          </PageListHead>
          <SectionInner>
            <div>
              <CardRow>
                <CardLabel>Status:</CardLabel>
                <CardValue tight>
                  {singleVehicle && (
                    <VehicleStatusDisplay
                      online={singleVehicle.onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id}
                      responding={
                        singleVehicle.respondingStatus ===
                        vehicleRespondingStatusViewData.RESPONDING.id
                      }
                    >
                      {displayVehicleStatusText(singleVehicle)}
                    </VehicleStatusDisplay>
                  )}
                </CardValue>
              </CardRow>
              <CardRow>
                <CardLabel>License plate:</CardLabel>
                <CardValue tight>{vehicle.license_plate}</CardValue>
              </CardRow>
              <CardRow>
                <CardLabel>Year:</CardLabel>
                <CardValue horizontalStack tight>
                  {vehicle.mfg_year}
                </CardValue>
                <CardLabel>Make:</CardLabel>
                <CardValue horizontalStack tight>
                  {vehicle.make}
                </CardValue>
                <CardLabel>Model:</CardLabel>
                <CardValue tight>{vehicle.model}</CardValue>
              </CardRow>
              <CardRow>
                <CardLabel>VSG ID:</CardLabel>
                <CardValue tight>{singleVehicle.meta.formatted_device_id}</CardValue>
              </CardRow>
            </div>
          </SectionInner>
          <ListingCount
            listingCount={listingCount}
            includeTotalText={false}
            totalCount={devicesToUpdate.length}
            itemTypeName={'Device'}
          />
          <TableCheckboxFilterWrapper>
            <OnlyShowPendingUpdatesCheckbox
              checked={onlyShowPendingUpdates}
              handleChecked={() => {
                setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal(!onlyShowPendingUpdates),
                  setOnlyShowPendingUpdates(!onlyShowPendingUpdates);
              }}
            />
          </TableCheckboxFilterWrapper>
          <SectionBody>
            <Media
              queries={{
                tablet: { maxWidth: sizes.tablet },
                mobile: { maxWidth: sizes.mobile },
              }}
            >
              {(matches) =>
                matches.tablet ? (
                  <div>
                    <Cards ota>
                      {filteredDeviceUpdates.map((device) => (
                        <OtaUpdateCardByVehicle
                          config={false}
                          device={device}
                          vehicle={singleVehicle}
                          updatePostLoading={firmwareUpdatePostLoading}
                          updatesInProgress={updatesInProgress}
                          handleUpdateFirmware={async (device) => {
                            device.vehicleId = vehicleId;
                            setFirmwareUpdatePostLoading(device.device_id);
                            await updateFirmwareMutation.mutateAsync({
                              productId: device.product_id,
                              updateChannel: device.channel,
                              updateVersion: device.version,
                              devicesToUpdate: [device],
                              organizationId: filteredOrganizationId,
                            });
                          }}
                        />
                      ))}
                    </Cards>
                  </div>
                ) : (
                  <FirmwareUpdateTableByVehicle
                    deviceUpdates={filteredDeviceUpdates}
                    vehicle={singleVehicle}
                    updatePostLoading={firmwareUpdatePostLoading}
                    updatesInProgress={updatesInProgress}
                    handleUpdateListingCount={(listingCount) => setListingCount(listingCount)}
                    handleUpdateFirmware={async (device) => {
                      setFirmwareUpdatePostLoading(device.device_id);
                      await updateFirmwareMutation.mutateAsync({
                        productId: device.product_id,
                        updateChannel: device.channel,
                        updateVersion: device.version,
                        vehicleId,
                        devicesToUpdate: [device],
                        organizationId: filteredOrganizationId,
                      });
                    }}
                  />
                )
              }
            </Media>
          </SectionBody>
        </PageListWrapper>
      ))
  );
};

export default FirmwareUpdateDetailsForVehicle;
