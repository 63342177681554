import { logWcpError } from 'legacy/core/error/utilities/ErrorLogger';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { useDispatch } from 'react-redux';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';

const useDispatchErrorNotification = () => {
  const dispatch = useDispatch();

  const dispatchErrorToast = ({
    message = 'An error has occurred!',
    err,
    logError = true,
    showError = false,
  }) => {
    if (logError) logWcpError(err);

    showError
      ? dispatch(addNotification({ notification: createNotification(LEVELS.ERROR, message, err) }))
      : dispatch(addNotification({ notification: createNotification(LEVELS.ERROR, message) }));
  };

  return [dispatchErrorToast];
};

export default useDispatchErrorNotification;
