import { apiCall } from 'legacy/core/api/API';

export const deleteRecipientGroupsApi = async ({ groupId, organizationId }) => {
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  return await apiCall({
    path: `/user_group/${groupId}`,
    method: 'del',
    queryStringParameters,
  });
};
