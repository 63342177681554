import { useEffect } from 'react';

import { NameFieldDiv } from 'legacy/shared/v1/styles/components/ManageAlerts';
import AnimatedField from 'legacy/shared/v1/ui/fields/AnimatedField';

const RecipientGroupNameField = ({ formik, recipientGroupName, forceAnimated }) => {
  useEffect(() => {
    formik.setFieldValue('recipientGroupNameField', recipientGroupName, true);
  }, [recipientGroupName]);

  return (
    <NameFieldDiv>
      <AnimatedField
        required
        name="recipientGroupNameField"
        label={'Name'}
        placeholder={'Name'}
        value={formik.values.recipientGroupNameField}
        touched={formik.touched.recipientGroupNameField}
        validationError={formik.errors.recipientGroupNameField}
        handleBlur={formik.handleBlur}
        handleChange={(e) => {
          formik.handleChange(e);
        }}
        forceAnimated={forceAnimated}
      />
    </NameFieldDiv>
  );
};

export default RecipientGroupNameField;
