import { apiCall } from 'legacy/core/api/API';

export const getVehicleGroupsApi = async ({ organizationId }) => {
  const queryStringParameters = { organization_id: organizationId };
  let results = await apiCall({
    path: '/group',
    method: 'get',
    queryStringParameters,
  });

  return results;
};
