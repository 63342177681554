import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';
import {
  calculateTotalCounts,
  filterOnlyNextScheduledEvents,
} from 'legacy/features/maintenance/utilities/general';
import { sortDescendingAlpha } from 'legacy/shared/v1/utilities/general';

export const setMaintenanceSummaryFiltersReducer = (state, payload) => {
  let {
    selectedGroup,
    selectedVehicle,
    selectedType,
    selectedStatus,
    filterOnlyNextScheduledEvent,
  } = payload;

  // initialize filteredMaintenanceEvents
  let filteredMaintenanceEvents = state.allMaintenanceEvents;

  if (!filteredMaintenanceEvents) {
    return state;
  }

  // initialize filters
  selectedGroup = selectedGroup === undefined ? state.selectedGroup : selectedGroup;
  selectedVehicle = selectedVehicle === undefined ? state.selectedVehicle : selectedVehicle;
  selectedType = selectedType === undefined ? state.selectedType : selectedType;
  selectedStatus = selectedStatus === undefined ? state.selectedStatus : selectedStatus;

  const checkEngineEvents =
    filteredMaintenanceEvents?.filter(
      (item) => item.status === maintenanceStatusConstants.CHECK_ENGINE,
    ) || [];

  const filteredWithoutCheckEngineEvents =
    filteredMaintenanceEvents?.filter(
      (item) => item.status !== maintenanceStatusConstants.CHECK_ENGINE,
    ) || [];

  // filter checkbox
  filterOnlyNextScheduledEvent =
    filterOnlyNextScheduledEvent === undefined
      ? state.filterOnlyNextScheduledEvent
      : filterOnlyNextScheduledEvent;

  // process filter checkbox
  if (filterOnlyNextScheduledEvent) {
    filteredMaintenanceEvents = [
      ...filterOnlyNextScheduledEvents(filteredWithoutCheckEngineEvents),
      ...checkEngineEvents,
    ];
  }

  // process filter dropdowns
  filteredMaintenanceEvents = filteredMaintenanceEvents.filter(
    (item) =>
      (!selectedGroup || selectedGroup.vehicle_id.includes(item.vehicleId)) &&
      (!selectedVehicle || item.vehicleId === selectedVehicle.vehicleId) &&
      (!selectedType || item.maintenanceType?.value === selectedType.value) &&
      (!selectedStatus ||
        (selectedStatus.value === maintenanceStatusConstants.NEEDS_ATTENTION
          ? item.status === maintenanceStatusConstants.DUE_IN_SOON ||
            item.status === maintenanceStatusConstants.DUE_FOR_WORK ||
            item.status === maintenanceStatusConstants.CHECK_ENGINE
          : item.status === selectedStatus.value)),
  );

  // sort by timestamp
  filteredMaintenanceEvents = filteredMaintenanceEvents.sort((a, b) =>
    sortDescendingAlpha(a.timestamp, b.timestamp),
  );

  // calculate counts
  let listingCount = filteredMaintenanceEvents.length;
  if (listingCount > state.listingCount) listingCount = state.listingCount;
  let totalCounts = calculateTotalCounts(state.allMaintenanceEvents);

  // return state
  const newState = {
    ...state,
    selectedGroup,
    selectedVehicle,
    selectedType,
    selectedStatus,
    filterOnlyNextScheduledEvent,
    filteredMaintenanceEvents,
    ...totalCounts,
    listingCount,
  };

  return newState;
};
