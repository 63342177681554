import { useDispatch, useSelector } from 'react-redux';

import { toggleSidebarAlerts } from 'legacy/core/redux/ui/actions';
import Content from 'legacy/shared/v1/styles/components/Content';
import Main from 'legacy/shared/v1/styles/components/Main';
import StyledApplicationContainer from 'legacy/shared/v1/styles/components/StyledApplicationContainer';
import WcpHeader from 'legacy/core/layout/WcpHeader';
import PageOverlay from 'legacy/shared/v1/ui/layout/PageOverlay';
import NavigationSidebar from 'legacy/core/navigation/components/NavigationSidebar';

const WcpLayout = ({ children }) => {
  const dispatch = useDispatch();
  const fullscreenMapMode = useSelector((state) => state.ui.fullscreenMapMode);
  const isSidebarVisible = useSelector((state) => state.ui.isSidebarVisible);
  const isPageOverlayVisible = useSelector((state) => state.ui.isPageOverlayVisible);
  const overlayIsVisible = useSelector((state) => state.ui.overlayIsVisible);

  return (
    <StyledApplicationContainer fullscreenMapMode={fullscreenMapMode}>
      <WcpHeader fullscreenMapMode={fullscreenMapMode} />
      <NavigationSidebar fullscreenMapMode={fullscreenMapMode} />
      <Main fullscreenMapMode={fullscreenMapMode}>
        <Content
          flexColumn
          overlayIsVisible={overlayIsVisible}
          onClick={isSidebarVisible ? toggleSidebar : null}
        >
          <PageOverlay
            isVisible={isPageOverlayVisible}
            toggle={() => {
              dispatch(toggleSidebarAlerts());
            }}
          />
          {children}
        </Content>
      </Main>
    </StyledApplicationContainer>
  );
};

export default WcpLayout;
