/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'legacy/shared/v1/constants/colors';

export const VehicleDeviceTableNav = styled.ul`
  display: flex;
  justify-content: space-around;
`;

export const TableTab = styled.li`
  display: block;
  flex: 1 1 25%;
  padding-bottom: 12px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  text-align: center;
  color: ${colors.haze};
  cursor: pointer;

  ${(props) =>
    props.active &&
    `
    color: ${colors.midnight};
    border-bottom: solid 2px ${colors.charcoal};
  `};
`;

export const ConfigurationsTableNav = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 33.334%;
  margin: 0 auto 32.5px;
`;
