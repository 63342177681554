import {
  SimpleDropdownBtn,
  SimpleDropdownItem,
} from 'legacy/shared/v1/styles/components/DropdownMenu';

const DeleteAlertMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Alert</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteAlertMenuItem;
