import { createAction } from 'redux-actions';

import {
  getConfigurations,
  getConfigurationUpdates,
  uploadConfiguration as uploadConfigurationAPI,
  updateConfiguration,
  deleteConfiguration as deleteConfigurationAPI,
} from 'legacy/core/api/configuration';
import { addNotification } from 'legacy/core/redux/ui/actions';
import { createNotification, LEVELS } from 'legacy/shared/v1/utilities/notification';
import {
  userRoleOptions as userRoles,
  MAP_ANALYST,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  FIELD_SOLUTION_ENGINEER,
  SYSTEM_ADMIN,
  variableScopeAllowedUserRoles,
  permissionData,
} from 'legacy/shared/v1/constants/users';
import { checkForUserPermission } from '../user/selectors';

export const requestConfigurations = createAction('REQUEST_CONFIGURATIONS');
export const receiveConfigurations = createAction('RECEIVE_CONFIGURATIONS');
export const rejectConfigurations = createAction('REJECT_CONFIGURATIONS');
export const requestConfigurationUpdates = createAction('REQUEST_CONFIGURATION_UPDATES');
export const receiveConfigurationUpdates = createAction('RECEIVE_CONFIGURATION_UPDATES');
export const rejectConfigurationUpdates = createAction('REJECT_CONFIGURATION_UPDATES');
export const requestUploadConfiguration = createAction('REQUEST_UPLOAD_CONFIGURATION');
export const receiveUploadConfiguration = createAction('RECEIVE_UPLOAD_CONFIGURATION');
export const rejectUploadConfiguration = createAction('REJECT_UPLOAD_CONFIGURATION');
export const requestUpdateConfiguration = createAction('REQUEST_UPLOAD_CONFIGURATION');
export const receiveUpdateConfiguration = createAction('RECEIVE_UPLOAD_CONFIGURATION');
export const rejectUpdateConfiguration = createAction('REJECT_UPLOAD_CONFIGURATION');
export const requestDeleteConfiguration = createAction('REQUEST_DELETE_CONFIGURATIONS');
export const receiveDeleteConfiguration = createAction('RECEIVE_DELETE_CONFIGURATIONS');
export const rejectDeleteConfiguration = createAction('REJECT_DELETE_CONFIGURATIONS');
export const requestUpdateVehiclesWithConfiguration = createAction(
  'REQUEST_UPDATE_VEHICLES_CONFIGURATION',
);
export const receiveUpdateVehiclesWithConfiguration = createAction(
  'RECEIVE_UPDATE_VEHICLES_CONFIGURATION',
);
export const rejectUpdateVehiclesWithConfiguration = createAction(
  'REJECT_UPDATE_VEHICLES_CONFIGURATION',
);

export const fetchConfigurations = () => async (dispatch, getState) => {
  dispatch(requestConfigurations());
  try {
    const userState = getState().user;
    const filteredOrganizationId = checkForUserPermission(userState, permissionData.viewotaconfig)
      ? userState.filteredOrganizationId
      : '';
    const { response } = await getConfigurations(filteredOrganizationId);
    if (response && response.message) {
      dispatch(receiveConfigurations(response.message));
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectConfigurations(e));
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error Getting configurations', e),
      }),
    );
  }
  return false;
};

export const fetchConfigurationUpdates = () => async (dispatch, getState) => {
  dispatch(requestConfigurationUpdates());
  try {
    const userState = getState().user;
    const filteredOrganizationId = userState.filteredOrganizationId;
    const { response } = await getConfigurationUpdates(filteredOrganizationId);
    if (response && response.message) {
      dispatch(receiveConfigurationUpdates(response.message));
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectConfigurationUpdates(e));
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error Getting configuration updates', e),
      }),
    );
  }
};

export const uploadConfiguration = (file) => async (dispatch, getState) => {
  dispatch(requestUploadConfiguration());
  try {
    const userState = getState().user;
    const filteredOrganizationId = variableScopeAllowedUserRoles.includes(userState.user.userRole)
      ? userState.filteredOrganizationId
      : '';
    const { response } = await uploadConfigurationAPI({
      file,
      organizationId: filteredOrganizationId,
    });
    if (response && response.message) {
      dispatch(receiveUploadConfiguration(response.message));
      dispatch(fetchConfigurations());
      return {
        configId: response.message.new_item.config_id,
        configName: response.message.new_item.config_name,
      };
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectUploadConfiguration(e));
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error uploading configuration', e),
      }),
    );
  }
  return false;
};

export const editConfiguration = (configuration, configurationId) => async (dispatch) => {
  dispatch(requestUpdateConfiguration(configurationId));
  try {
    const { response } = await updateConfiguration(configuration, configurationId);
    if (response && response.message) {
      dispatch(receiveUpdateConfiguration(response.message));
      dispatch(fetchConfigurations());
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(
      rejectUpdateConfiguration({
        errorMessage: e,
        configId: configurationId,
      }),
    );
    dispatch(
      addNotification({
        notification: createNotification(LEVELS.ERROR, 'Error updating configuration', e),
      }),
    );
    return false;
  }
};

export const deleteConfiguration = (configurationId) => async (dispatch) => {
  dispatch(requestDeleteConfiguration(configurationId));
  try {
    const { response } = await deleteConfigurationAPI(configurationId);
    if (response && response.message) {
      dispatch(receiveDeleteConfiguration(response.message));
      dispatch(fetchConfigurations());
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(
      rejectDeleteConfiguration({
        errorMessage: e,
        configurationId,
      }),
    );
    dispatch(
      addNotification({
        notification: createNotification(
          LEVELS.ERROR,
          `Error deleting configuration with ID: ${configurationId}`,
          e,
        ),
      }),
    );
  }
};
