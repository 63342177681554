import { apiCall } from 'legacy/core/api/API';

export const deleteMaintenanceApi = async ({ maintenanceEventId, organizationId }) => {
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  return await apiCall({
    path: `/maintenance/${maintenanceEventId}`,
    method: 'del',
    queryStringParameters,
  });
};
