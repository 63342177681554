import {
  FieldError,
  FormAsterisk,
  FormLabelAnimated,
} from 'legacy/shared/v1/styles/components/Form';
import { IntervalInputDiv } from 'legacy/shared/v1/styles/components/Maintenance';
import StyledDayPickerInput from 'legacy/shared/v1/ui/StyledDayPickerInput';
import { getShortDateFromDatePicker } from 'legacy/shared/v1/utilities/time';

const NextDueDateField = ({ formikProps: { values, errors, setFieldValue, touched } }) => {
  return (
    <IntervalInputDiv date>
      <FormLabelAnimated animated>
        Due Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        name="nextDueDate"
        placeholder={values.nextDueDate || 'Select a Date'}
        onDayChange={(date) => {
          const formattedDate = getShortDateFromDatePicker(date);
          setFieldValue('nextDueDate', formattedDate);
        }}
      />
      <FieldError maintenanceEvents>{touched.nextDueDate && errors.nextDueDate}</FieldError>
    </IntervalInputDiv>
  );
};

export default NextDueDateField;
