import React, { useState, useRef, useEffect } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import StyledReactSelect from 'legacy/shared/v1/styles/components/SelectField';
import colors from 'legacy/shared/v1/constants/colors';

const QRCodeReader = ({ isOpen, handleScan }) => {
  const [inputDevices, setInputDevices] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [codeReader] = useState(() => new BrowserMultiFormatReader());

  useEffect(() => {
    if (isOpen) {
      loadDevices();
    } else {
      if (codeReader) {
        console.log('Stopping camera stream...');
        codeReader.stopStreams();
      }
    }
    return () => {
      if (codeReader) {
        console.log('Stopping camera stream...');
        codeReader.stopStreams();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (selectedDevice) {
      handleInputDeviceSelect();
    }
  }, [selectedDevice]);

  const loadDevices = async () => {
    try {
      console.log('Requesting camera access...');
      await window.navigator.mediaDevices.getUserMedia({ video: true });
      try {
        console.log('Requesting camera access...');
        const inputDevices = await codeReader.listVideoInputDevices();

        if (inputDevices.length > 0) {
          setInputDevices(inputDevices);

          // set first device
          setSelectedDevice({
            deviceId: inputDevices[1] ? inputDevices[1].deviceId : inputDevices[0].deviceId,
          });
        } else {
          throw 'No input devices found.';
        }
      } catch (err) {
        console.log('Error while getting input devices', { err });
        setErrorMessage('No camera was found.');
      }
    } catch (err) {
      console.log('Error requesting camera access', err);
      this.setState({ errorMessage: 'Unable to access device camera.' });
    }
  };

  const handleInputDeviceSelect = async () => {
    try {
      console.log('Scanning on device id: ', selectedDevice.deviceId);

      // https://github.com/zxing-js/library/issues/395
      // Not found loop in console logs is intended behavior of library
      const result = await codeReader.decodeOnceFromVideoDevice(
        selectedDevice.deviceId,
        'videoScanner',
      );

      handleScan(result.text);
    } catch (err) {
      if (err instanceof NotFoundException) {
        console.log('No QR code found.');
      }
    }
  };

  return isOpen ? (
    <>
      {inputDevices.length > 1 && (
        <StyledReactSelect
          name="camera"
          placeholder="Select Camera"
          defaultValue={{
            label: inputDevices.find((i) => i.deviceId === selectedDevice?.deviceId).label,
          }}
          onChange={(e) => setSelectedDevice({ deviceId: e.value })}
          options={inputDevices.map((d) => ({ value: d.deviceId, label: d.label }))}
          isClearable={false}
          isSearchable={false}
        />
      )}
      <section style={styles.scannerContainer}>
        <div style={styles.targetArea} />
        <video id="videoScanner" style={styles.video} />
      </section>

      {errorMessage && <div style={styles.errorMsg}>{errorMessage}</div>}
    </>
  ) : null;
};

const styles = {
  errorMsg: {
    minHeight: '14px',
    color: `${colors.midnight}`,
    fontSize: '12px',
    marginTop: '4px',
    marginBottom: '12px',
    textAlign: 'center',
  },
  scannerContainer: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    zIndex: 0,
  },
  targetArea: {
    top: '0px',
    left: '0px',
    zIndex: 1,
    boxSizing: 'border-box',
    border: '50px solid rgba(0, 0, 0, 0.3)',
    boxShadow: 'rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  video: {
    top: '0px',
    left: '0px',
    display: 'block',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default QRCodeReader;
