import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';

import { getUserNameDisplayFromUserList } from 'legacy/shared/v2/utilities/getUserNameDisplayFromUserList';
import { manageRecipientGroupsSummaryTableColumns } from 'legacy/features/recipientGroups/components/ManageRecipientGroupsSummary/tables/ManageRecipientGroupsSummaryTableColumns';

const ManageRecipientGroupsSummaryTable = ({ recipientGroups, handleUpdateListingCount }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    recipientGroups?.forEach((group) => {
      group.formattedUserDisplayNames = getUserNameDisplayFromUserList(group.recipientGroupMembers);
    });
  }, [recipientGroups]);

  return (
    <WcpTable
      tableClassName="table-simple"
      columns={manageRecipientGroupsSummaryTableColumns}
      tableSourceData={recipientGroups ? recipientGroups : []}
      emptyText="No recipient groups are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageRecipientGroupsSummaryTable;
