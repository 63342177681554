import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import WcpTable from 'legacy/shared/v2/components/controls/WcpTable/WcpTable';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import {
  SimpleDropdownItem,
  SimpleDropdownBtn,
  DropdownLink,
} from 'legacy/shared/v1/styles/components/DropdownMenu';
import { Badge } from 'legacy/shared/v1/styles/components/Badge';
import {
  debouncedFetchOrganizations as fetchOrganizations,
  activateOrganization,
} from 'legacy/core/redux/organizations/actions';
import { toggleDeactivateOrganizationModal } from 'legacy/core/redux/ui/actions';
import useAPIData from 'legacy/shared/v1/hooks/useAPIData';
import LoadingOverlay from 'legacy/shared/v1/ui/spinners/LoadingOverlay';
import ModalPortal from 'legacy/shared/v1/modals/ModalPortal';
import OrganizationForm from 'legacy/shared/v1/modals/OrganizationForm';
import ActionMenu from 'legacy/shared/v1/ui/table/ActionMenu';
import { createOrganization, editOrganization } from 'legacy/core/redux/organizations/actions';
import { TableSpinner } from 'legacy/shared/v1/styles/components/Spinner';
import { closeGlobalModal } from 'legacy/core/redux/ui/actions';
import { customerTypeDictionary } from 'legacy/shared/v1/constants/organizations';
import { TruncatedTextWithWrap } from 'legacy/shared/v1/styles/components/TruncatedText';

/**
 * @ Styled Components
 */
import PageListWrapper, {
  PageListHead,
  PageListTitle,
} from 'legacy/shared/v1/styles/components/PageList';
import { ActionButtonWithIcon, ActionButtonText } from 'legacy/shared/v1/styles/components/Button';

const OrganizationsPage = ({
  organizations,
  fetchOrganizations,
  createOrganization,
  editOrganization,
  toggleDeactivateOrganizationModal,
  activateOrganization,
  userRole,
  closeGlobalModal,
}) => {
  const organizationsLoaded = useAPIData({ fetchAPIData: fetchOrganizations });
  const [organizationList, setOrganizationList] = useState(null);
  const [listingCount, setListingCount] = useState(organizations?.length);

  useEffect(() => {
    setOrganizationList(organizations);
    setListingCount(organizations?.length);
  }, [organizations]);

  const handleToggleDeactivateOrganizationModal = ({ organizationId }) => {
    toggleDeactivateOrganizationModal(organizationId);
  };
  const toggleActiveStateHandler = (organization) => {
    const { active, organizationId } = organization;
    return (event) => {
      event.preventDefault();
      active
        ? handleToggleDeactivateOrganizationModal({ organizationId })
        : activateOrganization(organization);
    };
  };

  /** Modal State & Handlers  **/
  const defaultModalState = {
    isOpen: false,
    mode: null,
    organization: null,
    confirmHandler: null,
  };
  const [organizationModal, updateOrganizationModalProps] = useState(defaultModalState);

  const handleOpenCreateModal = () =>
    updateOrganizationModalProps({
      isOpen: true,
      mode: OrganizationForm.MODE.CREATE,
      confirmHandler: createOrganization,
      userRole: userRole,
    });
  const handleOpenEditModal = (event, organizationId) => {
    event.preventDefault();
    const organization = organizations.find(
      (organization) => organization.organizationId === organizationId,
    );

    updateOrganizationModalProps({
      organization,
      isOpen: true,
      mode: OrganizationForm.MODE.EDIT,
      confirmHandler: editOrganization,
      userRole: userRole,
    });
  };
  const handleCloseModal = () => {
    closeGlobalModal();
    updateOrganizationModalProps({ defaultModalState });
  };

  /** Table Column Definitions **/
  const columns = [
    {
      title: 'Organization Name',
      displayProperty: 'organizationName',
      className: 'name',
      render({ rowData, cellJsx }) {
        return (
          <span
            className="vehicleNameAndIcon"
            title={'Organization Name: ' + rowData.organizationName}
          >
            {rowData.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent svgFileName={'organizations'} alt="Organizations" />
            )}

            <Link
              to={`${encodeURIComponent(rowData.organizationId)}`}
              style={{ textDecoration: 'none' }}
            >
              <TruncatedTextWithWrap orgTable>{cellJsx}</TruncatedTextWithWrap>
            </Link>
          </span>
        );
      },
    },
    {
      title: 'Contact Email',
      displayProperty: 'contactEmail',
      render({ rowData, cellJsx }) {
        return (
          <TruncatedTextWithWrap orgTable title={'Contact Email: ' + rowData.contactEmail}>
            {cellJsx}
          </TruncatedTextWithWrap>
        );
      },
    },
    {
      title: 'Customer Type',
      displayProperty: 'customerType',
      render({ rowData }) {
        const specifyType = rowData.customerType
          ? customerTypeDictionary.find((ct) => ct.value === rowData.customerType)?.label
          : 'Not Specified';
        return <span title={'Customer Type: ' + specifyType}>{specifyType}</span>;
      },
    },
    {
      title: 'Contact Phone',
      displayProperty: 'contactPhone',
    },
    {
      title: 'Start Date',
      displayProperty: 'startDate',
    },
    {
      title: 'End Date',
      displayProperty: 'endDate',
    },
    {
      title: 'Vehicle Count',
      displayProperty: 'vehicleCount',
      width: '5%',
    },
    {
      title: 'Status',
      displayProperty: 'status',
      render: ({ rowData, cellJsx }) => (
        <Badge active={rowData.active}>
          <span title="Status">{cellJsx}</span>
        </Badge>
      ),
    },
    {
      title: 'HAAS Integration',
      displayProperty: 'haasEnabled',
      render: ({ rowData }) =>
        rowData.haasEnabled &&
        (rowData.haasSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="HAAS Enabled"
            title="HAAS Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="HAAS Pending"
            title="HAAS Integration Pending"
          />
        )),
    },
    {
      title: 'Miovision Integration',
      displayProperty: 'gttEnabled',
      render: ({ rowData }) =>
        rowData.gttEnabled &&
        (rowData.gttSetup ? (
          <IconSvgComponent
            svgFileName={'check-success'}
            alt="Miovision Enabled"
            title="Miovision Integration Enabled"
          />
        ) : (
          <IconSvgComponent
            svgFileName={'check-pending'}
            alt="Miovision Pending"
            title="Miovision Integration Pending"
          />
        )),
    },
    {
      displayProperty: '',
      className: 'action',
      searchEnabled: false,
      render: ({ rowData }) => {
        return (
          <ActionMenu>
            <SimpleDropdownItem>
              <SimpleDropdownBtn onClick={(e) => handleOpenEditModal(e, rowData.organizationId)}>
                Edit
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
            <SimpleDropdownItem>
              <DropdownLink to={`${encodeURIComponent(rowData.organizationId)}`}>
                Manage Users
              </DropdownLink>
            </SimpleDropdownItem>

            <SimpleDropdownItem>
              <SimpleDropdownBtn onClick={toggleActiveStateHandler(rowData)}>
                {rowData.active ? 'Deactivate' : 'Activate'}
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          </ActionMenu>
        );
      },
    },
  ];

  return organizationList ? (
    <PageListWrapper>
      <PageListHead>
        <PageListTitle>Organizations</PageListTitle>
        <ActionButtonWithIcon create onClick={handleOpenCreateModal}>
          <IconSvgComponent svgFileName="plus-simple" alt="Create" />
          <ActionButtonText>Create Organization</ActionButtonText>
        </ActionButtonWithIcon>
      </PageListHead>
      <h5>
        {listingCount === organizations?.length
          ? `Total of ${listingCount} Organization${listingCount !== 1 ? 's' : ''}`
          : `${listingCount} of ${organizations?.length} Organization${
              organizations?.length !== 1 ? 's' : ''
            }`}
      </h5>
      <WcpTable
        tableClassName="table-simple"
        columns={columns}
        tableSourceData={organizationList}
        rowKey={(record, i) => i}
        rowClassName={(record, i) => `row-${i}`}
        setListingCount={setListingCount}
      />
      {organizationModal.isOpen && (
        <ModalPortal onRequestClose={handleCloseModal}>
          <OrganizationForm
            mode={organizationModal.mode}
            organization={organizationModal.organization}
            confirmHandler={organizationModal.confirmHandler}
            userRole={userRole}
          />
        </ModalPortal>
      )}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  (state) => ({
    organizations: state.organizations.organizations,
    currentUserUsername: state.user.user.username,
    userRole: state.user.user.userRole,
  }),
  (dispatch) => ({
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    createOrganization: (organization) => dispatch(createOrganization(organization)),
    editOrganization: (organization) => dispatch(editOrganization(organization)),
    toggleDeactivateOrganizationModal: (organizationId) =>
      dispatch(toggleDeactivateOrganizationModal({ organizationId })),
    activateOrganization: (organization) => dispatch(activateOrganization(organization)),
    closeGlobalModal: () => dispatch(closeGlobalModal()),
  }),
)(OrganizationsPage);
