import { DropdownLink } from 'legacy/shared/v1/styles/components/DropdownMenu';

const ViewOnMapMenuItem = ({ vehicle }) => {
  return vehicle.gps ? (
    <DropdownLink to={`/livemap/${encodeURIComponent(vehicle.vehicleId)}`}>
      View on Map
    </DropdownLink>
  ) : (
    <DropdownLink
      title="Last vehicle location unknown"
      style={{ cursor: 'not-allowed' }}
      to={{
        pathname: '',
      }}
    >
      View on Map
    </DropdownLink>
  );
};

export default ViewOnMapMenuItem;
