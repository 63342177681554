import { alertMethodConstants } from 'legacy/features/alerts/data/enums/alertMethodConstants';
import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptionsLegacy';

const alertMethodDropdownOptions = new DropdownOptions([
  { value: alertMethodConstants.SMS, label: 'Text' },
  { value: alertMethodConstants.EMAIL, label: 'Email' },
  { value: alertMethodConstants.SMS_AND_EMAIL, label: 'Text and Email' },
  { value: alertMethodConstants.CUSTOM, label: 'Custom' },
]);

export default alertMethodDropdownOptions;
