import { useEditVehicleAliasMutation } from 'legacy/features/vehicleGroups/hooks/v1/useEditAliasMutation';
import VehicleAliasField from 'legacy/features/vehicles/components/forms/textField/VehicleAliasField';
import { FormRow } from 'legacy/shared/v1/styles/components/Form';
import IconSvgComponent from 'legacy/shared/v1/ui/icons/IconSvgComponent';
import GenericFormModal from 'legacy/shared/v1/ui/modals/GenericFormModal';
import { validateAliasModal } from 'legacy/features/livemap/validators/validateAliasModal';

const useEditVehicleAliasModal = ({ show, vehicle, handleCloseModal }) => {
  const editVehicleAliasMutation = useEditVehicleAliasMutation();

  return (
    show && (
      <GenericFormModal
        customWidth={550}
        icon={<IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />}
        title={'Edit Vehicle Alias'}
        initialValues={{ alias: vehicle.meta.alias ? vehicle.meta.alias : '' }}
        submitHandler={async (values, formikActions) => {
          vehicle.meta.alias = values.alias;
          let result = await editVehicleAliasMutation.mutateAsync({
            vehicle: vehicle.meta,
          });
          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateAliasModal}
        handleCloseModal={handleCloseModal}
        submitButtonText={'Apply'}
        errorMessage={editVehicleAliasMutation.isError ? editVehicleAliasMutation.error : null}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <FormRow>
            <VehicleAliasField formikProps={formikProps} />
          </FormRow>
        )}
      </GenericFormModal>
    )
  );
};

export default useEditVehicleAliasModal;
