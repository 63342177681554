import { Cards } from 'legacy/shared/v1/styles/components/MobileCard';
import ManageAlertsLogCard from 'legacy/features/alerts/ui/components/AlertLogsSummary/mobile/ManageAlertsLogCard';
import ManageAlertsLogsActionMenu from 'legacy/features/alerts/ui/components/AlertLogsSummary/menus/ManageAlertsLogsActionMenu';

const ManageAlertsLogCardContainer = ({ alertLogs }) => {
  const getActionMenu = (log) => {
    return <ManageAlertsLogsActionMenu log={log} />;
  };
  return (
    <div>
      <Cards>
        {alertLogs.map((a) => (
          <ManageAlertsLogCard log={a} key={a.id} actionMenuComponent={() => getActionMenu(a)} />
        ))}
      </Cards>
    </div>
  );
};

export default ManageAlertsLogCardContainer;
