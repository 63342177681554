import { maintenanceStatusConstants } from 'legacy/features/maintenance/data/maintenanceStatusConstants';
import DropdownOptions from 'legacy/shared/v2/utilities/dropdown/DropdownOptions';

export const maintenanceStatusDropdownData = [
  { value: maintenanceStatusConstants.DUE_FOR_WORK, label: 'Due for Work' },
  { value: maintenanceStatusConstants.DUE_IN_SOON, label: 'Due in Soon' },
  { value: maintenanceStatusConstants.CHECK_ENGINE, label: 'Check Engine' },
  { value: maintenanceStatusConstants.UP_TO_DATE, label: 'Up to Date' },
  { value: maintenanceStatusConstants.NEEDS_ATTENTION, label: 'Needs Attention' },
];

export const getMaintenanceStatusDropdownObject = ({ value, label }) => {
  return value
    ? maintenanceStatusDropdownData.find((i) => i.value === value)
    : label
      ? maintenanceStatusDropdownData.find((i) => i.label === label)
      : null;
};

export const maintenanceStatusDropdownOptions = new DropdownOptions(maintenanceStatusDropdownData);
