import React from 'react';
import { Navigate } from 'react-router-dom';
import { loginIsFresh } from 'legacy/shared/v1/utilities/localStore';

const UnauthenticatedRoute = ({ children }) => {
  const isLoggedIn = loginIsFresh();
  return isLoggedIn ? <Navigate to={'/livemap'} /> : <>{children}</>;
};

export default UnauthenticatedRoute;
