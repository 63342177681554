import PropTypes from 'prop-types';
import Table from 'rc-table';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import PaginationControls from 'legacy/shared/v2/components/controls/WcpTable/PaginationControls';
import { tableActions } from 'legacy/shared/v2/components/controls/WcpTable/tableActions';
import useModifyColumnProperties from 'legacy/shared/v2/components/controls/WcpTable/useModifyColumnProperties';
import useTableReducer from 'legacy/shared/v2/components/controls/WcpTable/useTableReducer';
import WcpTableHeaderCell from 'legacy/shared/v2/components/controls/WcpTable/WcpTableHeaderCell';
import Searchbox from 'legacy/shared/v1/components/Searchbox';
import { PAGINATION_OPTIONS } from 'legacy/shared/v1/constants/pagination';
import { SORT_ORDER } from 'legacy/shared/v1/constants/table';
import { PaginationStyles, TableFilters } from 'legacy/shared/v1/styles/components/PageList';

// custom header cell component that wraps/overwrites default table header cell (see: Table -> components prop line 56)

const WcpTable = ({
  columns,
  tableSourceData = [],
  emptyText,
  defaultPageSize = PAGINATION_OPTIONS[2].value,
  defaultSortProperty = columns[0].sortProperty || columns[0].displayProperty,
  defaultSortOrder = SORT_ORDER.ASC,
  displaySearch = true,
  displayPaging = true,
  setListingCount,
}) => {
  if (!displayPaging) {
    defaultPageSize = tableSourceData?.length;
  }

  const state = useTableReducer({
    tableSourceData,
    searchEnabledColumns: columns
      .filter((column) => (column.searchEnabled === undefined ? true : column.searchEnabled))
      .map((column) => column.displayProperty),

    defaultPageSize,
    defaultSortProperty,
    defaultSortOrder,
  });

  let {
    currentSortProperty,
    currentSortOrder,
    currentPageSize,
    currentPageIndex,
    totalPageCount,
    searchResults,
    pagedResults,
    tableDispatch,
  } = state;

  const modifiedColumns = useModifyColumnProperties({
    columns,
    currentSortProperty,
    currentSortOrder,
  });

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    tableDispatch({
      type: tableActions.RESET_DISPLAY_DATA,
      payload: { tableSourceData, searchText },
    });
  }, [tableSourceData]);

  useEffect(() => {
    if (setListingCount) {
      setListingCount(searchResults?.length);
    }
  }, [searchResults?.length]);

  useEffect(() => {
    tableDispatch({
      type: tableActions.SEARCH,
      payload: { searchText },
    });
  }, [searchText]);

  const handlePageChange = (e) => {
    tableDispatch({
      type: tableActions.SELECT_PAGE,
      payload: { selectedPageIndex: e.selected },
    });
  };

  const handlePageSizeChange = (e) => {
    tableDispatch({
      type: tableActions.SELECT_PAGE_SIZE,
      payload: { pageSize: e.value },
    });
  };

  return (
    modifiedColumns && (
      <PaginationStyles>
        <TableFilters>
          {displaySearch ? (
            <Searchbox smallSearch={false} setSearchText={setSearchText} searchText={searchText} />
          ) : (
            <div className="spacer" />
          )}
          {displayPaging && (
            <PaginationControls
              currentPageIndex={currentPageIndex}
              totalPageCount={totalPageCount}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              currentPageSize={currentPageSize}
            />
          )}
        </TableFilters>
        <Table
          className="table-simple"
          columns={modifiedColumns}
          data={pagedResults}
          rowClassName={(_, i) => `row-${i}`}
          rowKey={() => uuid()}
          emptyText={searchText ? 'No records match the current search criteria' : emptyText}
          components={{
            header: {
              cell: (props) => (
                <WcpTableHeaderCell
                  currentSortOrder={currentSortOrder}
                  currentSortProperty={currentSortProperty}
                  tableDispatch={tableDispatch}
                  {...props}
                />
              ),
            },
          }}
        />
        <TableFilters>
          <div className="spacer" />
          {displayPaging && (
            <PaginationControls
              currentPageIndex={currentPageIndex}
              totalPageCount={totalPageCount}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              currentPageSize={currentPageSize}
            />
          )}
        </TableFilters>
      </PaginationStyles>
    )
  );
};

WcpTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      displayProperty: PropTypes.string,
      sortProperty: PropTypes.string,
      searchEnabled: PropTypes.bool,
    }),
  ).isRequired,
  tableSourceData: PropTypes.array,
  emptyText: PropTypes.string,
  defaultPageSize: PropTypes.number,
  defaultSortProperty: PropTypes.string,
  defaultSortOrder: PropTypes.oneOf([SORT_ORDER.ASC, SORT_ORDER.DESC]),
  displaySearch: PropTypes.bool,
  displayPaging: PropTypes.bool,
  setListingCount: PropTypes.func,
};

export default WcpTable;
